export default {
  data() {
    return {
      stayrateTypeConst: [
        {label: this.$t('stayrate.city'), value: 'CITY'},
        {label: this.$t('stayrate.regional'), value: 'REGIONAL'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForStayrateTypeConst(value) {
      let result = this.stayrateTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    displayForStayrateTypeConstWithoutUndefined() {
      return this.stayrateTypeConst.filter((el) => el.value !== undefined);
    },
  },
};
