<script>
import {Icon} from '@iconify/vue';
import DropdownMenu from 'v-dropdown-menu';
import {debounce} from '@utils';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import $ from 'jquery';
import DropdownMenuAk from '@components/v2/general/DropdownMenuAk.vue';

export default {
  components: {AkAutocomplete, DropdownMenuAk},
  inject: {
    pSubmitted: {default: null},
    pDisabled: {default: null},
  },
  props: {
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    itemDisplay: {
      type: String,
      required: false,
      default: 'name',
    },
    itemValue: {
      type: String,
      required: false,
      default: 'id',
    },
    searchMethod: {
      type: Function,
      required: true,
    },
    initMethod: {
      type: Function,
      required: false,
      default: undefined,
    },
    searchLabel: {
      type: String,
      required: false,
      default: 'query',
    },
    searchObject: {
      type: Object,
      required: false,
      default(rawProps) {
        return {};
      },
    },
    direction: {
      type: String,
      required: false,
      default: 'center',
      validator(value) {
        return ['center', 'left', 'right'].includes(value);
      },
    },
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      itemSelected: this.defaultItem,
      changed: false,
      isLoading: false,
    };
  },
  emits: ['update:modelValue', 'search'],
  mounted() {},
  watch: {
    currentValue: function (newVal, oldVal) {
      if (newVal && this.initMethod) {
        this.isLoading = true;
        this.initMethod(newVal)
          .then((data) => {
            this.itemSelected = data;
            this.$refs.autocomplete.init(data);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (newVal === undefined || newVal === null) {
        if (newVal != oldVal) {
          this.changed = true;
          this.$refs.autocomplete.reset();
        }
        this.itemSelected = undefined;
      }
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    labelDisplay() {
      if (this.itemSelected) {
        return this.itemSelected[this.itemDisplay];
      }
      return this.label;
    },
    hasValue() {
      return this.itemSelected !== undefined;
    },
  },
  methods: {
    initObject(item) {
      this.itemSelected = item;
      this.currentValue = this.itemSelected[this.itemValue];
      this.isLoading = false;
    },
    itemSelect(item) {
      if (item) {
        this.itemSelected = item;
        this.currentValue = this.itemSelected[this.itemValue];
        this.changed = true;
        this.$refs.dropdown.hide();
      }
    },
    launchSearch() {
      if (this.changed) {
        this.$emit('search');
      }
    },
    reset() {
      this.changed = true;
      this.itemSelected = undefined;
      this.currentValue = undefined;
      this.$refs.autocomplete.reset();
      this.launchSearch();
    },
    scrollToTop() {
      this.changed = false;
      this.$nextTick(() => {
        if (this.$refs.scroll) this.$refs.scroll.$el.scrollTop = 0;
        if (!this.$isMobile()) $(this.$refs.autocomplete.$refs.autocomplete.$refs.focusInput).focus();
      });
    },
  },
};
</script>

<template>
  <dropdown-menu-ak
    @closed="launchSearch"
    @opened="scrollToTop"
    overlay
    ref="dropdown"
    class="dropdown-filter dropdown-xl"
    :closeOnClickOutside="true"
    :direction="direction"
    mode="click">
    <template #trigger>
      <div
        class="flex items-center gap-1 py-2.5 px-1.5 hover:cursor-pointer hover:text-primary"
        :class="[hasValue ? 'text-primary' : 'text-gray']">
        <span class="flex-1 font-inter font-medium text-sm" :title="labelDisplay">{{ labelDisplay }}</span>
        <i class="ga-icon ga-chevron_down icon-rotate" v-if="!this.isLoading" />
        <span class="spinner primary xs" v-else />
      </div>
    </template>
    <template #body>
      <AkAutocomplete
        ref="autocomplete"
        v-model="currentValue"
        :inline="false"
        :label="label"
        class="pb-2"
        :option-label="this.itemDisplay"
        :option-value="this.itemValue"
        :init-method="this.initMethod"
        :search-method="this.searchMethod"
        :search-object="this.searchObject"
        :force-selection="false"
        :search-label="this.searchLabel"
        @itemSelect="itemSelect"
        @init="initObject">
        <template #item="slotProps">
          <div>{{ slotProps.item[this.itemDisplay] }}</div>
        </template>
      </AkAutocomplete>
      <hr />
      <div
        class="flex gap-2 items-center text-gray justify-center pt-4 hover:text-primary hover:cursor-pointer"
        @click="reset">
        <i class="ga-icon ga-refresh text-3xl" />
        <span class="font-medium text-base">Réinitialiser</span>
      </div>
    </template>
  </dropdown-menu-ak>
</template>
