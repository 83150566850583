import stayRateService from '@services/stayRateService';

export default {
  data() {
    return {
      stayrates: [],
    };
  },
  mounted() {
    stayRateService.stayratesStorage().then((data) => (this.stayrates = data));
  },
  computed: {
    loadingStayrates() {
      return this.stayrates === undefined || this.stayrates.length === 0;
    },
  },
  methods: {
    displayForStayrate(id) {
      let result = this.stayrates.find((stayrate) => stayrate.id === id);
      return result ? result.name : '';
    },
  },
};
