<script>
/* COMPOSANTS */
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import GaView from '@components/v2/layout/GaView.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

import filterListMixin from '@mixins/filterListMixin';
import companyMixin from '@mixins/companyMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';

/* SERVICES */
import missionWaitingOrderService from '@services/missionWaitingOrderService';
import {useTabStore} from '../../../store/tabStore';

export default {
  components: {
    AkDatatablePagination,
    GaView,
    GaListView,
    AkListFilter,
    AkAllFilter,
    AkAutocompleteFilter,
  },
  mixins: [
    permissionsMixin,
    utilsMixin,
    sidebarMixin,
    filterListMixin,
    companyMixin,
    ownerAutocompleteMixin,
    reservationAutocompleteMixin,
  ],
  metaInfo() {
    return {
      title: 'waiting_orders.title',
    };
  },
  data() {
    return {
      list: [],
      loading: false,
      sortTable: [{field: 'date_checkin', order: 1}],
      filter: {
        page: 0,
        limit: 50,
        sort: ['date_checkin:ASC'],
        dateStartBetweenStart: new Date(),
        dateStartBetweenEnd: undefined,
      },
      textSearch: '',
      chosenReservation: null,
      totalItems: 0,
      activeTab: undefined,
    };
  },
  mounted() {
    // Par défaut : filtrer sur une période de 12 mois
    this.activeTab = useTabStore().getTabActive('MissionWaitingOrderList', 0);
  },
  computed: {
    first() {
      return (this.filter.page - 1) * this.filter.limit;
    },
    getEndingDateFromTab() {
      let date;
      switch (this.activeTab) {
        case 1:
          // 30 jours (ou 1 mois)
          date = this.addMonths(this.filter.dateStartBetweenStart, 1);
          break;
        case 2:
          // 7 jours
          date = this.addDays(this.filter.dateStartBetweenStart, 7);
          break;
        case 3:
          // 24 heures
          date = this.addDays(this.filter.dateStartBetweenStart, 1);
          break;
        default:
          // 12 mois
          date = this.addYears(this.filter.dateStartBetweenStart, 1);
          break;
      }
      return date;
    },
  },
  methods: {
    // FILTER

    openFilterPanel() {
      this.toggleFilterTitle(
        'GaWaitingOrdersFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        dateStartBetweenStart: new Date(),
        sort: ['date_checkin:ASC'],
      };
      this.sortTable = [{field: 'date_checkin', order: 1}];
      this.filter.dateStartBetweenEnd = this.getEndingDateFromTab;
      this.toggleFilterTitle(
        'GaWaitingOrdersFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },

    search() {
      this.loading = true;

      missionWaitingOrderService
        .pagination(this.filter)
        .then((data) => {
          this.totalItems = data.totalItems;
          this.list = data.data;
        })
        .finally(() => (this.loading = false));
    },

    clickNew() {
      this.$router.push({
        name: 'ordersCreate',
        params: {accommodationId: 0},
      });
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
      useTabStore().setTabActive('MissionWaitingOrderList', tab);
      this.filter.page = 1;
      this.filter.dateStartBetweenEnd = this.getEndingDateFromTab;
      this.search();
    },
    // SIDEBAR
    rowClick(event) {
      let data = event.data;
      this.toggleSidebar('GaMissionWaitingOrdersSidebar', data.accommodationName, data.id);
    },
    displayPeriodForReservation(data) {
      return this.displayDatesSeparator(data.dateCheckin, data.dateCheckout, false);
    },
  },
};
</script>

<template>
  <GaView>
    <template #action>
      <div class="flex justify-end gap-4">
        <button class="btn-primary-gradient xs" @click="clickNew">
          {{ this.$t('waiting_orders.new') }}
        </button>
      </div>
    </template>
    <template #content>
      <GaListView searchable @search="searchQuery">
        <template #tabs>
          <div class="flex items-center">
            <span class="list-table-item" @click="this.changeActiveTab(0)" :class="[{active: this.activeTab === 0}]">
              {{ this.$t('waiting_orders.tabs.filter_12_months') }}
            </span>
            <span class="list-table-item" @click="this.changeActiveTab(1)" :class="[{active: this.activeTab === 1}]">
              {{ this.$t('waiting_orders.tabs.filter_30_days') }}
            </span>
            <span class="list-table-item" @click="this.changeActiveTab(2)" :class="[{active: this.activeTab === 2}]">
              {{ this.$t('waiting_orders.tabs.filter_7_days') }}
            </span>
            <span class="list-table-item" @click="this.changeActiveTab(3)" :class="[{active: this.activeTab === 3}]">
              {{ this.$t('waiting_orders.tabs.filter_24_hours') }}
            </span>
          </div>
        </template>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.clickSearch"
            :label="$t('role.owner')"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAutocompleteFilter
            v-model="this.filter.reservationId"
            @search="this.clickSearch"
            label="Réservation"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteReservationById"
            :search-method="this.autocompleteReservation" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :value="list"
            lazy
            :filter="this.filter"
            @search="search"
            :loading="loading"
            v-model:multiSortMeta="this.sortTable"
            :totalRecords="totalItems"
            @rowClick="rowClick">
            <template #empty>{{ $t('waiting_orders.empty') }}</template>
            <Column :header="$t('company.company')" v-if="this.isGas()" style="width: 200px; min-width: 200px">
              <template #body="slotProps">
                {{ slotProps.data.companyDisplay }}
              </template>
            </Column>

            <Column
              :header="$t('accommodation_title')"
              style="width: 300px; min-width: 300px"
              sortable
              sort-field="j_accommodation_name">
              <template #body="slotProps">
                <router-link
                  :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.accommodationId}}">
                  {{ slotProps.data.accommodationName }}
                </router-link>
              </template>
            </Column>

            <Column style="min-width: 200px; width: 300px" :header="$t('accommodation.ownerName')" :sortable="false">
              <template #body="slotProps">
                <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.ownerId}}">
                  {{ slotProps.data.ownerDisplay }}
                </router-link>
              </template>
            </Column>

            <Column
              :header="$t('reservation.one')"
              style="width: 200px; min-width: 200px"
              sortable
              sort-field="localizer">
              <template #body="slotProps">
                <router-link
                  :to="{
                    name: 'reservationPage',
                    params: {
                      reservationId: slotProps.data.id,
                    },
                  }">
                  {{ slotProps.data.localizer }}
                </router-link>
              </template>
            </Column>

            <Column
              :header="$t('date_created')"
              style="width: 150px; min-width: 150px"
              sortable
              sort-field="date_created">
              <template #body="slotProps">
                {{ slotProps.data.dateCreated ? new Date(slotProps.data.dateCreated).toLocaleDateString() : '' }}
              </template>
            </Column>

            <Column
              :header="$t('reservation.period')"
              style="width: 100px; min-width: 100px"
              sortable
              sort-field="date_checkin">
              <template #body="slotProps">
                {{ displayPeriodForReservation(slotProps.data) }}
              </template>
            </Column>

            <Column :header="$t('client.one')" style="min-width: 200px; width: 200px">
              <template #body="slotProps">
                <router-link
                  :to="{
                    name: 'clientPage',
                    params: {
                      clientId: slotProps.data.guestId,
                    },
                  }"
                  v-if="slotProps.data.guestId">
                  {{ slotProps.data.guestDisplay }}
                </router-link>
                <div v-else>
                  {{ slotProps.data.guestDisplay }}
                </div>
                <div>
                  {{ slotProps.data.guestPhone }}
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
