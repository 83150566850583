<script>
/* COMPOSANTS */
import GaContentView from '@components/v2/layout/GaContentView';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import cdcmodelTypesConst from '@mixins/const/cdcmodelTypesConst';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import cdcService from '@services/cdcService';
import zoneService from '@services/zoneService';
import taskService from '@services/taskService';
import AkLoader from '@components/general/AkLoader.vue';

export default {
  props: {
    cdcId: {
      required: true,
    },
    type: {
      required: true,
    },
  },
  data() {
    return {
      cdc: {
        id: null,
        type: null,
        duration: null,
      },
      zones: undefined,
      tasks: undefined,
    };
  },
  components: {
    AkLoader,
    GaContentView,
  },
  mixins: [cdcmodelTypesConst, loaderMixin, messageMixin, randomRef, utilsMixin],
  computed: {
    listZones() {
      if (!this.zones) return null;
      return this.zones.filter((elem) => elem.type === 'ZONE');
    },
    listSubzones() {
      if (!this.zones) return null;
      return this.zones.filter((elem) => elem.type === 'SUBZONE');
    },
  },
  mounted() {
    // Anomalie, le cdc n'existe plus
    if (!this.cdcId) {
      this.hideLoader();
      return;
    }
    this.$refs.loader.load();

    let p1 = cdcService.cdc(this.cdcId).then((data) => {
      this.cdc = data;
    });

    let p2 = zoneService.zones().then((data) => {
      this.zones = data;
    });

    let p3 = taskService.tasks().then((data) => {
      this.tasks = data;
    });

    Promise.all([p1, p2, p3]).finally(() => {
      this.$refs.loader.hide();
    });
  },
  methods: {
    getZoneValue(zoneId) {
      const zone = this.listZones?.find(({id}) => id === zoneId);
      return zone ? zone.label : '';
    },
    getSubZoneValue(subzoneId) {
      const subZone = this.listSubzones?.find(({id}) => id === subzoneId);
      return subZone ? subZone.label : '';
    },
    getTaskValue(taskId) {
      const task = this.tasks?.find(({id}) => id === taskId);
      return task ? task.label : '';
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref">
    <template #content>
      <div class="mission-order-cdc">
        <div class="section" v-if="this.cdcId">
          <span class="title-border">{{ displayForCdcmodelTypeConst(type) }}</span>

          <AkLoader ref="loader" color="var(--color-primary)" :scale="1" message="Chargement du CDC en cours">
            <template #content>
              <div class="flex flex-col gap-1 mt-4">
                <span class="font-inter font-semibold text-dark text-base">Durée </span>
                <span class="font-inter font-medium text-gray text-md"> {{ cdc.duration }} minutes </span>
              </div>
              <div v-for="(item, index) in cdc.itemList" :key="index" class="flex flex-col gap-2 mt-4 mb-4">
                <div class="flex-1">
                  <hr />
                </div>
                <!-- Zone et sous-zone -->
                <div class="grid grid-cols-2 gap-2">
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">{{ $t('cdcmodelitem.zone') }} </span>
                    <span class="font-inter font-medium text-gray text-md">
                      {{ getZoneValue(item.zoneId) }}
                    </span>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">{{ $t('cdcmodelitem.subzone') }} </span>
                    <span class="font-inter font-medium text-gray text-md">
                      {{ getSubZoneValue(item.subzoneId) }}
                    </span>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Tâches </span>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Commentaires </span>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-2" v-for="(task, taskIdx) in item.taskList" :key="taskIdx">
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-medium text-gray text-md">{{ getTaskValue(task.taskId) }}</span>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-medium text-gray text-md"> {{ task.comment }}</span>
                  </div>
                </div>
                <!-- Valeurs tâches et commentaire -->
              </div>
            </template>
          </AkLoader>
        </div>
        <div class="section" v-else>
          <!-- Bloc d'anomalie (le cdc n'existe plus) -->
          <h5>{{ $t('error.error') }}</h5>
          <div class="item">
            <span class="title">{{ $t('error.CDC_NOT_FOUND') }}</span>
          </div>
        </div>
      </div>
    </template>
  </GaContentView>
</template>
