export default {
  data() {
    return {
      prospectOfferConst: [
        {label: 'Premium', value: 'ALL_INCLUSIVE'},
        {label: 'Essentielle', value: 'ONLINE'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForProspectOfferConst(value) {
      let result = this.prospectOfferConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
