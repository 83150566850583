export default {
  data() {
    return {
      ownerTypesConst: [
        {label: this.$t('owner.ownerType_individu'), value: 'INDIVIDU'},
        {label: this.$t('owner.ownerType_personneMorale'), value: 'PERSONNE_MORALE'},
        {label: this.$t('owner.ownerType_indivision'), value: 'INDIVISION'},
      ],
      ownerTypesConstFilter: [
        {label: this.$t('owner.ownerType_individu'), value: 'INDIVIDU'},
        {label: this.$t('owner.ownerType_personneMorale'), value: 'PERSONNE_MORALE'},
        {label: this.$t('owner.ownerType_indivision'), value: 'INDIVISION'},
      ],
    };
  },
  methods: {
    displayForOwnerTypeConst(value) {
      let result = this.ownerTypesConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
  computed: {
    ownerTypesConstAlphabetical() {
      return this.ownerTypesConst.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    },
  },
};
