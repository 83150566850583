import {defineStore} from 'pinia';

export const useBack = defineStore('back', {
  state: () => ({hasBack: false}),
  getters: {
    back: (state) => state.hasBack,
  },
  actions: {
    setBack(back) {
      this.hasBack = back;
    },
  },
});
