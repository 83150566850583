<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkInputMoney from '@components/v2/input/AkInputMoney.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import litigeService from '@services/litigeService';

export default {
  components: {
    AkSidebar,
    GaMessages,
    AkCalendar,
    AkInputMoney,
    AkButtonFooterSidebar,
  },
  mixins: [utilsMixin, messageMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      sidebarVisible: false,
      litigeUpdate: {},
      submitted: false,
    };
  },
  validations() {
    return {
      litigeUpdate: {
        dateDecision: {required},
        amountTtc: {required},
        amountGas: {required},
        amountGav: {required},
        amountOwner: {required},
      },
    };
  },
  emits: ['litige-updated'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    // SIDEBAR

    show(litige) {
      this.sidebarVisible = true;
      this.initLitige(litige);
    },
    initLitige(litige) {
      if (!litige) return;
      this.litigeUpdate = {...litige};
      this.litigeUpdate.amountGas = 0;
      this.litigeUpdate.amountGav = 0;
      this.litigeUpdate.amountOwner = 0;
    },
    hide() {
      this.submitted = false;
      this.litigeUpdate = undefined;
      this.sidebarVisible = false;
    },

    // PROCESS

    process() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.litigeUpdate.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      let total = this.addNumbers([
        this.litigeUpdate.amountGas,
        this.litigeUpdate.amountGav,
        this.litigeUpdate.amountOwner,
      ]);

      if (total !== this.litigeUpdate.amountTtc) {
        this.$refs.sidebarMessages.error(this.$t('litige.error_total_amount'));
        this.$refs.footerSidebar.init();
        return;
      }

      this.litigeUpdate.resolution = 'GAGNE';

      litigeService
        .stepResolve(this.litigeUpdate)
        .then((data) => {
          this.addSuccess(this.$t('litige.resolved'));
          this.$emit('litige-updated');
          this.hide();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.$refs.footerSidebar.init();
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <AkSidebar v-model:visible="sidebarVisible" :baseZIndex="9998" dismissable @hide="hide" block-scroll position="right">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('litige.resolve_title') + ' (' + $t('litige.gagne') + ')' }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4" :options="{suppressScrollX: true}">
          <div class="grid grid-cols-1 gap-2">
            <AkCalendar
              v-model="litigeUpdate.dateDecision"
              :validator="v$.litigeUpdate.dateDecision"
              :label="$t('litige.date_decision')"
              :maxDate="new Date()"
              :submitted="submitted" />
          </div>
          <div class="grid md:grid-cols-2 grid-cols-1 gap-2">
            <AkInputMoney
              v-model="litigeUpdate.amountEstimatedTtc"
              :label="$t('reservation.amountLitigeEstimated')"
              :disabled="true" />
            <AkInputMoney
              v-model="litigeUpdate.amountTtc"
              :label="$t('reservation.amountLitigeReported')"
              :validator="v$.litigeUpdate.amountTtc"
              :submitted="submitted" />
          </div>
          <div class="grid md:grid-cols-3 grid-cols-1 gap-2">
            <AkInputMoney
              v-model="litigeUpdate.amountGas"
              :label="$t('reservation.amountLitigeGas')"
              :validator="v$.litigeUpdate.amountGas"
              :max="litigeUpdate.amountTtc"
              :submitted="submitted" />
            <AkInputMoney
              v-model="litigeUpdate.amountGav"
              :label="$t('reservation.amountLitigeGav')"
              :validator="v$.litigeUpdate.amountGav"
              :max="litigeUpdate.amountTtc"
              :submitted="submitted" />
            <AkInputMoney
              v-model="litigeUpdate.amountOwner"
              :label="$t('reservation.amountLitigeOwner')"
              :validator="v$.litigeUpdate.amountOwner"
              :max="litigeUpdate.amountTtc"
              :submitted="submitted" />
          </div>

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancelLabel="$t('cancel')"
            :validateLabel="$t('litige.resolve')"
            @action="process"
            @cancel="hide()" />
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
