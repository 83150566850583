<script>
import {required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkInputMoney from '@components/input/AkInputMoney.vue';
import AkInputText from '@components/input/AkInputText.vue';
import AkAccountAutocomplete from '@components/general/AkAccountAutocomplete.vue';

/* SERVICES */

export default {
  components: {
    AkAccountAutocomplete,
    AkInputMoney,
    AkInputText,
  },
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    index: {
      type: Number,
      required: true,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['add', 'delete', 'change'],
  data() {
    return {
      freeOd: {},
      displayDeleteDialog: false,
      firstLine: true,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorMinDate() {
      return (
        this.validator !== undefined &&
        this.validator.minDate !== undefined &&
        this.validator.minDate.$invalid &&
        this.alreadySubmitted
      );
    },
    isValidatorMaxDate() {
      return (
        this.validator !== undefined &&
        this.validator.maxDate !== undefined &&
        this.validator.maxDate.$invalid &&
        this.alreadySubmitted
      );
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  methods: {
    addNewLine() {
      this.$emit('add', this.index);
    },
    confirmDelete() {
      this.displayDeleteDialog = true;
    },
    performDelete() {
      this.displayDeleteDialog = false;
      this.$emit('delete', this.index);
    },
    changeDate() {
      this.$emit('change', this.index);
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      currentValue: {
        accountNum: {required},
        label: {required},
      },
    };
  },
};
</script>

<template>
  <span id="baseSpan">
    <div class="form-inline" style="column-gap: 15px">
      <!--<AkCalendar
           v-model="currentValue.date"
           :placeholder="$t('operation.date')"
           :inline="true"
           :display-label="false"
           class-name="flex-1"
           @update:model-value="changeDate"
       >
       </AkCalendar>-->
      <AkAccountAutocomplete
        v-model="currentValue.accountNum"
        :placeholder="$t('operation.number')"
        option-label="num"
        option-value="num"
        :display-value="currentValue.accountNum"
        :display-label="false"
        :inline="true"
        class-name="flex-1"
        :validator="v$.currentValue.accountNum" />
      <AkInputText
        v-model="currentValue.label"
        :label="$t('operation.label')"
        :display-label="false"
        :inline="true"
        class-name="flex-1"
        :validator="v$.currentValue.label" />
      <AkInputMoney
        v-model="currentValue.debit"
        :placeholder="$t('accounting.debit')"
        :inline="true"
        class-name="flex-1"
        :display-label="false" />
      <AkInputMoney
        v-model="currentValue.credit"
        :placeholder="$t('accounting.credit')"
        class-name="flex-1"
        :inline="true"
        :display-label="false" />
      <div class="d-flex flex-1">
        <a class="btn btn-inverse-secondary btn-xs" @click="addNewLine" :title="$t('operation.add_line')">
          <i class="fe fe-plus pr-1" /> {{ $t('operation.add_line') }}
        </a>
        <span
          v-if="canDelete"
          class="btn btn-inverse-danger btn-xs ml-1"
          @click.prevent="confirmDelete()"
          :title="$t('operation.delete_line')">
          <i class="fe fe-trash" />
        </span>
      </div>
    </div>
  </span>

  <PDialog v-model:visible="displayDeleteDialog" :header="$t('delete_dialog')" :modal="true" :style="{width: '450px'}">
    <div class="confirmation-content">
      <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
      <span>{{ $t('operation.delete_line_warning') }}</span>
    </div>
    <template #footer>
      <div class="d-flex justify-content-end">
        <span class="btn btn-outline-primary btn-xs mr-1" @click="displayDeleteDialog = false">{{
          this.$t('no')
        }}</span>
        <span class="btn btn-inverse-danger btn-xs" @click="performDelete">{{ this.$t('yes') }}</span>
      </div>
    </template>
  </PDialog>
</template>
