import {h} from 'vue';

export default {
  setup() {
    return {loader: undefined};
  },
  methods: {
    showLoader(ref, canCancel = false, backgroundColor = 'var(--color-dark-background)', color = 'var(--color-dark)') {
      this.loader = this.$loading.show({
        container: ref,
        canCancel: canCancel,
        backgroundColor: backgroundColor,
        color: color,
        isFullPage: false,
      });
    },
    showTotalLoader(
      canCancel = false,
      backgroundColor = 'var(--color-dark-background)',
      color = 'var(--color-dark)',
      after = '',
    ) {
      this.loader = this.$loading.show(
        {
          canCancel: canCancel,
          backgroundColor: backgroundColor,
          color: color,
          isFullPage: true,
          opacity: 1,
        },
        {
          // Pass slots by their names
          after: after !== '' ? h('span', {class: 'loader-message'}, after) : undefined,
        },
      );
    },
    showTotalLoaderGuestadom(
      canCancel = false,
      backgroundColor = 'var(--color-white)',
      color = 'var(--color-orange-status)',
      after = '',
    ) {
      this.loader = this.$loading.show(
        {
          canCancel: canCancel,
          backgroundColor: backgroundColor,
          color: color,
          isFullPage: true,
          opacity: 1,
        },
        {
          // Pass slots by their names
          before: h('span', {class: 'guestadom-logo-loader'}),
          after: after !== '' ? h('span', {class: 'loader-message'}, after) : undefined,
        },
      );
    },
    showTotalLoaderWithAfter(
      after,
      canCancel = false,
      backgroundColor = 'var(--color-dark-background)',
      color = 'var(--color-dark)',
    ) {
      this.loader = this.$loading.show(
        {
          canCancel: canCancel,
          backgroundColor: backgroundColor,
          color: color,
          isFullPage: true,
        },
        {
          after: after !== '' ? () => h('span', {class: 'loader-message'}, after) : undefined,
        },
      );
    },
    hideLoader() {
      if (this.loader) {
        this.loader.hide();
        this.loader = undefined;
      }
    },
  },
  unmounted() {
    if (this.loader) {
      this.loader.hide();
      this.loader = undefined;
    }
  },
};
