import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  planning(filter) {
    return Api()
      .get('/mission/planning/participants', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  participant(participantId, filter) {
    return Api()
      .get('/mission/planning/participant/' + participantId, {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  pending(filter) {
    return Api()
      .get('/mission/planning/participant/pending', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  assign(mission) {
    return Api()
      .put('/mission/planning/participant/assign', mission)
      .then((res) => {
        return res.data;
      });
  },
  formatRes(e) {
    if (e.dateCheckin) e.dateCheckin = new Date(e.dateCheckin);
    if (e.dateCreated) e.dateCreated = new Date(e.dateCreated);
  },
};
