<script>
/* COMPOSANTS */
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import GaView from '@components/v2/layout/GaView.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import MaintenanceCreateSidebar from '@views/maintenance/sidebar/MaintenanceCreateSidebar.vue';
import MaintenanceCard from '@views/maintenance/MaintenanceCard.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import maintenanceInterlocutorTypeConst from '@mixins/const/maintenanceInterlocutorTypeConst';
import maintenanceStatusConst from '@mixins/const/maintenanceStatusConst';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';

/* SERVICES */
import maintenanceService from '@services/maintenanceService';

export default {
  components: {
    AkAutocompleteFilter,
    AkListFilter,
    AkAllFilter,
    AkButtonAction,
    GaView,
    GaListView,
    MaintenanceCreateSidebar,
    MaintenanceCard,
  },
  mixins: [
    randomRef,
    utilsMixin,
    messageMixin,
    permissionsMixin,
    sidebarMixin,
    loaderMixin,
    maintenanceInterlocutorTypeConst,
    maintenanceStatusConst,
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
    companyMixin,
  ],
  metaInfo() {
    return {
      title: this.$i18n.t('maintenance.list') + ' (' + this.countMaintenance + ')',
    };
  },
  data() {
    return {
      loading: false,
      filter: {
        sort: ['id:DESC'],
      },
      list: [],
      maintenanceCreate: {
        interlocutorTypes: [],
      },
      accommodations: [],
      companies: [],
      files: [],
      submitted: false,
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    statusAResoudre() {
      return this.list.filter((item) => item.status === 'A_RESOUDRE');
    },
    statusEnCours() {
      return this.list
        .filter((item) => ['A_TRAITER', 'EN_ATTENTE', 'DECISION_PRISE', 'EN_COURS'].includes(item.status))
        .sort((a, b) => a.status.localeCompare(b.status));
    },
    statusResolue() {
      return this.list
        .filter((item) => item.status === 'RESOLUE')
        .sort(function (a, b) {
          return new Date(b.resolutionDate) - new Date(a.resolutionDate);
        });
    },
    countMaintenance() {
      return this.statusAResoudre.length + this.statusEnCours.length;
    },
  },
  methods: {
    // SEARCH

    search() {
      this.$nextTick(() => {
        this.showTotalLoaderWithAfter('Récupération des maintenances en cours');
      });

      maintenanceService
        .maintenances(this.filter)
        .then((data) => {
          this.list = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilterTitle(
        'GaMaintenanceListFilter',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilterTitle(
        'GaMaintenanceListFilter',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },

    // EXPORT

    exportMaintenances() {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      maintenanceService.exportMaintenances(this.filter).then((data) => {
        this.hideLoader();
      });
    },

    // SIDEBAR

    toggleCreateSidebar() {
      this.$nextTick(() => {
        this.$refs.maintenanceCreateSidebar.hide();
        this.search();
      });
    },

    openCreateSidebar() {
      if (this.$refs.maintenanceCreateSidebar) this.$refs.maintenanceCreateSidebar.show();
    },
  },
};
</script>

<template>
  <GaView>
    <template #action>
      <AkButtonAction :label="$t('payment.download')" little secondary @click="exportMaintenances" />
      <AkButtonAction v-if="!isOwner()" :label="$t('maintenance.create')" little @click="openCreateSidebar()" />
    </template>
    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="isGas()"
            v-model="filter.companyIds"
            :display-filter="true"
            :items="companies"
            :label="$t('employee.company')"
            :multi-selection="true"
            direction="right"
            @search="search" />
          <AkAutocompleteFilter
            v-if="!isOwner()"
            v-model="filter.ownerId"
            :init-method="autocompleteOwnerById"
            :label="$t('role.owner')"
            :search-label="autocompleteOwnerQuery"
            :search-method="autocompleteOwner"
            direction="right"
            item-display="display"
            item-value="id"
            @search="search" />
          <AkAutocompleteFilter
            v-model="filter.accommodationId"
            :init-method="autocompleteAccommodationById"
            :label="$t('housekeeper.accommodation')"
            :search-label="autocompleteAccommodationQuery"
            :search-method="autocompleteAccommodation"
            direction="right"
            item-display="display"
            item-value="id"
            @search="search" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <div class="flex md:flex-row flex-col w-full gap-3">
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min" v-if="!this.isOwner()">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden"
                    @click="this.toggleElement(this.$refs, 'newList', $event)" />
                  <span class="text-dark font-semibold text-base uppercase">Nouveau</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ this.statusAResoudre.length }}</span>
              </div>
              <div ref="newList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.statusAResoudre.length > 0">
                  <MaintenanceCard :maintenance="item" v-for="item of this.statusAResoudre" :key="item.id" />
                </perfect-scrollbar>
              </div>
            </div>
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden"
                    @click="this.toggleElement(this.$refs, 'progressList', $event)" />
                  <span class="text-dark font-semibold text-base uppercase">En cours</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ this.statusEnCours.length }}</span>
              </div>
              <div ref="progressList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.statusEnCours.length > 0">
                  <MaintenanceCard :maintenance="item" v-for="item of this.statusEnCours" :key="item.id" />
                </perfect-scrollbar>
              </div>
            </div>
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden"
                    @click="this.toggleElement(this.$refs, 'closedList', $event)" />
                  <span class="text-dark font-semibold text-base uppercase">Terminée</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ this.statusResolue.length }}</span>
              </div>
              <div ref="closedList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.statusResolue.length > 0">
                  <MaintenanceCard :maintenance="item" v-for="item of this.statusResolue" :key="item.id" />
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </template>
      </GaListView>
    </template>
    <template #extra>
      <MaintenanceCreateSidebar ref="maintenanceCreateSidebar" @creation="toggleCreateSidebar" />
    </template>
  </GaView>
</template>
