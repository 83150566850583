<script>
/* COMPOSANTS */

/* MIXINS */

/* SERVICES */

export default {
  components: {},
  props: {
    modelValue: {required: true},
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <div v-if="currentValue" class="flex flex-col gap-4">
    <span class="title-border" v-if="currentValue.label">{{ currentValue.label }}</span>
    <div class="text-dark whitespace-pre-wrap">
      {{ currentValue.comment }}
    </div>
  </div>
</template>
