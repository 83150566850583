import housekeeperService from '@services/housekeeperService';

export default {
  methods: {
    autocompleteHousekeeper(e) {
      return housekeeperService.autocomplete(e);
    },
    autocompleteHousekeeperById(e) {
      return housekeeperService.housekeeperDisplay(e);
    },
  },
};
