<script>
import DropdownMenu from 'v-dropdown-menu';
import $ from 'jquery';

export default {
  extends: DropdownMenu,
  setup(props, ctx) {
    return {
      ...DropdownMenu.setup(props, ctx),
      // local bindings
    };
  },
  data() {
    return {
      overlayAk: true,
    };
  },
  props: {
    closeOnClickOutsideAk: {
      type: Boolean,
      required: false,
      default: false,
    },
    classInside: {
      type: Array,
      required: false,
      default: () => [],
    },
    blockScroll: {
      type: Boolean,
      required: false,
      default: true,
    },
    overlay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.overlayAk = this.$isMobile();
      if (this.closeOnClickOutsideAk) {
        this.registerCloseDropdownOnClickOutsideAk();
      }
    });
  },
  updated() {
    if (this.$isMobile()) {
      $(this.rootRef).addClass('dropdown__mobile');
    }
  },
  beforeUnmount() {
    this.destroyCloseDropdownOnClickOutsideAk();
  },
  watch: {
    'menu.isOpen': function (newValue) {
      if (newValue) {
        if (this.overlayAk || this.overlay) {
          $('<div>', {
            id: 'dropdown-overlay',
            class: 'v-dropdown-menu__overlay v-dropdown-menu__overlay_ak',
          }).appendTo($(this.rootRef));
          let that = this;
          $('.v-dropdown-menu__overlay_ak').on('click', this.hide);
        }
        if (this.blockScroll) {
          $('body').addClass('overflow-hidden');
        }
      } else {
        $('.v-dropdown-menu__overlay').remove();
        $('.v-dropdown-menu__overlay_ak').remove();
        if (this.blockScroll) {
          $('body').removeClass('overflow-hidden');
        }
      }
    },
  },
  methods: {
    registerCloseDropdownOnClickOutsideAk() {
      window.addEventListener('click', this.closeDropdownOnClickOutsideAk);
    },
    closeDropdownOnClickOutsideAk(e) {
      // eslint-disable-next-line no-undef
      if (this.menu.isOpen) {
        let isInside = $(this.rootRef).html().includes($(e.target).html());
        let hasClassInside = false;
        for (let c of this.classInside) {
          hasClassInside = $(e.target).hasClass(c);
          if (hasClassInside) break;
        }
        if ((!isInside && !hasClassInside) || (isInside && this.classInside.length === 0)) {
          this.menu.isOpen = false;
        }
      }
    },
    destroyCloseDropdownOnClickOutsideAk() {
      if (this.closeOnClickOutside) {
        window.removeEventListener('click', this.closeDropdownOnClickOutsideAk);
      }
    },
  },
};
</script>
