import {defineStore} from 'pinia';
import Api from '@services/api';
import {removeEmptyParams} from '@utils';

export const useCompanyStore = defineStore('company', {
  state: () => ({companies: undefined}),
  actions: {
    async all() {
      if (this.isFilled()) return this.companies;
      let companies = await Api(true, false)
        .get('/companies', {params: removeEmptyParams({})})
        .then((res) => {
          return res.data;
        });
      this.$patch({
        companies: companies,
      });
      return this.companies;
    },
    clear() {
      this.$patch({
        companies: undefined,
      });
    },
    isFilled() {
      return this.companies !== undefined;
    },
  },
});
