import Api from '@/services/api';
import pictureApi from '@/services/pictureUserApi';

import {
  createFormDataFic,
  createFormDataFicForUser,
  createFormDataFile,
  createFormDataFiles,
  removeEmptyParams,
  transformDownloadResponse,
} from '@utils';
import contentDisposition from 'content-disposition';
import {useAxios} from '../store/axiosStore';

export default {
  fics(filter) {
    return Api()
      .get('/fics', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  ficMandatsAndAvenants(filter) {
    return Api()
      .get('/ficmandatsavenants', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  ficMandats(filter) {
    return Api()
      .get('/ficmandats', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  ficAvenants(filter) {
    return Api()
      .get('/ficavenants', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  displayUser(userId) {
    return pictureApi()
      .get('/fic/user/' + userId, {
        responseType: 'arraybuffer',
        transformResponse: [transformDownloadResponse],
        id: userId,
        cache: {
          ttl: 1000 * 60 * 5,
        },
      })
      .then((response) => {
        let image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
      })
      .catch((e) => {});
  },
  download(fic, isPreview) {
    return Api()
      .get('/fic/' + fic.id, {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
      })
      .then((response) => {
        if (isPreview) {
          const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
          const link = document.createElement('a');
          link.href = url;
          link.title = fic.fileName;
          link.target = '_blank';
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
          link.setAttribute('download', fileName);
          link.click();
          window.URL.revokeObjectURL(url);
        }
      });
  },
  exportFicMandats(filter) {
    return Api()
      .get('/ficmandats/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  exportFicAvenants(filter) {
    return Api()
      .get('/ficavenants/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  preview(fic) {
    return Api()
      .get('/fic/' + fic.id, {responseType: 'arraybuffer', transformResponse: [transformDownloadResponse]})
      .then((response) => {
        let image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        if (response.headers['content-type'].toLowerCase().includes('image'))
          return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        return undefined;
      });
  },
  delete(fic) {
    return Api()
      .delete('/fic/' + fic.id)
      .then(() => fic);
  },
  deleteMany(ficIds) {
    return Api()
      .post('/fics/deleteall', ficIds)
      .then(() => ficIds);
  },
  create(tableName, tableId, files) {
    let data = createFormDataFic(tableName, tableId, files);
    return Api()
      .post('/fics', data)
      .then((res) => res.data);
  },
  createItem(obj, file) {
    let data = createFormDataFile(obj, file);
    return Api()
      .post('/fic', data)
      .then((res) => res.data);
  },
  createItems(obj, files) {
    let data = createFormDataFiles(obj, files);
    return Api()
      .post('/fics', data)
      .then((res) => res.data);
  },
  uploadUserPicture(file) {
    let data = createFormDataFicForUser(file);
    return Api()
      .post('/fic/upload/user', data)
      .then((res) => res.data);
  },
};
