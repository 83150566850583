<script>
import DropdownMenu from 'v-dropdown-menu';
import AkRadio from '@components/v2/input/AkRadio.vue';
import AkCheckbox from '@components/v2/input/AkCheckbox.vue';
import {debounce} from '@utils';
import AkInputText from '@components/v2/input/AkInputText.vue';
import DropdownMenuAk from '@components/v2/general/DropdownMenuAk.vue';

export default {
  components: {DropdownMenuAk, AkInputText, AkCheckbox, AkRadio},
  inject: {
    pSubmitted: {default: null},
    pDisabled: {default: null},
  },
  props: {
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    items: {
      type: Array,
      required: false,
      default: undefined,
    },
    itemDisplay: {
      type: String,
      required: false,
      default: 'name',
    },
    itemValue: {
      type: String,
      required: false,
      default: 'id',
    },
    multiSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    pluralLabel: {
      type: String,
      required: false,
      default: 'elements',
    },
    direction: {
      type: String,
      required: false,
      default: 'center',
      validator(value) {
        return ['center', 'left', 'right'].includes(value);
      },
    },
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      itemSelected: undefined,
      itemsFilter: undefined,
      debounceFn: undefined,
      changed: false,
      filter: undefined,
    };
  },
  emits: ['update:modelValue', 'search'],
  mounted() {
    this.debounceFn = debounce(() => this.filterItem(), 500);
  },
  watch: {
    currentValue: function (newValue) {
      if (newValue) {
        if (this.multiSelection) {
          this.itemSelected = [];
        }
        for (var item of this.items) {
          if (this.multiSelection) {
            if (this.currentValue.includes(item[this.itemValue])) {
              this.itemSelected.push(item);
            }
          } else {
            if (item[this.itemValue] === this.currentValue) {
              this.itemSelected = item;
            }
          }
        }
      }
    },
    items: function (newValue) {
      if (newValue && (this.itemSelected === undefined || this.itemSelected.length === 0) && this.currentValue) {
        if (this.multiSelection) {
          this.itemSelected = [];
        }
        for (var item of this.items) {
          if (this.multiSelection) {
            if (this.currentValue.includes(item[this.itemValue])) {
              this.itemSelected.push(item);
            }
          } else {
            if (item[this.itemValue] === this.currentValue) {
              this.itemSelected = item;
            }
          }
        }
      }
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    labelDisplay() {
      if (this.itemSelected && this.hasValue) {
        if (this.multiSelection) {
          if (this.itemSelected.length > 1) return this.$t(this.pluralLabel, [this.itemSelected.length]);
          else if (this.itemSelected.length === 1) {
            return this.itemSelected[0][this.itemDisplay];
          }
        } else return this.itemSelected[this.itemDisplay];
      }
      return this.label;
    },
    isLoading() {
      return this.items === undefined;
    },
    hasItems() {
      return this.items === undefined || this.items.length > 0;
    },
    hasValue() {
      return this.currentValue !== undefined && this.currentValue.length > 0;
    },
  },
  methods: {
    initItems() {
      if (this.itemsFilter === undefined && this.items) {
        this.itemsFilter = this.items;
        for (var item of this.items) {
          if (item[this.itemValue] === this.currentValue) {
            this.itemSelected = item;
          }
        }
      }
    },
    selectItem(item) {
      if (this.multiSelection) {
        this.changed = true;
        if (this.itemSelected === undefined || !(this.itemSelected instanceof Array)) {
          this.itemSelected = [];
        }
        if (this.itemSelected.includes(item)) {
          var index = this.itemSelected.indexOf(item);
          if (index !== -1) {
            this.itemSelected.splice(index, 1);
          }
        } else this.itemSelected.push(item);
      } else {
        if (this.itemSelected !== item) {
          this.changed = true;
        }
        this.itemSelected = item;
      }
    },
    launchSearch() {
      this.$refs.dropdown.hide();
      if (this.changed) {
        this.changed = false;
        this.$emit('search');
      }
      setTimeout(() => {
        this.filter = undefined;
        this.filterItem();
      }, 100);
    },
    reset() {
      this.changed = true;
      this.itemSelected = undefined;
      this.currentValue = undefined;
    },
    filterItem() {
      this.itemsFilter = [];
      if (this.filter) {
        this.itemsFilter.push(
          ...this.items.filter((i) => i[this.itemDisplay].toLowerCase().indexOf(this.filter.toLowerCase()) > -1),
        );
      } else this.itemsFilter.push(...this.items);
    },
    scrollToTop() {
      this.changed = false;
      this.initItems();
      this.$nextTick(() => {
        if (this.$refs.scroll) this.$refs.scroll.$el.scrollTop = 0;
      });
    },
  },
};
</script>

<template>
  <dropdown-menu-ak
    @opened="scrollToTop"
    @closed="launchSearch"
    v-if="hasItems"
    ref="dropdown"
    overlay
    class="dropdown-filter dropdown-xl"
    :closeOnClickOutside="true"
    :direction="direction"
    mode="click">
    <template #trigger>
      <div
        class="flex items-center gap-1 py-2.5 px-1.5 hover:cursor-pointer hover:text-primary"
        :class="[hasValue ? 'text-primary' : 'text-gray']">
        <span class="flex-1 font-inter font-medium text-sm" :title="labelDisplay">{{ labelDisplay }}</span>
        <i class="ga-icon ga-chevron_down icon-rotate" v-if="!this.isLoading" />
        <span class="spinner primary xs" v-else />
      </div>
    </template>
    <template #body>
      <div v-if="!isLoading" class="list">
        <form class="pb-4" v-if="displayFilter">
          <AkInputText :placeholder="$t('filter')" inputClass="xs" v-model="filter" @keyup="debounceFn" />
        </form>
        <perfect-scrollbar ref="scroll" class="md:max-h-[calc(40vh_-_95px_-_28px)] max-h-[calc(60vh_-_95px_-_28px)]">
          <ul>
            <li
              v-for="child in itemsFilter"
              :key="child.id"
              class="py-2 font-inter text-base text-gray font-medium hover:text-primary focus:text-primary hover:cursor-pointer"
              :class="{'text-primary': this.currentValue === child[itemValue]}">
              <AkRadio
                :binary="false"
                v-model="this.currentValue"
                :label="child[itemDisplay]"
                :value="child[itemValue]"
                @update:modelValue="selectItem(child)"
                label-class="font-medium text-gray text-base"
                v-if="!this.multiSelection" />
              <AkCheckbox
                v-model="this.currentValue"
                :value="child[itemValue]"
                :binary="false"
                @update:modelValue="selectItem(child)"
                label-class="font-medium text-gray text-base"
                :label="child[itemDisplay]"
                v-else />
            </li>
          </ul>
        </perfect-scrollbar>
        <hr />
        <div
          class="flex gap-2 items-center text-gray justify-center pt-4 hover:text-primary hover:cursor-pointer"
          @click="reset">
          <i class="ga-icon ga-refresh text-3xl" />
          <span class="font-medium text-base">Réinitialiser</span>
        </div>
      </div>
      <div v-else class="flex flex-col items-center gap-2">
        <span class="spinner primary xl"></span>
        <span class="text-gray font-normal text-xs font-inter">Chargement...</span>
      </div>
    </template>
  </dropdown-menu-ak>
</template>
