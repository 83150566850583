<script>
/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import upsellStatusConst from '@mixins/const/upsellStatusConst';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';
import mandatAutocompleteMixin from '@mixins/autocomplete/mandatAutocompleteMixin';

/* SERVICES */

export default {
  components: {
    AkAutocomplete,
    AkInputText,
    AkCalendar,
    MultiSelect,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    utilsMixin,
    companyMixin,
    sidebarMixin,
    mandatAutocompleteMixin,
    clientAutocompleteMixin,
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
    reservationAutocompleteMixin,
    permissionsMixin,
    upsellStatusConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statuses: [],
    };
  },
  mounted() {
    this.initStatuses();
  },
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
      if (this.$refs.mandatAutocomplete) this.$refs.mandatAutocomplete.reset();
      this.callback[1]();
    },
    initStatuses() {
      this.statuses = this.upsellStatusConstForFilter();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :options="this.companies"
      :placeholder="$t('employee.company')"
      class="flex-1 form-control"
      option-label="name"
      filter
      filter-placeholder="Filtrer"
      @change="itemSelect"
      option-value="id" />
    <AkAutocomplete
      v-if="!this.isOwner()"
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      :label="$t('role.owner')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />
    <AkAutocomplete
      ref="clientAutocomplete"
      v-model="currentValue.clientId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      placeholder="Voyageur"
      :search-label="this.autocompleteClientQuery"
      :init-method="this.autocompleteClientById"
      :search-method="this.autocompleteClient" />

    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      placeholder="Logement"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery"
      :init-method="this.autocompleteAccommodationById" />

    <AkAutocomplete
      ref="mandatAutocomplete"
      v-model="currentValue.mandatId"
      :inline="true"
      :label="$t('mandat.details')"
      option-value="id"
      option-label="num"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteMandatById"
      :search-method="this.autocompleteMandat" />

    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      @item-select="itemSelect"
      label="Réservation"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation" />
    <AkInputText
      v-model="currentValue.name"
      inline
      @value-change="itemSelect"
      :display-label="false"
      :placeholder="$t('upsell.name')"
      class-name="" />
    <AkCalendar
      v-model="currentValue.periodDateCheckin"
      :inline="true"
      @value-change="itemSelect"
      :placeholder="$t('reservation.dateCheckin')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.periodDateCheckout"
      :inline="true"
      @value-change="itemSelect"
      :placeholder="$t('reservation.dateCheckout')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.periodDateBuy"
      :inline="true"
      @value-change="itemSelect"
      :placeholder="$t('upsell.buyDate')"
      date-format="dd/mm/yy"
      selection="range" />

    <MultiSelect
      v-model="currentValue.statuses"
      :placeholder="$t('upsell.status')"
      :options="statuses"
      option-label="label"
      @change="itemSelect"
      option-value="value"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      inline />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
