import Api from '@/services/api';
import {createFormDataFile, removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  list(filter) {
    return Api()
      .get('/documentmodels', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  documentModel(id) {
    return Api()
      .get('/documentmodel/' + id)
      .then((res) => this.formatRes(res.data));
  },
  documentModelDownload(id) {
    return Api()
      .get('/documentmodel/' + id + '/download', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  documentModelDto(id) {
    return Api()
      .get('/documentmodel/' + id + '/dto')
      .then((res) => this.formatRes(res.data));
  },
  create(documentModel, file) {
    let data = createFormDataFile(documentModel, file);
    return Api()
      .post('/documentmodel', data)
      .then((res) => this.formatRes(res.data));
  },
  update(documentModel) {
    return Api()
      .put('/documentmodel/' + documentModel.id, documentModel)
      .then((res) => this.formatRes(res.data));
  },
  delete(documentModel) {
    return Api()
      .delete('/documentmodel/' + documentModel.id)
      .then(() => documentModel);
  },
  formatRes(e) {
    return e;
  },
};
