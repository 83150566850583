import Api from '@/services/api';
import {removeEmptyParams} from '@utils';
import moment from 'moment/moment';

export default {
  planning(filter) {
    return Api()
      .get('/reservations/planning', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          e.reservationList.forEach((r) => {
            this.formatRes(r);
          });
        });
        return res.data;
      });
  },
  planningYearly(filter) {
    return Api()
      .get('/reservations/planning/yearly', {params: removeEmptyParams(filter)})
      .then((res) => {
        if (res.data.reservationList)
          res.data.reservationList.forEach((r) => {
            this.formatRes(r);
          });
        return res.data;
      });
  },
  createReservationHote(reservationHote) {
    return Api()
      .post('/reservation/hote', reservationHote)
      .then((res) => res.data);
  },
  cancelReservationHote(reservation) {
    return Api()
      .delete('/reservation/hote/' + reservation.externalId)
      .then(() => reservation);
  },

  formatRes(e) {
    if (e.dateStart) e.dateStart = moment(new Date(e.dateStart));
    if (e.dateEnd) e.dateEnd = moment(new Date(e.dateEnd));
    if (e.dateCreated) e.dateCreated = moment(new Date(e.dateCreated));
    if (e.dateCheckin) e.dateCheckin = moment(new Date(e.dateCheckin));
    if (e.dateCheckout) e.dateCheckout = moment(new Date(e.dateCheckout));

    if (e.type === 'GUEST') {
      e.title = e.guestDisplay;
    } else if (e.type === 'OWNER') {
      e.title = 'Réservation hôte';
    } else if (e.type === 'BLOCKADE') {
      e.title = e.blockadeReason;
    }
  },
};
