<script>
/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';
import MultiSelect from 'primevue/multiselect';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import ticketPriorityConst from '@mixins/const/ticketPriorityConst';
import ticketStatusConst from '@mixins/const/ticketStatusConst';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';

/* SERVICES */
import poleService from '@services/poleService';
import ticketCategoryService from '@services/ticketCategoryService';

export default {
  components: {
    AkAutocomplete,
    MultiSelect,
    AkInputSwitch,
    AkInputText,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    utilsMixin,
    sidebarMixin,
    ownerAutocompleteMixin,
    accommodationAutocompleteMixin,
    ticketPriorityConst,
    ticketStatusConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusConst: [],
      poles: [],
      categories: [],
    };
  },
  mounted() {
    this.statusConst = this.displayForTicketStatusConstWithoutUndefined();
    this.loadPoles();
  },
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
    },
    loadPoles() {
      let p2 = ticketCategoryService.ticketCategorys();
      p2.then((data) => {
        this.categories = data;
      });
      poleService.poles().then((data) => {
        this.poles = data;
      });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    subCategories() {
      let tab = [];
      for (let c of this.categories) {
        tab.push(...c.subCategories);
      }
      return tab;
    },
    hasOptions() {
      return this.categories && this.categories.length > 0;
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-model="currentValue.poleIds"
      :placeholder="$t('ticket.attribution')"
      :options="poles"
      optionValue="id"
      @change="itemSelect"
      optionLabel="display"
      :displayLabel="false"
      class="form-control"
      inline />

    <AkAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      label="Hôte"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      placeholder="Logement"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery"
      :init-method="this.autocompleteAccommodationById" />
    <AkInputText
      @value-change="itemSelect"
      v-model="currentValue.label"
      :placeholder="$t('ticket.label')"
      type="text"
      inline />

    <MultiSelect
      v-if="hasOptions"
      v-model="currentValue.subCategoryIds"
      placeholder="Catégorie"
      :options="categories"
      option-group-children="subCategories"
      option-group-label="label"
      optionValue="id"
      @change="itemSelect"
      optionLabel="label"
      :displayLabel="false"
      class="form-control"
      inline />

    <MultiSelect
      v-model="currentValue.prioritys"
      :placeholder="$t('ticket.priority')"
      :options="ticketPriorityConst"
      option-label="label"
      option-value="value"
      :display-label="false"
      class="form-control"
      @change="itemSelect"
      inline />
    <MultiSelect
      v-model="currentValue.status"
      :placeholder="$t('ticket.status')"
      :options="this.displayForTicketStatusConstWithoutUndefined()"
      option-label="label"
      option-value="value"
      :display-label="false"
      @change="itemSelect"
      class="form-control"
      inline />

    <AkInputSwitch
      v-model="currentValue.attributedToMe"
      @change="itemSelect"
      :label="$t('ticket.attributed_to_me')"
      :labelBefore="false"
      inline />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
