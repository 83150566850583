import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  findPrevReservation(reservationId) {
    return Api()
      .get('/prestation/reservation/' + reservationId + '/prev')
      .then((res) => {
        return res.data;
      });
  },
  findNextReservation(reservationId) {
    return Api()
      .get('/prestation/reservation/' + reservationId + '/next')
      .then((res) => {
        return res.data;
      });
  },
  formatRes(e) {
    if (e.dateCheckin) e.dateCheckin = new Date(e.dateCheckin);
    if (e.dateCreated) e.dateCreated = new Date(e.dateCreated);
  },
};
