import Api from '@/services/api';

export default {
  login(user) {
    return Api(false)
      .post('/token', user)
      .then((res) => res.data);
  },
  refresh(refreshToken) {
    return Api(false)
      .post('/refreshToken', refreshToken)
      .then((res) => res.data);
  },
};
