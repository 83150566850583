import employeeService from '@services/employeeService';

export default {
  methods: {
    autocompleteEmployee(e) {
      return employeeService.autocomplete(e);
    },
    autocompleteEmployeeById(e) {
      return employeeService.employeeDisplay(e);
    },
  },
};
