import Api from '@/services/api';
import {createFormDataFiles, removeEmptyParams} from '@utils';

export default {
  create(prestataire, files) {
    let data = createFormDataFiles(prestataire, files);
    return Api()
      .post('/prestataire', data)
      .then((res) => this.formatRes(res.data));
  },
  get(prestataireId) {
    return Api()
      .get('/prestataire/' + prestataireId)
      .then((res) => this.formatRes(res.data));
  },
  prestataireDisplay(prestataireId) {
    return Api()
      .get('/prestataire/' + prestataireId + '/display')
      .then((res) => this.formatRes(res.data));
  },
  update(prestataireId, prestataire, files) {
    let data = createFormDataFiles(prestataire, files);
    return Api()
      .put(`/prestataire/${prestataireId}`, data)
      .then((res) => this.formatRes(res.data));
  },
  disable(prestataireId) {
    return Api()
      .get(`/prestataire/${prestataireId}/disable`)
      .then((res) => this.formatRes(res.data));
  },
  enable(prestataireId) {
    return Api()
      .get(`/prestataire/${prestataireId}/enable`)
      .then((res) => this.formatRes(res.data));
  },
  // getAll(filter) {
  //   return Api()
  //     .get('/prestataires', {params: removeEmptyParams(filter)})
  //     .then((res) => this.formatRes(res.data));
  // },
  prestatairePagination(filter) {
    return Api()
      .get('/prestataires/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  autocomplete(filter) {
    return Api()
      .get('/prestataires/autocomplete', {params: removeEmptyParams(filter)})
      .then((res) => this.formatRes(res.data));
  },
  countAll(filter) {
    return Api()
      .get('/prestataires/count', {params: removeEmptyParams(filter)})
      .then((res) => this.formatRes(res.data));
  },
  getAllStats(startDate, endDate) {
    return Api().get(`/prestataires/stats?dateStart=${startDate}&dateEnd=${endDate}`);
  },
  formatRes(e) {
    if (e.contact && e.contact.birthDate) e.contact.birthDate = new Date(e.contact.birthDate);
    return e;
  },
};
