<script>
import {provide, ref} from 'vue';

export default {
  props: {
    reference: {
      type: String,
      required: false,
      default: 'form',
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    ignoredValidator: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    let pSubmitted = ref(props.submitted !== undefined ? props.submitted : false);
    provide('pSubmitted', pSubmitted);

    let pDisabled = ref(props.disabled !== undefined ? props.disabled : false);
    provide('pDisabled', pDisabled);

    let pIgnoredValidator = ref(props.ignoredValidator !== undefined ? props.ignoredValidator : []);
    provide('pIgnoredValidator', pIgnoredValidator);
    return {pSubmitted, pDisabled, pIgnoredValidator};
  },
  watch: {
    submitted: function (newType) {
      this.pSubmitted = newType;
    },
    disabled: function (newType) {
      this.pDisabled = newType;
    },
    ignoredValidator: function (newType) {
      this.pIgnoredValidator = newType;
    },
  },
  methods: {
    handleKeydownEnter(evt) {
      let name = evt.target.nodeName.toLowerCase();
      if (evt.keyCode == 13 && name !== 'textarea') evt.preventDefault();
    },
  },
};
</script>

<template>
  <form :ref="reference" @submit.prevent="" @keydown.enter.stop="handleKeydownEnter($event)">
    <slot />
  </form>
</template>
