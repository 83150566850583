import {defineStore} from 'pinia';

export const useTabStore = defineStore('tab', {
  state: () => {
    return {
      tab: {},
    };
  },
  actions: {
    setTabActive(page, tabActive) {
      this.$state.tab[page] = tabActive;
    },
    getTabActive(page, defaut) {
      if (this.tab[page]) return this.tab[page];
      return defaut;
    },
  },
});
