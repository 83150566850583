export default {
  data() {
    return {
      reservationGuestTypesConst: [
        {label: this.$t('reservation.reservationType_guest'), value: 'GUEST'},
        {label: this.$t('reservation.reservationType_guest_cancel'), value: 'GUEST_CANCEL'},
      ],
    };
  },
  methods: {
    displayForReservationGuestTypeConst(value) {
      let result = this.reservationTypesConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
