export default {
  data() {
    return {
      accountOperationsConst: [
        {label: this.$t('operation.ACHAT_HOTE'), value: 'ACHAT_HOTE'},
        {label: this.$t('operation.DEEP_CLEANING'), value: 'DEEP_CLEANING'},
        {label: this.$t('operation.FRAIS_DE_MENAGE_HOTE'), value: 'FRAIS_DE_MENAGE_HOTE'},
        {label: this.$t('operation.FRAIS_DE_MAINTENANCE'), value: 'FRAIS_DE_MAINTENANCE'},
        {label: this.$t('operation.FRAIS_ANNULATION_HOTE'), value: 'FRAIS_ANNULATION_HOTE'},
        {label: this.$t('operation.FRAIS_DE_BLANCHISSERIE'), value: 'FRAIS_DE_BLANCHISSERIE'},
        {label: this.$t('operation.FORFAIT_VERIFICATION_MENAGE_HOTE'), value: 'FORFAIT_VERIFICATION_MENAGE_HOTE'},
        {label: this.$t('operation.NUISANCE_SONORE'), value: 'NUISANCE_SONORE'},
        {label: this.$t('operation.REMBOURSEMENT_VOYAGEUR'), value: 'REMBOURSEMENT_VOYAGEUR'},
        {label: this.$t('operation.REMBOURSEMENT_HOTE'), value: 'REMBOURSEMENT_HOTE'},
        {label: this.$t('operation.REFUND_GUEST'), value: 'REFUND_GUEST'},
        {label: this.$t('operation.VIREMENT_OTA'), value: 'VIREMENT_OTA'},
        {label: this.$t('operation.FRAIS_DE_MAINTENANCE_OLD'), value: 'FRAIS_DE_MAINTENANCE_OLD'},
        {label: this.$t('operation.FRAIS_DE_LANCEMENT'), value: 'FRAIS_DE_LANCEMENT'},
        {label: this.$t('operation.LITIGE'), value: 'LITIGE'},
        {label: this.$t('operation.RECLAMATION'), value: 'RECLAMATION'},
        {label: this.$t('operation.OUVERTURE_HIVERNAGE'), value: 'OUVERTURE_HIVERNAGE'},
        {label: this.$t('operation.FERMETURE_HIVERNAGE'), value: 'FERMETURE_HIVERNAGE'},
        {label: this.$t('operation.ASSURANCE'), value: 'ASSURANCE'},
        {label: this.$t('operation.FRAIS_OPERATIONNEL'), value: 'FRAIS_OPERATIONNEL'},
        {label: this.$t('operation.MAINTENANCE'), value: 'MAINTENANCE'},
      ],
      accountOperationsSmallConst: [
        {label: this.$t('facturation_hote.ACHAT_HOTE'), value: 'ACHAT_HOTE'},
        {label: this.$t('operation.DEEP_CLEANING'), value: 'DEEP_CLEANING'},
        {label: this.$t('operation.OUVERTURE_HIVERNAGE'), value: 'OUVERTURE_HIVERNAGE'},
        {label: this.$t('operation.FERMETURE_HIVERNAGE'), value: 'FERMETURE_HIVERNAGE'},
        {label: this.$t('operation.FRAIS_ANNULATION_HOTE'), value: 'FRAIS_ANNULATION_HOTE'},
        {label: this.$t('facturation_hote.FRAIS_DE_MAINTENANCE'), value: 'FRAIS_DE_MAINTENANCE'},
        {label: this.$t('operation.FRAIS_DE_MENAGE_HOTE'), value: 'FRAIS_DE_MENAGE_HOTE'},
        {label: this.$t('operation.REMBOURSEMENT_HOTE'), value: 'REMBOURSEMENT_HOTE'},
        {label: this.$t('operation.REMBOURSEMENT_VOYAGEUR'), value: 'REMBOURSEMENT_VOYAGEUR'},
        {label: this.$t('operation.LITIGE'), value: 'LITIGE'},
        {label: this.$t('operation.MAINTENANCE'), value: 'MAINTENANCE'},
        {label: this.$t('operation.NUISANCE_SONORE'), value: 'NUISANCE_SONORE'},
        {label: this.$t('operation.RECLAMATION'), value: 'RECLAMATION'},
        {label: this.$t('operation.PAYMENT'), value: 'PAYMENT'},
        {label: this.$t('operation.CRG_REPORT'), value: 'CRG_REPORT'},
        {label: this.$t('operation.CRG_CLOSED'), value: 'CRG_CLOSED'},
        {label: this.$t('operation.VIREMENT_CRG'), value: 'VIREMENT_CRG'},
        {label: this.$t('operation.CANCEL'), value: 'CANCEL'},
      ],
      accountOperationsConstForRecapitulatif: [
        {label: this.$t('operation.FRAIS_DE_LANCEMENT'), value: 'FRAIS_DE_LANCEMENT'},
        {label: this.$t('operation.ASSURANCE'), value: 'ASSURANCE'},
        {label: this.$t('operation.FRAIS_DE_BLANCHISSERIE'), value: 'FRAIS_DE_BLANCHISSERIE'},
        {label: this.$t('operation.FRAIS_ACCUEIL'), value: 'FRAIS_ACCUEIL'},
        {label: this.$t('operation.FRAIS_DE_MENAGE'), value: 'FRAIS_DE_MENAGE'},
        {label: this.$t('operation.COMMISSION'), value: 'COMMISSION'},
        {label: this.$t('operation.RECLAMATION'), value: 'RECLAMATION'},
        {label: this.$t('operation.LITIGE'), value: 'LITIGE'},
        {label: this.$t('operation.ACHAT_HOTE'), value: 'ACHAT_HOTE'},
        {label: this.$t('operation.FRAIS_DE_MAINTENANCE'), value: 'FRAIS_DE_MAINTENANCE'},
        {label: this.$t('operation.OUVERTURE_HIVERNAGE'), value: 'OUVERTURE_HIVERNAGE'},
        {label: this.$t('operation.FERMETURE_HIVERNAGE'), value: 'FERMETURE_HIVERNAGE'},
        {label: this.$t('operation.FRAIS_DE_MENAGE_HOTE'), value: 'FRAIS_DE_MENAGE_HOTE'},
        {label: this.$t('operation.FRAIS_ANNULATION_HOTE'), value: 'FRAIS_ANNULATION_HOTE'},
        {label: this.$t('operation.NUISANCE_SONORE'), value: 'NUISANCE_SONORE'},
        {label: this.$t('operation.REMBOURSEMENT_HOTE'), value: 'REMBOURSEMENT_HOTE'},
        {label: this.$t('operation.REMBOURSEMENT_VOYAGEUR'), value: 'REMBOURSEMENT_VOYAGEUR'},
      ],
    };
  },
  methods: {
    displayForAccountOperationsConst(value) {
      let result = this.accountOperationsConst.find((el) => el.value === value);
      return result ? result.label : '';
    },

    // liste utilisée dans la page de consultation des opérations pour la création d'opération
    accountOperationsConstForConsultation() {
      return this.accountOperationsConst.filter(
        (el) =>
          el.value !== 'REFUND_GUEST' &&
          el.value !== 'VIREMENT_OTA' &&
          el.value !== 'FRAIS_DE_LANCEMENT' &&
          el.value !== 'RECLAMATION' &&
          el.value !== 'ASSURANCE' &&
          el.value !== 'FRAIS_OPERATIONNEL' &&
          el.value !== 'MAINTENANCE',
      );
    },

    // liste utilisée dans la page de consultation des opérations pour la recherche d'opérations
    accountOperationsConstForConsultationSearch() {
      return this.accountOperationsConst.filter((el) => el.value !== 'REFUND_GUEST' && el.value !== 'VIREMENT_OTA');
    },
  },
};
