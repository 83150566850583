<script>
/* COMPOSANTS */
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkCalendar from '@components/v2/input/AkCalendar';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import reservationLitigeReclamationConst from '@mixins/const/reservationLitigeReclamationConst';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import sidebarMixin from '@/mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */

export default {
  components: {
    AkAutocomplete,
    MultiSelect,
    AkCalendar,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    permissionsMixin,
    clientAutocompleteMixin,
    reservationAutocompleteMixin,
    reservationLitigeReclamationConst,
    reservationSiteConst,
    reservationTypesConst,
    sidebarMixin,
    utilsMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
      this.itemSelect();
    },
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <AkAutocomplete
      ref="clientAutocomplete"
      v-model="currentValue.clientId"
      inline
      @item-select="itemSelect"
      :label="$t('client.one')"
      option-value="id"
      option-label="display"
      :search-label="this.autocompleteClientQuery"
      :init-method="this.autocompleteClientById"
      :search-method="this.autocompleteClient"
      :search-object="{accommodationId: this.currentValue.accommodationId}" />

    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      @item-select="itemSelect"
      label="Réservation"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation"
      :search-object="{accommodationId: this.currentValue.accommodationId}" />

    <AkCalendar
      v-model="currentValue.dateCheckinPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCheckin')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.dateCheckoutPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCheckout')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.dateCreatedPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCreated')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.dateCancelPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.dateCancel')"
      date-format="dd/mm/yy"
      selection="range" />
    <MultiSelect
      v-model="currentValue.types"
      :options="this.getReservationTypesConstFilter()"
      :placeholder="$t('reservation.type')"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <MultiSelect
      v-model="currentValue.reservationSiteList"
      :options="this.reservationSiteConstDefined"
      :placeholder="$t('reservation.reservation_site')"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <MultiSelect
      v-if="this.isGasGav()"
      v-model="currentValue.statusLitigeReclamation"
      :options="this.reservationLitigeReclamationConst"
      placeholder="Statut de gestion"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
