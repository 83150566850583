<script>
import {mapState} from 'pinia';
import {useMessageStore} from '../../store/messagesStore';

/* COMPOSANTS */
import GaTitleMenu from '@components/ga/general/GaTitleMenu';
import GaItemTitle from '@components/ga/general/GaItemTitle';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

export default {
  components: {GaItemTitle, GaTitleMenu},
  mixins: [messageMixin, loaderMixin],
  data() {
    return {
      tab: {},
    };
  },
  props: {
    title: {type: String, required: true},
    tabs: {
      type: Array,
      required: true,
    },
    firstTab: {type: String, default: undefined},
  },
  mounted() {
    if (this.firstTab) {
      for (let t of this.tabs) {
        if (t.id === this.firstTab) {
          this.tab = t;
        }
      }
    } else {
      this.tab = this.tabs[0];
    }
  },

  methods: {
    display(tab) {
      this.tab = tab;
    },
    resetMessage() {
      useMessageStore().$reset();
    },
  },
  computed: {
    ...mapState(useMessageStore, ['list']),
  },
};
</script>

<template>
  <div class="h-100 d-flex flex-column">
    <slot name="back" />
    <Message
      v-for="msg of this.list"
      :key="msg.content"
      :closable="msg.closable"
      :severity="msg.severity"
      @close="resetMessage">
      {{ msg.content }}
    </Message>
    <GaTitleMenu :with-bullet="false">
      <slot name="title">
        <GaItemTitle :active="true" :title="title" />
      </slot>
    </GaTitleMenu>
    <perfect-scrollbar class="tab nav-border-bottom">
      <ul class="nav nav-tabs mb-2" role="tablist">
        <template v-for="tab of tabs" :key="tab">
          <li v-if="!tab.hidden" :class="{active: this.tab.id === tab.id}" class="nav-item" @click="display(tab)">
            <i :class="'fe fe-' + tab.icon + ' pr-1'" />
            {{ tab.name }}
          </li>
        </template>
      </ul>
    </perfect-scrollbar>
    <perfect-scrollbar class="content-list h-100">
      <slot :name="tab.id" />
    </perfect-scrollbar>
    <slot name="empty" />
    <slot name="extra" />
  </div>
</template>
