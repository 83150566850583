<script>
/* COMPOSANTS */
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkInputText from '@components/v2/input/AkInputText';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import reservationLitigeReclamationConst from '@mixins/const/reservationLitigeReclamationConst';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import sidebarMixin from '@/mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import companyService from '@services/companyService';
import accommodationService from '@services/accommodationService';

export default {
  components: {
    AkAutocomplete,
    AkInputText,
    MultiSelect,
    AkCalendar,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
    permissionsMixin,
    reservationAutocompleteMixin,
    reservationLitigeReclamationConst,
    reservationSiteConst,
    reservationTypesConst,
    sidebarMixin,
    utilsMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      accommodations: [],
    };
  },
  mounted() {
    companyService.companiesStorage().then((res) => {
      this.companies = res;
    });
    if (this.isOwner()) {
      accommodationService.accommodations({userId: this.myUserId()}).then((data) => {
        this.accommodations = data;
      });
    }
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
      this.itemSelect();
    },
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingAccommodations() {
      return this.accommodations.length === 0;
    },
    loadingCompanies() {
      return this.companies === undefined && !(this.companies instanceof Promise);
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :options="this.companies"
      :loading="this.loadingCompanies"
      :placeholder="$t('employee.company')"
      class="form-control"
      option-label="name"
      @change="itemSelect"
      option-value="id" />

    <AkAutocomplete
      v-if="!this.isOwner()"
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      label="Hôte"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <AkInputText
      @valueChange="itemSelect"
      v-model="currentValue.guestName"
      placeholder="Voyageur"
      className="flex-1 form-control"
      :inline="true" />

    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      placeholder="Logement"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery"
      :init-method="this.autocompleteAccommodationById" />

    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      @item-select="itemSelect"
      label="Réservation"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation" />

    <AkCalendar
      v-model="currentValue.dateCheckinPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCheckin')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.dateCheckoutPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCheckout')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.dateCreatedPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCreated')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.dateCancelPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.dateCancel')"
      date-format="dd/mm/yy"
      selection="range" />
    <MultiSelect
      v-model="currentValue.types"
      :options="this.getReservationTypesConstFilter()"
      :placeholder="$t('reservation.type')"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <MultiSelect
      v-model="currentValue.reservationSiteList"
      :options="this.reservationSiteConstDefined"
      :placeholder="$t('reservation.reservation_site')"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <MultiSelect
      v-if="this.isGasGav()"
      v-model="currentValue.statusLitigeReclamation"
      :options="this.reservationLitigeReclamationConst"
      placeholder="Statut de gestion"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
