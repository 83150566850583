<script>
/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';

/* SERVICES */

export default {
  components: {
    MultiSelect,
    AkCalendar,
    AkAutocomplete,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    utilsMixin,
    sidebarMixin,
    permissionsMixin,
    ownerAutocompleteMixin,
    reservationAutocompleteMixin,
    accommodationAutocompleteMixin,
    clientAutocompleteMixin,
    companyMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="this.isGas()"
      @change="itemSelect"
      v-model="currentValue.companyIds"
      :placeholder="$t('company.company')"
      :options="this.companies"
      option-label="name"
      option-value="id"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      inline />

    <AkAutocomplete
      @item-select="itemSelect"
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      :label="$t('owner.ownerDisplay')"
      option-value="id"
      option-label="display"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <AkAutocomplete
      @item-select="itemSelect"
      ref="clientAutocomplete"
      v-model="currentValue.clientId"
      inline
      option-value="id"
      option-label="display"
      :placeholder="$t('client.one')"
      :search-label="this.autocompleteClientQuery"
      :init-method="this.autocompleteClientById"
      :search-method="this.autocompleteClient" />

    <AkAutocomplete
      @item-select="itemSelect"
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      inline
      :placeholder="$t('accommodation_title')"
      option-value="id"
      option-label="display"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery"
      :init-method="this.autocompleteAccommodationById" />

    <AkAutocomplete
      @item-select="itemSelect"
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :placeholder="$t('reservation.one')"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation"
      inline />

    <AkCalendar
      @value-change="itemSelect"
      v-model="currentValue.periodCheckin"
      inline
      :placeholder="$t('reservation.periodCheckin')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      @value-change="itemSelect"
      v-model="currentValue.periodCheckout"
      inline
      :placeholder="$t('reservation.periodCheckout')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      @value-change="itemSelect"
      v-model="currentValue.periodCreation"
      inline
      :placeholder="$t('reservation.periodCreated')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
