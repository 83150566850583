import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  reviews(filter) {
    return Api()
      .get('/reviews', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  reviewsAccommodation(accommodationId, filter) {
    return Api()
      .get('/reviews/accommodation/' + accommodationId, {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.items = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  reviewsPagination(filter) {
    return Api()
      .get('/reviews/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.items = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  globalCountForAccommodation(accommodationId) {
    return Api()
      .get('/reviews/count/accommodation/' + accommodationId)
      .then((res) => this.formatRes(res.data));
  },
  globalCountForOwner() {
    return Api()
      .get('/reviews/owner')
      .then((res) => this.formatRes(res.data));
  },
  review(id) {
    return Api()
      .get('/review/' + id)
      .then((res) => this.formatRes(res.data));
  },
  reviewSidebar(id) {
    return Api()
      .get('/review/' + id + '/sidebar')
      .then((res) => this.formatRes(res.data));
  },
  exportList(filter) {
    return Api()
      .get('/reviews/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  exportListForOwner(filter) {
    return Api()
      .get('/reviews/export/owner', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  create(review) {
    return Api()
      .post('/review', review)
      .then((res) => this.formatRes(res.data));
  },
  update(review) {
    return Api()
      .put('/review/' + review.id, review)
      .then((res) => this.formatRes(res.data));
  },
  delete(review) {
    return Api()
      .delete('/review/' + review.id)
      .then(() => review);
  },
  formatRes(e) {
    if (e.submittedAt) e.submittedAt = new Date(e.submittedAt);

    return e;
  },
};
