<script>
/* COMPOSANTS */
import LitigeCard from '@views/litige/LitigeCard';
import GaView from '@components/v2/layout/GaView.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import loaderMixin from '@mixins/loaderMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import companyMixin from '@mixins/companyMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import filterListMixin from '@mixins/filterListMixin';
import litigeStatusConst from '@mixins/const/litigeStatusConst';

/* SERVICES */
import litigeService from '@services/litigeService';

export default {
  components: {
    AkAutocompleteFilter,
    AkListFilter,
    AkAllFilter,
    GaListView,
    GaView,
    LitigeCard,
  },
  mixins: [
    companyMixin,
    utilsMixin,
    messageMixin,
    permissionsMixin,
    loaderMixin,
    sidebarMixin,
    filterListMixin,
    litigeStatusConst,
    reservationAutocompleteMixin,
    ownerAutocompleteMixin,
  ],
  metaInfo() {
    return {
      title: this.$i18n.t('litige.list') + ' (' + this.countLitige + ')',
    };
  },
  data() {
    return {
      list: [],
      filter: {
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    statusATraiter() {
      return this.list.filter((item) => item.status === 'A_TRAITER');
    },
    statusEnCours() {
      return this.list
        .filter((item) => item.status === 'EN_COURS')
        .sort((a, b) => a.resolution.localeCompare(b.resolution));
    },
    statusResolu() {
      return this.list
        .filter((item) => item.status === 'RESOLU')
        .sort(function (a, b) {
          return new Date(b.validatedDate) - new Date(a.validatedDate);
        });
    },
    countLitige() {
      return this.statusATraiter.length + this.statusEnCours.length;
    },
  },
  methods: {
    // SEARCH

    search() {
      this.$nextTick(() => {
        this.showTotalLoaderWithAfter('Récupération des litiges en cours');
      });

      litigeService
        .litiges(this.filter)
        .then((data) => {
          this.list = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilterTitle(
        'GaLitigeListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaLitigeListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
  },
};
</script>
<template>
  <GaView>
    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-if="!this.isOwner()"
            v-model="this.filter.ownerId"
            @search="this.clickSearch"
            :label="$t('role.owner')"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAutocompleteFilter
            v-model="this.filter.reservationId"
            @search="this.clickSearch"
            label="Réservation"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteReservationById"
            :search-method="this.autocompleteReservation" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <div class="flex md:flex-row flex-col w-full gap-3">
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden"
                    @click="this.toggleElement(this.$refs, 'newList', $event)" />
                  <span class="text-dark font-semibold text-base uppercase">Nouveau</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ this.statusATraiter.length }}</span>
              </div>
              <div ref="newList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.statusATraiter.length > 0">
                  <LitigeCard :litige="item" v-for="item of this.statusATraiter" :key="item.id" />
                </perfect-scrollbar>
              </div>
            </div>
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden"
                    @click="this.toggleElement(this.$refs, 'progressList', $event)" />
                  <span class="text-dark font-semibold text-base uppercase">En cours</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ this.statusEnCours.length }}</span>
              </div>
              <div ref="progressList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.statusEnCours.length > 0">
                  <LitigeCard :litige="item" v-for="item of this.statusEnCours" :key="item.id" />
                </perfect-scrollbar>
              </div>
            </div>
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden"
                    @click="this.toggleElement(this.$refs, 'closedList', $event)" />
                  <span class="text-dark font-semibold text-base uppercase">Terminé</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ this.statusResolu.length }}</span>
              </div>
              <div ref="closedList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.statusResolu.length > 0">
                  <LitigeCard :litige="item" v-for="item of this.statusResolu" :key="item.id" />
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
