import {defineStore} from 'pinia';
import Api from '@services/api';
import {removeEmptyParams} from '@utils';

export const useStayrateStore = defineStore('stayrate', {
  state: () => ({stayrates: undefined}),
  actions: {
    async all() {
      if (this.isFilled()) return this.stayrates;
      let stayrates = await Api(true, false)
        .get('/stayrates', {params: removeEmptyParams({})})
        .then((res) => {
          return res.data;
        });
      this.$patch({
        stayrates: stayrates,
      });
      return this.stayrates;
    },
    clear() {
      this.$patch({
        stayrates: undefined,
      });
    },
    isFilled() {
      return this.stayrates !== undefined;
    },
  },
});
