<script>
/* COMPOSANTS */
import AkCalendar from '@components/v2/input/AkCalendar';
import MultiSelect from 'primevue/multiselect';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import sidebarMixin from '@mixins/sidebarMixin';
import companyMixin from '@mixins/companyMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import reservationSpecificitiesConst from '@mixins/const/reservationSpecificitiesConst';

/* SERVICES */

export default {
  components: {
    AkAutocomplete,
    AkCalendar,
    MultiSelect,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    residenceTypeConst,
    permissionsMixin,
    reservationTypesConst,
    reservationSiteConst,
    reservationSpecificitiesConst,
    blockadeReasonsConst,
    utilsMixin,
    sidebarMixin,
    companyMixin,
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
    accommodationTypeConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      :loading="this.loadingCompanies"
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :placeholder="$t('employee.company')"
      class="flex-1 form-control"
      :options="this.companies"
      option-label="name"
      @change="itemSelect"
      option-value="id" />
    <AkAutocomplete
      v-if="!this.isOwner()"
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      label="Hôte"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />
    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      option-value="id"
      option-label="display"
      :force-selection="false"
      @item-select="itemSelect"
      placeholder="Logement"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery"
      :init-method="this.autocompleteAccommodationById" />

    <MultiSelect
      v-model="currentValue.clusters"
      :options="getAccommodationTypeConstOrder()"
      :placeholder="$t('accommodation.accommodationType')"
      class="form-control flex-1"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <MultiSelect
      v-model="currentValue.typologies"
      :options="getResidenceTypeConstOrder()"
      :placeholder="$t('accommodation.residenceType')"
      class="form-control flex-1"
      @change="itemSelect"
      option-label="label"
      option-value="value" />

    <MultiSelect
      v-model="currentValue.specificities"
      :options="this.reservationSpecifitiesConst"
      placeholder="Spécificités"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <AkCalendar
      v-model="currentValue.date"
      class-name="form-control flex-1"
      date-format="dd/mm/yy"
      :inline="true"
      placeholder="Date de référence"
      @value-change="itemSelect"
      selection-mode="single" />

    <MultiSelect
      v-model="currentValue.types"
      :options="this.reservationTypesPlanningConst"
      :placeholder="$t('reservation.type')"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <MultiSelect
      v-model="currentValue.reservationSiteList"
      :options="this.reservationSiteConstDefined"
      :placeholder="$t('reservation.reservation_site')"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
