<script>
/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import permissionsMixin from '@mixins/permissionsMixin';
import companyMixin from '@mixins/companyMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import mandatAutocompleteMixin from '@mixins/autocomplete/mandatAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';

/* SERVICES */

export default {
  components: {
    MultiSelect,
    AkCalendar,
    AkAutocomplete,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    utilsMixin,
    sidebarMixin,
    permissionsMixin,
    companyMixin,
    ownerAutocompleteMixin,
    accommodationAutocompleteMixin,
    mandatAutocompleteMixin,
    reservationAutocompleteMixin,
    reservationSiteConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    reservationSiteConstForFilter() {
      return this.reservationSiteConst.slice(0, -1);
    },
  },
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      if (this.$refs.mandatAutocomplete) this.$refs.mandatAutocomplete.reset();
      this.callback[1]();
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="this.isGas() && this.companies"
      @change="itemSelect"
      v-model="currentValue.companyIds"
      :placeholder="$t('company.one')"
      :options="this.companies"
      option-label="name"
      option-value="id"
      class="form-control"
      inline />
    <AkAutocomplete
      @valueChange="itemSelect"
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      :label="$t('owner.ownerDisplay')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />
    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :init-method="autocompleteAccommodationById"
      :placeholder="$t('accommodation.short')"
      :search-label="autocompleteAccommodationQuery"
      :search-method="autocompleteAccommodation"
      inline
      option-label="display"
      option-value="id"
      @item-select="itemSelect" />
    <AkAutocomplete
      ref="mandatAutocomplete"
      v-model="currentValue.mandatId"
      :inline="true"
      :label="$t('mandat.details')"
      option-value="id"
      option-label="num"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteMandatById"
      :search-method="this.autocompleteMandat" />
    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      @item-select="itemSelect"
      label="Réservation"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation" />

    <AkCalendar
      @valueChange="itemSelect"
      v-model="currentValue.submittedAt"
      inline
      :placeholder="$t('review.submitted_at')"
      selection="range"
      :maxDate="new Date()" />
    <MultiSelect
      @change="itemSelect"
      v-model="currentValue.reservationSites"
      :placeholder="$t('reservation.reservation_site')"
      :options="reservationSiteConstForFilter"
      option-label="label"
      option-value="value"
      class="form-control"
      inline />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
