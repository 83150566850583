<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkInputFilter from '@components/v2/filter/AkInputFilter.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import clientTypeConst from '@mixins/const/clientTypeConst';
import civilitiesConst from '@mixins/const/civilitiesConst';
import yesNoConst from '@mixins/const/yesNoConst';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import sidebarMixin from '@mixins/sidebarMixin';
import filterListMixin from '@mixins/filterListMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';

/* SERVICES */
import clientService from '@services/clientService';

export default {
  components: {
    AkInputFilter,
    AkListFilter,
    AkAutocompleteFilter,
    AkAllFilter,
    GaListView,
    AkDatatablePagination,
    AkButtonAction,
    GaView,
  },
  mixins: [
    civilitiesConst,
    clientTypeConst,
    randomRef,
    utilsMixin,
    yesNoConst,
    permissionsMixin,
    sidebarMixin,
    filterListMixin,
    reservationAutocompleteMixin,
    companyMixin,
  ],
  metaInfo() {
    return {
      title: 'client.list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      totalItems: 0,
      item: {},
      filter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {},
  methods: {
    search() {
      this.loading = true;
      clientService.clientsPagination(this.filter).then((data) => {
        this.list = data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaClientListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaClientListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.CLIENT.MANAGE);
    },
  },
};
</script>
<template>
  <GaView>
    <template #action>
      <AkButtonAction
        v-if="hasManagePermission"
        :label="$t('client.add')"
        little
        @click="$router.push({name: 'clientCreate'})" />
    </template>
    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            v-model="this.filter.companyIds"
            :display-filter="true"
            :items="this.companies"
            :label="$t('employee.company')"
            :multi-selection="true"
            direction="right"
            @search="this.search" />
          <AkInputFilter v-model="filter.name" :label="$t('role.traveller')" @search="search" />
          <AkAutocompleteFilter
            v-model="this.filter.reservationId"
            :init-method="this.autocompleteReservationById"
            :search-method="this.autocompleteReservation"
            direction="right"
            item-display="display"
            item-value="id"
            label="Réservation"
            @search="this.clickSearch" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :filter="filter"
            :loading="loading"
            :nb-items="totalItems"
            :value="list"
            @search="search">
            <template #empty>
              {{ $t('client.empty') }}
            </template>
            <Column :header="$t('role.traveller')" :sortable="true" field="first_name">
              <template #body="slotProps">
                <router-link :to="{name: 'clientPage', params: {clientId: slotProps.data.id}}">
                  {{ slotProps.data.display }}
                </router-link>
              </template>
            </Column>
            <Column :header="$t('client.email')" :sortable="true" field="email">
              <template #body="slotProps">
                {{ slotProps.data.email }}
              </template>
            </Column>
            <Column :header="$t('client.phone')" :sortable="true" field="phone">
              <template #body="slotProps">
                {{ slotProps.data.phone }}
              </template>
            </Column>
            <Column body-style="text-align: center; overflow: visible" header-style=" width: 160px; text-align: center">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <router-link
                    :to="{name: 'clientPage', params: {clientId: slotProps.data.id}}"
                    class="btn btn-xs btn-inverse-primary mr-1">
                    <i class="ga-icon ga-external text-2xl" />
                  </router-link>
                  <router-link
                    v-if="hasManagePermission"
                    :to="{name: 'clientUpdate', params: {clientId: slotProps.data.id}}"
                    class="btn btn-xs btn-inverse-primary">
                    <i class="ga-icon ga-edit text-2xl" />
                  </router-link>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
