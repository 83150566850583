<script>
import AkLabel from '@components/v2/general/AkLabel';
import SelectButton from 'primevue/selectbutton';

export default {
  components: {AkLabel, SelectButton},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    returnType: {type: String, default: 'String'},
    options: {
      type: Array,
      default: undefined,
    },
    optionLabel: {
      type: [String, Function],
      required: false,
      default: 'label',
    },
    optionValue: {
      type: [String, Function],
      required: false,
      default: 'value',
    },
    optionDisabled: {
      type: String,
      default: 'disabled',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: null,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid && this.alreadySubmitted;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  mounted() {
    this.id = 'id_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  },
  methods: {},
};
</script>

<template>
  <div :class="className" class="w-full input-layout form-control">
    <AkLabel :required="isRequired">
      {{ label }}
    </AkLabel>
    <SelectButton
      v-model="currentValue"
      :disabled="isDisabled"
      :multiple="multiple"
      :option-disabled="optionDisabled"
      :option-label="optionLabel"
      :option-value="optionValue"
      :options="options" />
    <div>
      <small v-if="isValidatorRequired" class="p-error">{{ $t('field_required', [label]) }}</small>
    </div>
  </div>
</template>
