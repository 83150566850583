<script>
import AkLabel from '@components/v2/general/AkLabel';
import InputText from 'primevue/inputtext';
import {Icon} from '@iconify/vue';

export default {
  components: {Icon, AkLabel, InputText},
  inject: {
    pSubmitted: {default: null},
    pDisabled: {default: null},
    pIgnoredValidator: {default: null},
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: undefined,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    ignoredValidator: {
      type: Array,
      required: false,
      default: undefined,
    },
    formatter: {
      type: Function,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    displayLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    link: {
      type: Object,
      required: false,
      default: undefined,
    },
    linkExternal: {
      type: Object,
      required: false,
      default: undefined,
    },
    displayer: {
      type: Function,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      changed: false,
    };
  },
  emits: ['update:modelValue', 'value-change'],
  computed: {
    currentValue: {
      get: function () {
        if (this.displayer) return this.displayer(this.modelValue);
        return this.modelValue;
      },
      set: function (val) {
        let valFormat = val;
        if (this.formatter) {
          valFormat = this.formatter(valFormat);
        }
        this.$nextTick(() => {
          this.$emit('update:modelValue', valFormat);
        });
      },
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    getIgnoredValidator() {
      if (this.ignoredValidator != undefined) return this.ignoredValidator;
      if (this.pIgnoredValidator != undefined) return this.pIgnoredValidator;
      return [];
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      if (this.hasValidator && this.getIgnoredValidator.length > 0) {
        if (this.validator.$errors) {
          for (let error of this.validator.$errors) {
            if (this.getIgnoredValidator.indexOf(error.$validator) === -1) {
              return true;
            }
          }
        } else {
          if (this.hasValidator) {
            for (let p of Object.keys(this.validator)) {
              console.log('On check : ' + p);
              if (
                this.getIgnoredValidator.indexOf(p) === -1 &&
                this.validator[p] === false &&
                p.startsWith('$') === false &&
                this.validator.$invalid
              ) {
                return true;
              }
            }
          } else return false;
        }
      } else {
        return this.hasValidator && this.validator.$invalid;
      }
      return false;
    },
    isRequired() {
      return (
        this.hasValidator &&
        this.validator.required !== undefined &&
        (this.validator.required.$params === undefined ||
          this.validator.required.$params.type === 'required' ||
          (this.validator.required.$params.type === 'requiredIf' && this.validator.required.$params.prop === true))
      );
    },
    isRequiredFailed() {
      return (
        this.isRequired &&
        this.getIgnoredValidator.indexOf('required') === -1 &&
        (this.validator.required.$invalid || this.validator.$invalid) &&
        this.alreadySubmitted
      );
    },

    isMinLength() {
      return this.hasValidator && this.validator.minLengthValue !== undefined;
    },
    isMinLengthFailed() {
      return this.isMinLength && this.validator.minLengthValue.$invalid && this.alreadySubmitted;
    },

    isMaxLength() {
      return this.hasValidator && this.validator.maxLengthValue !== undefined;
    },
    isMaxLengthFailed() {
      return (
        ((this.isMaxLength && this.validator.maxLengthValue.$invalid) ||
          (this.isMaxLength && this.validator.maxLengthValue === false && this.validator.$invalid)) &&
        this.alreadySubmitted
      );
    },

    isMinMaxLength() {
      return this.isMinLength && this.isMaxLength;
    },
    isMinMaxLengthFailed() {
      return this.isMinMaxLength && (this.isMinLengthFailed || this.isMaxLengthFailed);
    },

    isEmail() {
      return this.hasValidator && this.validator.email !== undefined;
    },
    isEmailFailed() {
      return this.isEmail && this.validator.email.$invalid && this.alreadySubmitted;
    },

    isPhone() {
      return this.hasValidator && this.validator.phoneValidator !== undefined;
    },
    isPhoneFailed() {
      return this.isPhone && this.validator.phoneValidator.$invalid && this.alreadySubmitted;
    },

    isIban() {
      return this.hasValidator && this.validator.ibanValidator !== undefined;
    },
    isIbanFailed() {
      return this.isIban && this.validator.ibanValidator.$invalid && this.alreadySubmitted;
    },

    isBic() {
      return this.hasValidator && this.validator.bicValidator !== undefined;
    },
    isBicFailed() {
      return this.isBic && this.validator.bicValidator.$invalid && this.alreadySubmitted;
    },

    isNotUsed() {
      return this.hasValidator && this.validator.notUsed !== undefined;
    },
    isNotUsedFailed() {
      return this.isNotUsed && this.validator.notUsed.$invalid && this.alreadySubmitted;
    },
    hasIcon() {
      return this.icon !== undefined;
    },
    hasLabel() {
      return this.label !== undefined && this.displayLabel;
    },
    getMaxLength() {
      return this.validator.maxLengthValue ? this.validator.maxLengthValue : this.validator;
    },
    hasMaxLengthValue() {
      return this.getMaxLength.$params != undefined;
    },
  },
  methods: {
    openExternalLink(url) {
      window.open(url);
    },
    onBlur() {
      if (this.changed) this.$emit('value-change');
      this.changed = false;
    },
  },
  watch: {
    currentValue: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.changed = true;
      }
    },
  },
};
</script>

<template>
  <div class="w-full input-layout form-control" :class="[className, {relative: hasIcon}]">
    <AkLabel v-if="label && !inline && displayLabel" :required="this.isRequired || this.required === true">
      {{ label }}
      <template v-if="linkExternal !== undefined || link !== undefined" #link>
        <a
          v-if="linkExternal && linkExternal.path"
          :href="linkExternal.path"
          target="_blank"
          class="flex justify-between items-center">
          <i class="ga-icon ga-external text-secondary hover:text-primary" />
        </a>
        <router-link
          v-if="displayLink && link !== undefined"
          class="leading-[13px] text-[16px]"
          :to="{name: link.route, params: link.params}"
          target="_blank">
          <Icon v-if="link.icon" :icon="link.icon" class="text-secondary hover:text-primary" />
          <i v-if="link.class" :class="link.class" />
        </router-link>
      </template>
    </AkLabel>
    <InputText
      ref="input"
      @blur="onBlur"
      v-model="currentValue"
      class="w-full text-dark text-base font-inter form-control"
      :class="[inputClass, {'p-invalid': isInvalid && alreadySubmitted, 'has-icon': hasIcon}]"
      :disabled="isDisabled"
      :placeholder="getPlaceholder"
      :type="inputType" />
    <i :class="[icon, {'has-label': hasLabel}]" class="input-icon" v-if="hasIcon" />
    <div v-if="!isInline" class="flex flex-col flex-1">
      <small v-if="isRequiredFailed" class="p-error">
        {{ $t('field_required', [this.label]) }}
      </small>
      <small v-if="isEmailFailed" class="p-error">
        {{ $t('bad_format', [this.label]) }}
      </small>
      <small v-if="isPhoneFailed" class="p-error">
        {{ $t('check_phone_number') }}
      </small>
      <small v-if="isIbanFailed" class="p-error">
        {{ $t('check_iban_number') }}
      </small>
      <small v-if="isBicFailed" class="p-error">
        {{ $t('check_bic_number') }}
      </small>
      <small v-if="isNotUsedFailed" class="p-error">
        {{ $t('key_already_used') }}
      </small>
      <small v-if="isMinMaxLengthFailed" class="p-error">
        {{
          $t('min_max_length', [
            this.label,
            this.validator.minLengthValue.$params.min,
            this.validator.maxLengthValue.$params.max,
          ])
        }}
      </small>
      <small v-else-if="isMinLengthFailed" class="p-error">
        {{ $t('min_length', [this.label, this.validator.minLengthValue.$params.min]) }}
      </small>
      <small v-else-if="isMaxLengthFailed && hasMaxLengthValue" class="p-error">
        {{ $t('max_length', [this.label, this.getMaxLength.$params.max]) }}
      </small>
      <small v-else-if="isMaxLengthFailed" class="p-error">
        {{ $t('max_length_no_bound', [this.label]) }}
      </small>
    </div>
  </div>
</template>
