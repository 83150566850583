<script>
/* COMPOSANTS */

/* MIXINS */

import backMixin from '@mixins/backMixin';
import {useActiveMeta} from 'vue-meta';

export default {
  components: {},
  mixins: [backMixin],
  data() {
    return {
      tab: {},
    };
  },
  props: {
    details: {type: Boolean, required: false, default: false},
    scrollable: {type: Boolean, required: false, default: false},
    noPadding: {type: Boolean, required: false, default: false},
  },
  mounted() {},
  computed: {
    title() {
      return this.$i18n.t(useActiveMeta().title);
    },
  },
};
</script>

<template>
  <div :class="[{'layout-details': this.details}]">
    <div
      class="md:flex flex-col md:flex-row justify-between items-center mb-3"
      :class="{hidden: this.$isMobile() && !this.$slots.action}">
      <div class="hidden md:flex flex-1 items-center justify-start gap-2 min-h-[40px] w-full md:w-auto">
        <div v-if="this.hasBack">
          <a @click="this.hasBack()" class="hover:cursor-pointer">
            <i class="ga-icon ga-chevron_left text-2xl font-bold text-primary" />
          </a>
        </div>
        <span class="font-inter text-dark font-bold text-xl">{{ title }}</span>
      </div>
      <div class="flex items-center justify-end gap-2 w-full md:w-auto">
        <slot name="action" />
      </div>
    </div>
    <div
      class="p-3 border-solid border-[#F1F2F5] rounded-lg"
      :class="[
        {
          'bg-detailsBg': this.details,
          border: !this.details,
          'layout-scrollable': this.scrollable,
          'p-0': this.noPadding,
        },
      ]">
      <slot name="content" />
    </div>
    <slot name="empty" />
    <slot name="extra" />
  </div>
</template>
