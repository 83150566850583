export default {
  data() {
    return {
      residenceTypeConst: [
        {label: this.$t('accommodation.mainResidence'), value: 'primary_residence'},
        {label: this.$t('accommodation.secondResidence'), value: 'secondary_residence'},
        {label: this.$t('accommodation.rentalInvestment'), value: 'non_residential'},
        {label: this.$t('undefined'), value: undefined},
      ],
      residenceTypeConstForProspect: [
        {label: this.$t('accommodation.mainResidence'), value: 'primary_residence'},
        {label: this.$t('accommodation.secondResidence'), value: 'secondary_residence'},
        {label: this.$t('accommodation.rentalInvestment'), value: 'non_residential'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForResidenceTypeConst(value) {
      let result = this.residenceTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    getResidenceTypeConstForProspect() {
      return this.residenceTypeConstForProspect;
    },
    getResidenceTypeConstForFilter() {
      return this.residenceTypeConst.filter((el) => el.value);
    },
    getResidenceTypeConstOrder() {
      return this.residenceTypeConst.filter((el) => el.value);
    },
  },
};
