/* MIXINS POUR LES AVIS (REVIEWS) */
export default {
  methods: {
    colorRating(rating) {
      if (!rating) return 'text-gray opacity-50';
      if (rating <= 4) return 'text-red';
      if (rating >= 8) return 'text-green';
      return 'text-gray';
    },

    colorCriteraRating(rating) {
      if (!rating) return 'text-gray opacity-50';
      if (rating <= 2) return 'text-red';
      if (rating >= 4) return 'text-green';
      return 'text-gray';
    },
  },
};
