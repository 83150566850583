<script>
export default {
  props: {
    buttonText: {type: String, default: 'click'},
    buttonClass: {type: String, default: ''},
    className: {type: String, default: ''},
    little: {type: Boolean, default: false},
    title: {type: String, required: false},
    href: {type: String, required: false},
    withButton: {type: Boolean, default: false},
    withLink: {type: Boolean, default: false},
  },
  emits: ['click-btn'],
  mounted() {},
  methods: {
    clickBtn() {
      this.$emit('click-btn');
    },
  },
};
</script>

<template>
  <div :class="className" class="flex-column d-flex align-items-center">
    <span v-if="title" class="poppins-medium fs-16 color-secondary bloc-title-space">{{ title }}</span>
    <div class="d-flex flex-column align-items-center full-width h-100">
      <perfect-scrollbar
        :class="{little: little, 'bloc-content-btn': withButton || withLink}"
        class="d-flex flex-column bloc-content">
        <slot name="content"></slot>
      </perfect-scrollbar>
      <div v-if="withButton" class="button-zone">
        <span :class="buttonClass" class="button pointer" @click="clickBtn">{{ buttonText }}</span>
      </div>
      <div v-if="withLink" class="button-zone">
        <a :class="buttonClass" target="_blank" class="button pointer" :href="href">{{ buttonText }}</a>
      </div>
    </div>
  </div>
</template>
