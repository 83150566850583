export default {
  data() {
    return {
      mandatTypeConst: [
        {label: this.$t('mandat.type_courte_duree'), value: 'COURTE_DUREE'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForMandatTypeConst(value) {
      let result = this.mandatTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
