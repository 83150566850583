export default {
  data() {
    return {
      yesNoConst: [
        {label: this.$t('yes'), value: true},
        {label: this.$t('no'), value: false},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForYesNoConst(value) {
      let result = this.yesNoConst.find((el) => el.value === value);
      return result ? result.label : this.$t('no');
    },
  },
};
