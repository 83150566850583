<script>
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

export default {
  components: {AkButtonAction},
  props: {
    validateLabel: {
      type: String,
      required: true,
    },
  },
  emits: ['action', 'reset'],
  methods: {
    clickAction() {
      this.$emit('action');
    },
    reset() {
      this.$emit('reset');
    },
  },
};
</script>

<template>
  <div class="grid grid-cols-4 gap-6 items-center">
    <i
      v-on:click="this.reset()"
      class="ga-icon ga-refresh text-3xl text-gray hover:text-primary hover:cursor-pointer col-start-2 col-end-2 justify-self-end" />
    <AkButtonAction
      :label="this.validateLabel"
      class="col-start-3 col-end-5 btn-primary-gradient"
      @click="clickAction" />
  </div>
</template>
