<script>
import AkLabel from '@components/general/AkLabel';
import Calendar from 'primevue/calendar';

export default {
  components: {AkLabel, Calendar},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    format: {
      type: String,
      required: false,
      default: 'dd/mm/yy',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    maxDate: {
      type: Date,
      required: false,
      default: undefined,
    },
    minDate: {
      type: Date,
      required: false,
      default: undefined,
    },
    selection: {
      type: String,
      required: false,
      default: 'single',
    },
    isForm: {
      type: Boolean,
      required: false,
      default: true,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorMinDate() {
      return (
        this.validator !== undefined &&
        this.validator.minDate !== undefined &&
        this.validator.minDate.$invalid &&
        this.alreadySubmitted
      );
    },
    isValidatorMaxDate() {
      return (
        this.validator !== undefined &&
        this.validator.maxDate !== undefined &&
        this.validator.maxDate.$invalid &&
        this.alreadySubmitted
      );
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="[className, {'form-group': !isInline, 'form-control': isInline}]">
    <AkLabel v-if="displayLabel && !isInline" :required="isRequired">
      {{ label }}
    </AkLabel>
    <Calendar
      v-model="currentValue"
      :class="[
        inputClass,
        {'p-invalid': isInvalid && alreadySubmitted, 'form-control': !isInline, disabled: isDisabled},
      ]"
      :dateFormat="format"
      :disabled="isDisabled"
      :max-date="maxDate"
      :min-date="minDate"
      :show-button-bar="true"
      :placeholder="getPlaceholder"
      :selection-mode="selection" />
    <small v-if="isValidatorRequired && !isInline" class="p-error">{{ $t('field_required', [label]) }}</small>
    <small v-if="isValidatorMinDate && !isInline" class="p-error">{{ $t('error_min_date', [label]) }}</small>
    <small v-if="isValidatorMaxDate && !isInline" class="p-error">{{ $t('error_max_date', [label]) }}</small>
  </div>
</template>
