<script>
/* COMPOSANTS */
import GaIcon from '@components/ga/general/GaIcon';

/* MIXINS */
import civilitiesConst from '@mixins/const/civilitiesConst';
import accommodationContactConst from '@mixins/const/accommodationContactConst';
/* SERVICES */

export default {
  components: {GaIcon},
  mixins: [civilitiesConst, accommodationContactConst],
  props: {
    modelValue: {required: true},
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<template>
  <div class="d-flex align-items-center flex-column mt-35">
    <GaIcon bg-color="bg-blue-arrow" icon="gst-people2" icon-color="color-white" />
    <span class="poppins-bold fs-25 color-blue-dark mt-20 text-center">{{
      this.displayForAccommodationContactConst(modelValue.type)
    }}</span>
    <span class="poppins-light fs-16 color-blue-dark mt-8"
      >{{ displayForCivilityConst(modelValue.civility) }} {{ modelValue.lastName }}</span
    >
    <span class="poppins-light fs-16 color-blue-dark">{{ modelValue.firstName }}</span>
    <span class="poppins-light fs-16 color-blue-dark mt-20">{{ modelValue.phone }}</span>
    <span class="poppins-light fs-16 color-blue-dark">{{ modelValue.email }}</span>
    <span class="poppins-light fs-16 color-blue-dark mt-20">{{ modelValue.address }}</span>
    <span class="poppins-light fs-16 color-blue-dark">{{ modelValue.postalCode }} {{ modelValue.city }}</span>

    <span class="poppins-light fs-16 color-blue-dark mt-8">{{ modelValue.comment }}</span>
  </div>
</template>
