export default {
  data() {
    return {
      cdcmodelTypesConst: [
        {label: this.$t('cdcmodel.cdcmodelType_check_in'), value: 'CHECK_IN'},
        {label: this.$t('cdcmodel.cdcmodelType_check_out'), value: 'CHECK_OUT'},
        {label: this.$t('cdcmodel.cdcmodelType_cleaning'), value: 'CLEANING'},
        {label: this.$t('cdcmodel.cdcmodelType_cleaning_before'), value: 'CLEANING_BEFORE'},
        {label: this.$t('cdcmodel.cdcmodelType_cleaning_after'), value: 'CLEANING_AFTER'},
        {label: this.$t('cdcmodel.cdcmodelType_running'), value: 'RUNNING'},
        {label: this.$t('cdcmodel.cdcmodelType_quality_control'), value: 'QUALITY_CONTROL'},
        {label: this.$t('cdcmodel.cdcmodelType_maintenance'), value: 'MAINTENANCE'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForCdcmodelTypeConst(value) {
      let result = this.cdcmodelTypesConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
