<script>
import AkLabel from '@components/v2/general/AkLabel';
import PTextarea from 'primevue/textarea';

export default {
  components: {AkLabel, PTextarea},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}, pIgnoredValidator: {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    ignoredValidator: {
      type: Array,
      required: false,
      default: undefined,
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    rows: {
      type: Number,
      required: false,
      default: 5,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    getIgnoredValidator() {
      if (this.ignoredValidator != undefined) return this.ignoredValidator;
      if (this.pIgnoredValidator != undefined) return this.pIgnoredValidator;
      return [];
    },
    isInvalid() {
      if (this.hasValidator && this.getIgnoredValidator.length > 0) {
        if (this.validator.$errors) {
          for (let error of this.validator.$errors) {
            if (this.getIgnoredValidator.indexOf(error.$validator) === -1) {
              return true;
            }
          }
        } else {
          if (this.hasValidator) {
            for (let p of Object.keys(this.validator)) {
              if (
                this.getIgnoredValidator.indexOf(p) === -1 &&
                this.validator[p] === false &&
                p.startsWith('$') === false &&
                this.validator.$invalid
              ) {
                return true;
              }
            }
          } else return false;
        }
      } else {
        return this.hasValidator && this.validator.$invalid;
      }
      return false;
    },
    isRequired() {
      return (
        this.hasValidator &&
        this.validator.required !== undefined &&
        (this.validator.required.$params === undefined ||
          this.validator.required.$params.type === 'required' ||
          (this.validator.required.$params.type === 'requiredIf' && this.validator.required.$params.prop === true))
      );
    },
    isRequiredFailed() {
      return (
        this.isRequired &&
        this.getIgnoredValidator.indexOf('required') === -1 &&
        (this.validator.required.$invalid || this.validator.$invalid) &&
        this.alreadySubmitted
      );
    },

    isMinLength() {
      return this.hasValidator && this.validator.minLengthValue !== undefined;
    },
    isMinLengthFailed() {
      return this.isMinLength && this.validator.minLengthValue.$invalid && this.alreadySubmitted;
    },

    isMaxLength() {
      return this.hasValidator && this.validator.maxLengthValue === false;
    },
    isMaxLengthFailed() {
      return (
        ((this.isMaxLength && this.validator.maxLengthValue.$invalid) ||
          (this.isMaxLength && this.validator.$invalid)) &&
        this.alreadySubmitted
      );
    },

    isMinMaxLength() {
      return this.isMinLength && this.isMaxLength;
    },
    isMinMaxLengthFailed() {
      return this.isMinMaxLength && (this.isMinLengthFailed || this.isMaxLengthFailed);
    },
    getMaxLength() {
      return this.validator.maxLengthValue ? this.validator.maxLengthValue : this.validator;
    },
    hasMaxLengthValue() {
      return this.getMaxLength.$params != undefined;
    },

    isEmail() {
      return this.hasValidator && this.validator.email !== undefined;
    },
    isEmailFailed() {
      return this.isEmail && this.validator.email.$invalid && this.alreadySubmitted;
    },

    isPhone() {
      return this.hasValidator && this.validator.phoneValidator !== undefined;
    },
    isPhoneFailed() {
      return this.isPhone && this.validator.phoneValidator.$invalid && this.alreadySubmitted;
    },

    isNotUsed() {
      return this.hasValidator && this.validator.notUsed !== undefined;
    },
    isNotUsedFailed() {
      return this.isNotUsed && this.validator.notUsed.$invalid && this.alreadySubmitted;
    },
  },
};
</script>

<template>
  <div :class="className" class="form-control">
    <AkLabel v-if="label && displayLabel" :required="this.isRequired">
      {{ label }}
    </AkLabel>
    <PTextarea
      v-model="currentValue"
      :class="{'p-invalid': isInvalid && alreadySubmitted}"
      :disabled="isDisabled"
      :placeholder="getPlaceholder"
      :rows="rows"
      :type="inputType"
      class="w-full text-dark text-base font-inter form-control" />

    <div
      class="flex flex-col flex-1"
      v-if="
        isRequiredFailed ||
        isEmailFailed ||
        isPhoneFailed ||
        isNotUsedFailed ||
        isMinMaxLengthFailed ||
        isMinLengthFailed ||
        isMaxLengthFailed
      ">
      <small v-if="isRequiredFailed" class="p-error">
        {{ $t('field_required', [this.label]) }}
      </small>
      <small v-if="isEmailFailed" class="p-error">
        {{ $t('bad_format', [this.label]) }}
      </small>
      <small v-if="isPhoneFailed" class="p-error">
        {{ $t('check_phone_number') }}
      </small>
      <small v-if="isNotUsedFailed" class="p-error">
        {{ $t('key_already_used') }}
      </small>
      <small v-if="isMinMaxLengthFailed" class="p-error">
        {{
          $t('min_max_length', [
            this.label,
            this.validator.minLengthValue.$params.min,
            this.validator.maxLengthValue.$params.max,
          ])
        }}
      </small>
      <small v-else-if="isMinLengthFailed" class="p-error">
        {{ $t('min_length', [this.label, this.validator.minLengthValue.$params.min]) }}
      </small>
      <small v-else-if="isMaxLengthFailed && hasMaxLengthValue" class="p-error">
        {{ $t('max_length', [this.label, this.getMaxLength.$params.max]) }}
      </small>
      <small v-else-if="isMaxLengthFailed" class="p-error">
        {{ $t('max_length_no_bound', [this.label]) }}
      </small>
    </div>
  </div>
</template>
