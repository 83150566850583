import accommodationService from '@services/accommodationService';

export default {
  data() {
    return {
      autocompleteAccommodationQuery: 'name',
    };
  },
  methods: {
    autocompleteAccommodation(e) {
      return accommodationService.autocomplete(e);
    },
    autocompleteAccommodationById(e) {
      return accommodationService.accommodationDisplay(e);
    },
  },
};
