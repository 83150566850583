<script>
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';

export default {
  components: {AkButtonValidation, AkButtonAction},
  props: {
    cancelLabel: {
      type: String,
      required: false,
      default: 'Annuler',
    },
    validateLabel: {
      type: String,
      required: true,
    },
  },
  emits: ['action', 'cancel'],
  methods: {
    init() {
      this.$refs.btnUpdate.init();
    },
    clickAction() {
      this.$emit('action');
    },
    clickCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <div class="grid grid-cols-4 gap-6">
    <AkButtonAction :label="this.cancelLabel" class="col-start-2 col-end-2" @click="clickCancel()" />
    <AkButtonValidation
      ref="btnUpdate"
      :label="this.validateLabel"
      class="col-start-3 col-end-5 xs btn-primary-gradient"
      @action="clickAction" />
  </div>
</template>
