import Api from '@/services/api';

export default {
  roles() {
    return Api()
      .get('/roles')
      .then((res) => res.data);
  },
  permissions(roleId) {
    return Api()
      .get('/role/' + roleId + '/permissions')
      .then((res) => res.data);
  },
};
