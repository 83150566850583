<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import HousekeeperFilter from '@views/settings/Stakeholder/filters/HousekeeperFilter.vue';
import PartenaireFilter from '@views/settings/Stakeholder/filters/PartenaireFilter.vue';
import EmployeeCreateSidebar from '@views/employee/EmployeeCreateSidebar.vue';
import EmployeeList from '@views/settings/Stakeholder/tabs/EmployeeList.vue';
import PartenaireList from '@views/settings/Stakeholder/tabs/PartenaireList.vue';
import HousekeeperList from '@views/settings/Stakeholder/tabs/HousekeeperList.vue';
import PrestataireList from '@views/settings/Stakeholder/tabs/PrestataireList.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import housekeeperService from '@services/housekeeperService';
import partenaireService from '@services/partenaireService';

export default {
  components: {
    AkButtonAction,
    GaListView,
    GaView,
    HousekeeperFilter,
    PartenaireFilter,
    EmployeeCreateSidebar,
    EmployeeList,
    PartenaireList,
    HousekeeperList,
    PrestataireList,
  },
  mixins: [randomRef, loaderMixin, messageMixin, utilsMixin],
  metaInfo() {
    return {
      title: 'settings.stakeholders',
    };
  },
  data() {
    return {
      currentTab: 'EMPLOYEES',
      employeeFilter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      prestataireFilter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      partenaireFilter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      housekeeperFilter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      refresher: false,
      showEmployeeCreateSidebar: false,
    };
  },
  methods: {
    query(search) {
      switch (this.currentTab) {
        case 'EMPLOYEES':
          this.employeeFilter.query = search;
          break;
        case 'PRESTATAIRES':
          this.prestataireFilter.query = search;
          break;
        case 'PARTENAIRES':
          this.partenaireFilter.query = search;
          break;
        case 'HOUSEKEEPERS':
          this.housekeeperFilter.query = search;
          break;
      }
    },
    openCreateSidebar() {
      if (this.$refs.employeeCreateSidebar) this.$refs.employeeCreateSidebar.show();
      this.showEmployeeCreateSidebar = true;
    },
    exportList() {
      this.showTotalLoaderWithAfter(this.$t('litige.list_creation_in_progress'));
      let p;
      if (this.currentTab === 'PARTENAIRES') p = partenaireService.exportList(this.partenaireFilter);
      if (this.currentTab === 'HOUSEKEEPERS') p = housekeeperService.exportList(this.housekeeperFilter);
      p.catch((e) => {
        let message = this.msgForErr(this.$t, e);
        this.addError(message);
      }).finally(() => {
        this.hideLoader();
      });
    },
    refreshEmployeeList() {
      this.refresher = !this.refresher;
    },
  },
};
</script>

<template>
  <GaView>
    <template #action>
      <AkButtonAction
        v-if="currentTab === 'PARTENAIRES' || currentTab === 'HOUSEKEEPERS'"
        :label="$t('download')"
        little
        @click="exportList" />
      <AkButtonAction v-if="currentTab === 'EMPLOYEES'" :label="$t('add')" little @click="openCreateSidebar" />
    </template>
    <template #content>
      <GaListView searchable @search="query">
        <template #filter>
          <PartenaireFilter v-if="currentTab === 'PARTENAIRES'" @search="partenaireFilter = {...$event}" />
          <HousekeeperFilter v-if="currentTab === 'HOUSEKEEPERS'" @search="housekeeperFilter = {...$event}" />
        </template>
        <template #tabs>
          <div class="flex items-center">
            <span
              :class="[{active: currentTab === 'EMPLOYEES'}]"
              class="list-table-item"
              @click="currentTab = 'EMPLOYEES'"
              >{{ $t('employee.list') }}</span
            >
            <span
              :class="[{active: currentTab === 'PRESTATAIRES'}]"
              class="list-table-item"
              @click="currentTab = 'PRESTATAIRES'"
              >{{ $t('prestataire.title') }}</span
            >
            <span
              :class="[{active: currentTab === 'PARTENAIRES'}]"
              class="list-table-item"
              @click="currentTab = 'PARTENAIRES'"
              >{{ $t('partenaire.title') }}</span
            >
            <span
              :class="[{active: currentTab === 'HOUSEKEEPERS'}]"
              class="list-table-item"
              @click="currentTab = 'HOUSEKEEPERS'"
              >{{ $t('housekeeper.title') }}</span
            >
          </div>
        </template>
        <template #content>
          <EmployeeList v-if="currentTab === 'EMPLOYEES'" :key="refresher" :filter="employeeFilter" />
          <PrestataireList v-if="currentTab === 'PRESTATAIRES'" :filter="prestataireFilter" />
          <PartenaireList v-if="currentTab === 'PARTENAIRES'" :filter="partenaireFilter" />
          <HousekeeperList v-if="currentTab === 'HOUSEKEEPERS'" :filter="housekeeperFilter" />
        </template>
      </GaListView>
    </template>
    <template #extra>
      <EmployeeCreateSidebar
        v-if="showEmployeeCreateSidebar"
        ref="employeeCreateSidebar"
        @creation="refreshEmployeeList" />
    </template>
  </GaView>
</template>
