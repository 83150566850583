export default {
  data() {
    return {
      documentModelTypeConst: [
        {
          label: 'RH',
          value: 'RH',
          children: [
            {label: this.$t('undefined'), value: undefined},
            {label: 'Contrat de travail', value: 'CONTRACT'},
            {label: 'Avenant de contrat', value: 'AVENANT'},
          ],
        },
        {
          label: 'Commercial',
          value: 'COMMERCIAL',
          children: [
            {label: this.$t('undefined'), value: undefined},
            {
              label: 'Mandat de gestion',
              value: 'MANDAT',
            },
            {
              label: 'Avenant au mandat de gestion',
              value: 'AVENANT',
            },
            {
              label: 'Compte-rendu de gestion hôte',
              value: 'CRG',
            },
            {
              label: 'Compte-rendu de gestion hôte avec TVA',
              value: 'CRG_TVA',
            },
            {
              label: this.$t('settings.document_balance'),
              value: 'BALANCE',
            },
          ],
        },
        {
          label: 'Prestation',
          value: 'PRESTATION',
          children: [
            {label: this.$t('undefined'), value: undefined},
            {label: this.$t('prestation.prestationType_check_in'), value: 'CHECK_IN'},
            {label: this.$t('prestation.prestationType_check_out'), value: 'CHECK_OUT'},
            {label: this.$t('prestation.prestationType_cleaning'), value: 'CLEANING'},
            {label: this.$t('prestation.prestationType_cleaning_before'), value: 'CLEANING_BEFORE'},
            {label: this.$t('prestation.prestationType_cleaning_after'), value: 'CLEANING_AFTER'},
            {label: this.$t('prestation.prestationType_running'), value: 'RUNNING'},
            {label: this.$t('prestation.prestationType_maintenance'), value: 'MAINTENANCE'},
            {label: this.$t('prestation.prestationType_quality_control'), value: 'QUALITY_CONTROL'},
          ],
        },
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  created() {},
  methods: {
    displayTypeForDocumentModelTypeConst(value) {
      let result = this.documentModelTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    displaySubTypeForDocumentModelTypeConst(type, value) {
      let result = this.documentModelTypeConst.find((el) => el.value === type);
      if (result) {
        let tmp = result.children.find((i) => i.value === value);
        return tmp ? tmp.label : '';
      }
      return '';
    },
    childrenForDocumentModelType(value) {
      if (value) {
        let result = this.documentModelTypeConst.find((el) => el.value === value);
        return result ? result.children : [];
      }
      return [];
    },
  },
};
