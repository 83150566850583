export default {
  data() {
    return {
      taskTypeConst: [
        {label: this.$t('tasktype.picture'), value: 'PICTURE'},
        {label: this.$t('tasktype.picture_text'), value: 'PICTURE_TEXT'},
        {label: this.$t('tasktype.done'), value: 'DONE'},
        {label: this.$t('tasktype.yesno'), value: 'YESNO'},
        {label: this.$t('tasktype.date'), value: 'DATE'},
        {label: this.$t('tasktype.rating'), value: 'RATING'},
        {label: this.$t('tasktype.simple_option'), value: 'SIMPLE_OPTION'},
        {label: this.$t('tasktype.multi_option'), value: 'MULTI_OPTION'},
        {label: this.$t('tasktype.done2'), value: 'DONE2'},
        {label: this.$t('tasktype.free_text'), value: 'FREE_TEXT'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForTaskTypeConst(value) {
      let result = this.taskTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
