<script>
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';
import {isValidPhoneNumber} from 'libphonenumber-js';
/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkFilesUpload from '@components/v2/input/AkFilesUpload.vue';
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import AkFicItem from '@components/v2/layout/AkFicItem.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import companyMixin from '@mixins/companyMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import formatMixin from '@mixins/formatMixin';
import {useCompanyStore} from '@store/companyStore';

/* SERVICES */
import prestataireService from '@services/prestataireService';
import ficService from '@services/ficService';

const phoneValidator = (value) => !value || isValidPhoneNumber(value, 'FR');

export default {
  components: {
    AkButtonFooterSidebar,
    AkFicItem,
    AkSidebar,
    AkFilesUpload,
    AkCalendar,
    GaMessages,
    AkDropdown,
    AkInputText,
  },
  mixins: [messageMixin, civilitiesConst, companyMixin, permissionsMixin, formatMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      poles: [],
      companies: [],
      files: [],
      updateSidebarVisible: false,
      prestataire: {},
      submitted: false,
      loading: true,
    };
  },
  validations() {
    return {
      prestataire: {
        contact: {
          civility: {required},
          firstName: {required},
          lastName: {required},
          phone: {required, phoneValidator: phoneValidator},
          homePhone: {phoneValidator: phoneValidator},
        },
        companyId: {required},
      },
    };
  },
  emits: ['update', 'updateFile', 'hide'],
  methods: {
    show(id) {
      this.updateSidebarVisible = true;
      this.prestataireId = id;
      useCompanyStore()
        .all()
        .then((companies) => {
          this.companies = companies;
        });
      this.reload();
    },
    hide() {
      this.updateSidebarVisible = false;
      this.prestataire = undefined;
      this.prestataireId = undefined;
      this.files = [];
      this.$emit('hide');
    },
    reload() {
      prestataireService.get(this.prestataireId).then((data) => {
        this.prestataire = data;
      });
    },
    update() {
      if (this.prestataireId === -1) return;

      this.submitted = true;
      this.v$.prestataire.$touch();
      if (this.v$.prestataire.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      prestataireService
        .update(this.prestataireId, this.prestataire, this.files)
        .then(() => {
          this.success(this.$t('prestataire.updated'));
          this.prestataire = {};
          this.$emit('update');
          this.hide();
        })
        .catch((e) => {
          this.$refs.sidebarMessages.error(this.$t('error.' + e.response.data.error));
          this.$refs.footerSidebar.init();
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    deleteFile(file) {
      ficService
        .delete(file)
        .then(() => {
          this.prestataire.files = this.prestataire.files.filter((f) => f.id !== file.id);
          this.$emit('updateFile');
          this.success('Le fichier a bien été supprimé');
        })
        .catch((e) => {
          this.addError('Une erreur est survenue');
        });
    },
  },
};
</script>

<template>
  <AkSidebar
    v-model:visible="updateSidebarVisible"
    :baseZIndex="9998"
    :dismissable="true"
    block-scroll
    position="right"
    @hide="hide">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('prestataire.update') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div v-if="prestataire?.contact" class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4">
          <span class="title-border text-dark text-xl font-bold mb-3">Informations</span>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2">
            <AkDropdown
              v-model="prestataire.contact.civility"
              :label="$t('prestataire.civility')"
              :options="civilitiesConst"
              :submitted="submitted"
              :validator="v$.prestataire.contact.civility" />
            <AkCalendar
              v-model="prestataire.contact.birthDate"
              :date-format="'dd/mm/yy'"
              :label="$t('prestataire.birthDate')"
              :maxDate="new Date()"
              :submitted="submitted"
              :validator="v$.prestataire.contact.birthDate" />
            <AkInputText
              v-model="prestataire.contact.lastName"
              :formatter="toUppercase"
              :label="$t('prestataire.lastName')"
              :submitted="submitted"
              :validator="v$.prestataire.contact.lastName" />
            <AkInputText
              v-model="prestataire.contact.firstName"
              :label="$t('prestataire.firstName')"
              :submitted="submitted"
              :validator="v$.prestataire.contact.firstName" />
          </div>
          <span class="title-border text-dark text-xl font-bold mb-3">Coordonnées</span>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2">
            <AkInputText
              v-model="prestataire.contact.phone"
              :label="$t('prestataire.phone')"
              :submitted="submitted"
              :validator="v$.prestataire.contact.phone"
              icon="ga-icon ga-call"
              type="tel" />
            <AkInputText
              v-model="prestataire.contact.homePhone"
              :label="$t('prestataire.homePhone')"
              :submitted="submitted"
              :validator="v$.prestataire.contact.homePhone"
              icon="ga-icon ga-call"
              type="tel" />
          </div>
          <template v-if="isGas()">
            <span class="title-border text-dark text-xl font-bold mb-3">Informations complémentaires</span>
            <AkDropdown
              v-model="prestataire.companyId"
              :label="$t('prestataire.company')"
              :options="companies"
              :submitted="submitted"
              :validator="v$.prestataire.companyId"
              option-label="name"
              option-value="id" />
          </template>
          <span class="title-border text-dark text-xl font-bold mb-3">{{ $t('files') }}</span>
          <div v-if="prestataire.files && prestataire.files.length > 0" class="grid grid-cols-1 mt-4 gap-4 md:gap-2">
            <div v-for="(file, idx) of prestataire.files" :key="file" class="flex flex-col gap-2">
              <AkFicItem v-model="prestataire.files[idx]" @delete="deleteFile(file)" />
            </div>
          </div>
          <AkFilesUpload v-model="files" multiple />

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancel-label="$t('cancel')"
            :validate-label="$t('update')"
            @action="update()"
            @cancel="hide()" />
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
