<script>
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation.vue';

export default {
  components: {AkPictureAccommodation},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rating() {
      if (this.item.globalRating || this.item.nbTotalReviews)
        return {rating: this.item.globalRating, nbReviews: this.item.nbTotalReviews};
      return undefined;
    },
  },
};
</script>

<template>
  <router-link :to="{name: 'accommodationPage', params: {accommodationId: item.id}}">
    <div class="flex flex-col">
      <AkPictureAccommodation
        :name="item.name"
        :md5="item.md5"
        cover
        :height="260"
        :id="item.id"
        :rating="this.rating" />
      <div class="flex justify-between px-4">
        <div class="flex flex-col items-center">
          <i class="ga-icon text-gray ga-path text-2xl opacity-65"></i>
          <span class="text-dark font-medium opacity-80 text-base">{{ item.area }}m2</span>
        </div>
        <div class="flex flex-col items-center">
          <i class="ga-icon text-gray ga-users text-2xl opacity-65"></i>
          <span class="text-dark font-medium opacity-80 text-base">{{ item.capacity }}</span>
        </div>
        <div class="flex flex-col items-center">
          <i class="ga-icon text-gray ga-bath text-2xl opacity-65"></i>
          <span class="text-dark font-medium opacity-80 text-base">{{ item.nbBathroom }}</span>
        </div>
        <div class="flex flex-col items-center">
          <i class="ga-icon text-gray ga-bed text-2xl opacity-65"></i>
          <span class="text-dark font-medium opacity-80 text-base">{{ item.nbBedroom }}</span>
        </div>
      </div>
    </div>
  </router-link>
</template>
