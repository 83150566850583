<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkButtonValidation from '@components/v2/general/AkButtonValidation';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkFileUploadSimple from '@components/v2/input/AkFileUploadSimple';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputText from '@components/v2/input/AkInputText';
import GaView from '@components/layout/GaView';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import documentModelTypeConst from '@mixins/const/documentModelTypeConst';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import documentModelService from '@services/documentModelService';

export default {
  components: {
    AkButtonValidation,
    AkDropdown,
    AkFileUploadSimple,
    AkFormSubmitted,
    AkInputText,
  },
  mixins: [backMixin, documentModelTypeConst, loaderMixin, messageMixin, randomRef, utilsMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'document_model.add',
    };
  },
  emits: ['create-done'],
  data() {
    return {
      messages: [],
      submitted: false,
      file: undefined,
      documentModel: {},
    };
  },
  validations() {
    return {
      documentModel: {
        type: {required},
        name: {required},
        subType: {required},
      },
      file: {required},
    };
  },
  computed: {
    getSubType() {
      if (this.documentModel.type) return this.childrenForDocumentModelType(this.documentModel.type);
      return [];
    },
  },
  watch: {
    'documentModel.type': function () {
      this.documentModel.subType = undefined;
    },
  },
  mounted() {},
  methods: {
    addSubError(content) {
      this.messages = [];
      this.messages.push({id: this.generateUUID(), closable: true, severity: 'error', content: content, life: 10000});
    },
    create() {
      this.messages = [];
      this.$refs.btnCreate.load();
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.btnCreate.init();
        return;
      }
      documentModelService
        .create(this.documentModel, this.file)
        .then(() => {
          this.$refs.btnCreate.init();
          this.success(this.$t('document_model.added'));
          this.submitted = false;
          this.file = undefined;
          this.documentModel = {};
          this.$emit('create-done');
        })
        .catch((e) => {
          this.$refs.btnCreate.init();
          this.addSubError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <Message
    class="mb-2"
    v-for="msg of this.messages"
    :key="msg.id"
    :closable="msg.closable"
    :severity="msg.severity"
    :life="msg.life">
    {{ msg.content }}
  </Message>
  <AkFormSubmitted :submitted="submitted" reference="form" @submit="create()">
    <div class="flex flex-col gap-2">
      <AkInputText
        v-model="documentModel.name"
        :label="$t('document_model.name')"
        :validator="v$.documentModel.name"
        class-name="col-md-4" />
      <AkDropdown
        v-model="documentModel.type"
        :label="$t('document_model.type')"
        :options="documentModelTypeConst"
        :validator="v$.documentModel.type" />
      <AkDropdown
        v-if="documentModel.type"
        v-model="documentModel.subType"
        :label="$t('document_model.subtype')"
        :options="getSubType"
        :validator="v$.documentModel.subType" />
      <AkFileUploadSimple
        ref="uploader"
        v-model="file"
        :validator="v$.file"
        :label="$t('document_model.file')"
        accept=".docx,application/pdf" />
    </div>
    <div class="mt-2 flex justify-end">
      <AkButtonValidation ref="btnCreate" @action="create" :label="$t('add')"></AkButtonValidation>
    </div>
  </AkFormSubmitted>
</template>
