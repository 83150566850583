export default {
  data() {
    return {
      prestationStatusConst: [
        {label: this.$t('prestataire.status_available'), value: 'AVAILABLE'},
        {label: this.$t('prestataire.status_unavailable'), value: 'UNAVAILABLE'},
        {label: this.$t('prestataire.status_in_mission'), value: 'IN_MISSION'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForPrestataireStatusConst(value) {
      let result = this.prestationStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
