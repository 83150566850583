<script>
import {getDayShort, getMonthShort} from '@utils/dateTime';

/* COMPOSANTS */
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import missionMixin from '@mixins/missionMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';

/* SERVICES */
import missionPlanningParticipantService from '@/services/missionPlanningParticipantService';

export default {
  components: {DataTable, Column},
  mixins: [utilsMixin, missionMixin, prestationTypesConst],
  props: {
    modelValue: {
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hours: [],
      participant: {},
      loading: false,
    };
  },
  setup() {
    return {};
  },
  watch: {},
  mounted() {
    this.loading = true;

    // Défini de 8h à 20h : #8489
    const minHour = 8;
    const maxHour = 20;

    // Récupération des heures (ouvrées)
    this.hours = this.getHoursFromRange(minHour, maxHour);
    // transformation en objet pour le Datatable
    this.hours = this.hours.map((hour) => ({hour}));

    // Récupération des détails des missions pour l'intervenant
    const filter = {
      assignedStaff: this.currentValue.assignedStaff,
      dateStart: this.filter.dateStart,
      dateEnd: this.filter.dateEnd,
      statusList: this.filter.statusList,
      types: this.filter.types,
      reservationId: this.filter.reservationId,
      dateCheckin: this.filter.dateCheckin,
      dateCheckout: this.filter.dateCheckout,
    };

    missionPlanningParticipantService
      .participant(this.currentValue.id, filter)
      .then((data) => {
        this.participant = data;
      })
      .catch((e) => {
        let message = this.msgForErr(this.$t, e);
        this.addError(message);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    getDayShort,
    getMonthShort,

    // GET HOURS FROM RANGE

    // Prend des nombres en entrée
    getHoursFromRange(startHour, endHour) {
      if (startHour < 0) startHour = 0;
      if (endHour > 23) endHour = 23;
      if (startHour == endHour) return [startHour];
      if (startHour > endHour || endHour < startHour) return [];
      let hours = [];
      for (let i = startHour; i <= endHour; i++) {
        let date = new Date();
        date.setHours(i, 0);
        const hour = this.displayDateWith(date, 'HH:mm');
        hours.push(hour);
      }
      return hours;
    },

    // Sert pour l'affichage d'une mission dans la bonne tranche horaire
    isMissionOnSameHour(mission, hourItem) {
      let dateStart = new Date(mission.dateStart);
      let dateEnd = new Date(mission.dateEnd);
      let hour = this.createDateFromTime(hourItem);
      const isSameStart = dateStart.getHours() === hour.getHours();
      const isSameEnd = dateEnd.getHours() === hour.getHours() && dateEnd.getMinutes() > 0;
      const isBetweenStartAndEnd = dateStart.getHours() < hour.getHours() && dateEnd.getHours() > hour.getHours();
      return isSameStart || isSameEnd || isBetweenStartAndEnd;
    },

    // DAY BACKGROUND FOR HOUR
    getDayBackgroundForHour(missions, hourItem) {
      if (!missions) return '';
      let unavailability = missions.find((m) => m?.type === 'UNAVAILABILITY');
      if (unavailability) {
        if (unavailability.dateStart.getHours() > 0 && unavailability.dateEnd.getHours() < 23) {
          let dateStart = new Date(unavailability.dateStart);
          let dateEnd = new Date(unavailability.dateEnd);
          let hour = this.createDateFromTime(hourItem);
          return hour.getHours() >= dateStart.getHours() && hour.getHours() < dateEnd.getHours()
            ? 'unavailability'
            : '';
        }
        return 'unavailability';
      }
      return '';
    },

    getTaskBackgroundForHourBetweenStartAndEnd(mission, hourItem) {
      let dateStart = new Date(mission.dateStart);
      let dateEnd = new Date(mission.dateEnd);
      let hour = this.createDateFromTime(hourItem);
      const isBetweenStartAndEnd = dateStart.getHours() < hour.getHours() && dateEnd.getHours() > hour.getHours();
      return isBetweenStartAndEnd ? 'full-height' : '';
    },
  },
};
</script>

<template>
  <div class="participants-planning">
    <h2>{{ currentValue.name }}</h2>
    <DataTable
      :value="this.hours"
      :paginator="false"
      :always-show-paginator="false"
      :rows="1"
      :loading="this.loading"
      class="table pointer gd-datatable"
      current-page-report-template="{first}-{last}/{totalRecords}"
      removable-sort
      responsive-layout="scroll"
      show-gridlines
      striped-rows>
      <Column field="hour" :header="this.$t('planning_intervenant.start_hour')"></Column>

      <Column v-for="date in dates" :key="date.toString()" :field="date.toString()">
        <template #header>
          <div class="d-flex justify-content-around align-items-center w-100 px-1">
            <span v-text="getDayShort(date)"></span>
            <span class="date-badge bg-primary" v-text="new Date(date).getDate()"></span>
            <span v-text="getMonthShort(date)"></span>
          </div>
        </template>
        <template #body="slotProps" v-if="!this.loading && this.participant.dateMap">
          <div
            :class="getDayBackgroundForHour(this.participant.dateMap[dateToIndex(date)], slotProps.data.hour)"
            class="day">
            <template v-for="mission in this.participant.dateMap[dateToIndex(date)]" :key="mission.id">
              <a
                v-if="mission.id !== null && isMissionOnSameHour(mission, slotProps.data.hour)"
                class="task"
                :class="[
                  getTaskBackground(mission),
                  getTaskBackgroundForHourBetweenStartAndEnd(mission, slotProps.data.hour),
                ]"
                :href="`/mission-order/${mission.id}`"
                v-tooltip.bottom="mission.addressDisplay">
                <span v-text="mission.accommodationName"></span>
                <span v-text="displayForPrestationTypeConst(mission.type)" class="mt-1"></span>
                <span v-text="displayPrestationTime(mission)"></span>
              </a>
            </template>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style lang="scss" scoped>
.participants-planning {
  .date-badge {
    border-radius: 32px;
    padding: 5px 8px;
    font: 600 12px/18px 'Nunito Sans';
    color: white;
  }

  .p-datatable > .p-datatable-wrapper > .p-datatable-tbody > tr {
    background-color: red !important;
  }

  td {
    .day {
      display: flex;
      flex-direction: column;
      min-height: 75px;

      &.unavailability {
        border-radius: 4px;
        padding: 5%;
        background-image: linear-gradient(
          45deg,
          #cccccc 25%,
          #ffffff 25%,
          #ffffff 50%,
          #cccccc 50%,
          #cccccc 75%,
          #ffffff 75%,
          #ffffff 100%
        );
        background-size: 40px 40px;
        width: 100%;
        height: 100%;
      }

      > .task {
        padding: 8px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;

        &.full-height {
          padding-bottom: 65%;
        }

        &:first-child {
          padding-top: 8px;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        > span {
          font: 400 14px/20px 'Nunito Sans';
          color: white;
        }
      }
    }
  }
}
</style>
