<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputMoney from '@components/v2/input/AkInputMoney.vue';
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkFicList from '@components/v2/general/AkFicList.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */

export default {
  components: {AkFicList, AkInputText, AkInputMoney},
  mixins: [utilsMixin],
  props: {
    modelValue: {
      required: true,
    },
    submitted: {
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    displayForSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
    displayMaintenanceFiles: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayMaintenanceDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDeleteFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
    canUploadFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
    bgClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      currentValue: {
        amountEstimatedTtc: {required},
      },
    };
  },
  data() {
    return {};
  },
  mounted() {
    if (!this.currentValue.ficList) this.currentValue.ficList = [];
  },
  emits: ['removed', 'amount-changed'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    title() {
      return 'Maintenance n°' + (this.index + 1);
    },
    canDelete() {
      return this.canDeleteFiles && this.currentValue.ficList?.length > 0;
    },
    canUpload() {
      return this.canUploadFiles;
    },
    amountEstimatedTtc() {
      if (!this.currentValue.amountEstimatedTtc) return '';
      return this.formatCurrencyEUR(this.currentValue.amountEstimatedTtc);
    },
  },
  methods: {
    removed() {
      this.$emit('removed');
    },
    amountChange(event) {
      this.$emit('amount-changed');
    },
  },
};
</script>

<template>
  <div class="p-2">
    <span class="title-border mb-4">{{ title }}</span>

    <div :class="{'md:grid-cols-3': !displayForSidebar}" class="grid sm:grid-cols-2 grid-cols-1 gap-4" v-if="disabled">
      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('maintenance.date_maintenance') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{
          this.formatDateIfNeeded(currentValue.maintenanceDate)
        }}</span>
      </div>
      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.date') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{
          this.formatDateIfNeeded(currentValue.dateCreated)
        }}</span>
      </div>
      <div class="flex flex-col gap-1" :class="{'col-span-2': displayForSidebar}">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('maintenance.problem') }}</span>
        <span class="font-inter font-medium text-gray text-md break-all">{{ currentValue.maintenanceProblem }}</span>
      </div>
    </div>
  </div>

  <div class="p-2" v-if="disabled">
    <div class="grid grid-cols-1 gap-4">
      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('maintenance.description') }}</span>
        <span class="font-inter font-medium text-gray text-md break-all">{{
          currentValue.maintenanceDescription
        }}</span>
      </div>
    </div>
  </div>

  <div class="p-2">
    <div
      class="grid grid-cols-1 gap-4"
      :class="{'sm:grid-cols-3': !displayForSidebar, 'sm:grid-cols-2': displayForSidebar}">
      <template v-if="disabled">
        <div class="flex flex-col gap-1">
          <span class="font-inter font-semibold text-dark text-base">{{ $t('maintenance.amount_estimated') }}</span>
          <span class="font-inter font-medium text-gray text-md">{{ this.amountEstimatedTtc }}</span>
        </div>

        <div class="flex flex-col gap-1 sm:col-span-2">
          <span class="font-inter font-semibold text-dark text-base">{{ $t('maintenance.link_url') }}</span>
          <span class="font-inter font-medium text-primary text-md break-all">
            <a v-if="currentValue.link" :href="currentValue.link" target="_blank" class="font-semibold">
              {{ currentValue.link }}
            </a>
          </span>
        </div>
      </template>
      <template v-else>
        <AkInputMoney
          v-model="currentValue.amountEstimatedTtc"
          :label="$t('maintenance.amount_estimated')"
          :placeholder="$t('maintenance.amount_estimated')"
          :submitted="submitted"
          :validator="v$.currentValue.amountEstimatedTtc"
          @value-change="amountChange($event)" />
        <AkInputText
          class="sm:col-span-2"
          v-model="currentValue.link"
          :label="$t('maintenance.link_url')"
          :placeholder="$t('maintenance.link_url')"
          :linkExternal="{path: currentValue.link}"
          :submitted="submitted" />
      </template>
    </div>
  </div>

  <!-- Facture -->

  <div class="p-2">
    <AkFicList
      v-if="this.displayFiles"
      v-model="currentValue.ficList"
      :title="$t('litige.file')"
      :table-id="currentValue.id"
      table-name="litige_item"
      :bgClass="bgClass"
      :gridLayout="!displayForSidebar"
      :canDelete="canDelete"
      :canUpload="canUpload"
      @removed="removed" />
  </div>

  <!-- Photos maintenance -->

  <div class="p-2" v-if="disabled">
    <AkFicList
      v-if="currentValue.maintenanceId && displayMaintenanceFiles"
      v-model="currentValue.maintenanceFicList"
      :title="$t('maintenance.photos')"
      :table-id="currentValue.maintenanceId"
      accept=".png, .jpeg, .jpg"
      table-name="maintenance"
      ficPreview
      :canDelete="false"
      :canUpload="false" />
  </div>
</template>
