export default {
  data() {
    return {
      ticketInterlocutorTypeConst: [
        {label: this.$t('activity.accommodation'), value: 'ACCOMMODATION'},
        {label: this.$t('activity.proprietaire'), value: 'PROPRIETAIRE'},
        {label: this.$t('activity.prospect'), value: 'PROSPECT'},
        {label: this.$t('activity.voyageur'), value: 'VOYAGEUR'},
      ],
    };
  },
  methods: {
    displayForTicketInterlocutorTypeConst(value) {
      let result = this.ticketInterlocutorTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
