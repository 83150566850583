export default {
  data() {
    return {
      zoneTypesConst: [
        {label: this.$t('zone.zoneType_zone'), value: 'ZONE'},
        {label: this.$t('zone.zoneType_subzone'), value: 'SUBZONE'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForZoneTypeConst(value) {
      let result = this.zoneTypesConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
