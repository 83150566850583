<script>
/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import ficService from '@services/ficService';

export default {
  components: {
    GaContentView,
    PButton,
  },
  mixins: [randomRef, utilsMixin, loaderMixin, messageMixin, permissionsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'mandat.avenant_list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      search: {},
    };
  },
  computed: {},
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      let p1 = ficService.ficAvenants(this.search);
      p1.then((data) => {
        this.list = data;
      });

      Promise.all([p1]).then(() => {
        this.loading = false;
      });
    },
    getSignedFic(avenant) {
      let fic = avenant.fic;
      return !!fic;
    },
    download(fic) {
      ficService.download(fic, false).catch((e) => {
        if (e.response.status === 404) {
          this.addError("Le fichier n'existe pas !");
        }
      });
    },
    exportAvenants() {
      this.showTotalLoaderWithAfter(this.$t('account.fic_avenant_creation_in_progress'));
      let p1 = ficService.exportFicAvenants(this.search);
      p1.then((data) => {
        this.hideLoader();
      });
    },
    openFilterPanel() {
      this.toggleFilter('GaAvenantListFilter', this.search, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.search = {};
      this.toggleFilter('GaAvenantListFilter', this.search, this.refresh, this.resetFilter);
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('mandat.avenant_list')">
    <template #action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <PButton
          :badge="this.countFilter(this.search)"
          badgeClass="p-badge-primary"
          class="btn btn-inverse-primary btn-xs"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button class="btn btn-inverse-primary btn-xs" @click="exportAvenants()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #empty>
          {{ $t('settings.avenants_empty') }}
        </template>

        <Column :header="$t('mandat.num')">
          <template #body="slotProps">
            {{ slotProps.data.num }}
          </template>
        </Column>
        <Column :header="$t('mandat.num_avenant')">
          <template #body="slotProps">
            {{ slotProps.data.numAvenant }}
          </template>
        </Column>
        <Column v-if="this.isAdmin()" header="ID de l'avenant">
          <template #body="slotProps">
            {{ slotProps.data.id }}
          </template>
        </Column>
        <Column :header="$t('accommodation.name')">
          <template #body="slotProps">
            <span class="pointer">
              <router-link :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.accommodationId}}">
                {{ slotProps.data.accommodationName }}
              </router-link>
            </span>
          </template>
        </Column>
        <Column :header="$t('accommodation.ownerName')">
          <template #body="slotProps">
            <span class="pointer">
              <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.ownerId}}">
                {{ slotProps.data.ownerDisplay }}
              </router-link>
            </span>
          </template>
        </Column>
        <Column :header="$t('accommodation.companyName')">
          <template #body="slotProps">
            {{ slotProps.data.companyName }}
          </template>
        </Column>
        <Column :header="$t('mandat.status')">
          <template #body="slotProps">
            {{ slotProps.data.mandatStatusDisplay }}
          </template>
        </Column>

        <Column body-style="text-align: right; overflow: visible" header-style=" width: 80px; text-align: btn">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <span class="btn btn-xs btn-primary mr-1 pointer" @click="download(slotProps.data.fic)">
                <i class="fe fe-download" />
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </GaContentView>
</template>
