<script>
import DropdownMenu from 'v-dropdown-menu';
import AkRadio from '@components/v2/input/AkRadio.vue';
import AkCheckbox from '@components/v2/input/AkCheckbox.vue';
import {debounce} from '@utils';
import AkInputText from '@components/v2/input/AkInputText.vue';
import DropdownMenuAk from '@components/v2/general/DropdownMenuAk.vue';

export default {
  emits: ['open'],
  mounted() {},
  methods: {
    openPanel() {
      this.$emit('open');
    },
  },
};
</script>

<template>
  <div
    class="flex text-gray hover:text-primary items-center gap-1 py-2.5 px-1.5 hover:cursor-pointer"
    @click="openPanel()">
    <i class="ga-icon ga-filter" />
    <span class="flex-1 font-inter font-medium text-sm">Tous les filtres</span>
  </div>
</template>
