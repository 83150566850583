<script>
import {required, requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputMoney from '@components/v2/input/AkInputMoney.vue';
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkAutocomplete from '@components/v2/input/AkAutocomplete';

/* MIXINS */
import accountAutocompleteMixin from '@mixins/autocomplete/accountAutocompleteMixin';

/* SERVICES */

export default {
  components: {
    AkFormSubmitted,
    AkAutocomplete,
    AkInputMoney,
    AkInputText,
  },
  mixins: [accountAutocompleteMixin],
  props: {
    modelValue: {
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    submitted: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['add', 'delete', 'change'],
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      currentValue: {
        accountId: {required},
        label: {required},
        debit: {required: requiredIf(this.isDebitRequired)},
        credit: {required: requiredIf(this.isCreditRequired)},
      },
    };
  },
  data() {
    return {
      accounts: [],
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },

    isDebitRequired() {
      return this.currentValue?.credit === undefined;
    },
    isCreditRequired() {
      return this.currentValue?.debit === undefined;
    },
  },
  methods: {
    addNewLine() {
      this.$emit('add', this.index);
    },
    deleteLine() {
      this.$emit('delete', this.index);
    },
    change() {
      this.$emit('change', this.index);
    },
  },
};
</script>

<template>
  <AkFormSubmitted :submitted="submitted" :reference="'form' + this.index">
    <div :class="!this.$isMobile() ? 'flex items-center justify-between gap-4' : 'grid grid-cols-1 gap-2'">
      <!-- Champs transaction -->

      <AkAutocomplete
        :ref="'accountAutocomplete' + this.index"
        v-model="currentValue.accountId"
        :label="$t('operation.account')"
        :display-label="this.$isMobile() || this.index === 0"
        option-value="id"
        option-label="num"
        :display-value="currentValue.accountNum"
        :search-method="this.comptaAccounts"
        :init-method="this.comptaAccount"
        :disabled="currentValue.id !== undefined"
        :validator="v$.currentValue.accountId" />

      <AkInputText
        v-model="currentValue.label"
        :label="$t('operation.label')"
        :display-label="this.$isMobile() || this.index === 0"
        :validator="v$.currentValue.label" />

      <AkInputMoney
        v-model="currentValue.debit"
        :placeholder="$t('accounting.debit')"
        :label="$t('accounting.debit')"
        :display-label="this.$isMobile() || this.index === 0"
        :validator="v$.currentValue.debit"
        @value-change="change" />

      <AkInputMoney
        v-model="currentValue.credit"
        :placeholder="$t('accounting.credit')"
        :label="$t('accounting.credit')"
        :display-label="this.$isMobile() || this.index === 0"
        :validator="v$.currentValue.credit"
        @value-change="change" />

      <!-- Actions -->

      <div class="flex justify-end gap-2" :class="{'pt-6': this.index === 0}">
        <span class="cursor-pointer" @click="addNewLine()">
          <i v-if="this.canDelete" class="ga-icon ga-plus_circle text-2xl text-primary" />
        </span>
        <span class="cursor-pointer" @click="deleteLine()">
          <i v-if="this.canDelete" class="ga-icon ga-trash text-2xl text-red" />
        </span>
      </div>
    </div>
  </AkFormSubmitted>
</template>
