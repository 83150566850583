<script>
import {useVuelidate} from '@vuelidate/core';
import {maxLength, required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import AkFilesUpload from '@components/v2/input/AkFilesUpload.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkInputTextArea from '@components/v2/input/AkInputTextArea.vue';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import messageMixin from '@mixins/messageMixin';
import roleKeysConst from '@mixins/const/roleKeysConst';
import roleTypesConst from '@mixins/const/roleTypesConst';
import civilitiesConst from '@mixins/const/civilitiesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import maintenanceInterlocutorTypeConst from '@mixins/const/maintenanceInterlocutorTypeConst';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';
import employeeAutocompleteMixin from '@mixins/autocomplete/employeeAutocompleteMixin';
import partenaireAutocompleteMixin from '@mixins/autocomplete/partenaireAutocompleteMixin';
import prestataireAutocompleteMixin from '@mixins/autocomplete/prestataireAutocompleteMixin';
import utilsMixin from '@mixins/utilsMixin';
import companyMixin from '@mixins/companyMixin';

/* SERVICES */
import maintenanceService from '@services/maintenanceService';
import accommodationService from '@services/accommodationService';
import housekeeperService from '@services/housekeeperService';

export default {
  components: {
    AkSidebar,
    AkInputTextArea,
    AkCalendar,
    AkFilesUpload,
    GaMessages,
    AkDropdown,
    AkInputText,
    AkAutocomplete,
    AkButtonFooterSidebar,
  },
  mixins: [
    accommodationAutocompleteMixin,
    messageMixin,
    roleKeysConst,
    roleTypesConst,
    civilitiesConst,
    permissionsMixin,
    maintenanceInterlocutorTypeConst,
    ownerAutocompleteMixin,
    clientAutocompleteMixin,
    employeeAutocompleteMixin,
    partenaireAutocompleteMixin,
    prestataireAutocompleteMixin,
    utilsMixin,
    companyMixin,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      createSidebarVisible: false,
      employee: {},
      submitted: false,
      maintenance: {},
      accommodations: [],
      files: [],
    };
  },
  validations() {
    return {
      maintenance: {
        companyId: {required: requiredIf(this.isGas())},
        accommodationId: {required},
        interlocutorType: {required},
        ownerId: {required},
        clientId: {required: requiredIf(this.isClientSelected)},
        prestataireId: {required: requiredIf(this.isPrestataireSelected)},
        employeeId: {required: requiredIf(this.isEmployeeSelected)},
        partenaireId: {required: requiredIf(this.isPartenaireSelected)},
        housekeeperId: {required: requiredIf(this.isHousekeeperSelected)},
        dateMaintenance: {required},
        problem: {required, maxLengthValue: maxLength(500)},
        description: {required},
        reference: {maxLengthValue: maxLength(500)},
      },
    };
  },
  emits: ['creation'],
  mounted() {},
  methods: {
    show() {
      this.createSidebarVisible = true;
      if (!this.isGas()) this.maintenance.companyId = this.myCompanyId();
    },
    hide() {
      this.createSidebarVisible = false;
      this.maintenance = {};
    },
    create() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.maintenance.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      if (!this.isClientSelected) this.maintenance.clientId = undefined;
      if (!this.isEmployeeSelected) this.maintenance.employeeId = undefined;
      if (!this.isPrestataireSelected) this.maintenance.prestataireId = undefined;
      if (!this.isPartenaireSelected) this.maintenance.partenaireId = undefined;
      if (!this.isHousekeeperSelected) this.maintenance.housekeeperId = undefined;

      maintenanceService
        .create(this.maintenance, this.files)
        .then(() => {
          this.success(this.$t('maintenance.added'));
          this.files = undefined;
          this.maintenance = {};
          this.$emit('creation');
          this.hide();
        })
        .catch((e) => {
          this.addError(this.msgForErr(this.$t, e));
          this.$refs.footerSidebar.init();
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    // CALLBACKS

    resetAll() {
      this.maintenance.accommodationId = undefined;
      this.maintenance.ownerId = undefined;
      this.maintenance.interlocutorType = undefined;
      this.accommodations = [];
      this.$refs.ownerAutocomplete.reset();
      this.$refs.clientAutocomplete.reset();
      this.$refs.prestataireAutocomplete.reset();
      this.$refs.employeeAutocomplete.reset();
      this.$refs.partenaireAutocomplete.reset();
    },

    getAccommodations() {
      this.maintenance.accommodationId = undefined;
      this.maintenance.interlocutorType = undefined;
      this.accommodations = [];

      // Saisie effacée
      if (!this.maintenance.ownerId) {
        return;
      }

      // Récupérer les logements + définir l'hôte par défaut
      const filter = {
        enabled: true,
        ownerId: this.maintenance.ownerId,
        statuses: ['PUBLISHED', 'PREPUBLISHED'],
      };
      accommodationService.autocomplete(filter).then((data) => {
        this.accommodations = data;
      });
    },

    getHousekeeper() {
      if (this.isHousekeeper()) {
        this.maintenance.housekeeperId = this.myHousekeeperId();
        return;
      }

      const filter = {
        accommodationId: this.maintenance.accommodationId,
        companyId: this.maintenance.companyId,
        userEnabled: true,
      };
      housekeeperService.autocomplete(filter).then((data) => {
        if (data.length) {
          this.maintenanceInterlocutorTypeConst.find(
            (interlocutor) => interlocutor.value === 'CONCIERGE',
          ).disabled = false;
          this.maintenance.housekeeperId = data[0].id;
        } else {
          this.maintenanceInterlocutorTypeConst.find(
            (interlocutor) => interlocutor.value === 'CONCIERGE',
          ).disabled = true;
        }
      });
    },

    changeInterlocutorType() {
      this.$nextTick(() => {
        if (!this.isClientSelected) this.$refs.clientAutocomplete.reset();
        if (!this.isEmployeeSelected) this.$refs.prestataireAutocomplete.reset();
        if (!this.isPrestataireSelected) this.$refs.employeeAutocomplete.reset();
        if (!this.isPartenaireSelected) this.$refs.partenaireAutocomplete.reset();
        else if (this.isPartenaireSelected && this.isPartenaire())
          this.maintenance.partenaireId = this.myPartenaireId();
      });
    },
  },
  computed: {
    // INTERLOCUTEURS SELECTIONNES

    isOwnerSelected() {
      return this.maintenance.interlocutorType === 'PROPRIETAIRE';
    },
    isHousekeeperSelected() {
      return this.maintenance.interlocutorType === 'CONCIERGE';
    },
    isClientSelected() {
      return this.maintenance.interlocutorType === 'VOYAGEUR';
    },
    isPrestataireSelected() {
      return this.maintenance.interlocutorType === 'PRESTATAIRE';
    },
    isEmployeeSelected() {
      return this.maintenance.interlocutorType === 'SALARIE';
    },
    isPartenaireSelected() {
      return this.maintenance.interlocutorType === 'PARTENAIRE';
    },
  },
};
</script>

<template>
  <AkSidebar
    v-model:visible="createSidebarVisible"
    :baseZIndex="9998"
    :dismissable="true"
    @hide="hide"
    block-scroll
    position="right">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('maintenance.create') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4">
          <div class="flex flex-col gap-4">
            <AkDropdown
              v-if="isGas()"
              v-model="maintenance.companyId"
              :label="$t('employee.company')"
              :options="companies"
              :submitted="submitted"
              :validator="v$.maintenance.companyId"
              option-label="name"
              option-value="id"
              @value-change="resetAll()" />
            <AkAutocomplete
              ref="ownerAutocomplete"
              v-model="maintenance.ownerId"
              :disabled="!maintenance.companyId && this.isGas()"
              :init-method="autocompleteOwnerById"
              :label="$t('owner.ownerDisplay')"
              :search-label="autocompleteOwnerQuery"
              :search-method="autocompleteOwner"
              :search-object="{userEnabled: true, ownerStatus: 'CUSTOMER'}"
              :submitted="submitted"
              :validator="v$.maintenance.ownerId"
              option-label="display"
              option-value="id"
              required
              userEnabled
              @item-select="getAccommodations()" />
            <AkDropdown
              v-model="maintenance.accommodationId"
              :disabled="!maintenance.ownerId"
              :label="$t('maintenance.accommodationId')"
              :options="accommodations"
              :submitted="submitted"
              :validator="v$.maintenance.accommodationId"
              option-label="display"
              option-value="id"
              @value-change="getHousekeeper()" />
            <AkDropdown
              :disabled="!maintenance.accommodationId"
              v-model="maintenance.interlocutorType"
              :label="$t('interlocutor.interlocutorType')"
              :options="maintenanceInterlocutorTypeConst"
              option-disabled="disabled"
              :submitted="submitted"
              :validator="v$.maintenance.interlocutorType"
              optionLabel="label"
              optionValue="value"
              required
              @value-change="changeInterlocutorType()" />
            <AkAutocomplete
              v-show="isClientSelected"
              ref="clientAutocomplete"
              v-model="maintenance.clientId"
              :init-method="autocompleteClientById"
              :label="$t('maintenance.discovery_person')"
              :search-method="autocompleteClient"
              :search-object="{accommodationId: maintenance.accommodationId}"
              :search-label="this.autocompleteClientQuery"
              :submitted="submitted"
              :validator="v$.maintenance.clientId"
              option-label="display"
              option-value="id"
              required />
            <AkAutocomplete
              v-show="isPrestataireSelected"
              ref="prestataireAutocomplete"
              v-model="maintenance.prestataireId"
              :init-method="autocompletePrestataireById"
              :label="$t('maintenance.discovery_person')"
              :search-method="autocompletePrestataire"
              :search-object="{companyId: maintenance.companyId, userEnabled: true}"
              :submitted="submitted"
              :validator="v$.maintenance.prestataireId"
              option-label="display"
              option-value="id"
              required />
            <AkAutocomplete
              v-show="isEmployeeSelected"
              ref="employeeAutocomplete"
              v-model="maintenance.employeeId"
              :init-method="autocompleteEmployeeById"
              :label="$t('maintenance.discovery_person')"
              :search-method="autocompleteEmployee"
              :search-object="{companyId: maintenance.companyId, userEnabled: true}"
              :submitted="submitted"
              :validator="v$.maintenance.employeeId"
              option-label="display"
              option-value="id"
              required />
            <AkAutocomplete
              v-show="isPartenaireSelected"
              ref="partenaireAutocomplete"
              v-model="maintenance.partenaireId"
              :init-method="autocompletePartenaireById"
              :label="$t('maintenance.discovery_person')"
              :search-method="autocompletePartenaire"
              :search-object="{companyId: maintenance.companyId, userEnabled: true}"
              :submitted="submitted"
              :validator="v$.maintenance.partenaireId"
              :disabled="this.isPartenaire()"
              option-label="display"
              option-value="id"
              required />
            <AkCalendar
              v-model="maintenance.dateMaintenance"
              :label="$t('maintenance.date_maintenance')"
              :maxDate="new Date()"
              :placeholder="$t('maintenance.date_maintenance')"
              :submitted="submitted"
              :validator="v$.maintenance.dateMaintenance"
              date-format="dd/mm/yy"
              selection-mode="single" />
            <AkInputText
              v-model="maintenance.problem"
              :label="$t('maintenance.problem')"
              :placeholder="$t('maintenance.problem')"
              :submitted="submitted"
              :validator="v$.maintenance.problem" />
            <AkInputTextArea
              v-model="maintenance.description"
              :label="$t('maintenance.description')"
              :placeholder="$t('maintenance.description')"
              :submitted="submitted"
              :validator="v$.maintenance.description" />
            <AkFilesUpload
              ref="fileUpload"
              v-model="files"
              multiple
              :label="$t('maintenance.photos')"
              :submitted="submitted"
              accept=".png, .jpeg, .jpg" />
            <AkInputTextArea
              v-model="maintenance.reference"
              :label="$t('maintenance.reference')"
              :placeholder="$t('maintenance.reference')"
              :submitted="submitted"
              :validator="v$.maintenance.reference" />
          </div>

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancelLabel="$t('cancel')"
            :validateLabel="$t('create')"
            @action="create"
            @cancel="hide()" />
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
