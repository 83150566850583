import $ from 'jquery';
import moment from 'moment/moment';
import qs from 'qs';

/*************************
 App Header
 *************************/
export function appheader() {
  $('.mobile-toggle').on('click', function () {
    $('body').toggleClass('sidebar-toggled');
  });

  $(document).on('click', '.mega-menu.dropdown-menu', function (a) {
    a.stopPropagation();
  });

  $('.sidebar-toggle').on('click', function () {
    $('body').toggleClass('sidebar-mini');
    $('.app-navbar').toggleClass('expand');
  });
}

/*************************
 SidebarNav
 *************************/
export function sidebarNav() {
  var sidebarNav = $('.sidebar-nav');
  if (sidebarNav.length > 0) {
    (function (window, document, $) {
      $(function () {
        if (sidebarNav.length > 0) {
          $('#sidebarNav').metisMenu();
        }
      });
    })(window, document, $);
  }
}

/*************************
 SidebarNav
 *************************/
export function appNavBar() {
  $('.app-navbar').hover(function () {
    if ($('body').hasClass('sidebar-mini')) $('.app-wrap').toggleClass('expand-navbar');
  });
}

/**********
 * Remove Empty params
 ***********/
export function removeEmptyParams(params) {
  if (!params) return params;
  for (const key of Object.keys(params)) {
    if (params[key] === undefined || params[key] === null || params[key] === '') {
      delete params[key];
    }
  }
  return params;
}

/**********
 * Remove Empty params
 ***********/
export function dateToUtc(date) {
  return moment(date).utc(true).toDate();
}

export function formatToUtc(date) {
  return moment(date).utc(true).format();
}

export function transformDownloadResponse(data) {
  return data;
}

/********
 * Go back
 ********/
export function goBack(router) {
  if (window.history.length > 2) {
    router.go(-1);
  } else {
    router.push('/');
  }
}

/********
 * Back to
 ********/
export function goBackTo(router, route) {
  router.push({name: route});
}

export function createFormDataFiles(obj, files) {
  let formData = new FormData();
  formData.append(
    'item',
    new Blob([JSON.stringify(obj)], {
      type: 'application/json',
    }),
  );
  if (files)
    Array.from(files).forEach((f) => {
      formData.append('files', f);
    });
  return formData;
}

export function createFormDataFile(obj, file) {
  let formData = new FormData();
  formData.append(
    'item',
    new Blob([JSON.stringify(obj)], {
      type: 'application/json',
    }),
  );
  formData.append('file', file);
  return formData;
}

export function createFormDataFic(tableName, tableId, files) {
  let formData = new FormData();
  formData.append(
    'item',
    new Blob([JSON.stringify({tableName: tableName, tableId: tableId})], {
      type: 'application/json',
    }),
  );
  Array.from(files).forEach((f) => {
    formData.append('files', f);
  });
  return formData;
}

export function createFormDataFicForUser(file) {
  let formData = new FormData();
  formData.append('file', file);
  return formData;
}

/*********
 *
 * SWAP TWO ITEMS IN A LIST
 *********/
export function swap(arr, from, to) {
  if (arr.length < from || arr.length < to) return;
  if (from < 0 || to < 0) return;
  arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
}

/*********
 *
 * Debounce
 *********/

export const debounce = (func, delay = 600, immediate = false) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
    if (callNow) func.apply(context, args);
  };
};
