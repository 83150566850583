<script>
/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

export default {
  mixins: [messageMixin, loaderMixin],
  props: {
    classTitle: {
      type: String,
      required: false,
      default: '',
    },
    classSearch: {
      type: String,
      required: false,
      default: '',
    },
    classAction: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    hasActionSlot() {
      return this.$slots.action;
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-2 bg-white p-3 rounded-lg md:h-full">
    <div :class="classAction" v-if="hasActionSlot" class="flex justify-end gap-2">
      <slot name="action" />
    </div>
    <div>
      <slot name="content" />
    </div>
  </div>
  <slot name="extra" />
</template>
