import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  pagination(filter) {
    return Api()
      .get('/mission-order/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  count(filter) {
    return Api()
      .get('/mission-order/count', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  get(missionOrderId) {
    return Api()
      .get(`/mission-order/${missionOrderId}/details`)
      .then((res) => {
        return this.formatRes(res.data);
      });
  },
  repropose(missionOrderId) {
    return Api()
      .put(`/mission-order/${missionOrderId}/repropose`)
      .then((res) => {
        return res.data;
      });
  },
  cancel(missionOrderId) {
    return Api()
      .put(`/mission-order/${missionOrderId}/cancel`)
      .then((res) => {
        return res.data;
      });
  },
  update(missionOrderId, mission) {
    return Api()
      .put(`/mission-order/${missionOrderId}/update`, mission)
      .then((res) => {
        return res.data;
      });
  },
  formatRes(e) {
    if (e.prestationParticipants)
      for (let i of e.prestationParticipants) {
        if (i.clockIn) i.clockIn = new Date(i.clockIn);
      }
    return e;
  },
};
