<script>
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import PrestationBadge from '@/components/general/PrestationBadge';
import PrestationStatusBadge from '@/components/general/PrestationStatusBadge';
import AkDialog from '@components/general/AkDialog.vue';
import AkInputText from '@components/input/AkInputText.vue';
import AkMissionAssignment from '@views/operationnel/mission/components/AkMissionAssignment';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import randomRef from '@mixins/randomRef';
import missionMixin from '@mixins/missionMixin';

/* SERVICES */
import missionPlanningParticipantService from '@/services/missionPlanningParticipantService';
import AkPictureAccommodation from '../../../../components/v2/general/AkPictureAccommodation.vue';
import TicketCreateSidebar from '../../../ticket/v2/TicketCreateSidebar.vue';
import GaPendingMissionSidebar from './GaPendingMissionSidebar.vue';

export default {
  components: {
    GaPendingMissionSidebar,
    AkPictureAccommodation,
    PrestationBadge,
  },
  mixins: [utilsMixin, messageMixin, randomRef, missionMixin],
  props: {
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      missionAssignment: undefined,
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  watch: {},
  emits: ['mission-assigned'],
  mounted() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    planifMission(mission) {
      this.$refs.missionCreateSidebar.show(mission);
    },
  },
};
</script>

<template>
  <perfect-scrollbar
    :options="{suppressScrollY: true}"
    class="pending-missions flex flex-row gap-4 mb-2"
    v-if="this.currentValue && this.currentValue.length > 0">
    <div
      class="pending-mission md:w-[300px] w-11/12 flex flex-col bg-lightGray rounded-xl"
      v-for="m of this.currentValue"
      :key="m.id"
      @click="planifMission(m)">
      <AkPictureAccommodation
        disabled-link
        :id="m.id"
        :md5="m.accommodationPicture"
        height="100"
        cover
        :name="this.formatDateIfNeeded(m.dateStart) + ' - ' + m.durationDisplay"></AkPictureAccommodation>
      <div class="p-2 flex flex-col gap-1">
        <span class="text-dark text-ellipsis block overflow-hidden font-semibold whitespace-nowrap">{{
          m.accommodationName
        }}</span>
        <span
          class="text-dark text-ellipsis overflow-hidden items-center font-normal whitespace-nowrap text-sm flex gap-1">
          <PrestationBadge :type="m.type" />
          <span>-</span>
          <span> {{ statusDisplay(m.assignedStaff?.replace(' ', '')) }}</span>
        </span>
      </div>
    </div>
  </perfect-scrollbar>
  <GaPendingMissionSidebar ref="missionCreateSidebar" @mission-assigned="this.$emit('mission-assigned')" />
</template>
