<script>
/* COMPOSANTS */
import AkAllFilter from '@components/v2/filter/AkAllFilter';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination';
import AkListFilter from '@components/v2/filter/AkListFilter';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation';
import GaListView from '@components/v2/layout/GaListView';
import GaView from '@components/v2/layout/GaView';
import AkInputSwitch from '@components/v2/input/AkInputSwitch';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter';

/* MIXINS */
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import countryConst from '@mixins/const/countryConst';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import companyMixin from '@mixins/companyMixin';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import filterListMixin from '../../mixins/filterListMixin';
import {useFilterStore} from '../../store/filterStore';

export default {
  components: {
    AkAutocompleteFilter,
    AkInputSwitch,
    AkAllFilter,
    AkDatatablePagination,
    AkListFilter,
    AkPictureAccommodation,
    GaListView,
    GaView,
  },
  mixins: [
    accommodationTypeConst,
    countryConst,
    companyMixin,
    loaderMixin,
    messageMixin,
    ownerAutocompleteMixin,
    permissionsMixin,
    randomRef,
    residenceTypeConst,
    sidebarMixin,
    filterListMixin,
    utilsMixin,
  ],
  metaInfo() {
    return {
      title: 'accommodation.list',
    };
  },

  props: {
    resource: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      accommodations: [],
      totalItems: 0,
      loading: true,
      accommodationTypeFilter: {},
      filter: {
        enabled: null,
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      hasPrepublished: false,
    };
  },

  mounted() {
    let filter = useFilterStore().getFilter();
    if (filter) {
      this.filter = {...this.filter, ...filter};
    }
  },
  methods: {
    rowClick(event) {
      let data = event.data;
      this.$router.push({name: 'accommodationPage', params: {accommodationId: data.id}});
    },
    search() {
      this.loading = true;
      accommodationService
        .accommodationsPagination(this.filter)
        .then((data) => {
          this.accommodations = this.isOwner() ? data.data.filter((acc) => acc.status === 'PUBLISHED') : data.data;
          this.totalItems = data.totalItems;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    displayForArea(data) {
      if (!data.area) return '';
      return data.area + ' m²';
    },
    displayForPlace(data) {
      return data.city;
    },

    // ENABLE / DISABLE

    changeEnabled(data) {
      accommodationService
        .enabled(data)
        .then(() => {
          this.search();
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la modification';
          if (e.response) content = 'Une erreur est survenue durant la modification : ' + e.response.data.error;
          this.addError(content);
        });
    },

    // EXPORT

    exportAccommodations() {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      accommodationService.exportAccommodations(this.filter).then((data) => {
        this.hideLoader();
      });
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaAccommodationFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        enabled: null,
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaAccommodationFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
  },
  computed: {
    canDisplayOnboardingMessageForOwner() {
      return this.isOwner && this.hasPrepublished && this.accommodations.length === 0;
    },
  },
};
</script>

<template>
  <GaView :title="$t('accommodation.list')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <button class="btn-light-gray xs" @click="exportAccommodations()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>

    <template #content>
      <GaListView searchable @search="this.searchQuery">
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.clickSearch"
            :label="$t('role.owner')"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            v-if="!canDisplayOnboardingMessageForOwner"
            :loading="loading"
            :value="accommodations"
            :nb-items="this.totalItems"
            @row-click="rowClick($event)"
            @search="this.search"
            :filter="this.filter">
            <template #empty>
              {{ $t('accommodation.empty') }}
            </template>

            <Column
              style="width: 60px; max-width: 60px; padding-left: 0px; padding-right: 0px"
              header="Photo"
              class="text-center"
              :sortable="false">
              <template #body="slotProps">
                <AkPictureAccommodation
                  :md5="slotProps.data.picture"
                  :id="slotProps.data.id"
                  :height="48"
                  :radius="8" />
              </template>
            </Column>

            <Column
              :header="$t('accommodation_title')"
              style="min-width: 200px; width: 400px"
              :sortable="true"
              field="name">
              <template #body="slotProps">
                <router-link :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.id}}">
                  {{ slotProps.data.name }}
                </router-link>
              </template>
            </Column>

            <Column
              style="min-width: 200px; width: 400px"
              v-if="!isOwner()"
              :header="$t('accommodation.ownerName')"
              :sortable="false"
              field="contact_first_name">
              <template #body="slotProps">
                <span v-if="this.isPartenaire()">{{ slotProps.data.ownerContactName }}</span>
                <router-link v-else :to="{name: 'ownerPage', params: {ownerId: slotProps.data.ownerId}}">
                  {{ slotProps.data.ownerContactName }}
                </router-link>
              </template>
            </Column>

            <Column
              v-if="isGas()"
              style="width: 400px; min-width: 150px"
              :header="$t('company.company')"
              :sortable="true"
              field="j_company_name">
              <template #body="slotProps">
                <div class="flex items-center gap-2">
                  <img src="@refonte/img/guestadom.svg" class="z-10 h-6" alt="Guestadom logo" />
                  <span>{{ slotProps.data.companyName }}</span>
                </div>
              </template>
            </Column>

            <Column :header="$t('accommodation.localisation')" style="width: 300px" :sortable="true" field="city">
              <template #body="slotProps">
                {{ displayForPlace(slotProps.data) }}
              </template>
            </Column>

            <Column header="Description" style="width: 100px">
              <template #body="slotProps">
                <div class="flex gap-2 items-center">
                  <span v-if="slotProps.data.capacity" class="flex gap-1 items-center">
                    <i class="ga-icon ga-users text-2xl opacity-65"></i><span>{{ slotProps.data.capacity }}</span>
                  </span>
                  <span v-if="slotProps.data.nbRoom" class="flex gap-1 items-center">
                    <i class="ga-icon ga-bed text-2xl opacity-65"></i><span>{{ slotProps.data.nbRoom }}</span>
                  </span>
                  <span v-if="slotProps.data.nbBathroom" class="flex gap-1 items-center">
                    <i class="ga-icon ga-bath text-2xl opacity-65"></i><span>{{ slotProps.data.nbBathroom }}</span>
                  </span>
                </div>
              </template>
            </Column>
            <Column
              v-if="this.isGasGav()"
              style="width: 100px"
              :header="$t('user.enabled')"
              :sortable="true"
              class="text-center items-center"
              field="enabled">
              <template #body="slotProps">
                <AkInputSwitch
                  validationPopup
                  :nameOnPopup="slotProps.data.name"
                  v-model="slotProps.data.enabled"
                  @change="changeEnabled(slotProps.data)" />
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>

<style lang="scss" module>
@import '@design';

.title {
  text-align: center;
}
</style>
