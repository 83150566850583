<script>
import AkLabel from '@components/general/AkLabel';
import InputSwitch from 'primevue/inputswitch';

export default {
  components: {AkLabel, InputSwitch},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    modelValue: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelBefore: {
      type: Boolean,
      required: false,
      default: true,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {};
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        if (this.isDisabled) return;
        this.$emit('update:modelValue', val);
      },
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  methods: {
    changeValue() {
      this.$emit('change');
    },
  },
};
</script>

<template>
  <div :class="[className, {'form-group': !isInline, 'form-control transparent-switch': isInline}]">
    <div v-if="isInline" class="d-flex align-items-center">
      <AkLabel :inline="true" v-if="this.displayLabel && this.labelBefore === true" :required="isRequired">
        {{ label }}
      </AkLabel>
      <InputSwitch
        v-model="currentValue"
        :disabled="isDisabled"
        :class="[this.labelBefore ? 'ml-2' : 'mr-2']"
        @change="changeValue" />
      <AkLabel :inline="true" v-if="this.displayLabel && this.labelBefore === false" :required="isRequired">
        {{ label }}
      </AkLabel>
    </div>
    <div v-else>
      <AkLabel v-if="this.displayLabel" :required="isRequired">
        {{ label }}
      </AkLabel>
      <div class="d-flex flex-column flex-1">
        <InputSwitch v-model="currentValue" :disabled="isDisabled" @change="changeValue" />
      </div>
    </div>
  </div>
</template>
