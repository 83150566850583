import partenaireService from '@services/partenaireService';

export default {
  methods: {
    autocompletePartenaire(e) {
      return partenaireService.autocomplete(e);
    },
    autocompletePartenaireById(e) {
      return partenaireService.partenaireDisplay(e);
    },
  },
};
