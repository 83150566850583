import Api from '@/services/api';

export default {
  zones() {
    return Api()
      .get('/zones')
      .then((res) => {
        return res.data;
      });
  },
};
