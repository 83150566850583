import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  sectors(filter) {
    return Api()
      .get('/geosector', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  sector(sectorId) {
    return Api()
      .get('/geosector/' + sectorId)
      .then((res) => this.formatRes(res.data));
  },
  create(sector) {
    return Api()
      .post('/geosector', sector)
      .then((res) => this.formatRes(res.data));
  },
  update(sector) {
    return Api()
      .put('/geosector/' + sector.id, sector)
      .then((res) => this.formatRes(res.data));
  },
  delete(sector) {
    return Api()
      .delete('/geosector/' + sector.id)
      .then(() => sector);
  },
  formatRes(e) {
    return e;
  },
};
