<script>
import InputNumber from 'primevue/inputnumber';

export default {
  extends: InputNumber,
  methods: {
    getDecimalExpression() {
      const formatter = new Intl.NumberFormat(this.locale, {...this.getOptions(), useGrouping: false});
      let regExp = new RegExp(`[.,]`, 'g');
      return regExp;
    },
  },
};
</script>
