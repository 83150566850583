<script>
import useVuelidate from '@vuelidate/core';
import {email, required, requiredIf} from '@vuelidate/validators';

import {format, isValidPhoneNumber} from 'libphonenumber-js';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkInputMoney from '@components/v2/input/AkInputMoney';
import AkInputText from '@components/v2/input/AkInputText';
import AkAddressFull from '@components/v2/input/AkAddressFull';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation';
import GaView from '@components/v2/layout/GaView.vue';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';
import PartenairePrestationForm from '@views/partenaires/PartenairePrestationForm.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import currentCompanyMixin from '@mixins/currentCompanyMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import messageMixin from '@mixins/messageMixin';
import backMixin from '@mixins/backMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import prestationTypesConst from '@mixins/const/prestationTypesConst';

/* SERVICES */
import partenaireService from '@services/partenaireService';
import itemLabelService from '@services/itemLabelService';
import companyService from '@services/companyService';
import accommodationService from '@services/accommodationService';
import partenairePrestationService from '@services/partenairePrestationService';
import formatMixin from '@mixins/formatMixin';

const phoneValidator = (value) => !value || isValidPhoneNumber(value, 'FR');

export default {
  components: {
    AkButtonValidation,
    GaView,
    PartenairePrestationForm,
    AkPictureAccommodation,
    AkAddressFull,
    AkInputText,
    AkInputMoney,
    AkDropdown,
    AkFormSubmitted,
  },
  mixins: [
    randomRef,
    currentCompanyMixin,
    permissionsMixin,
    messageMixin,
    formatMixin,
    backMixin,
    civilitiesConst,
    prestationTypesConst,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'partenaire.create',
    };
  },
  validations() {
    return {
      partenaire: {
        companyId: {required: requiredIf(this.isGas() || this.isAdmin())},
        contact: {
          companyName: {required},
          formeJuridiqueId: {required},
          raisonSociale: {required},
          email: {required, email},
          phone: {
            required,
            phoneValidator: phoneValidator,
          },
          civility: {required},
          lastName: {required},
          firstName: {required},
        },
      },
    };
  },
  data() {
    return {
      submitted: false,
      listFormeJuridique: [],
      companies: [],
      accommodations: [],
      partenairePrestations: [],
      partnerMissions: [],
      partenaire: {
        companyId: null,
        accommodations: [],
        contact: {
          companyName: null,
          formeJuridiqueId: null,
          raisonSociale: null,
          capital: null,
          address: null,
          complement: null,
          postalCode: null,
          city: null,
          state: null,
          country: null,
          email: null,
          phone: null,
          villeRcs: null,
          siret: null,
          civility: null,
          lastName: null,
          firstName: null,
          function: null,
        },
      },
    };
  },
  mounted() {
    this.initGoBack();
    this.partenaire.companyId = this.currentCompanyId();

    itemLabelService.itemLabels({type: 'FORME_JURIDIQUE'}).then((data) => {
      this.listFormeJuridique = data;
    });
    companyService.companies().then((data) => {
      this.companies = data;
    });

    this.getAccommodations(this.partenaire.companyId);
  },
  methods: {
    createPartenaire() {
      this.partenaire.accommodationIds = this.selectedAccommodationIds;
      this.partenaire.partenairePrestations = this.partenairePrestations;

      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.btnCreate.init();
        return;
      }

      partenaireService
        .create(this.partenaire)
        .then(() => {
          this.success('Partenaire ajouté !');
          this.initPartenaire();
        })
        .catch((e) => {
          this.addError(this.$t('error.' + e.response.data.error));
        })
        .finally(() => {
          this.submitted = false;
          this.$refs.btnCreate.init();
        });
    },
    initPartenaire() {
      this.partenaire = {
        companyId: null,
        accommodations: [],
        contact: {
          companyName: null,
          formeJuridiqueId: null,
          raisonSociale: null,
          capital: null,
          address: null,
          complement: null,
          postalCode: null,
          city: null,
          state: null,
          country: null,
          email: null,
          phone: null,
          villeRcs: null,
          siret: null,
          civility: null,
          lastName: null,
          firstName: null,
          function: null,
        },
      };
    },
    toggleAccommodation(accommodation) {
      accommodation.isSelected ^= true;
      if (accommodation.isSelected) {
        this.partenairePrestations.push({accommodationId: accommodation.id});
      } else {
        let index = this.partenairePrestations.map((el) => el.accommodationId).indexOf(accommodation.id);
        this.partenairePrestations.splice(index, 1);
      }
    },
    selectAllAccommodation() {
      for (let accommodation of this.accommodations) {
        accommodation.isSelected = true;
        this.partenairePrestations.push({accommodationId: accommodation.id});
      }
    },
    unselectAllAccommodation() {
      for (let accommodation of this.accommodations) {
        accommodation.isSelected = false;
        let index = this.partenairePrestations.map((el) => el.accommodationId).indexOf(accommodation.id);
        this.partenairePrestations.splice(index, 1);
      }
    },
    getPartenairePrestationIndex(accommodation) {
      return this.partenairePrestations.map((el) => el.accommodationId).indexOf(accommodation.id);
    },

    // ACCOMMODATIONS
    getAccommodations(companyId) {
      if (!companyId) return;

      accommodationService.accommodationThumbnails({companyId: companyId, enabled: true}).then((data) => {
        this.accommodations = data;
      });
    },

    // PARTNER MISSIONS
    getPartnerMissions(companyId) {
      const filter = {
        companyId: companyId,
      };

      partenairePrestationService.partenairePrestations(filter).then((data) => {
        this.partnerMissions = data;
      });
    },

    getPartnerMissionTypesForForm(accommodationId) {
      return this.partnerMissions.filter((el) => el.accommodationId === accommodationId);
    },
  },
  computed: {
    isLoadingFormeJuridique() {
      return this.listFormeJuridique === undefined;
    },
    selectedAccommodations() {
      return this.accommodations.filter((acc) => acc.isSelected);
    },
    selectedAccommodationIds() {
      return this.selectedAccommodations.map((acc) => acc.id);
    },
  },
  watch: {
    'partenaire.companyId'(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.accommodations = [];
      this.partenaire.accommodationIds = [];
      this.partenairePrestations = [];
      this.getAccommodations(newVal);
      this.getPartnerMissions(newVal);
    },
  },
};
</script>

<template>
  <GaView details>
    <template #content>
      <AkFormSubmitted :disabled="false" :submitted="submitted" class="flex flex-col md:gap-4 gap-2" reference="form">
        <span class="title-border text-dark text-xl font-bold mb-3">Contact</span>
        <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkDropdown
            v-model="partenaire.contact.formeJuridiqueId"
            :label="$t('contact.formeJuridique')"
            :loading="isLoadingFormeJuridique"
            :options="this.listFormeJuridique"
            :validator="v$.partenaire.contact.formeJuridiqueId"
            optionLabel="label"
            optionValue="id" />
          <AkInputText
            v-model="partenaire.contact.raisonSociale"
            :label="$t('contact.raisonSociale')"
            :formatter="toUppercase"
            :validator="v$.partenaire.contact.raisonSociale" />
          <AkInputText
            v-model="partenaire.contact.companyName"
            :label="$t('contact.companyName')"
            :validator="v$.partenaire.contact.companyName" />
          <AkInputMoney v-model="partenaire.contact.capital" label="Capital (€)" />
        </div>

        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkInputText v-model="partenaire.contact.villeRcs" :label="$t('contact.villeRcs')" :formatter="toUppercase" />
          <AkInputText v-model="partenaire.contact.siret" :label="$t('contact.siret')" />
        </div>

        <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkDropdown
            v-model="partenaire.contact.civility"
            :label="$t('contact.civilityManager')"
            :options="this.getCivilitiesConstForProspect()"
            :validator="v$.partenaire.contact.civility" />
          <AkInputText
            v-model="partenaire.contact.lastName"
            :formatter="toUppercase"
            :label="$t('contact.lastNameManager')"
            :validator="v$.partenaire.contact.lastName" />
          <AkInputText
            v-model="partenaire.contact.firstName"
            :label="$t('contact.firstNameManager')"
            :validator="v$.partenaire.contact.firstName" />
          <AkInputText v-model="partenaire.contact.function" :label="$t('contact.functionManager')" />
        </div>

        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkInputText
            v-model="partenaire.contact.email"
            :label="$t('contact.email')"
            :validator="v$.partenaire.contact.email"
            icon="ga-icon ga-email" />
          <AkInputText
            v-model="partenaire.contact.phone"
            :label="$t('contact.phone')"
            :validator="v$.partenaire.contact.phone"
            icon="ga-icon ga-call" />
        </div>

        <span class="title-border text-dark text-xl font-bold mb-3">{{ $t('client.title_address') }}</span>
        <AkAddressFull
          v-model="partenaire.contact"
          :always-display-map="false"
          :can-change-position="false"
          :label="$t('contact.address1Manager')"
          :validator="v$.partenaire" />

        <span v-if="isGas() || isAdmin()" class="title-border text-dark text-xl font-bold mb-3"
          >Informations complémentaires</span
        >
        <div v-if="isGas() || isAdmin()" class="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkDropdown
            v-model="partenaire.companyId"
            :options="companies"
            :validator="v$.partenaire.companyId"
            label="Agence"
            option-label="name"
            option-value="id" />
        </div>

        <template v-if="accommodations && accommodations.length > 0 && partenaire.companyId">
          <div class="title-border text-dark text-xl font-bold mb-3">
            Logements
            <div class="flex flex-col lg:flex-row md:gap-4 gap-2 ml-4">
              <span class="xs btn-light-gray" @click="selectAllAccommodation()">
                <i class="ga-icon ga-check_circle mr-1" /> {{ $t('select_all') }}
              </span>
              <span class="xs btn-light-gray" @click="unselectAllAccommodation()">
                <i class="ga-icon ga-close_circle mr-1" /> {{ $t('unselect_all') }}
              </span>
            </div>
          </div>

          <div class="grid 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
            <div v-for="accommodation in accommodations" :key="accommodation.id">
              <AkPictureAccommodation
                :id="accommodation.id"
                :class="{'border-[#0d6be9]': accommodation.isSelected}"
                :md5="accommodation.picture"
                :name="accommodation.name"
                class="rounded-[14px] border-2 border-transparent"
                cover
                height="134"
                disabledLink
                @click="toggleAccommodation(accommodation)" />
              <div v-if="accommodation.isSelected" class="rounded-r-md p-4 bg-white">
                <PartenairePrestationForm
                  v-model="partenairePrestations"
                  :index="getPartenairePrestationIndex(accommodation)"
                  :partnerMissionTypes="getPartnerMissionTypesForForm(accommodation.id)"
                  :receptionTypeCheckin="accommodation.receptionTypeCheckin"
                  :receptionTypeCheckout="accommodation.receptionTypeCheckout" />
              </div>
            </div>
          </div>
        </template>
        <div class="flex justify-end mt-2">
          <AkButtonValidation
            ref="btnCreate"
            :label="$t('add')"
            class="btn-primary-gradient"
            @action="createPartenaire" />
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
