export default {
  data() {
    return {
      receptionTypeConst: [
        {label: this.$t('accommodation.autonomous'), value: 'AUTONOMOUS'},
        {label: this.$t('accommodation.physical'), value: 'PHYSICAL'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForReceptionTypeConst(value) {
      let result = this.receptionTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    receptionTypeConstRequired() {
      return this.receptionTypeConst.filter((el) => el.value !== 'UNDEFINED');
    },
  },
};
