<script>
/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import missionMonitoringStatusConst from '@mixins/const/missionMonitoringStatusConst';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';

/* SERVICES */

export default {
  components: {
    MultiSelect,
    AkDropdown,
    AkCalendar,
    AkAutocomplete,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    permissionsMixin,
    missionMonitoringStatusConst,
    prestationTypesConst,
    sidebarMixin,
    utilsMixin,
    accommodationAutocompleteMixin,
    reservationAutocompleteMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    prestationTypesList() {
      return this.prestationTypesConst.slice(0, -1);
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <!-- Accommodation -->
    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :init-method="autocompleteAccommodationById"
      :placeholder="$t('accommodation.short')"
      :search-label="autocompleteAccommodationQuery"
      :search-method="autocompleteAccommodation"
      inline
      option-label="display"
      option-value="id"
      @item-select="itemSelect" />

    <!-- Numéro de réservation -->
    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      :label="$t('reservation.one')"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation"
      @item-select="itemSelect" />

    <!-- Période -->
    <AkCalendar
      v-model="currentValue.period"
      :inline="true"
      placeholder="Période"
      date-format="dd/mm/yy"
      selection="range" />

    <!-- Statut -->
    <AkDropdown
      v-model="currentValue.status"
      :label="$t('mission_monitoring.status')"
      :options="missionMonitoringStatusConst"
      inline
      option-label="label"
      option-value="value"
      @valueChange="itemSelect" />

    <!-- Types de mission -->
    <MultiSelect
      v-model="currentValue.prestationTypes"
      :placeholder="$t('mission_order.type')"
      :options="prestationTypesList"
      option-label="label"
      option-value="value"
      class="form-control"
      inline
      filter
      filter-placeholder="Filtrer"
      @change="itemSelect" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
