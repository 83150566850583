export default {
  data() {
    return {
      upsellStatusConst: [
        {label: this.$t('upsell.refused'), value: 'REFUSED'},
        {label: this.$t('upsell.to_plan'), value: 'TO_PLAN'},
        {label: this.$t('upsell.planned'), value: 'PLANNED'},
        {label: this.$t('upsell.closed'), value: 'CLOSED'},
      ],
      upsellStatusEnsoConst: [
        {label: this.$t('upsell.paid'), value: 'paid'},
        {label: this.$t('upsell.refunded'), value: 'refunded'},
        {label: this.$t('upsell.requested'), value: 'requested'},
        {label: this.$t('upsell.denied'), value: 'denied'},
      ],
    };
  },
  methods: {
    // liste utilisée pour le filtre de la liste des extras
    upsellStatusConstForFilter() {
      return this.upsellStatusConst;
    },
    displayForUpsellStatusConst(value) {
      let result = this.upsellStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    displayForUpsellStatusEnsoConst(value) {
      let result = this.upsellStatusEnsoConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
