import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  upsellsPagination(filter) {
    return Api()
      .get('/upsells/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  exportUpsells(filter) {
    return Api()
      .get('/upsells/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  exportUpsellsForOwner(filter) {
    return Api()
      .get('/upsells/export/owner', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  formatRes(e) {
    return e;
  },
};
