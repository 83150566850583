import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';
import moment from 'moment/moment';

export default {
  // Ecritures comptables : Transactions.vue + TransactionUpdate.vue
  transactions(filter) {
    return Api()
      .get('/comptaoperations/transactions', {params: removeEmptyParams(filter)})
      .then((res) => this.formatResList(res.data));
  },
  transaction(transactionId) {
    return Api()
      .get('/comptaoperations/transaction/' + transactionId)
      .then((res) => this.formatResTransaction(res.data));
  },
  deleteTransaction(transactionId) {
    return Api()
      .delete('/comptaoperations/transaction/' + transactionId)
      .then(() => transactionId);
  },
  updateTransaction(transactionId, lines) {
    return Api()
      .put('/comptaoperation/od/' + transactionId, lines)
      .then((res) => this.formatRes(res.data));
  },

  // Opérations
  comptaOperations(filter) {
    return Api()
      .get('/comptaoperations', {params: removeEmptyParams(filter)})
      .then((res) => this.formatResList(res.data));
  },
  comptaOperationsForReservationDetails(filter) {
    return Api()
      .get('/comptaoperations/reservation', {params: removeEmptyParams(filter)})
      .then((res) => this.formatResList(res.data));
  },
  comptaOperation(id) {
    return Api()
      .get('/comptaoperation/' + id)
      .then((res) => this.formatRes(res.data));
  },
  createFreeOd(freeOds) {
    return Api()
      .post('/comptaoperation/freeod', freeOds)
      .then((res) => this.formatRes(res.data));
  },
  thirdPartyDetails(filter) {
    return Api()
      .get('/comptaoperations/thirdparty/filter', {params: removeEmptyParams(filter)})
      .then((res) => this.formatRes(res.data));
  },
  accommodationThirdPartyDetails(accommodationId) {
    return Api()
      .get('/comptaoperations/thirdparty/accommodation/' + accommodationId)
      .then((res) => this.formatRes(res.data));
  },

  // Taxes de séjour : Utilisation dans StayRateConsultation

  staytaxes(filter) {
    return Api()
      .get('/comptaoperation/staytaxes', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.items = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  createTDSGroup(stayrates) {
    return Api()
      .post('/comptaoperation/createtds', stayrates)
      .then((res) => this.formatRes(res.data));
  },
  exportStayrate(filter) {
    return Api()
      .get('/comptaoperation/staytaxes/export', {
        params: removeEmptyParams(filter),
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  formatRes(data) {
    if (data.date) {
      data.date = moment(data.date).utc(true).toDate();
    }
    if (data.amount > 0) {
      data.credit = data.amount;
      data.debit = 0;
    } else {
      data.debit = data.amount;
      data.credit = 0;
    }
    return data;
  },
  formatResList(data) {
    data.forEach(this.formatRes);
    return data;
  },

  formatResTransaction(data) {
    if (data.date) {
      data.date = moment(data.date).utc(true).toDate();
    }
    if (data.operations) {
      data.operations.forEach(this.formatRes);
    }
    return data;
  },
};
