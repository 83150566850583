<script>
/* COMPOSANTS */
import AkAvatar from '@components/general/AkAvatar';
import GaBlocWithButton from '@components/ga/general/GaBlocWithButton';

/* MIXINS */
import attributionKeysConst from '@mixins/const/attributionKeysConst';

/* SERVICES */

export default {
  components: {GaBlocWithButton, AkAvatar},
  mixins: [attributionKeysConst],
  props: {
    modelValue: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {};
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isDistributionManager() {
      return this.modelValue.technicalName === 'DISTRIBUTION_MANAGER';
    },
  },
  mounted() {},
  methods: {},
};
</script>
<template>
  <GaBlocWithButton>
    <template #content>
      <div class="d-flex flex-column align-items-center">
        <span class="poppins-bold fs-20 color-secondary">
          {{ displayForAttributionKeysConst(currentValue.technicalName) }}
        </span>

        <div class="mt-2 mb-2">
          <AkAvatar
            :first-name="currentValue.firstName"
            :last-name="currentValue.lastName"
            :user-id="currentValue.userId" />
        </div>

        <span class="poppins-bold fs-16 color-secondary" v-if="currentValue.accommodationName">
          <i class="fe fe-home mr-1" />
          {{ currentValue.accommodationName }}
        </span>

        <span class="poppins fs-13 color-secondary">
          <i class="fe fe-user mr-1" />
          {{ currentValue.display }}
        </span>

        <span class="poppins fs-13 color-secondary" v-if="currentValue.employeePhone && !this.isDistributionManager">
          <i class="fe fe-phone mr-1" />
          {{ currentValue.employeePhone }}
        </span>

        <span class="poppins fs-13 color-secondary" v-if="currentValue.employeeEmail && !this.isDistributionManager">
          <i class="fe fe-mail mr-1" />
          <a :href="'mailto:' + currentValue.employeeEmail">
            {{ currentValue.employeeEmail }}
          </a>
        </span>
      </div>
    </template>
  </GaBlocWithButton>
</template>
