import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  paginationATraiter(filter) {
    return Api()
      .get('/mission-dashboard/pagination/atraiter', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        return res.data;
      });
  },
  paginationPlanifie(filter) {
    return Api()
      .get('/mission-dashboard/pagination/planifie', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        return res.data;
      });
  },
  formatRes(e) {
    return e;
  },
};
