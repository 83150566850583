<script>
import {provide, ref} from 'vue';

export default {
  props: {
    withBullet: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    let pWithBullet = ref(props.withBullet !== undefined ? props.withBullet : false);
    provide('pWithBullet', pWithBullet);

    return {pWithBullet};
  },
  methods: {},
};
</script>

<template>
  <div class="d-flex align-items-center" :class="{'with-bullet': this.withBullet}">
    <slot />
  </div>
</template>
