<script>
import {Icon} from '@iconify/vue';

/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import AkDialog from '@components/general/AkDialog.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkInputFilter from '@components/v2/filter/AkInputFilter.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import StayRateCreateSidebar from '@views/accounting/stayrates/StayRateCreateSidebar.vue';
import StayRateUpdateSidebar from '@views/accounting/stayrates/StayRateUpdateSidebar.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import transfertFrequencyConst from '@mixins/const/transfertFrequencyConst';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

/* SERVICES */
import stayRateService from '@services/stayRateService';

export default {
  components: {
    AkInputFilter,
    AkListFilter,
    Icon,
    StayRateCreateSidebar,
    StayRateUpdateSidebar,
    AkDatatablePagination,
    GaListView,
    AkButtonAction,
    AkDialog,
    GaView,
  },
  mixins: [loaderMixin, messageMixin, randomRef, utilsMixin, permissionsMixin, transfertFrequencyConst],
  metaInfo() {
    return {
      title: 'accounting.stay_rate',
    };
  },
  data() {
    return {
      list: [],
      loading: false,
      item: {},
      filter: {
        sort: 'label:ASC',
      },
      currentStayRateId: null,
      regionalConst: [
        {label: this.$t('stayrate.city'), value: false},
        {label: this.$t('stayrate.regional'), value: true},
      ],
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      this.loading = true;
      stayRateService.stayrates(this.filter).then((data) => {
        this.list = data;
        this.loading = false;
      });
    },
    delete() {
      stayRateService
        .delete(this.item)
        .then((data) => {
          this.loading = true;
          this.removeInListById(this.list, data.id);
          this.success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.addError(content);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openCreateSidebar() {
      if (this.$refs.stayRateCreateSidebar) this.$refs.stayRateCreateSidebar.show();
    },
    toggleCreateSidebar() {
      this.$nextTick(() => {
        this.$refs.stayRateCreateSidebar.hide();
        this.search();
      });
    },
    openUpdateSidebar(stayRateId) {
      this.currentStayRateId = stayRateId;
      if (this.$refs.stayRateUpdateSidebar) this.$refs.stayRateUpdateSidebar.show();
    },
    toggleUpdateSidebar() {
      this.$nextTick(() => {
        this.$refs.stayRateUpdateSidebar.hide();
        this.currentStayRateId = null;
        this.search();
      });
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    typeToDisplay(regional) {
      return regional ? this.$t('stayrate.regional') : this.$t('stayrate.city');
    },
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.TDS_GROUP.MANAGE);
    },
  },
};
</script>

<template>
  <GaView>
    <template #action>
      <AkButtonAction
        v-if="hasManagePermission"
        :label="$t('add')"
        class="xs btn-primary-gradient"
        @click="openCreateSidebar" />
    </template>
    <template #content>
      <GaListView>
        <template #filter>
          <AkInputFilter v-model="filter.label" :label="$t('accounting.stay_rate')" @search="search" />
          <AkListFilter
            v-model="filter.regional"
            :items="regionalConst"
            :label="$t('visitor_tax.type')"
            direction="right"
            item-display="label"
            item-value="value"
            @search="search" />
        </template>
        <template #content>
          <AkDatatablePagination
            :lazy="false"
            :loading="loading"
            :value="list"
            :sortMeta="[{field: 'label', sort: -1}]">
            <template #empty>
              {{ $t('stayrate.empty') }}
            </template>

            <Column :header="$t('item_label.label')" field="label" header-style="width: 400px;" sortable />
            <Column :header="$t('stayrate.account')" field="account" header-style="width: 400px;" sortable />
            <Column :header="$t('stayrate.codes')" field="codes" header-style="max-width: 200px;" sortable />
            <Column :header="$t('stayrate.type')" field="regional" header-style="width: 250px;" sortable>
              <template #body="slotProps">
                {{ typeToDisplay(slotProps.data.regional) }}
              </template>
            </Column>
            <Column v-if="hasManagePermission" header-style="width: 25px;">
              <template #body="slotProps">
                <div class="flex items-center gap-2">
                  <div class="cursor-pointer" @click="openUpdateSidebar(slotProps.data.id)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </div>
                  <div class="cursor-pointer" @click="openDeleteDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-red" />
                  </div>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <StayRateCreateSidebar ref="stayRateCreateSidebar" @creation="toggleCreateSidebar" />
      <StayRateUpdateSidebar
        ref="stayRateUpdateSidebar"
        :stayRateId="currentStayRateId"
        @hide="currentStayRateId = null"
        @update="toggleUpdateSidebar" />

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="flex items-center gap-2">
          <Icon class="text-red" icon="fluent:warning-20-regular" width="26" />
          <span>{{ $t('stayrate.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
