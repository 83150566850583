<script>
import {useVuelidate} from '@vuelidate/core';
import {email, required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkDropdownGroup from '@components/v2/input/AkDropdownGroup.vue';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import AkMultiSelect from '@components/v2/input/AkMultiSelect.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import messageMixin from '@mixins/messageMixin';
import roleKeysConst from '@mixins/const/roleKeysConst';
import roleTypesConst from '@mixins/const/roleTypesConst';
import civilitiesConst from '@mixins/const/civilitiesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import {useCompanyStore} from '@store/companyStore';

/* SERVICES */
import employeeService from '@services/employeeService';
import poleService from '@services/poleService';
import formatMixin from '@mixins/formatMixin';

export default {
  components: {
    AkButtonFooterSidebar,
    AkSidebar,
    GaMessages,
    AkMultiSelect,
    AkDropdown,
    AkDropdownGroup,
    AkInputText,
  },
  mixins: [
    accommodationAutocompleteMixin,
    messageMixin,
    roleKeysConst,
    roleTypesConst,
    civilitiesConst,
    formatMixin,
    permissionsMixin,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      poles: [],
      companies: [],
      createSidebarVisible: false,
      employee: {},
      submitted: false,
    };
  },
  validations() {
    return {
      employee: {
        civility: {required},
        firstName: {required},
        lastName: {required},
        email: {required, email},
        roleKey: {required},
        roleType: {required},
        companyId: {required: requiredIf(this.isRoleTypeGav)},
      },
    };
  },
  emits: ['creation'],
  methods: {
    show() {
      this.createSidebarVisible = true;
      if (this.isGas()) {
        this.employee.roleType = 'GAS';
      } else if (this.isGav()) {
        this.employee.roleType = 'GAV';
      }
      poleService.poles().then((data) => {
        this.poles = data;
      });
      useCompanyStore()
        .all()
        .then((companies) => {
          this.companies = companies;
        });
    },
    hide() {
      this.createSidebarVisible = false;
      this.employee = {};
    },
    create() {
      this.submitted = true;
      this.v$.employee.$touch();
      if (this.v$.employee.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      employeeService
        .create(this.employee)
        .then(() => {
          this.success(this.$t('employee.added'));
          this.employee = {};
          this.$emit('creation');
          this.hide();
        })
        .catch((e) => {
          this.$refs.sidebarMessages.error(this.$t('error.' + e.response.data.error));
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
  computed: {
    isRoleTypeGas() {
      return this.employee.roleType === 'GAS';
    },
    isRoleTypeGav() {
      return this.employee.roleType === 'GAV';
    },
    buildRoleKeys() {
      if (this.isRoleTypeGav) return this.roleKeysForGav;
      if (this.isRoleTypeGas) return this.roleKeysForGas;
      return null;
    },
  },
};
</script>

<template>
  <AkSidebar
    v-model:visible="createSidebarVisible"
    :baseZIndex="9998"
    :dismissable="true"
    :hide="hide"
    block-scroll
    position="right">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('employee.add') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4 md:gap-2">
          <div class="flex flex-col gap-4">
            <AkDropdown
              v-model="employee.roleType"
              :label="$t('employee.roleType')"
              :options="roleTypesForEmployees"
              :disabled="isGas() || isGav()"
              :submitted="submitted"
              :validator="v$.employee.roleType" />
            <AkMultiSelect
              v-if="isRoleTypeGas"
              v-model="employee.poleIds"
              :label="$t('team')"
              :options="poles"
              :submitted="submitted"
              option-label="name"
              option-value="id" />
            <AkDropdown
              v-if="isRoleTypeGav"
              v-model="employee.roleKey"
              :label="$t('employee.roleKey')"
              :options="buildRoleKeys"
              :submitted="submitted"
              :validator="v$.employee.roleKey" />
            <AkDropdownGroup
              v-if="isRoleTypeGas"
              v-model="employee.roleKey"
              :label="$t('employee.roleKey')"
              :options="buildRoleKeys"
              :submitted="submitted"
              :validator="v$.employee.roleKey" />
            <AkDropdown
              v-if="isRoleTypeGav"
              v-model="employee.companyId"
              :label="$t('employee.company')"
              :options="companies"
              :submitted="submitted"
              :validator="v$.employee.companyId"
              option-label="name"
              option-value="id" />
            <AkDropdown
              v-model="employee.civility"
              :label="$t('employee.civility')"
              :options="civilitiesConst"
              :submitted="submitted"
              :validator="v$.employee.civility" />
            <AkInputText
              v-model="employee.lastName"
              :label="$t('employee.lastName')"
              :formatter="toUppercase"
              :submitted="submitted"
              :validator="v$.employee.lastName" />
            <AkInputText
              v-model="employee.firstName"
              :label="$t('employee.firstName')"
              :submitted="submitted"
              :validator="v$.employee.firstName" />
            <AkInputText
              v-model="employee.email"
              :label="$t('employee.email')"
              :submitted="submitted"
              :validator="v$.employee.email"
              icon="ga-icon ga-email" />
          </div>

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :validate-label="$t('add')"
            :cancel-label="$t('cancel')"
            @cancel="hide()"
            @action="create()" />
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
