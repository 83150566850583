<script>
import {getColorIconFromMIME, getFontAwesomeIconFromMIME} from '@utils/FontAwesomeMimeType';

/* COMPOSANTS */
import FileUpload from 'primevue/fileupload';
import AkFicCard from '@components/v2/general/AkFicCard.vue';
import AkDialogConfirm from '@components/general/AkDialogConfirm.vue';

/* MIXINS */
import envConst from '@mixins/const/envConst';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import ficService from '@services/ficService';

export default {
  components: {AkDialogConfirm, AkFicCard, FileUpload},
  mixins: [envConst, messageMixin],
  props: {
    modelValue: {
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
    tableId: {
      type: [Number, String],
      required: true,
    },
    ficType: {
      type: String,
      required: false,
      default: undefined,
    },
    accept: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    canUpload: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue', 'added', 'removed'],
  data() {
    return {
      fileAction: null,
      displayDeleteDialog: false,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    getFileIcon(fileName) {
      return getFontAwesomeIconFromMIME(fileName);
    },
    getColorIcon(fileName) {
      return getColorIconFromMIME(fileName);
    },
    downloadFile(file, isPreview) {
      ficService.download(file, isPreview).catch((e) => {
        if (e.response.status === 404) {
          this.addError("Le fichier n'existe pas");
        }
      });
    },
    selectFiles(event) {
      if (event.files) {
        let fic = {};
        fic.tableName = this.tableName;
        fic.tableId = this.tableId;
        if (this.ficType) {
          fic.ficType = this.ficType;
        }
        if (this.ficItemLabelId) {
          fic.ficItemLabelId = this.ficItemLabelId;
        }
        if (this.uniqueFic) {
          fic.uniqueFic = this.uniqueFic;
        }

        ficService.createItems(fic, event.files).then((e) => {
          Array.prototype.push.apply(this.currentValue, e);
          this.$refs.uploader.clear();
          this.$emit('added');
        });
      }
    },
    confirmDeleteFile(file) {
      this.fileAction = file;
      this.$refs.dialogDelete.show();
    },
    deleteFile() {
      ficService
        .delete(this.fileAction)
        .then(() => {
          this.currentValue = this.currentValue.filter((val) => val.id !== this.fileAction.id);
          this.success('Le fichier a bien été supprimé');
          this.$emit('removed');
        })
        .catch(() => {
          this.addError('Une erreur est survenue, merci de réessayer plus tard !');
        })
        .finally(() => {
          this.fileAction = undefined;
          this.$refs.dialogDelete.hide();
        });
    },
    uploadFile() {
      this.$refs.uploader.$refs.fileInput.click();
    },
  },
};
</script>

<template>
  <div class="flex justify-between items-center">
    <span class="text-dark text-md font-semibold">{{ title }}</span>
    <i
      class="ga-icon ga-plus_circle text-primary text-3xl hover:cursor-pointer"
      v-if="canUpload"
      @click="uploadFile"></i>
  </div>
  <div class="flex flex-col gap-2 mt-4" v-if="currentValue && currentValue.length > 0">
    <AkFicCard
      v-for="(item, idx) in currentValue"
      v-model="currentValue[idx]"
      :disabled="!canDelete"
      :key="item.id"
      custom-delete
      :reference="item.id"
      :table-id="item.id"
      :title="item.fileName"
      @delete-item="confirmDeleteFile(item)"
      :table-name="tableName" />
  </div>
  <FileUpload
    v-if="canUpload"
    ref="uploader"
    :invalidFileSizeMessage="$t('file.err_size')"
    invalidFileTypeMessage="Ce type est interdit"
    :maxFileSize="getMaxFileUpload()"
    :accept="accept"
    multiple
    @select="selectFiles">
    <template #header>
      <div></div>
    </template>
    <template #content="{files}">
      <div
        v-if="files.length > 0"
        class="flex flex-col justify-center items-center text-center gap-1 p-10 border-dashed rounded-xl border border-grayBg hover:cursor-pointer"
        @click="uploadFile">
        <i class="ga-icon ga-upload text-3xl text-dark" />
        <p class="text-base font-normal text-dark">Faites glisser un fichier ici</p>
        <p class="text-base font-normal text-dark">
          Ou <span class="text-primary">importer depuis votre ordinateur</span>
        </p>
      </div>
    </template>
    <template #empty>
      <div
        class="flex flex-col justify-center items-center text-center gap-1 p-10 border-dashed rounded-xl border border-grayBg hover:cursor-pointer"
        @click="uploadFile">
        <i class="ga-icon ga-upload text-3xl text-dark" />
        <p class="text-base font-normal text-dark">Faites glisser un fichier ici</p>
        <p class="text-base font-normal text-dark">
          Ou <span class="text-primary">importer depuis votre ordinateur</span>
        </p>
      </div>
    </template>
  </FileUpload>

  <AkDialogConfirm
    ref="dialogDelete"
    message="Etes-vous sur de vouloir supprimer ce fichier ?"
    title="Suppression de fichier"
    @yes="deleteFile">
  </AkDialogConfirm>
</template>
