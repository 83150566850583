<script>
import {mapState} from 'pinia/dist/pinia';
import {useMessageStore} from '@store/messagesStore';

/* COMPOSANTS */
import AkLoader from '@components/general/AkLoader';
import AkPicture from '@components/v2/general/AkPicture';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation';
import GaTabView from '@components/v2/layout/GaTabView';
import GaTripleView from '@components/v2/layout/GaTripleView';
import PrestationStatusBadge from '@components/general/PrestationStatusBadge';
import MissionDetails from '@views/operationnel/missionorders/tabs/MissionDetails';
import MissionOrderCDC from '@views/operationnel/missionorders/tabs/MissionOrderCDC';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import litigeStatusConst from '@mixins/const/litigeStatusConst';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import missionOrderPageTabMixin from '@mixins/tab/missionOrderPageTabMixin';
import personnelTypeConst from '@mixins/const/personnelTypeConst';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import missionOrderService from '@services/missionOrderService';
import prestationReservationService from '@services/prestationReservationService';
import AkDialogConfirm from '@components/general/AkDialogConfirm.vue';
import MissionUpdateSidebar from '@views/operationnel/missionorders/MissionUpdateSidebar.vue';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';
import PrestationBadge from '../../../components/general/PrestationBadge.vue';

export default {
  components: {
    PrestationBadge,
    AkButtonValidation,
    MissionUpdateSidebar,
    AkDialogConfirm,
    AkLoader,
    AkPicture,
    AkPictureAccommodation,
    GaTabView,
    GaTripleView,
    MissionDetails,
    MissionOrderCDC,
    PrestationStatusBadge,
  },
  mixins: [
    backMixin,
    litigeStatusConst,
    loaderMixin,
    messageMixin,
    missionOrderPageTabMixin,
    personnelTypeConst,
    prestationTypesConst,
    reservationTypesConst,
    utilsMixin,
  ],
  metaInfo() {
    return {
      title: this.title,
    };
  },
  props: {
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      mission: null,
      previousReservation: undefined,
      nextReservation: undefined,
    };
  },
  mounted() {
    this.initGoBack();
    this.refresh();
  },
  computed: {
    title() {
      if (!this.mission) return '';
      return '#' + this.mission?.id;
    },
    ...mapState(useMessageStore, ['list']),
    statusPrestation() {
      if (!this.mission) return null;
      return this.mission.statusPrestation;
    },
    statusParticipants() {
      if (!this.mission) return null;
      return this.mission.statusParticipants;
    },
    // Statuts d'annulation
    isStatusPrestationCanceled() {
      return this.statusPrestation === 'CANCELED';
    },
    isStatusParticipantsCanceled() {
      return this.statusParticipants === 'CANCELED';
    },
    isStatusParticipantsPartiallyCanceled() {
      return this.statusParticipants === 'PARTIALLY_CANCELED';
    },
    // Statuts de proposition / planification
    isStatusParticipantsProposed() {
      return this.statusParticipants === 'PROPOSED';
    },
    isStatusParticipantsToPlan() {
      return this.statusParticipants === 'TO_PLAN';
    },
    isStatusParticipantsTodo() {
      return this.statusParticipants === 'TODO';
    },
    isStatusParticipantsDoing() {
      return this.statusParticipants === 'DOING';
    },
    isStatusParticipantsCompleted() {
      return this.statusParticipants === 'COMPLETED';
    },
    isAssignedStaffPartner() {
      return this.mission.assignedStaff === 'PARTNER';
    },
    isAssignedStaffIntern() {
      return this.mission.assignedStaff === 'INTERN';
    },
    isAssignedStaffHousekeeper() {
      return this.mission.assignedStaff === 'HOUSEKEEPER';
    },
    isTimeToPlan() {
      if (!(this.mission.dateStart instanceof Date)) return true;
      return (
        (this.isAssignedStaffIntern || this.isAssignedStaffHousekeeper) &&
        this.mission.dateStart.getHours() === 0 &&
        this.mission.dateStart.getMinutes() === 0
      );
    },
    // HAS
    hasReservation() {
      return this.mission && this.mission.reservation;
    },
    hasPreviousReservation() {
      return this.previousReservation && this.previousReservation.id;
    },
    hasNextReservation() {
      return this.nextReservation && this.nextReservation.id;
    },
    hasClient() {
      return this.mission.reservation.client;
    },
    clientDisplay() {
      if (!this.hasClient) return '-';
      return this.mission.reservation.client.firstName + ' ' + this.mission.reservation.client.lastName;
    },
    // CAN
    canModify() {
      if (this.isStatusPrestationCanceled || this.isAssignedStaffPartner) return false;
      // Peut modifier la mission si le statut est "Proposée" ou "A planifier" ou "Planifiée"
      return (
        !this.prestationEdited &&
        (this.isStatusParticipantsProposed || this.isStatusParticipantsToPlan || this.isStatusParticipantsTodo)
      );
    },
    canCancel() {
      return (
        !this.isStatusPrestationCanceled &&
        !this.isStatusParticipantsDoing &&
        !this.isStatusParticipantsCompleted &&
        !this.isAssignedStaffPartner
      );
    },
    canRepropose() {
      if (this.isStatusPrestationCanceled || this.isAssignedStaffPartner) return false;
      // Peut reproposer la mission si les prestataires ont tous annulé ou partiellement annulé
      return this.isStatusParticipantsCanceled || this.isStatusParticipantsPartiallyCanceled;
    },
  },
  methods: {
    resetMessage() {
      useMessageStore().$reset();
    },
    refresh() {
      this.showTotalLoaderGuestadom();
      missionOrderService
        .get(this.$route.params.missionOrderId)
        .then((data) => {
          this.mission = data;
          this.loadPrevReservation();
          this.loadNextReservation();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    repropose() {
      missionOrderService
        .repropose(this.$route.params.missionOrderId)
        .then(() => {
          this.success(this.$t('mission_order_details.repropose_success'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.btnRepropose.init();
        });
    },
    cancel() {
      this.$refs.dialogCancel.show();
    },
    handleCancel() {
      missionOrderService
        .cancel(this.mission.id)
        .then((data) => {
          this.success(this.$t('mission_order_details.cancel_success'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.dialogCancel.hide();
        });
    },
    loadPrevReservation() {
      this.$refs.prevLoader.load();

      if (!this.hasReservation) {
        this.$refs.prevLoader.hide();
        return;
      }

      prestationReservationService
        .findPrevReservation(this.mission.reservation.id)
        .then((data) => {
          this.previousReservation = data;
        })
        .finally(() => {
          this.$refs.prevLoader.hide();
        });
    },
    loadNextReservation() {
      this.$refs.nextLoader.load();

      if (!this.hasReservation) {
        this.$refs.nextLoader.hide();
        return;
      }

      prestationReservationService
        .findNextReservation(this.mission.reservation.id)
        .then((data) => {
          this.nextReservation = data;
        })
        .finally(() => {
          this.$refs.nextLoader.hide();
        });
    },
    showUpdateSidebar() {
      this.$refs.missionUpdateSidebar.show();
    },
  },
};
</script>

<template>
  <GaTripleView>
    <template #action v-if="this.mission">
      <button class="btn-action xs bg-red text-white" v-if="this.canCancel" @click="this.cancel()">
        Annuler la mission
      </button>
      <button class="btn-primary-gradient xs" v-if="this.canModify" @click="this.showUpdateSidebar()">
        Modifier la mission
      </button>
      <AkButtonValidation
        v-if="this.canRepropose"
        ref="btnRepropose"
        btnClass="btn-action xs bg-lightGray text-dark hover:text-dark"
        @action="this.repropose()"
        label="Remettre en ligne">
      </AkButtonValidation>
    </template>
    <template #left>
      <div class="flex flex-col gap-2" v-if="this.mission">
        <div class="w-[160px] self-center">
          <AkPictureAccommodation
            :id="this.mission.accommodation.id"
            cover
            height="160"
            radius="160"
            :md5="this.mission.accommodation.md5"></AkPictureAccommodation>
        </div>
        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement(this.$refs, 'accommodationData', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Informations du logement</span>
        </div>
        <div ref="accommodationData" class="grid grid-cols-2 mt-4 gap-4">
          <div class="flex flex-col gap-1 col-span-2">
            <span class="font-inter font-semibold text-dark text-base">Logement</span>

            <router-link
              class="text-primary hover:text-primary font-semibold text-md"
              :to="{name: 'accommodationPage', params: {accommodationId: this.mission.accommodation.id}}">
              {{ this.mission.accommodation.name }}
            </router-link>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Hôte</span>
            <span class="font-inter font-medium text-gray text-md">
              <router-link
                class="text-primary hover:text-primary font-semibold text-md"
                :to="{name: 'ownerPage', params: {ownerId: this.mission.accommodation.ownerId}}">
                {{ this.mission.accommodation.ownerDisplay }}
              </router-link>
            </span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Agence</span>
            <span class="font-inter font-medium text-gray text-md">{{ this.mission.accommodation.companyName }}</span>
          </div>
          <div class="flex flex-col gap-1 col-span-2">
            <span class="font-inter font-semibold text-dark text-base">Adresse</span>
            <div class="flex flex-col">
              <span class="font-inter font-medium text-gray text-md">
                {{ this.mission.accommodation.address }}
              </span>
              <span class="font-inter font-medium text-gray text-md">
                {{ this.mission.accommodation.complement }}
              </span>
              <span class="font-inter font-medium text-gray text-md">
                {{ this.mission.accommodation.postalCode }} {{ this.mission.accommodation.city }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement(this.$refs, 'missionData', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Informations de mission</span>
        </div>
        <div ref="missionData" class="grid grid-cols-2 mt-4 gap-4">
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Création</span>
            <span class="font-inter font-medium text-gray text-md">{{
              this.formatDateIfNeeded(this.mission.dateCreated)
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Prestation</span>
            <span class="font-inter font-medium text-gray text-md"
              >{{ this.formatDateIfNeeded(this.mission.dateStart) }} à
              {{ this.displayTime(this.mission.dateStart) }}</span
            >
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Type de mission</span>
            <span class="font-inter font-medium text-gray text-md">
              <PrestationBadge :type="this.mission.type" />
            </span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Statut de la mission</span>
            <span class="font-inter font-medium text-gray text-md">
              <PrestationStatusBadge :status="this.mission.statusParticipants" />
            </span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('mission_order.assigned_staff') }}</span>
            <span class="font-inter font-medium text-gray text-md">
              {{ this.displayForPersonnelTypeConst(this.mission.statusPrestation) }}
            </span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('mission_order.nb_participants') }}</span>
            <span class="font-inter font-medium text-gray text-md">
              {{ this.mission.participantNumber }}
            </span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Durée prévue</span>
            <span class="font-inter font-medium text-gray text-md">
              {{ this.mission.duration }}
            </span>
          </div>
          <div class="flex flex-col gap-1" v-if="!this.isAssignedStaffPartner">
            <span class="font-inter font-semibold text-dark text-base">Heure de début prévue</span>
            <span class="font-inter font-medium text-gray text-md">
              <span v-if="!this.isTimeToPlan">
                {{ this.displayTime(this.mission.dateStart) }}
              </span>
              <span v-else>A planifier</span>
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #right>
      <div class="flex justify-between items-center" v-if="this.hasReservation">
        <span class="text-dark text-md font-semibold">Réservation actuelle</span>
      </div>
      <div ref="reservationData" class="bg-secondaryBg rounded-lg p-2 mt-2 mb-4" v-if="this.hasReservation">
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Réservation</span>

            <router-link
              class="text-primary hover:text-primary font-semibold text-md"
              :to="{
                name: 'reservationPage',
                params: {
                  reservationId: this.mission.reservation.id,
                },
              }">
              {{ this.mission.reservation.reservationNum }}
            </router-link>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Type</span>
            <span class="font-inter font-medium text-gray text-md">{{
              displayForReservationTypeConst(this.mission.reservation.type)
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Check-in</span>
            <span class="font-inter font-medium text-gray text-md">{{
              this.formatDateIfNeeded(this.mission.reservation.dateCheckin)
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Check-out</span>
            <span class="font-inter font-medium text-gray text-md">{{
              this.formatDateIfNeeded(this.mission.reservation.dateCheckout)
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Création</span>
            <span class="font-inter font-medium text-gray text-md">{{
              this.formatDateIfNeeded(this.mission.reservation.dateCreated)
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Voyageur</span>
            <router-link
              v-if="this.mission.reservation.client.id"
              class="font-inter font-medium text-primary hover:text-primary text-md break-words"
              :to="{name: 'clientPage', params: {clientId: this.mission.reservation.client.id}}">
              {{ clientDisplay }}
            </router-link>
            <span v-else class="font-inter font-medium text-gray text-md break-words">-- --</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">E-mail</span>
            <span class="font-inter font-medium text-gray text-md break-words">{{
              this.mission.reservation.client.email
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Téléphone</span>
            <span class="font-inter font-medium text-gray text-md break-words">{{
              this.mission.reservation.client.phone
            }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center">
        <span class="text-dark text-md font-semibold">Réservation précédente</span>
      </div>
      <div>
        <AkLoader ref="prevLoader" message="Récupération de la réservation">
          <template #content>
            <div class="mb-4">
              <div v-if="this.hasPreviousReservation" class="bg-detailsBg rounded-lg p-2 mt-2">
                <div class="grid grid-cols-2 gap-4">
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Réservation</span>

                    <router-link
                      class="text-primary hover:text-primary font-semibold text-md"
                      :to="{
                        name: 'reservationPage',
                        params: {
                          reservationId: this.previousReservation.id,
                        },
                      }">
                      {{ this.previousReservation.reservationNum }}
                    </router-link>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Type</span>
                    <span class="font-inter font-medium text-gray text-md">
                      {{ displayForReservationTypeConst(this.previousReservation.type) }}
                    </span>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Check-in</span>
                    <span class="font-inter font-medium text-gray text-md">{{
                      this.formatDateIfNeeded(this.previousReservation.dateCheckin)
                    }}</span>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Check-out</span>
                    <span class="font-inter font-medium text-gray text-md">{{
                      this.formatDateIfNeeded(this.previousReservation.dateCheckout)
                    }}</span>
                  </div>
                </div>
                <div v-if="this.previousReservation.litiges?.length > 0">
                  <div class="mt-2" v-for="(litige, idx) of this.previousReservation.litiges" :key="litige.id">
                    <div class="flex flex-col gap-2">
                      <span class="font-bold text-md text-dark">Litige {{ idx + 1 }}</span>
                      <div class="flex flex-col gap-2 gray-border">
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Status</span>
                          <span class="font-inter font-medium text-gray text-md">
                            {{ displayForLitigeStatusConst(litige.status) }}
                          </span>
                        </div>
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Description</span>
                          <span class="font-inter font-medium text-gray text-md">
                            {{ litige.description }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="bg-detailsBg rounded-lg p-2 mt-2">
                <div class="flex flex-col items-center">
                  <i class="ga-icon ga-calendar text-3xl text-gray"></i>
                  <span class="text-gray font-medium text-md">Pas de réservation précédente</span>
                </div>
              </div>
            </div>
          </template>
        </AkLoader>
      </div>

      <div class="flex justify-between items-center">
        <span class="text-dark text-md font-semibold">Réservation suivante</span>
      </div>
      <div>
        <AkLoader ref="nextLoader" message="Récupération de la réservation">
          <template #content>
            <div class="mb-2">
              <div v-if="this.hasNextReservation" class="bg-detailsBg rounded-lg p-2 mt-2">
                <div class="grid grid-cols-2 gap-4">
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Réservation</span>
                    <router-link
                      class="text-primary hover:text-primary font-semibold text-md"
                      :to="{
                        name: 'reservationPage',
                        params: {
                          reservationId: this.nextReservation.id,
                        },
                      }">
                      {{ this.nextReservation.reservationNum }}
                    </router-link>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Type</span>
                    <span class="font-inter font-medium text-gray text-md">
                      {{ displayForReservationTypeConst(this.nextReservation.type) }}
                    </span>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Check-in</span>
                    <span class="font-inter font-medium text-gray text-md">{{
                      this.formatDateIfNeeded(this.nextReservation.dateCheckin)
                    }}</span>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span class="font-inter font-semibold text-dark text-base">Check-out</span>
                    <span class="font-inter font-medium text-gray text-md">{{
                      this.formatDateIfNeeded(this.nextReservation.dateCheckout)
                    }}</span>
                  </div>
                </div>
              </div>
              <div v-else class="bg-detailsBg rounded-lg p-2 mt-2">
                <div class="flex flex-col items-center">
                  <i class="ga-icon ga-calendar text-3xl text-gray"></i>
                  <span class="text-gray font-medium text-md">Pas de réservation suivante</span>
                </div>
              </div>
            </div>
          </template>
        </AkLoader>
      </div>
    </template>
    <template #content>
      <GaTabView :tabs="tabs">
        <template #intervenant>
          <MissionDetails :modelValue="this.mission" />
        </template>
        <template #picture>
          <div class="grid grid-cols-3 gap-4" v-if="this.mission.imagesFicList?.length">
            <AkPicture v-for="fic in this.mission.imagesFicList" :key="fic.id" :fic="fic" cover />
          </div>
          <div v-else>
            <div class="flex flex-col gap-2 items-center justify-center min-h-[200px]">
              <i class="ga-icon ga-camera text-4xl text-gray"></i>
              <span class="text-gray font-medium text-md">Aucune photo pour cette mission</span>
            </div>
          </div>
        </template>
        <template #cdc>
          <MissionOrderCDC :cdcId="this.mission.cdcId" :type="this.mission.type" />
        </template>
      </GaTabView>
    </template>
    <template #extra>
      <AkDialogConfirm
        ref="dialogCancel"
        title="Annulation de la mission"
        message="Êtes-vous sur de vouloir annuler cette mission ?"
        @yes="handleCancel()" />

      <MissionUpdateSidebar
        v-if="this.mission"
        ref="missionUpdateSidebar"
        :missionId="this.mission.id"
        @update-done="refresh" />
    </template>
  </GaTripleView>
</template>
