<script>
import moment from 'moment';

/* COMPOSANTS */
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import PrestationBadge from '@components/general/PrestationBadge.vue';
import PrestationStatusBadge from '@components/general/PrestationStatusBadge.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';

/* SERVICES */
import prestatairePageService from '@services/prestatairePageService';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';

export default {
  components: {AkCalendar, PrestationBadge, PrestationStatusBadge, AkDatatablePagination},
  mixins: [utilsMixin],
  props: {
    prestataireId: {
      required: true,
    },
  },
  data() {
    return {
      list: [],
      count: 0,
      stats: {
        turnover: 0,
        minutesDone: 0,
        missionsDone: 0,
        totalRecords: 0,
      },
      loading: false,
      monthChosen: new Date(),
    };
  },
  watch: {
    monthChosen: function () {
      this.getStats();
      this.getMissions();
    },
  },
  mounted() {
    this.getStats();
    this.getMissions();
  },
  methods: {
    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'prestatairePage',
        params: {prestataireId: data.contact.id},
      });
    },
    getStats() {
      const startDate = new Date(this.monthChosen.getFullYear(), this.monthChosen.getMonth(), 1).toJSON();
      const endDate = new Date(
        this.monthChosen.getMonth() === 11 ? this.monthChosen.getFullYear() + 1 : this.monthChosen.getFullYear(),
        this.monthChosen.getMonth() === 11 ? 0 : this.monthChosen.getMonth() + 1,
        1,
      ).toJSON();

      prestatairePageService.getStats(this.prestataireId, startDate, endDate).then((data) => {
        this.stats = data;
      });
    },
    getMissions() {
      this.loading = true;
      const startDate = new Date(this.monthChosen.getFullYear(), this.monthChosen.getMonth(), 1).toJSON();
      const endDate = new Date(
        this.monthChosen.getMonth() === 11 ? this.monthChosen.getFullYear() + 1 : this.monthChosen.getFullYear(),
        this.monthChosen.getMonth() === 11 ? 0 : this.monthChosen.getMonth() + 1,
        1,
      ).toJSON();

      let filter = {
        prestataireId: this.prestataireId,
        dateStart: startDate,
        dateEnd: endDate,
        sort: ['j_prestation_date_start:ASC'],
      };
      prestatairePageService.getMissions(filter).then((data) => {
        this.list = data;
        this.loading = false;
      });
    },

    onRowClick(event) {
      this.$router.push({name: 'mission-order-detail', params: {missionOrderId: event.data.prestationId}});
    },
    getDuration(slotProps) {
      const duration = moment.duration(slotProps.data.dateEnd - slotProps.data.dateStart);
      if (duration.asDays() >= 1) return `${Math.round(duration.asDays() * 100) / 100}j`;
      return `${duration.asMinutes()} min`;
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-4 mt-4">
    <span class="title-border">Missions</span>

    <AkCalendar v-model="monthChosen" format="MM yy" view="month" />

    <div class="flex flex-col md:grid md:grid-cols-3 gap-4">
      <div class="p-8 rounded-lg bg-white flex flex-col gap-1">
        <span class="font-inter font-semibold text-[#475467]">Missions effectuées</span>
        <span class="font-poppins font-bold text-dark text-[36px]"> {{ stats.missionsDone }}</span>
      </div>
      <div class="p-8 rounded-lg bg-white flex flex-col gap-1">
        <span class="font-inter font-semibold text-[#475467]">Heures effectuées</span>
        <span class="font-poppins font-bold text-dark text-[36px]">
          {{ stats.durationDone === '' ? '0h' : stats.durationDone }}</span
        >
      </div>
      <div class="p-8 rounded-lg bg-white flex flex-col gap-1">
        <span class="font-inter font-semibold text-[#475467]">CA généré</span>
        <span class="font-poppins font-bold text-dark text-[36px]">
          {{ formatCurrencyEUR(stats.turnover) + ' HT' }}</span
        >
      </div>
    </div>

    <AkDatatablePagination
      :lazy="false"
      :loading="loading"
      :value="list"
      class="p-3 rounded-lg bg-white cursor-pointer"
      @row-click="onRowClick">
      <template #empty>Aucune mission</template>
      <Column header="Mission">
        <template #body="slotProps">
          {{ new Date(slotProps.data.dateStart).toLocaleDateString() }}
        </template>
      </Column>
      <Column header="Heure">
        <template #body="slotProps">
          {{ new Date(slotProps.data.dateStart).toLocaleTimeString().slice(0, -3) }}
        </template>
      </Column>
      <Column header="Durée">
        <template #body="slotProps">
          {{ getDuration(slotProps) }}
        </template>
      </Column>
      <Column header="Type">
        <template #body="slotProps">
          <PrestationBadge :type="slotProps.data.type" />
        </template>
      </Column>
      <Column header="Réservation">
        <template #body="slotProps">
          <router-link
            class="break-words"
            v-if="slotProps.data.reservationId"
            :to="{
              name: 'reservationPage',
              params: {reservationId: slotProps.data.reservationId},
            }">
            {{ slotProps.data.reservationNum }}
          </router-link>
          <span v-else>---</span>
        </template>
      </Column>
      <Column header="Statut de la mission">
        <template #body="slotProps">
          <PrestationStatusBadge :status="slotProps.data.statusParticipant" />
        </template>
      </Column>
      <Column header="Annulation">
        <template #body="slotProps">
          {{ displayDateTime(slotProps.data.dateCancel) }}
        </template>
      </Column>
      <Column header="Personne qui a annulé la mission">
        <template #body="slotProps">
          {{ slotProps.data.cancellerDisplay }}
        </template>
      </Column>
    </AkDatatablePagination>

    <span class="title-border">Indisponibilités</span>

    <AkDatatablePagination
      :lazy="false"
      :loading="loading"
      :value="stats.unavailabilities"
      class="p-3 rounded-lg bg-white">
      <template #empty>Aucune indisponibilité</template>
      <Column header="Ajout">
        <template #body="slotProps">
          {{ new Date(slotProps.data.dateCreated).toLocaleDateString() }}
        </template>
      </Column>
      <Column header="Début">
        <template #body="slotProps">
          {{ new Date(slotProps.data.startDate).toLocaleDateString() }}
        </template>
      </Column>
      <Column header="Fin">
        <template #body="slotProps">
          {{ new Date(slotProps.data.endDate).toLocaleDateString() }}
        </template>
      </Column>
    </AkDatatablePagination>
  </div>
</template>
