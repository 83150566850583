<script>
import AkDialogConfirm from '@components/general/AkDialogConfirm';

export default {
  components: {AkDialogConfirm},
  props: {
    label: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      required: false,
    },
    little: {
      type: Boolean,
      required: false,
      default: true,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    danger: {
      type: Boolean,
      required: false,
      default: false,
    },
    success: {
      type: Boolean,
      required: false,
      default: false,
    },
    messagePopup: {
      type: String,
      required: false,
      default: undefined,
    },
    titlePopup: {
      type: String,
      required: false,
      default: undefined,
    },
    widthPopup: {
      type: String,
      default: '600px',
    },
  },
  emits: ['click'],
  computed: {
    buildClass() {
      if (this.class) return this.class;
      return {
        xs: this.little,
        'btn-primary-gradient': !this.secondary && !this.danger && !this.success,
        'btn-light-gray': this.secondary,
        'btn-danger': this.danger,
        'btn-success': this.success,
      };
    },
    buildPopupMessage() {
      if (this.messagePopup) return this.messagePopup;
      return '';
    },
    buildPopupTitle() {
      if (this.titlePopup) return this.titlePopup;
      return '';
    },
  },
  methods: {
    init() {
      this.$refs.confirmDialog.init();
      this.$refs.confirmDialog.hide();
    },
    openConfirmDialog() {
      this.$refs.confirmDialog.show();
    },
    onConfirmYes() {
      this.$emit('click', this.init);
    },
  },
};
</script>

<template>
  <button type="submit" @click="openConfirmDialog()" :class="buildClass">
    {{ this.label }}
  </button>

  <AkDialogConfirm
    ref="confirmDialog"
    :loadable="true"
    :width="widthPopup"
    :message="buildPopupMessage"
    :title="buildPopupTitle"
    @yes="onConfirmYes()" />
</template>
