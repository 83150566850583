<script>
import AkLabel from '@components/general/AkLabel';
import InputNumber from '@components/input/InputNumber';

export default {
  components: {AkLabel, InputNumber},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    mode: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
      default: undefined,
    },
    min: {
      type: Number,
      required: false,
      default: null,
    },
    max: {
      type: Number,
      required: false,
      default: null,
    },
    fractionDigits: {
      type: Number,
      required: false,
      default: 2,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    validatorRequired() {
      if (!this.validator) return null;
      return this.validator.required;
    },
    validatorRequiredType() {
      if (!this.validatorRequired) return null;
      return this.validator.required.$params.type;
    },
    isRequired() {
      if (this.validatorRequiredType === 'required') return true;
      if (this.validatorRequiredType === 'requiredIf') return this.validatorRequired.$params.prop !== undefined;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isValidatorRequired() {
      return this.isRequired && this.validator.required.$invalid && this.alreadySubmitted;
    },
    isBoundaryRequired() {
      return (
        this.validator !== undefined &&
        this.validator.betweenValue !== undefined &&
        this.validator.betweenValue.$invalid &&
        this.alreadySubmitted
      );
    },
    isMinValueRequired() {
      return (
        this.validator !== undefined &&
        this.validator.minValue !== undefined &&
        this.validator.minValue.$invalid &&
        this.alreadySubmitted
      );
    },
    getCurrency() {
      return this.mode === 'currency' ? 'EUR' : undefined;
    },
    useGrouping() {
      return false;
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="[className, {'form-group': !isInline, 'form-control': isInline, disabled: isDisabled}]">
    <AkLabel v-if="!isInline && displayLabel" :required="this.isRequired || this.required === true">
      {{ label }}
    </AkLabel>
    <InputNumber
      v-model="currentValue"
      :class="[{'p-invalid': isInvalid && alreadySubmitted, 'form-control': !isInline, disabled: isDisabled}]"
      :currency="getCurrency"
      :disabled="isDisabled"
      :max="max"
      :max-fraction-digits="fractionDigits"
      :min="min"
      :min-fraction-digits="fractionDigits"
      :mode="mode"
      :placeholder="getPlaceholder"
      :suffix="suffix"
      :use-grouping="useGrouping"
      type="text" />
    <small v-if="isValidatorRequired" class="p-error">{{ $t('field_required', [label]) }}</small>

    <small v-if="isBoundaryRequired" class="p-error">{{
      $t('min_max_value', [label, validator.betweenValue.$params.min, validator.betweenValue.$params.max])
    }}</small>

    <small v-if="isMinValueRequired" class="p-error">{{
      $t('min_value', [label, validator.minValue.$params.min])
    }}</small>
  </div>
</template>
