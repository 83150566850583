import comptaAccountService from '@services/comptaAccountService';

export default {
  data() {
    return {};
  },
  methods: {
    comptaAccount(e) {
      return comptaAccountService.comptaAccount(e);
    },
    comptaAccounts(e) {
      return comptaAccountService.comptaAccounts(e);
    },
    comptaAccountsWithOwnersName(e) {
      return comptaAccountService.comptaAccountsWithOwnersName(e);
    },
    autocompleteAccountMandat() {
      return {type: 'MANDAT'};
    },
  },
};
