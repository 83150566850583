export default {
  data() {
    //GROUP SERT POUR LE MENU DE L'APPLI
    return {
      permissionConst: [
        {label: 'Opération', value: 'OPERATION_VIEW', group: 'COMPTA'},
        {label: 'Saisie OD Libre', value: 'OD_LIBRE_MANAGE', group: 'COMPTA'},
        {label: 'Consultation', value: 'CONSULTATION_VIEW', group: 'COMPTA'},
        {label: 'Balance', value: 'BALANCE_VIEW', group: 'COMPTA'},
        {label: 'Transaction', value: 'TRANSACTION_VIEW', group: 'COMPTA'},
        {label: 'Compte tiers', value: 'THIRD_ACCOUNT_VIEW', group: 'COMPTA'},
        {label: 'Taxe de séjour', value: 'TDS_VIEW', group: 'COMPTA'},
        {label: 'Groupe de TDS', value: 'TDS_GROUP_VIEW', group: 'COMPTA'},
        {label: 'Virement TDS', value: 'TDS_TRANSFERT_VIEW', group: 'COMPTA'},
        {label: 'Facturation hôte', value: 'FACTURATION_HOTE_VIEW', group: 'COMPTA'},
        {label: 'Facturation voyageur', value: 'FACTURATION_VOYAGEUR_VIEW', group: 'COMPTA'},
        {label: 'Réglement', value: 'RESERVATION_REGLEMENT_VIEW', group: 'RESERVATION'},
        {label: 'Annulation', value: 'RESERVATION_ANNULATION_VIEW', group: 'RESERVATION'},
        {label: 'Liste des réservations', value: 'RESERVATION_VIEW', group: 'RESERVATION'},
        {label: 'Planning des réservations', value: 'RESERVATION_PLANNING_VIEW', group: 'RESERVATION'},
        {label: 'Taxe de séjour logement', value: 'TDS_ACCOMMODATION_VIEW', group: 'ACCOMMODATION'},
        {label: 'Compte tiers logement', value: 'THIRD_ACCOUNT_ACCOMMODATION_VIEW', group: 'ACCOMMODATION'},
        {label: 'Rapprochement bancaire', value: 'BANKING_RECONCILIATION_VIEW', group: 'COMPTA'},
        {label: 'Récapitulatif de facturation', value: 'RECAPITULATIF_FACTURATION_VIEW', group: 'COMPTA'},
        {label: 'Prospect', value: 'PROSPECT_VIEW', group: 'COMMERCIAL'},
        {label: 'Hôtes', value: 'OWNER_VIEW', group: 'COMMERCIAL'},
        {label: 'Voyageurs', value: 'CLIENT_VIEW', group: 'COMMERCIAL'},
        {label: 'Registre des mandats', value: 'MANDAT_VIEW', group: 'COMMERCIAL'},
        {label: 'Tickets', value: 'TICKET_VIEW', group: 'TICKET'},
        {label: 'RH', value: 'RH_VIEW', group: 'RH'},
        {label: 'Suivi des onboardings', value: 'ONBOARDING_VIEW', group: 'ONBOARDING'},
      ],
    };
  },
  methods: {
    displayForPermissionConst(value) {
      let result = this.permissionConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    getPermissionsForGroup(group) {
      return this.permissionConst.filter((p) => p.group === group).map((p) => p.value);
    },
  },
};

export const PermissionMap = Object.freeze({
  TDS: {
    VIEW: 'TDS_VIEW',
    MANAGE: 'TDS_MANAGE',
  },
  TDS_GROUP: {
    VIEW: 'TDS_GROUP_VIEW',
    MANAGE: 'TDS_GROUP_MANAGE',
  },
  TDS_TRANSFERT: {
    VIEW: 'TDS_TRANSFERT_VIEW',
    MANAGE: 'TDS_TRANSFERT_MANAGE',
  },
  TDS_ACCOMMODATION: {
    VIEW: 'TDS_ACCOMMODATION_VIEW',
    MANAGE: 'TDS_ACCOMMODATION_MANAGE',
  },
  OD_LIBRE: {
    VIEW: 'OD_LIBRE_VIEW',
    MANAGE: 'OD_LIBRE_MANAGE',
  },
  BALANCE: {
    VIEW: 'BALANCE_VIEW',
    MANAGE: 'BALANCE_MANAGE',
  },
  TRANSACTION: {
    VIEW: 'TRANSACTION_VIEW',
    MANAGE: 'TRANSACTION_MANAGE',
  },
  OPERATION: {
    VIEW: 'OPERATION_VIEW',
    MANAGE: 'OPERATION_MANAGE',
  },
  CONSULTATION: {
    VIEW: 'CONSULTATION_VIEW',
    MANAGE: 'CONSULTATION_MANAGE',
  },
  THIRD_ACCOUNT: {
    VIEW: 'THIRD_ACCOUNT_VIEW',
    MANAGE: 'THIRD_ACCOUNT_MANAGE',
  },
  THIRD_ACCOUNT_ACCOMMODATION: {
    VIEW: 'THIRD_ACCOUNT_ACCOMMODATION_VIEW',
    MANAGE: 'THIRD_ACCOUNT_ACCOMMODATION_MANAGE',
  },
  RESERVATION_REGLEMENT: {
    VIEW: 'RESERVATION_REGLEMENT_VIEW',
    MANAGE: 'RESERVATION_REGLEMENT_MANAGE',
  },
  RESERVATION: {
    VIEW: 'RESERVATION_VIEW',
    MANAGE: 'RESERVATION_MANAGE',
  },
  RESERVATION_PLANNING: {
    VIEW: 'RESERVATION_PLANNING_VIEW',
    MANAGE: 'RESERVATION_PLANNING_MANAGE',
  },
  CRG: {
    VIEW: 'CRG_VIEW',
    MANAGE: 'CRG_MANAGE',
  },
  CRG_CONSULTATION: {
    VIEW: 'CRG_CONSULTATION_VIEW',
    MANAGE: 'CRG_CONSULTATION_MANAGE',
  },
  CRG_TRANSFERT: {
    VIEW: 'CRG_TRANSFERT_VIEW',
    MANAGE: 'CRG_TRANSFERT_MANAGE',
  },
  BANKING_RECONCILIATION: {
    VIEW: 'BANKING_RECONCILIATION_VIEW',
    MANAGE: 'BANKING_RECONCILIATION_MANAGE',
  },
  RECAPITULATIF_FACTURATION: {
    VIEW: 'RECAPITULATIF_FACTURATION_VIEW',
    MANAGE: 'RECAPITULATIF_FACTURATION_MANAGE',
  },
  PROSPECT: {
    VIEW: 'PROSPECT_VIEW',
    MANAGE: 'PROSPECT_MANAGE',
    DELETE: 'PROSPECT_DELETE',
  },
  OWNER: {
    VIEW: 'OWNER_VIEW',
    MANAGE: 'OWNER_MANAGE',
  },
  CLIENT: {
    VIEW: 'CLIENT_VIEW',
    MANAGE: 'CLIENT_VIEW',
  },
  MANDAT: {
    VIEW: 'MANDAT_VIEW',
    MANAGE: 'MANDAT_MANAGE',
  },
  TICKET: {
    VIEW: 'TICKET_VIEW',
    MANAGE: 'TICKET_MANAGE',
  },
  RH: {
    VIEW: 'RH_VIEW',
    MANAGE: 'RH_MANAGE',
  },
  ONBOARDING: {
    VIEW: 'ONBOARDING_VIEW',
    MANAGE: 'ONBOARDING_MANAGE',
  },
  FACTURATION_HOTE: {
    VIEW: 'FACTURATION_HOTE_VIEW',
    MANAGE: 'FACTURATION_HOTE_MANAGE',
  },
  FACTURATION_VOYAGEUR: {
    VIEW: 'FACTURATION_VOYAGEUR_VIEW',
    MANAGE: 'FACTURATION_VOYAGEUR_MANAGE',
  },
  OPERATION_OWNER_CHARGE: {
    VIEW: 'OPERATION_OWNER_CHARGE_VIEW',
    MANAGE: 'OPERATION_OWNER_CHARGE_MANAGE',
  },
  MAINTENANCE: {
    VIEW: 'MAINTENANCE_VIEW',
    MANAGE: 'MAINTENANCE_MANAGE',
  },
});
