<script>
/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import ficService from '@services/ficService';

export default {
  components: {
    GaContentView,
    PButton,
  },
  mixins: [randomRef, utilsMixin, messageMixin, loaderMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'mandat.mandat_list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      item: {},
      search: {},
    };
  },
  computed: {},
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      let p1 = ficService.ficMandats(this.search);
      p1.then((data) => {
        this.list = data;
      });

      Promise.all([p1]).then(() => {
        this.loading = false;
      });
    },
    getSignedFic(mandat) {
      let fic = mandat.fic;
      return !!fic;
    },
    download(fic) {
      ficService.download(fic, false).catch((e) => {
        if (e.response.status === 404) {
          this.addError("Le fichier n'existe pas !");
        }
      });
    },
    exportMandats() {
      this.showTotalLoaderWithAfter(this.$t('account.fic_mandat_creation_in_progress'));
      let p1 = ficService.exportFicMandats(this.search);
      p1.then((data) => {
        this.hideLoader();
      });
    },
    openFilterPanel() {
      this.toggleFilter('GaMandatListFilter', this.search, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.search = {};
      this.toggleFilter('GaMandatListFilter', this.search, this.refresh, this.resetFilter);
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('mandat.mandat_list')">
    <template #action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <PButton
          :badge="this.countFilter(this.search)"
          badgeClass="p-badge-primary"
          class="btn btn-inverse-primary btn-xs"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button class="btn btn-inverse-primary btn-xs" @click="exportMandats()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>
    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #empty>
          {{ $t('settings.mandats_empty') }}
        </template>

        <Column :header="$t('mandat.num')">
          <template #body="slotProps">
            {{ slotProps.data.num }}
          </template>
        </Column>
        <Column header="ID de mandat">
          <template #body="slotProps">
            {{ slotProps.data.id }}
          </template>
        </Column>
        <Column :header="$t('accommodation.name')">
          <template #body="slotProps">
            {{ slotProps.data.accommodationName }}
          </template>
        </Column>
        <Column :header="$t('accommodation.ownerName')">
          <template #body="slotProps">
            {{ slotProps.data.ownerDisplay }}
          </template>
        </Column>
        <Column :header="$t('accommodation.companyName')">
          <template #body="slotProps">
            {{ slotProps.data.companyName }}
          </template>
        </Column>
        <Column :header="$t('accommodation.status')">
          <template #body="slotProps">
            {{ slotProps.data.mandatStatusDisplay }}
          </template>
        </Column>
        <Column body-style="text-align: right; overflow: visible" header-style=" width: 80px; text-align: btn">
          <template #body="slotProps">
            <div class="d-flex justify-content-end">
              <span class="btn btn-xs btn-inverse-primary mr-1 pointer" @click="download(slotProps.data.fic)">
                <i class="fe fe-download" />
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra> </template>
  </GaContentView>
</template>
