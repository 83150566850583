import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  // reservationAutocompleteMixin
  autocomplete(filter) {
    return Api()
      .get('/reservations/autocomplete', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  reservationDisplay(reservationId) {
    return Api()
      .get('/reservation/' + reservationId + '/display')
      .then((res) => this.formatRes(res.data));
  },
  reservationLitige(reservationId) {
    return Api()
      .get('/reservation/' + reservationId + '/litige')
      .then((res) => this.formatRes(res.data));
  },
  reservationReclamation(reservationId) {
    return Api()
      .get('/reservation/' + reservationId + '/reclamation')
      .then((res) => this.formatRes(res.data));
  },
  reservationClient(reservationId) {
    return Api()
      .get('/reservation/' + reservationId + '/client')
      .then((res) => this.formatRes(res.data));
  },

  reservations(filter) {
    return Api()
      .get('/reservations', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        return res.data;
      });
  },
  reservationsPagination(filter) {
    return Api()
      .get('/reservations/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  reservationsResiliation(mandatId) {
    return Api()
      .get('/reservations/resiliation/' + mandatId)
      .then((res) => {
        return res.data;
      });
  },
  countArrival(filter) {
    return Api()
      .get('/reservation/count/arrival', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  countDeparture(filter) {
    return Api()
      .get('/reservation/count/departure', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  countCheckin(filter) {
    return Api()
      .get('/reservation/count/checkin', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  countCheckout(filter) {
    return Api()
      .get('/reservation/count/checkout', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },

  reservation(reservationId) {
    return Api()
      .get('/reservation/' + reservationId)
      .then((res) => this.formatRes(res.data));
  },
  reservationDetails(reservationId) {
    return Api()
      .get('/reservation/' + reservationId + '/details')
      .then((res) => this.formatRes(res.data));
  },
  reservationSidebar(reservationId) {
    return Api()
      .get('/reservation/' + reservationId + '/sidebar')
      .then((res) => this.formatRes(res.data));
  },
  reservationDTO(reservationId) {
    return Api()
      .get('/reservation/payment/details/' + reservationId)
      .then((res) => this.formatRes(res.data));
  },
  create(reservation) {
    return Api()
      .post('/reservation', reservation)
      .then((res) => this.formatRes(res.data));
  },
  update(reservation) {
    return Api()
      .put('/reservation/' + reservation.id, reservation)
      .then((res) => this.formatRes(res.data));
  },
  updateLitige(reservation) {
    return Api()
      .put('/reservation/litige/' + reservation.id, reservation)
      .then((res) => this.formatRes(res.data));
  },
  updateReclamation(reservation) {
    return Api()
      .put('/reservation/reclamation/' + reservation.id, reservation)
      .then((res) => this.formatRes(res.data));
  },
  delete(reservation) {
    return Api()
      .delete('/reservation/' + reservation.id)
      .then(() => reservation);
  },
  exclude(reservation) {
    return Api()
      .post('/reservation/exclude', reservation)
      .then((res) => this.formatRes(res.data));
  },
  synchronize(reservation) {
    return Api()
      .post('/reservation/synchronize', reservation)
      .then((res) => this.formatRes(res.data));
  },
  formatRes(e) {
    if (e.dateStart) e.dateStart = new Date(e.dateStart);
    if (e.dateEnd) e.dateEnd = new Date(e.dateEnd);
    if (e.dateCheckin) e.dateCheckin = new Date(e.dateCheckin);
    if (e.dateCheckout) e.dateCheckout = new Date(e.dateCheckout);
    if (e.reservationDate) e.reservationDate = new Date(e.reservationDate);

    if (e.type === 'GUEST') {
      e.blockadeReason = null;
      e.occupantInfo = null;
    } else if (e.type === 'OWNER' || e.type === 'OWNER_CANCEL') {
      e.reservationSite = null;
      e.guestFirstName = null;
      e.guestLastName = null;
      e.guestPhone = null;
      e.guestPhone2 = null;
      e.guestMail = null;
      e.nbBabies = null;
      e.cautionDone = null;
      e.paymentDone = null;
      e.blockadeReason = null;
    } else if (e.type === 'BLOCKADE') {
      e.reservationSite = null;
      e.guestFirstName = null;
      e.guestLastName = null;
      e.guestPhone = null;
      e.guestPhone2 = null;
      e.guestMail = null;
      e.nbAdults = null;
      e.nbChildren = null;
      e.nbBabies = null;
      e.cautionDone = null;
      e.paymentDone = null;
      e.occupantInfo = null;
    }
    return e;
  },
};
