import {defineStore} from 'pinia';

export const useFilterStore = defineStore('filter', {
  state: () => ({filter: undefined}),
  actions: {
    setFilter(filter) {
      this.$patch({
        filter: filter,
      });
    },
    getFilter() {
      let filter = this.filter;
      if (filter) {
        this.$patch({
          filter: undefined,
        });
      }
      return filter;
    },
  },
});
