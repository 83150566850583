<script>
/* COMPOSANTS */
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkInputInteger from '@components/v2/input/AkInputInteger';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import reservationCancelledBefore30 from '@mixins/const/reservationCancelledBefore30';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import reservationStatusRefundConst from '@mixins/const/reservationStatusRefundConst';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import companyMixin from '@mixins/companyMixin';

/* SERVICES */

export default {
  components: {
    AkDropdown,
    AkAutocomplete,
    AkCalendar,
    AkInputInteger,
    MultiSelect,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
    clientAutocompleteMixin,
    reservationAutocompleteMixin,
    reservationCancelledBefore30,
    reservationSiteConst,
    reservationStatusRefundConst,
    sidebarMixin,
    utilsMixin,
    companyMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
      this.callback[1]();
      this.itemSelect();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <!--AGENCE-->
    <MultiSelect
      v-model="currentValue.companyIds"
      @change="itemSelect"
      :placeholder="$t('company.one')"
      :options="this.companies"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="name"
      option-value="id" />

    <!--HOTE-->
    <AkAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      :label="$t('owner.ownerDisplay')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <!--VOYAGEUR-->
    <AkAutocomplete
      ref="clientAutocomplete"
      v-model="currentValue.clientId"
      :inline="true"
      :label="$t('client.one')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :search-label="this.autocompleteClientQuery"
      :init-method="this.autocompleteClientById"
      :search-method="this.autocompleteClient" />

    <!--LOGEMENT-->
    <AkAutocomplete
      @valueChange="itemSelect"
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      :label="$t('accommodation.short')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteAccommodationById"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery" />

    <!--RESERVATIONS-->
    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :label="$t('reservation.one')"
      :search-method="this.autocompleteReservation"
      :init-method="this.autocompleteReservationById" />

    <!--PERIOD CHECK IN-->
    <AkCalendar
      v-model="currentValue.dateCheckinPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCheckin')"
      date-format="dd/mm/yy"
      selection="range" />

    <!--PERIOD CHECK OUT-->
    <AkCalendar
      v-model="currentValue.dateCheckoutPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCheckout')"
      date-format="dd/mm/yy"
      selection="range" />

    <!--PERIOD DATE DE CREATION-->
    <AkCalendar
      v-model="currentValue.dateCreatedPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('date_created')"
      date-format="dd/mm/yy"
      selection="range" />

    <!--PERIOD DATE D'ANNULATION-->
    <AkCalendar
      v-model="currentValue.dateCancelPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.cancel')"
      date-format="dd/mm/yy"
      selection="range" />

    <!--ANNULEES PLUS DE 30J-->
    <AkDropdown
      inline
      v-model="currentValue.cancelled30Days"
      :label="$t('reservation.cancelled30Days')"
      :options="this.reservationCancelledBefore30"
      @valueChange="itemSelect"
      class-name="form-control"
      option-label="label"
      option-value="value" />

    <!--NOMBRE DE JOURS-->
    <AkInputInteger
      v-model="currentValue.nbDaysCancelled"
      @valueChange="itemSelect"
      :label="$t('nb_days')"
      :inline="true"
      :display-label="false" />

    <!--PLATEFORME-->
    <MultiSelect
      v-model="currentValue.reservationSiteList"
      :placeholder="$t('reservation.reservation_site')"
      @change="itemSelect"
      :options="reservationSiteConst"
      option-label="label"
      option-value="value"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      :display-label="false" />

    <!--STATUT REMBOURSEMENT-->
    <MultiSelect
      v-model="currentValue.statusRefundList"
      :placeholder="$t('status')"
      @change="itemSelect"
      :options="reservationStatusRefundConst"
      option-label="label"
      option-value="value"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      :display-label="false" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
