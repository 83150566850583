import companyService from '@services/companyService';

export default {
  data() {
    return {
      companies: [],
    };
  },
  mounted() {
    companyService.companiesStorage().then((data) => (this.companies = data));
  },
  computed: {
    loadingCompanies() {
      return this.companies === undefined || this.companies.length === 0;
    },
    findCompanyAllInclusive() {
      return this.companies.filter((c) => c.name.includes('PREMIUM'))[0];
    },
    findCompanyOnline() {
      return this.companies.filter(
        (c) => c.denominationCommerciale === 'OFFRE ESSENTIELLE' || c.denominationCommerciale === 'GESTION EN LIGNE',
      )[0];
    },
  },
  methods: {
    displayForCompany(id, returnLabel = '') {
      let result = this.companies.find((company) => company.id === id);
      return result ? result.name : returnLabel;
    },
  },
};
