import Api from '@/services/api';
import {createFormDataFiles, createFormDataFile, removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  maintenances(filter) {
    return Api()
      .get('/maintenances', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  maintenancesAccommodation(accommodationId, filter) {
    return Api()
      .get('/maintenances/accommodation/' + accommodationId, {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },

  maintenance(id) {
    return Api()
      .get('/maintenance/' + id)
      .then((res) => this.formatRes(res.data));
  },
  maintenanceDetails(id) {
    return Api()
      .get('/maintenance/' + id + '/details')
      .then((res) => this.formatRes(res.data));
  },
  count(filter) {
    return Api()
      .get('/maintenances/count', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  exportMaintenances(filter) {
    return Api()
      .get('/maintenances/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  create(maintenance, files) {
    let formData = createFormDataFiles(maintenance, files);
    return Api()
      .post('/maintenance', formData)
      .then((res) => this.formatRes(res.data));
  },
  update(maintenance, files) {
    let formData = createFormDataFiles(maintenance, files);
    return Api()
      .put('/maintenance/' + maintenance.id, formData)
      .then((res) => this.formatRes(res.data));
  },
  stepToResolve(maintenance) {
    return Api()
      .post('/maintenance/' + maintenance.id + '/steps/toResolve', maintenance)
      .then((res) => this.formatRes(res.data));
  },
  stepTrait(maintenance, file) {
    let formData = createFormDataFile(maintenance, file);
    return Api()
      .post('/maintenance/' + maintenance.id + '/steps/trait', formData)
      .then((res) => this.formatRes(res.data));
  },
  stepDecision(maintenance) {
    return Api()
      .post('/maintenance/' + maintenance.id + '/steps/decision', maintenance)
      .then((res) => this.formatRes(res.data));
  },
  stepLaunch(maintenance) {
    return Api()
      .post('/maintenance/' + maintenance.id + '/steps/launch')
      .then((res) => this.formatRes(res.data));
  },
  stepResolve(maintenance, file) {
    let formData = createFormDataFile(maintenance, file);
    return Api()
      .post('/maintenance/' + maintenance.id + '/steps/resolve', formData)
      .then((res) => this.formatRes(res.data));
  },
  delete(maintenance) {
    return Api()
      .delete('/maintenance/' + maintenance.id)
      .then(() => maintenance);
  },
  formatRes(e) {
    return e;
  },
};
