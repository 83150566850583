import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  pagination(filter) {
    return Api()
      .get('/mission-waiting-order/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  waitingOrder(reservationId) {
    return Api()
      .get('/mission-waiting-order/' + reservationId)
      .then((res) => {
        return res.data;
      });
  },
  count(filter) {
    return Api()
      .get('/mission-waiting-order/count', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  formatRes(e) {
    if (e.dateCheckin) e.dateCheckin = new Date(e.dateCheckin);
    if (e.dateCreated) e.dateCreated = new Date(e.dateCreated);
  },
};
