<script>
export default {
  inject: {pWithBullet: {default: false}},
  props: {
    title: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {},
  computed: {
    displayBullet() {
      return this.pWithBullet === true;
    },
  },
};
</script>

<template>
  <div :class="{active: this.active}" class="white-space-pre d-flex flex-column page-title">
    <div class="mb-2 mb-sm-0">
      <h1>{{ this.title }}</h1>
    </div>
    <span v-show="displayBullet" class="bullet"></span>
  </div>
</template>
