<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputText from '@components/v2/input/AkInputText';
import AkDropdown from '@components/v2/input/AkDropdown';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import documentModelTypeConst from '@mixins/const/documentModelTypeConst';

/* SERVICES */
import documentModelService from '@services/documentModelService';
import backMixin from '@mixins/backMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import AkLoader from '@components/general/AkLoader.vue';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';

export default {
  components: {AkButtonValidation, AkLoader, AkDropdown, AkFormSubmitted, AkInputText},
  mixins: [randomRef, backMixin, loaderMixin, messageMixin, documentModelTypeConst],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'document_model.update',
    };
  },
  data() {
    return {
      messages: [],
      submitted: false,
      documentModel: {},
    };
  },
  props: {
    documentModelId: {
      required: true,
    },
  },
  validations() {
    return {
      documentModel: {
        type: {required},
        name: {required},
        subType: {required},
      },
    };
  },
  computed: {
    getSubType() {
      if (this.documentModel.type) return this.childrenForDocumentModelType(this.documentModel.type);
      return [];
    },
  },
  watch: {
    'documentModel.type': function (newType, oldType) {
      if (oldType != undefined) this.documentModel.subType = undefined;
    },
  },
  mounted() {
    this.$refs.loader.load();
    documentModelService
      .documentModel(this.documentModelId)
      .then((data) => {
        this.documentModel = data;
      })
      .finally(() => {
        this.$refs.loader.hide();
      });
  },
  methods: {
    addSubError(content) {
      this.messages = [];
      this.messages.push({id: this.generateUUID(), closable: true, severity: 'error', content: content, life: 10000});
    },
    update() {
      this.messages = [];
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.btnCreate.init();
        return;
      }
      documentModelService
        .update(this.documentModel)
        .then((data) => {
          this.success(this.$t('document_model.updated'));
          this.submitted = false;
          this.documentModel = data;
          this.$emit('create-done');
        })
        .catch((e) => {
          this.$refs.btnCreate.init();
          this.addSubError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :scale="2" message="Chargement en cours...">
    <template #content>
      <Message
        class="mb-2"
        v-for="msg of this.messages"
        :key="msg.id"
        :closable="msg.closable"
        :severity="msg.severity"
        :life="msg.life">
        {{ msg.content }}
      </Message>
      <AkFormSubmitted :submitted="submitted" reference="form" @submit="update()">
        <div class="flex flex-col gap-2">
          <AkInputText
            v-model="documentModel.name"
            :label="$t('document_model.name')"
            :validator="v$.documentModel.name"
            class-name="col-md-4" />
          <AkDropdown
            v-model="documentModel.type"
            :label="$t('document_model.type')"
            :options="documentModelTypeConst"
            :validator="v$.documentModel.type" />
          <AkDropdown
            v-if="documentModel.type"
            v-model="documentModel.subType"
            :label="$t('document_model.subtype')"
            :options="getSubType"
            :validator="v$.documentModel.subType" />
        </div>
        <div class="mt-2 flex justify-end">
          <AkButtonValidation ref="btnCreate" @action="update" :label="$t('update')"></AkButtonValidation>
        </div>
      </AkFormSubmitted>
    </template>
  </AkLoader>
</template>
