<script>
import {debounce} from '@utils';

/* COMPOSANTS */
import AkAutocomplete from '@components/general/AkAutocomplete';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import comptaAccountService from '@services/comptaAccountService';

export default {
  components: {
    AkAutocomplete,
  },
  mixins: [loaderMixin, utilsMixin],
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: String,
      required: false,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    typeAccount: {
      type: String,
      required: false,
      default: undefined,
    },
    pool: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      items: [],
      debounceFn: undefined,
    };
  },
  mounted() {
    if (this.currentValue !== undefined && this.currentValue !== null) {
      let search = {};
      search.query = this.currentValue;
      if (this.typeAccount) {
        search.type = this.typeAccount;
      } else if (this.pool) {
        search.type = this.pool.typeAccount;
      }
      comptaAccountService.comptaAccountsWithOwnersName(search).then((data) => {
        let item = data[0];
        this.items.push(item);
        this.$refs.autocomplete.init(item);
      });
    }
    this.debounceFn = debounce((e) => this.search(e));
  },
  methods: {
    search(event) {
      let search = {};
      search.query = event.query;
      if (this.typeAccount) {
        search.type = this.typeAccount;
      } else if (this.pool) {
        search.type = this.pool.typeAccount;
      }
      comptaAccountService.comptaAccountsWithOwnersName(search).then((data) => {
        this.items = data;
        for (let item of this.items) {
          if (item.type === 'MANDAT') {
            if (item.ownerName === null || item.ownerName === undefined) {
              item.display = item.display + ' - ' + item.num;
            } else {
              item.display = item.ownerName + ' - ' + item.display + ' - ' + item.num;
            }
          } else {
            item.display = item.display + ' - ' + item.num;
          }
        }
        this.items.sort(function compare(a, b) {
          if (a.type === 'MANDAT') return -1;
          if (a.type !== 'MANDAT') return 1;
          return 0;
        });
      });
    },
    reset() {
      this.$refs.autocomplete.reset();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <AkAutocomplete
    ref="autocomplete"
    v-model="currentValue"
    :inline="inline"
    :validator="validator"
    :submitted="submitted"
    :disabled="disabled"
    :label="label"
    :placeholder="placeholder"
    :className="className"
    option-label="num"
    option-value="num"
    :items="items"
    @search="debounceFn">
    <template #item="slotProps">
      <div>{{ slotProps.item.display }}</div>
    </template>
  </AkAutocomplete>
</template>
