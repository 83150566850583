export default {
  data() {
    return {
      visitorTaxStatusConst: [
        {label: this.$t('visitor_tax.state_reversed'), value: true},
        {label: this.$t('visitor_tax.state_not_reversed'), value: false},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForVisitorTaxStatusConst(value) {
      let result = this.visitorTaxStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    displayForVisitorTaxStatusConstWithoutUndefined() {
      return this.visitorTaxStatusConst.filter((el) => el.value !== undefined);
    },
  },
};
