<script>
import {useMessageStore} from '@store/messagesStore';
import {mapState} from 'pinia/dist/pinia';

/* COMPOSANTS */
import GaTitleMenu from '@components/ga/general/GaTitleMenu';
import GaItemTitle from '@components/ga/general/GaItemTitle';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

export default {
  components: {GaItemTitle, GaTitleMenu},
  mixins: [messageMixin, loaderMixin],
  data() {
    return {
      tab: {},
    };
  },
  props: {
    title: {type: String, required: true},
    fullSearch: {type: Boolean, required: false, default: false},
  },
  mounted() {},
  methods: {
    resetMessage() {
      useMessageStore().$reset();
    },
  },
  computed: {
    ...mapState(useMessageStore, ['list']),
  },
};
</script>

<template>
  <div class="h-100 d-flex flex-column">
    <slot name="back" />
    <Message
      v-for="msg of this.list"
      :key="msg.content"
      :closable="msg.closable"
      :severity="msg.severity"
      @close="resetMessage">
      {{ msg.content }}
    </Message>
    <GaTitleMenu :with-bullet="false">
      <slot name="title">
        <GaItemTitle :active="true" :title="title" />
      </slot>
    </GaTitleMenu>
    <div class="row mb-20 mt-20">
      <div :class="{'col-12': this.fullSearch, 'col-6': !this.fullSearch}">
        <slot name="search" />
      </div>
      <div class="col-6">
        <slot name="action" />
      </div>
    </div>

    <perfect-scrollbar class="content-list h-100">
      <slot name="content" />
    </perfect-scrollbar>
    <slot name="empty" />
    <slot name="extra" />
  </div>
</template>
