export default {
  data() {
    return {
      transfertFrequencyConst: [
        {label: this.$t('stayrate.frequency_month_6'), value: 'MONTH_6'},
        {label: this.$t('stayrate.frequency_year_1'), value: 'YEAR_1'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForTransfertFrequencyConst(value) {
      let result = this.transfertFrequencyConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
