<script>
import useVuelidate from '@vuelidate/core';
import bigDecimal from 'js-big-decimal';
import {ref} from 'vue';

/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import AkFreeOdLine from '@components/input/AkFreeOdLine';
import AkLoader from '@components/general/AkLoader';
import AkCalendar from '@components/input/AkCalendar';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import comptaOperationService from '@services/comptaOperationService';

export default {
  components: {
    GaView,
    AkCalendar,
    AkLoader,
    AkFreeOdLine,
  },
  mixins: [messageMixin, loaderMixin],
  metaInfo() {
    return {
      title: 'accounting.free_od',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      freeOds: [],
      totalTransaction: new bigDecimal(0),
      dateOperation: undefined,
    };
  },
  validations() {
    return {};
  },
  mounted() {
    this.initFreeOd();
  },
  computed: {
    canDeleteLine() {
      return this.freeOds.length > 2;
    },
  },
  methods: {
    ref,
    initFreeOd() {
      this.$refs.loader.load();
      this.totalTransaction = 0;

      this.freeOds = [{}, {}];
      for (let i = 0; i < this.freeOds.length; i++) {
        this.freeOds[i].randomId = Math.random().toString(36);
      }

      this.dateOperation = undefined;

      this.$refs.loader.hide();
    },
    createFreeOd() {
      this.totalTransaction = new bigDecimal(0);
      this.v$.$touch();
      for (let freeOd of this.freeOds) {
        //VALIDATION
        if (freeOd.date === undefined) {
          this.addError(this.$t('operation.empty_date'), true, true);
          return;
        }
        if (freeOd.accountNum === undefined) {
          this.addError(this.$t('operation.empty_account_num'), true, true);
          return;
        }
        if (freeOd.label === undefined || freeOd.label === '') {
          this.addError(this.$t('operation.empty_label'), true, true);
          return;
        }

        if (freeOd.credit == undefined || freeOd.credit === '') {
          freeOd.credit = 0;
        }
        if (freeOd.debit == undefined || freeOd.debit === '') {
          freeOd.debit = 0;
        }
        if (freeOd.credit === 0 && freeOd.debit === 0) {
          this.addError(this.$t('operation.empty_amount'), true, true);
          return;
        }
        if (freeOd.credit !== 0 && freeOd.debit !== 0) {
          this.addError(this.$t('operation.double_amount'), true, true);
          return;
        }

        let credit = new bigDecimal(freeOd.credit);
        let debit = new bigDecimal(freeOd.debit).negate();
        freeOd.amount = credit.add(debit).round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue();
        this.totalTransaction = this.totalTransaction.add(new bigDecimal(freeOd.amount));
      }
      if (this.totalTransaction.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue() !== '0.00') {
        this.addError(this.$t('operation.error_transaction'), true, true);
        return;
      }

      comptaOperationService
        .createFreeOd(this.freeOds)
        .then(() => {
          this.success(this.$t('operation.added_free_od'));
          this.initFreeOd();
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('account.no_exist'), true, true);
            }
            this.addError(this.$t('error.' + e.response.data.error));
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    addNewLine(index) {
      this.freeOds.splice(index + 1, 0, {});
      if (this.dateOperation !== undefined) {
        this.freeOds[index + 1].date = this.dateOperation;
      }
      this.freeOds[index + 1].randomId = Math.random().toString(36);
    },
    deleteLine(index) {
      if (this.canDeleteLine) {
        this.freeOds.splice(index, 1);
      } else {
        this.addError(this.$t('operation.limit_size_line'), true, true);
      }
    },
    setDefaultDate() {
      for (let line of this.freeOds) {
        line.date = this.dateOperation;
      }
    },
  },
};
</script>
<template>
  <GaView>
    <template #content>
      <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
        <template #content>
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-statistics">
                <div class="card-body">
                  <div class="table-responsive">
                    <form ref="form" @submit.prevent="createFreeOd">
                      <div class="form-inline mb-1">
                        <AkCalendar
                          v-model="dateOperation"
                          :display-label="false"
                          :inline="true"
                          :placeholder="$t('operation.date')"
                          class-name="col-md-3"
                          @update:model-value="setDefaultDate">
                        </AkCalendar>
                      </div>

                      <div v-for="(freeOd, index) in this.freeOds" :key="freeOd.randomId" class="mb-1">
                        <AkFreeOdLine
                          v-model="this.freeOds[index]"
                          :can-delete="canDeleteLine"
                          :index="index"
                          @add="addNewLine"
                          @change="setDefaultDate"
                          @delete="deleteLine" />
                      </div>
                      <button
                        :title="$t('operation.add')"
                        class="btn btn-inverse-primary btn-xs col-md-1"
                        type="submit">
                        {{ $t('operation.add') }}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AkLoader>
    </template>
  </GaView>
</template>
