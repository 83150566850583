<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/v2/layout/GaView';
import AkMultiSelect from '@components/v2/input/AkMultiSelect.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import ticketCategoryService from '@services/ticketCategoryService';
import poleService from '@services/poleService';
import ticketSubCategoryService from '@services/ticketSubCategoryService';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import {Icon} from '@iconify/vue';

export default {
  components: {
    Icon,
    AkDatatablePagination,
    GaListView,
    AkButtonAction,
    AkMultiSelect,
    GaView,
    AkInputText,
    AkDialog,
  },
  mixins: [randomRef, utilsMixin, messageMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.ticket_category',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      poles: [],
      item: {
        label: '',
      },
      subCategory: {},
    };
  },
  validations() {
    return {
      item: {
        label: {required},
        poleIds: {required},
      },
      subCategory: {
        label: {required},
      },
    };
  },
  mounted() {
    this.initTicketCategoryList();
  },
  methods: {
    onRowExpand(event) {},
    initTicketCategoryList() {
      this.loading = true;

      let p1 = ticketCategoryService.ticketCategorys();
      p1.then((data) => {
        this.list = data;
      });

      let p2 = poleService.poles();
      p2.then((data) => {
        this.poles = data;
      });

      Promise.all([p1, p2]).then(() => {
        this.loading = false;
      });
    },
    create() {
      this.v$.$touch();
      if (this.v$.item.$error) {
        this.$refs.dialogCreate.init();
        return;
      }

      ticketCategoryService
        .create(this.item)
        .then((data) => {
          this.success(this.$t('settings.ticket_category_added'));
          this.list.push(data);
        })
        .catch((e) => {
          if (e.response.status === 412) {
            this.addError(this.$t('settings.error_empty_field'));
          } else {
            this.addError(this.$t('unknow_error'));
          }
        })
        .finally(() => {
          this.$refs.dialogCreate.hide();
          this.initTicketCategoryList();
        });
    },
    createSubCategory() {
      this.v$.$touch();
      if (this.v$.subCategory.$error) {
        this.$refs.dialogSubCategoryCreate.init();
        return;
      }

      ticketSubCategoryService
        .create(this.subCategory)
        .then((data) => {
          this.success(this.$t('sub_category.added'));
        })
        .catch((e) => {
          if (e.response.status === 412) {
            this.addError(this.$t('settings.error_empty_field'));
          } else {
            this.addError(this.$t('unknow_error'));
          }
        })
        .finally(() => {
          this.$refs.dialogSubCategoryCreate.hide();
          this.initTicketCategoryList();
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.item.$error) {
        this.$refs.dialogEdit.init();
        return;
      }

      ticketCategoryService
        .update(this.item)
        .then((data) => {
          this.success(this.$t('settings.ticket_category_updated'));
        })
        .catch((e) => {
          this.addError(this.msgForErr(this.$t, e));
        })
        .finally(() => {
          this.$refs.dialogEdit.hide();
          this.initTicketCategoryList();
        });
    },
    updateSubCategory() {
      this.v$.$touch();
      if (this.v$.subCategory.$error) {
        this.$refs.dialogSubCategoryEdit.init();
        return;
      }

      ticketSubCategoryService
        .update(this.subCategory)
        .then(() => {
          this.success(this.$t('sub_category.updated'));
        })
        .catch((e) => {
          this.addError(this.msgForErr(this.$t, e));
        })
        .finally(() => {
          this.$refs.dialogSubCategoryEdit.hide();
          this.initTicketCategoryList();
        });
    },
    delete() {
      ticketCategoryService
        .delete(this.item)
        .then((data) => {
          this.success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response && e.response.status === 406) {
            content =
              'Une erreur est survenue durant la suppression : la catégorie est associée à un ou plusieurs tickets.';
          } else if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
            this.addError(content);
          }
          this.addError(content);
        })
        .finally(() => {
          this.$refs.dialogDelete.hide();
          this.initTicketCategoryList();
        });
    },
    deleteSubCategory() {
      ticketSubCategoryService
        .delete(this.subCategory)
        .then((data) => {
          this.success(this.subCategory.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response && e.response.status === 406) {
            content =
              'Une erreur est survenue durant la suppression : la sous-categorie est associée à un ou plusieurs tickets.';
          } else if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.addError(content);
        })
        .finally(() => {
          this.$refs.dialogDeleteSubCategory.hide();
          this.initTicketCategoryList();
        });
    },
    openCreateDialog() {
      this.item = {
        label: '',
        poleIds: null,
      };
      this.$refs.dialogCreate.show();
    },
    openCreateSubCategoryDialog(data) {
      this.subCategory = {
        ticketCategoryId: data.id,
      };
      this.$refs.dialogSubCategoryCreate.show();
    },
    openDeleteSubCategoryDialog(data) {
      this.subCategory = data;
      this.$refs.dialogDeleteSubCategory.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    openSubCategoryEditDialog(subCategory) {
      this.subCategory = {...subCategory};
      this.$refs.dialogSubCategoryEdit.show();
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.ticket_category')">
    <template #action>
      <AkButtonAction :label="this.$t('settings.add_ticket_category')" little @click="openCreateDialog()">
      </AkButtonAction>
    </template>

    <template #content>
      <GaListView>
        <template #content>
          <AkDatatablePagination
            :loading="loading"
            :value="list"
            :lazy="false"
            dataKey="id"
            @rowExpand="onRowExpand"
            v-model:expandedRows="list.subCategories">
            <template #empty>
              {{ $t('settings.ticket_category_empty') }}
            </template>
            <template #expansion="slotProps">
              <div v-for="(subcategory, index) in slotProps.slotProps.data.subCategories" :key="subcategory.id">
                <div class="flex justify-between py-2">
                  <div class="w-[60px]"></div>
                  <div class="flex-1 flex items-center">
                    <span>
                      {{ subcategory.label }}
                    </span>
                  </div>
                  <div class="flex justify-end gap-2">
                    <span
                      class="hover:cursor-pointer"
                      @click="openSubCategoryEditDialog(subcategory)"
                      :title="this.$t('sub_category.update_ticket_category')">
                      <i class="ga-icon ga-edit text-primary text-2xl" />
                    </span>
                    <span
                      class="hover:cursor-pointer"
                      @click="openDeleteSubCategoryDialog(subcategory)"
                      :title="this.$t('sub_category.delete_sub_category')">
                      <i class="ga-icon ga-trash text-red text-2xl" />
                    </span>
                  </div>
                </div>
                <hr v-if="index !== slotProps.slotProps.data.subCategories.length - 1" class="datatable" />
              </div>
            </template>
            <Column expander style="width: 60px"></Column>
            <Column :header="$t('item_label.label')" field="label">
              <template #body="slotProps">
                <span>{{ slotProps.data.label }}</span>
              </template>
            </Column>
            <Column :header="$t('pole.list')" field="poleDisplay">
              <template #body="slotProps">
                <span>{{ slotProps.data.poleDisplay }}</span>
              </template>
            </Column>
            <Column body-style="text-align: right; overflow: visible" header-style=" width: 160px; text-align: btn">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span
                    class="hover:cursor-pointer btn-table xs"
                    @click="openCreateSubCategoryDialog(slotProps.data)"
                    :title="this.$t('sub_category.create')">
                    <span>Ajouter</span>
                  </span>
                  <span
                    class="hover:cursor-pointer"
                    @click="openEditDialog(slotProps.data)"
                    :title="this.$t('settings.update_ticket_category')">
                    <i class="ga-icon ga-edit text-primary text-2xl" />
                  </span>
                  <span
                    class="hover:cursor-pointer"
                    @click="openDeleteDialog(slotProps.data)"
                    :title="this.$t('settings.delete_ticket_category')">
                    <i class="ga-icon ga-trash text-red text-2xl" />
                  </span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('settings.ticket_category')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="flex flex-col gap-2">
          <AkInputText
            v-model="item.label"
            :label="$t('item_label.label')"
            :validator="v$.item.label"
            class-name="col-md-12" />
          <AkMultiSelect
            v-model="item.poleIds"
            :label="$t('pole.list')"
            :validator="v$.item.poleIds"
            :options="this.poles"
            option-label="name"
            option-value="id"
            class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('settings.ticket_category')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="flex flex-col gap-2">
          <AkInputText
            v-model="item.label"
            :label="$t('item_label.label')"
            :validator="v$.item.label"
            class-name="col-md-12" />
          <AkMultiSelect
            v-model="item.poleIds"
            :label="$t('pole.list')"
            :options="this.poles"
            option-label="name"
            option-value="id"
            class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-red" width="26" />
          <span class="text-dark font-normal">{{ $t('settings.confirm_delete_ticket_category') }}</span>
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogSubCategoryCreate"
        :title="$t('sub_category.sub_category')"
        :validate-label="$t('add')"
        width="450px"
        @validate="createSubCategory()">
        <AkInputText v-model="subCategory.label" :label="$t('item_label.label')" :validator="v$.subCategory.label" />
      </AkDialog>

      <AkDialog
        ref="dialogSubCategoryEdit"
        :title="$t('sub_category.sub_category')"
        :validate-label="$t('update')"
        width="450px"
        @validate="updateSubCategory()">
        <div class="form-row">
          <AkInputText
            v-model="subCategory.label"
            :label="$t('item_label.label')"
            :validator="v$.subCategory.label"
            class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDeleteSubCategory"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.deleteSubCategory()">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-red" width="26" />
          <span class="text-dark font-normal">{{ $t('sub_category.confirm_delete_sub_category') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
