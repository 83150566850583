export default {
  data() {
    return {
      roleTypesConst: [
        {label: this.$t('role.gav'), value: 'GAV'},
        {label: this.$t('role.gas'), value: 'GAS'},
        {label: this.$t('role.administrator'), value: 'ADMIN'},
        {label: this.$t('role.owner'), value: 'OWNER'},
        {label: this.$t('role.photographer'), value: 'PHOTOGRAPHER'},
        {label: this.$t('undefined'), value: undefined},
      ],
      roleTypesForEmployees: [
        {label: this.$t('role.gav'), value: 'GAV'},
        {label: this.$t('role.gas'), value: 'GAS'},
      ],
    };
  },
  methods: {
    displayForRoleTypesConst(value, returnLabel = '') {
      let result = this.roleTypesConst.find((el) => el.value === value);
      return result ? result.label : returnLabel;
    },
  },
};
