<script>
import {required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import GaTripleView from '@components/v2/layout/GaTripleView.vue';
import GaContentView from '@components/v2/layout/GaContentView.vue';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import GaSpanAmount from '@components/v2/general/GaSpanAmount.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';
import AkDialogConfirm from '@components/general/AkDialogConfirm';
import OdUpdateLine from '@views/accounting/transactions/OdUpdateLine.vue';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import comptaOperationService from '@services/comptaOperationService';

export default {
  components: {
    GaTripleView,
    GaContentView,
    AkFormSubmitted,
    GaSpanAmount,
    AkCalendar,
    AkButtonValidation,
    AkDialogConfirm,
    OdUpdateLine,
  },
  mixins: [messageMixin, loaderMixin, backMixin, utilsMixin],
  metaInfo() {
    return {
      title: this.$t('transaction.update'),
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      transaction: {
        date: {required},
      },
    };
  },
  data() {
    return {
      transaction: undefined,
      lines: [],
      submitted: false,
      totalCredit: 0,
      totalDebit: 0,
      balance: 0,
      selectedIndex: undefined,
    };
  },
  mounted() {
    this.initGoBack();
    this.refresh();
  },
  computed: {
    canDeleteLine() {
      return this.lines.length > 2;
    },
  },
  methods: {
    // REFRESH

    refresh() {
      this.showTotalLoaderGuestadom();

      this.totalCredit = 0;
      this.totalDebit = 0;

      // let filter = {transactionId: this.$route.params.transactionId};
      comptaOperationService
        .transaction(this.$route.params.transactionId)
        .then((data) => {
          this.transaction = data;

          this.lines = this.transaction.operations;
          for (let i = 0; i < this.lines.length; i++) {
            this.lines[i].randomId = Math.random().toString(36);
            if (this.lines[i].debit < 0) {
              this.lines[i].debit = this.lines[i].debit * -1;
            }
            // totaux
            this.totalCredit += this.lines[i].credit;
            this.totalDebit += this.lines[i].debit;
          }
          this.balance = this.addNumbers([this.totalCredit, -this.totalDebit]);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    // PROCESS

    setDefaultDate() {
      for (let line of this.lines) {
        line.date = this.transaction.date;
      }
    },

    // UPDATE

    updateTransaction() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.btnUpdate.init();
        return;
      }

      // Balance
      if (this.balance !== 0) {
        this.addError(this.$t('operation.error_transaction'), true, true);
        this.$refs.btnUpdate.init();
        return;
      }

      for (let freeOd of this.lines) {
        //VALIDATION

        if (freeOd.credit == undefined || freeOd.credit === '') {
          freeOd.credit = 0;
        }
        if (freeOd.debit == undefined || freeOd.debit === '') {
          freeOd.debit = 0;
        }
        if (freeOd.credit !== 0 && freeOd.debit !== 0) {
          this.addError(this.$t('operation.double_amount'), true, true);
          this.$refs.btnUpdate.init();
          return;
        }
        if (freeOd.credit === 0 && freeOd.debit === 0) {
          this.addError(this.$t('operation.empty_amount'), true, true);
          this.$refs.btnUpdate.init();
          return;
        }

        freeOd.amount = this.addNumbers([freeOd.credit, -freeOd.debit]);
      }

      comptaOperationService
        .updateTransaction(this.$route.params.transactionId, this.lines)
        .then(() => {
          this.submitted = false;
          this.success(this.$t('transaction.updated'));
          this.refresh();
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('error.' + e.response.data.error), true, true);
            }
          }
        })
        .finally(() => {
          this.$refs.btnUpdate.init();
        });
    },

    // CALLBACKS

    addNewLine(index) {
      let item = {};
      item.date = this.transaction.date;
      item.typeTransaction = this.transaction.typeTransaction;
      item.randomId = Math.random().toString(36);
      item.transactionId = this.$route.params.transactionId;
      this.lines.splice(index + 1, 0, item);
    },

    openConfirmDialog(index) {
      this.selectedIndex = index;
      this.$refs.confirmDialog.show();
    },

    deleteLine() {
      if (this.canDeleteLine && this.selectedIndex) {
        this.lines.splice(this.selectedIndex, 1);
        this.updatedLine();
        this.selectedIndex = undefined;
        this.$refs.confirmDialog.hide();
      } else {
        this.addError(this.$t('operation.limit_size_line'), true, true);
      }
    },

    updatedLine() {
      this.totalCredit = 0;
      this.totalDebit = 0;
      for (let line of this.lines) {
        this.totalCredit += line.credit ?? 0;
        this.totalDebit += line.debit ?? 0;
      }
      this.balance = this.addNumbers([this.totalCredit, -this.totalDebit]);
    },
  },
};
</script>
<template>
  <GaTripleView>
    <template #right>
      <div class="flex bg-detailsBg flex-col p-3 rounded-lg mt-2 gap-6">
        <div class="flex flex-row items-center gap-4">
          <i class="ga-icon ga-cash_in text-gray text-3xl" />
          <div class="flex flex-col gap-1">
            <span class="text-dark text-md font-medium">Crédit</span>
            <GaSpanAmount green :value="totalCredit" />
          </div>
        </div>
        <div class="flex flex-row items-center gap-4">
          <i class="ga-icon ga-cash_out text-gray text-3xl" />
          <div class="flex flex-col gap-1">
            <span class="text-dark text-md font-medium">Débit</span>
            <GaSpanAmount red :value="totalDebit" />
          </div>
        </div>
        <div class="flex flex-row items-center gap-4">
          <i class="ga-icon ga-solde text-gray text-3xl" />
          <div class="flex flex-col gap-1">
            <span class="text-dark text-md font-medium">Solde</span>
            <GaSpanAmount :value="balance" />
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <GaContentView v-if="transaction">
        <template #content>
          <AkFormSubmitted :submitted="submitted" reference="form">
            <!-- TODO: remplacer par un composant fait pour ça -->
            <div class="flex flex-col gap-2">
              <div class="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-2">
                <AkCalendar
                  v-model="transaction.date"
                  :label="$t('operation.date')"
                  :placeholder="$t('operation.date')"
                  @update:model-value="setDefaultDate"
                  :validator="v$.transaction.date">
                </AkCalendar>
              </div>

              <!-- Lignes transaction -->
              <div
                v-for="(freeOd, index) in this.lines"
                :key="freeOd.randomId"
                class="flex flex-col justify-between gap-2"
                :class="{'border border-none bg-detailsBg rounded-lg p-2 mt-2': this.$isMobile()}">
                <OdUpdateLine
                  v-model="this.lines[index]"
                  :can-delete="canDeleteLine"
                  :index="index"
                  :submitted="submitted"
                  @add="addNewLine"
                  @change="updatedLine"
                  @delete="openConfirmDialog" />
              </div>
            </div>

            <div class="flex justify-end mt-2">
              <AkButtonValidation ref="btnUpdate" :label="$t('update')" :little="false" @action="updateTransaction" />
            </div>
          </AkFormSubmitted>
        </template>

        <template #extra>
          <AkDialogConfirm
            ref="confirmDialog"
            loadable
            width="450px"
            :message="$t('operation.delete_line_warning')"
            :title="$t('operation.delete_line')"
            @yes="deleteLine" />
        </template>
      </GaContentView>
    </template>
  </GaTripleView>
</template>
