export default {
  data() {
    return {
      ownerChargeTypeConst: [
        {label: this.$t('facturation_hote.ACHAT_HOTE'), value: 'ACHAT_HOTE'},
        {label: this.$t('facturation_hote.FRAIS_DE_MAINTENANCE'), value: 'FRAIS_DE_MAINTENANCE'},
        {label: this.$t('operation.OUVERTURE_HIVERNAGE'), value: 'OUVERTURE_HIVERNAGE'},
        {label: this.$t('operation.FERMETURE_HIVERNAGE'), value: 'FERMETURE_HIVERNAGE'},
        {label: this.$t('operation.FRAIS_DE_MENAGE_HOTE'), value: 'FRAIS_DE_MENAGE_HOTE'},
      ],
      ownerChargeTypeConstForGAS: [
        {label: this.$t('facturation_hote.ACHAT_HOTE'), value: 'ACHAT_HOTE'},
        {label: this.$t('facturation_hote.FRAIS_DE_MAINTENANCE'), value: 'FRAIS_DE_MAINTENANCE'},
        {label: this.$t('operation.OUVERTURE_HIVERNAGE'), value: 'OUVERTURE_HIVERNAGE'},
        {label: this.$t('operation.FERMETURE_HIVERNAGE'), value: 'FERMETURE_HIVERNAGE'},
        {label: this.$t('operation.FRAIS_DE_MENAGE_HOTE'), value: 'FRAIS_DE_MENAGE_HOTE'},

        {label: this.$t('operation.FRAIS_ANNULATION_HOTE'), value: 'FRAIS_ANNULATION_HOTE'},
        {label: this.$t('operation.NUISANCE_SONORE'), value: 'NUISANCE_SONORE'},
        {label: this.$t('operation.REMBOURSEMENT_HOTE'), value: 'REMBOURSEMENT_HOTE'},
      ],
    };
  },
  methods: {
    displayForOwnerChargeTypeConst(value) {
      let result = this.ownerChargeTypeConstForGAS.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
