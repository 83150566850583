import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  societes(filter) {
    return Api()
      .get('/societes', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  societeGuestadom() {
    return Api()
      .get('/societe/guestadom')
      .then((res) => this.formatRes(res.data));
  },
  update(societe) {
    return Api()
      .put('/societe/' + societe.id, societe)
      .then((res) => this.formatRes(res.data));
  },
  formatRes(e) {
    return e;
  },
};
