import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  comptaReconciliations(filter) {
    return Api()
      .get('/comptareconciliations', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.items = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  comptaReconciliation(id) {
    return Api()
      .get('/comptareconciliation/' + id)
      .then((res) => this.formatRes(res.data));
  },
  comptaReconciliationDetails(id) {
    return Api()
      .get('/comptareconciliation/' + id + '/details')
      .then((res) => this.formatRes(res.data));
  },
  create(comptaReconciliation) {
    return Api()
      .post('/comptareconciliation', comptaReconciliation)
      .then((res) => this.formatRes(res.data));
  },
  update(comptaReconciliation) {
    return Api()
      .put('/comptareconciliation/' + comptaReconciliation.id, comptaReconciliation)
      .then((res) => this.formatRes(res.data));
  },
  delete(comptaReconciliation) {
    return Api()
      .delete('/comptareconciliation/' + comptaReconciliation.id)
      .then(() => comptaReconciliation);
  },
  formatRes(e) {
    return e;
  },
};
