<script>
/* COMPOSANTS */
import AkCalendar from '@components/v2/input/AkCalendar';
import AkInputMoney from '@components/v2/input/AkInputMoney';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import accountOperationsConst from '@mixins/const/accountOperationsConst';

export default {
  components: {
    AkAutocomplete,
    AkInputMoney,
    AkCalendar,
    MultiSelect,
    AkButtonFooterSidebarFilter,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin, reservationAutocompleteMixin, accountOperationsConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.inputReservation) this.$refs.inputReservation.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <AkAutocomplete
      ref="inputReservation"
      v-model="currentValue.reservationId"
      :init-method="autocompleteReservationById"
      :placeholder="$t('reservation.number')"
      option-label="display"
      option-value="id"
      :search-method="autocompleteReservation"
      @item-select="itemSelect"
      inline />

    <AkInputMoney
      v-model="currentValue.amount"
      :placeholder="$t('accounting.amount')"
      inline
      @value-change="itemSelect" />

    <AkCalendar v-model="currentValue.datePeriod" :placeholder="$t('reservation.period')" inline selection="range" />

    <MultiSelect
      v-model="currentValue.transactionTypes"
      :placeholder="$t('facturation_hote.type')"
      :options="accountOperationsSmallConst"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      @change="itemSelect"
      option-label="label"
      option-value="value" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
