export default {
  data() {
    return {
      mandatStatusFilterConst: [
        {label: this.$t('mandat.enabled'), value: 'EN_LIGNE'},
        {label: this.$t('mandat.en_cours_de_signature'), value: 'EN_COURS'},
        {label: this.$t('mandat.resiliation_pending'), value: 'EN_COURS_DE_RESILIATION'},
        {label: this.$t('mandat.disabled'), value: 'RESILIE'},
      ],
      mandatStatusFilterConstForThirdParty: [
        {label: this.$t('mandat.enabled'), value: 'EN_LIGNE'},
        {label: this.$t('mandat.resiliation_pending'), value: 'EN_COURS_DE_RESILIATION'},
        {label: this.$t('mandat.disabled'), value: 'RESILIE'},
      ],
    };
  },
  methods: {
    displayForMandatStatusFilterConst(value) {
      let result = this.mandatStatusFilterConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
