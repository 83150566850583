import scrollToTopMixin from '@mixins/scrollToTopMixin';
import {mapActions} from 'pinia';
import {useMessageStore} from '@store/messagesStore';

export default {
  mixins: [scrollToTopMixin],
  methods: {
    ...mapActions(useMessageStore, ['addError', 'addWarning', 'addSuccess', 'success']),
  },
};
