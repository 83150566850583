export default {
  data() {
    return {
      blockadeReasonsConst: [
        {label: this.$t('reservation.blockade_travaux'), value: 'TRAVAUX'},
        {label: this.$t('reservation.blockade_renov'), value: 'RENOV'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForBlockadeReason(value) {
      let result = this.blockadeReasonsConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
