import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  tasks(filter) {
    return Api()
      .get('/tasks', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  task(taskId) {
    return Api()
      .get('/task/' + taskId)
      .then((res) => this.formatRes(res.data));
  },
  create(task) {
    return Api()
      .post('/task', this.formatReq(task))
      .then((res) => this.formatRes(res.data));
  },
  update(task) {
    return Api()
      .put('/task/' + task.id, this.formatReq(task))
      .then((res) => this.formatRes(res.data));
  },
  delete(task) {
    return Api()
      .delete('/task/' + task.id)
      .then(() => task);
  },
  formatRes(e) {
    if (e.typeData) {
      e.typeData = JSON.parse(e.typeData);
    }
    return e;
  },
  formatReq(e) {
    if (e.typeData) e.typeData = JSON.stringify(e.typeData);
    return e;
  },
};
