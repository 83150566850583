<script>
import $ from 'jquery';
import Sidebar from 'primevue/sidebar';

export default {
  extends: Sidebar,
  updated() {
    if (this.$isMobile()) {
      this.$nextTick(() => {
        $(this.mask).addClass('sidebar-mobile');
      });
    }
  },
};
</script>
