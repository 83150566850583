export default {
  data() {
    return {
      ticketPriorityConst: [
        {label: this.$t('ticket.priority_low'), value: 'LOW'},
        {label: this.$t('ticket.priority_medium'), value: 'MEDIUM'},
        {label: this.$t('ticket.priority_high'), value: 'HIGH'},
        {label: this.$t('ticket.priority_critical'), value: 'CRITICAL'},
      ],
    };
  },
  methods: {
    displayForTicketPriorityConst(value) {
      let result = this.ticketPriorityConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
