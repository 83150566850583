<script>
/* COMPOSANTS */
import AkInputSwitch from '@components/v2/input/AkInputSwitch';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import receptionTypeConst from '@mixins/const/receptionTypeConst';
import litigeStatusConst from '@mixins/const/litigeStatusConst';
import maintenanceStatusConst from '@mixins/const/maintenanceStatusConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';

/* SERVICES */
import missionWaitingOrderService from '@services/missionWaitingOrderService';
import sidebarMixin from '@mixins/sidebarMixin';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation.vue';
import AkLoader from '@components/general/AkLoader.vue';
import prestationReservationService from '@services/prestationReservationService';

export default {
  components: {
    AkLoader,
    AkPictureAccommodation,
    AkInputSwitch,
  },
  mixins: [
    permissionsMixin,
    utilsMixin,
    receptionTypeConst,
    litigeStatusConst,
    maintenanceStatusConst,
    reservationTypesConst,
    sidebarMixin,
  ],
  data() {
    return {
      missionWaitingOrder: undefined,
      activeTab: 1, // Onglet "Actuelle"
      previousReservation: undefined,
      nextReservation: undefined,
    };
  },
  props: {
    modelValue: {required: true},
  },
  mounted() {
    this.$refs.loader.load();
    missionWaitingOrderService
      .waitingOrder(this.currentValue)
      .then((data) => {
        this.missionWaitingOrder = data;
      })
      .catch((e) => {
        let message = this.msgForErr(this.$t, e);
        this.addError(message);
      })
      .finally(() => {
        this.$refs.loader.hide();
      });
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    hasPrevReservation() {
      return this.previousReservation && this.previousReservation.id;
    },
    hasNextReservation() {
      return this.nextReservation && this.nextReservation.id;
    },
  },
  methods: {
    clickCreate() {
      this.hideSidebar();
      this.$router.push({
        name: 'ordersCreate',
        params: {
          accommodationId: this.missionWaitingOrder.accommodationId,
        },
        query: {
          reservationId: this.currentValue,
        },
      });
    },

    openMapWithAddress() {
      window.open(
        'https://www.google.com/maps/search/?api=1&query=' +
          this.missionWaitingOrder.accommodationAddress +
          ', ' +
          this.missionWaitingOrder.accommodationPostalCode +
          ', ' +
          this.missionWaitingOrder.accommodationCity +
          ', ',
        '_blank',
      );
    },
    loadPrevReservation() {
      this.activeTab = 0;
      this.$nextTick(() => {
        this.$refs.prevLoader.load();
      });
      prestationReservationService
        .findPrevReservation(this.missionWaitingOrder.id)
        .then((data) => {
          this.previousReservation = data;
        })
        .finally(() => {
          this.$refs.prevLoader.hide();
        });
    },
    loadNextReservation() {
      this.activeTab = 2;
      this.$nextTick(() => {
        this.$refs.nextLoader.load();
      });
      prestationReservationService
        .findNextReservation(this.missionWaitingOrder.id)
        .then((data) => {
          this.nextReservation = data;
        })
        .finally(() => {
          this.$refs.nextLoader.hide();
        });
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :scale="2" color="var(--color-primary)" message="Récupération en cours">
    <template #content>
      <div class="flex flex-col justify-between h-full" v-if="missionWaitingOrder">
        <perfect-scrollbar class="flex-1 overflow-hidden" style="height: calc(100% - 59px)">
          <div class="grid grid-cols-1 mb-4 gap-4">
            <AkPictureAccommodation
              :id="missionWaitingOrder.accommodationId"
              :name="missionWaitingOrder.accommodationName"
              :md5="missionWaitingOrder.pictureAccommodation"
              @click="this.hideSidebar"
              cover
              height="134" />

            <div class="flex flex-col gap-1" v-if="this.isGas()">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('company.company') }}</span>
              <span class="font-inter font-medium text-gray text-md">{{ missionWaitingOrder.companyDisplay }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('owner.ownerDisplay') }}</span>
              <span class="font-inter font-semibold text-primary text-md">
                <router-link
                  @click="this.hideSidebar()"
                  class="font-semibold"
                  :to="{name: 'ownerPage', params: {ownerId: missionWaitingOrder.ownerId}}">
                  {{ missionWaitingOrder.ownerDisplay }}
                </router-link>
              </span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('accommodation.address') }}</span>
              <span class="font-inter font-medium text-gray text-md flex gap-1 items-center"
                >{{
                  missionWaitingOrder.accommodationAddress + ' - ' + missionWaitingOrder.accommodationPostalCodeAndCity
                }}
                <i class="ga-icon ga-external text-2xl text-primary cursor-pointer" @click="openMapWithAddress" />
              </span>
            </div>
          </div>

          <!-- TABS -->
          <perfect-scrollbar :options="{suppressScrollY: true}">
            <div class="flex items-center mb-4">
              <span
                class="list-table-item flex-1"
                @click="loadPrevReservation()"
                :class="[{active: this.activeTab === 0}]">
                {{ this.$t('waiting_orders.tabs.sidebar_previous') }}
              </span>
              <span class="list-table-item flex-1" @click="activeTab = 1" :class="[{active: this.activeTab === 1}]">
                {{ this.$t('waiting_orders.tabs.sidebar_current') }}
              </span>
              <span
                class="list-table-item flex-1"
                @click="loadNextReservation()"
                :class="[{active: this.activeTab === 2}]">
                {{ this.$t('waiting_orders.tabs.sidebar_next') }}
              </span>
            </div>
          </perfect-scrollbar>

          <!-- CONTENT -->
          <!-- RESERVATION PRECEDENTE  -->

          <template v-if="this.activeTab === 0">
            <div>
              <AkLoader ref="prevLoader" color="var(--color-primary)" message="Récupération de la réservation">
                <template #content>
                  <div class="mb-4">
                    <div v-if="this.hasPrevReservation" class="bg-detailsBg rounded-lg p-2 mt-2">
                      <div class="grid grid-cols-2 gap-4">
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Numéro</span>
                          <span class="font-inter font-medium text-gray text-md">
                            <router-link
                              @click="this.hideSidebar()"
                              class="text-primary hover:text-primary font-semibold"
                              :to="{
                                name: 'reservationPage',
                                params: {
                                  reservationId: this.previousReservation.id,
                                },
                              }">
                              {{ this.previousReservation.reservationNum }}
                            </router-link>
                          </span>
                        </div>
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Type</span>
                          <span class="font-inter font-medium text-gray text-md">
                            {{ displayForReservationTypeConst(this.previousReservation.type) }}
                          </span>
                        </div>
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Date de Check-in</span>
                          <span class="font-inter font-medium text-gray text-md">{{
                            this.formatDateIfNeeded(this.previousReservation.dateCheckin)
                          }}</span>
                        </div>
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Date de Check-out</span>
                          <span class="font-inter font-medium text-gray text-md">{{
                            this.formatDateIfNeeded(this.previousReservation.dateCheckout)
                          }}</span>
                        </div>
                      </div>
                      <div v-if="this.previousReservation.litiges.length > 0">
                        <div class="mt-2" v-for="(litige, idx) of this.previousReservation.litiges" :key="litige.id">
                          <div class="flex flex-col gap-2">
                            <span class="font-bold text-md text-dark">Litige {{ idx + 1 }}</span>
                            <div class="flex flex-col gap-2 gray-border">
                              <div class="flex flex-col gap-1">
                                <span class="font-inter font-semibold text-dark text-base">Status</span>
                                <span class="font-inter font-medium text-gray text-md">
                                  {{ displayForLitigeStatusConst(litige.status) }}
                                </span>
                              </div>
                              <div class="flex flex-col gap-1">
                                <span class="font-inter font-semibold text-dark text-base">Description</span>
                                <span class="font-inter font-medium text-gray text-md">
                                  {{ litige.description }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="bg-detailsBg rounded-lg p-2 mt-2">
                      <div class="flex flex-col items-center">
                        <i class="ga-icon ga-calendar text-3xl text-gray"></i>
                        <span class="text-gray font-medium text-md">Pas de réservation précédente</span>
                      </div>
                    </div>
                  </div>
                </template>
              </AkLoader>
            </div>
          </template>

          <!-- RESERVATION ACTUELLE  -->

          <template v-if="this.activeTab === 1">
            <div class="bg-slate-50 rounded-lg p-4">
              <div class="grid">
                <span class="text-center font-inter font-medium text-gray text-md">
                  <router-link
                    @click="this.hideSidebar()"
                    class="text-primary hover:text-primary font-semibold"
                    :to="{
                      name: 'reservationPage',
                      params: {
                        reservationId: missionWaitingOrder.id,
                      },
                    }">
                    {{ missionWaitingOrder.localizer }}
                  </router-link>
                </span>
              </div>

              <div class="grid grid-cols-2 mt-4 gap-4">
                <div class="flex flex-col col-span-2 gap-1">
                  <span class="font-inter font-semibold text-dark text-base">{{ $t('reservation.dateCreated') }}</span>
                  <span class="font-inter font-medium text-gray text-md">{{
                    this.formatDateIfNeeded(missionWaitingOrder.dateCreated)
                  }}</span>
                </div>
                <div class="flex flex-col gap-1">
                  <span class="font-inter font-semibold text-dark text-base">{{ $t('reservation.dateCheckin') }}</span>
                  <span class="font-inter font-medium text-gray text-md">{{
                    this.formatDateIfNeeded(missionWaitingOrder.dateCheckin)
                  }}</span>
                </div>
                <div class="flex flex-col gap-1">
                  <span class="font-inter font-semibold text-dark text-base">{{ $t('reservation.dateCheckout') }}</span>
                  <span class="font-inter font-medium text-gray text-md">{{
                    this.formatDateIfNeeded(missionWaitingOrder.dateCheckout)
                  }}</span>
                </div>
                <div class="flex flex-col col-span-2 gap-1" v-if="missionWaitingOrder.guestDisplay">
                  <span class="font-inter font-semibold text-dark text-base">{{ $t('client.one') }}</span>
                  <span class="font-inter font-medium text-gray text-md break-all">{{
                    missionWaitingOrder.guestDisplay
                  }}</span>
                </div>
                <div class="flex flex-col gap-1" v-if="missionWaitingOrder.guestPhone">
                  <span class="font-inter font-semibold text-dark text-base">{{ $t('client.phone') }}</span>
                  <span class="font-inter font-medium text-gray text-md">{{ missionWaitingOrder.guestPhone }}</span>
                </div>
                <div class="flex flex-col gap-1" v-if="missionWaitingOrder.guestEmail">
                  <span class="font-inter font-semibold text-dark text-base">{{ $t('client.email') }}</span>
                  <span class="font-inter font-medium text-gray text-md break-all">{{
                    missionWaitingOrder.guestEmail
                  }}</span>
                </div>

                <div class="flex flex-col gap-1">
                  <span class="font-inter font-semibold text-dark text-base">{{ $t('prospect.receptionType') }}</span>
                  <span class="font-inter font-medium text-gray text-md">{{
                    this.displayForReceptionTypeConst(missionWaitingOrder.receptionCheckinType)
                  }}</span>
                </div>
                <div class="flex flex-col gap-1">
                  <span class="font-inter font-semibold text-dark text-base">{{
                    $t('prospect.receptionCheckoutType')
                  }}</span>
                  <span class="font-inter font-medium text-gray text-md">{{
                    this.displayForReceptionTypeConst(missionWaitingOrder.receptionCheckoutType)
                  }}</span>
                </div>
                <div class="flex flex-col col-span-2">
                  <AkInputSwitch
                    disabled
                    v-model="missionWaitingOrder.gestionLinge"
                    :label="$t('planification.laundry_included')" />
                </div>
              </div>

              <!-- MAINTENANCES EN COURS LOGEMENT -->

              <div v-if="missionWaitingOrder.maintenances">
                <span class="title-border mt-4">Maintenance en cours</span>
                <div v-for="(maintenance, idx) in missionWaitingOrder.maintenances" :key="maintenance.id">
                  <div class="grid grid-cols-1 mt-4">
                    <span class="text-center font-inter font-semibold text-dark text-md"
                      >Maintenance n°{{ idx + 1 }}</span
                    >
                  </div>

                  <div class="grid grid-cols-2 mt-4 gap-4">
                    <div class="flex flex-col gap-1">
                      <span class="font-inter font-semibold text-dark text-base">{{ $t('maintenance.status') }}</span>
                      <span class="font-inter font-medium text-gray text-md">{{
                        this.displayForMaintenanceStatusConst(maintenance.status)
                      }}</span>
                    </div>
                    <div class="flex flex-col gap-1">
                      <span class="font-inter font-semibold text-dark text-base">{{ $t('maintenance.problem') }}</span>
                      <span class="font-inter font-medium text-gray text-md">{{ maintenance.problem }}</span>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 mt-4">
                    <span class="font-inter font-semibold text-dark text-base">{{
                      $t('maintenance.description')
                    }}</span>
                    <span class="font-inter font-medium text-gray text-md">{{ maintenance.description }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- RESERVATION SUIVANTE  -->

          <template v-if="this.activeTab === 2">
            <div>
              <AkLoader ref="nextLoader" color="var(--color-primary)" message="Récupération de la réservation">
                <template #content>
                  <div class="mb-2">
                    <div v-if="this.hasNextReservation" class="bg-detailsBg rounded-lg p-2 mt-2">
                      <div class="grid grid-cols-2 gap-4">
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Numéro</span>
                          <span class="font-inter font-medium text-gray text-md">
                            <router-link
                              @click="this.hideSidebar()"
                              class="text-primary hover:text-primary font-semibold"
                              :to="{
                                name: 'reservationPage',
                                params: {
                                  reservationId: this.nextReservation.id,
                                },
                              }">
                              {{ this.nextReservation.reservationNum }}
                            </router-link>
                          </span>
                        </div>
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Type</span>
                          <span class="font-inter font-medium text-gray text-md">
                            {{ displayForReservationTypeConst(this.nextReservation.type) }}
                          </span>
                        </div>
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Date de Check-in</span>
                          <span class="font-inter font-medium text-gray text-md">{{
                            this.formatDateIfNeeded(this.nextReservation.dateCheckin)
                          }}</span>
                        </div>
                        <div class="flex flex-col gap-1">
                          <span class="font-inter font-semibold text-dark text-base">Date de Check-out</span>
                          <span class="font-inter font-medium text-gray text-md">{{
                            this.formatDateIfNeeded(this.nextReservation.dateCheckout)
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-else class="bg-detailsBg rounded-lg p-2 mt-2">
                      <div class="flex flex-col items-center">
                        <i class="ga-icon ga-calendar text-3xl text-gray"></i>
                        <span class="text-gray font-medium text-md">Pas de réservation suivante</span>
                      </div>
                    </div>
                  </div>
                </template>
              </AkLoader>
            </div>
          </template>
        </perfect-scrollbar>

        <div class="w-full text-center mt-2">
          <button class="btn-primary-gradient" @click="clickCreate">
            {{ this.$t('waiting_orders.create') }}
          </button>
        </div>
      </div>
    </template>
  </AkLoader>
</template>
