export default {
  data() {
    return {
      paymentStatusConst: [
        {label: this.$t('payment.TOTALLY_PAID'), value: 'TOTALLY_PAID'},
        {label: this.$t('payment.PARTIALLY_PAID'), value: 'PARTIALLY_PAID'},
        {label: this.$t('payment.UNPAID'), value: 'UNPAID'},
      ],
    };
  },
  methods: {
    displayForPaymentStatusConst(value) {
      let result = this.paymentStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
