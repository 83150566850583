<script>
import moment from 'moment/moment';
import {useTabStore} from '../../../store/tabStore';

/* COMPOSANTS */
import AkAllFilter from '@components/v2/filter/AkAllFilter';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter';
import AkListFilter from '@components/v2/filter/AkListFilter';
import AkPlanning from '@components/general/AkPlanning';
import AkPlanningYear from '@components/general/AkPlanningYear';
import GaListView from '@components/v2/layout/GaListView';
import GaView from '@components/v2/layout/GaView';
import CreateSidebar from '@views/reservation/planning/CreateSidebar';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import companyMixin from '@mixins/companyMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import sidebarMixin from '@mixins/sidebarMixin';
import smilyStatusConst from '@mixins/const/smilyStatusConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import reservationPlanningService from '@services/reservationPlanningService';

export default {
  components: {
    AkAllFilter,
    AkAutocompleteFilter,
    AkListFilter,
    GaListView,
    AkPlanningYear,
    GaView,
    AkPlanning,
    CreateSidebar,
  },
  mixins: [
    accommodationTypeConst,
    loaderMixin,
    messageMixin,
    permissionsMixin,
    randomRef,
    residenceTypeConst,
    sidebarMixin,
    smilyStatusConst,
    utilsMixin,
    companyMixin,
    ownerAutocompleteMixin,
    accommodationAutocompleteMixin,
  ],
  metaInfo() {
    return {
      title: this.isOwner() ? 'menu.my_planning' : 'reservation.planning',
    };
  },
  data() {
    return {
      datePlanning: moment(),
      companies: [],
      canHideLoader: true,
      loader: undefined,
      width: 0,
      filter: {date: moment().toDate()},
      planning: [],
      displayYear: false,
      accommodations: [],
      accommodation: {},
      durationSelectedId: 0,
      nbDays: 15,
      durationOptions: [
        {label: '2 semaines', value: 15},
        {label: '30 jours', value: 30},
      ],
    };
  },
  mounted() {
    this.durationSelectedId = useTabStore().getTabActive('ReservationPlanning', 0);
    this.nbDays = this.getDurationsOptions[this.durationSelectedId].value;
    this.refresh();
  },

  computed: {
    canDisplayYearly() {
      return (
        this.$route.query.accommodationId ||
        (this.isOwner() && this.hasSingleAccommodation) ||
        (this.planning != undefined &&
          (this.planning.length > 0 || typeof this.planning === 'object') &&
          this.filter.accommodationId != undefined &&
          this.filter.ownerId == undefined &&
          (this.filter.companyIds == undefined || this.filter.companyIds.length === 0) &&
          (this.filter.clusters == undefined || this.filter.clusters.length === 0) &&
          (this.filter.typologies == undefined || this.filter.typologies.length === 0) &&
          (this.filter.physicalReception == undefined || this.filter.physicalReception === false) &&
          (this.filter.laundry == undefined || this.filter.laundry === false))
      );
    },
    getDurationsOptions() {
      let durations = [...this.durationOptions];
      if (this.canDisplayYearly) {
        durations.push({label: 'Annuel', value: 0});
      }
      return durations;
    },
    hasSingleAccommodation() {
      return this.accommodations.length === 1;
    },
    singleAccommodationId() {
      return this.accommodations[0].id;
    },
  },

  methods: {
    refresh(forceRefresh = false) {
      if (this.isOwner()) {
        accommodationService.accommodations({userId: this.myUserId()}).then((data) => {
          this.accommodations = data;
          if (this.accommodations.length === 1) {
            this.filter.accommodationId = this.singleAccommodationId;
            this.selectDuration(this.durationSelectedId, true);
          } else if (this.filter.accommodationId) {
            this.selectDuration(this.durationSelectedId, true);
          } else {
            this.selectDuration(this.durationSelectedId, true);
          }
        });
      }
      if (
        (this.isGas() || this.isGav() || this.isPartenaire() || this.isHousekeeper()) &&
        this.$route.query.accommodationId
      ) {
        if (this.$route.query.accommodationId) this.filter.accommodationId = this.$route.query.accommodationId;
        this.selectDuration(2, true);
      } else if (this.isGav() || this.isPartenaire() || this.isHousekeeper()) {
        this.$nextTick(() => {
          this.showLoader(this.$refs.listView);
        });
        reservationPlanningService
          .planning(this.filter)
          .then((data) => {
            this.planning = data;
            this.removeDuplicate();
            this.canHideLoader = true;
          })
          .finally(() => {
            this.hideCustomLoader();
          });
      } else if (this.isGas()) {
        this.today();
      }
    },
    deleteReservation(success) {
      if (success) {
        this.success(this.$t('unavailability.deleted'));
        this.refresh(true);
      } else {
        this.addError(this.$t('unavailability.impossible_to_cancel'));
      }
    },
    resetFilter() {
      this.filter = {date: moment().toDate()};
      this.openFilterPanel();
    },
    selectDuration(index, forceSelect = false) {
      if (this.canHideLoader === false || (index === this.durationSelectedId && !forceSelect)) {
        return;
      }
      this.$nextTick(() => {
        this.showLoader(this.$refs.listView);
      });

      setTimeout(
        function () {
          if (index !== 2) useTabStore().setTabActive('ReservationPlanning', index);
          this.durationSelectedId = index;
          if (this.getDurationsOptions.length > index && !this.getDurationsOptions[index].value) {
            this.getPlanningYearly();
          } else if (index !== 2) {
            this.nbDays = this.getDurationsOptions[index].value;
            this.getPlanning();
          }
        }.bind(this),
        0,
      ); // the `bind(this)` is importan
    },
    nextDays() {
      this.$nextTick(() => {
        this.showLoader(this.$refs.listView);
      });
      let newDatePlanning = moment(this.datePlanning).add(10, 'd');
      this.filter.date = newDatePlanning.toDate();
      this.getPlanning();
    },
    prevDays() {
      this.$nextTick(() => {
        this.showLoader(this.$refs.listView);
      });
      let newDatePlanning = moment(this.datePlanning).add(-10, 'd');
      this.filter.date = newDatePlanning.toDate();
      this.getPlanning();
    },
    nextYear() {
      this.$nextTick(() => {
        this.showLoader(this.$refs.listView);
      });
      let newDatePlanning = moment(this.datePlanning).add(1, 'y');
      this.filter.date = newDatePlanning.toDate();
      this.getPlanningYearly();
    },
    prevYear() {
      this.$nextTick(() => {
        this.showLoader(this.$refs.listView);
      });
      let newDatePlanning = moment(this.datePlanning).add(-1, 'y');
      this.filter.date = newDatePlanning.toDate();
      this.getPlanningYearly();
    },
    today() {
      let newDatePlanning = moment();
      this.filter.date = newDatePlanning.toDate();
      this.$nextTick(() => {
        this.showLoader(this.$refs.listView);
      });
      if (this.displayYear) this.getPlanningYearly();
      else this.getPlanning();
    },
    hideCustomLoader() {
      if (this.canHideLoader) {
        this.hideLoader();
      }
    },
    search() {
      if (this.canHideLoader === false) return;
      if ((this.displayYear && this.canDisplayYearly) || this.isOwner()) {
        if (this.durationSelectedId === 2) {
          if (!this.canDisplayYearly) this.durationSelectedId = 0;
        }

        this.selectDuration(this.durationSelectedId, true);
      } else {
        this.selectDuration(this.durationSelectedId === 2 ? 0 : this.durationSelectedId, true);
      }
    },

    // PLANNING

    getPlanning() {
      this.canHideLoader = false;
      if (!this.durationSelectedId) this.durationSelectedId = 0;
      this.filter.dateYearly = undefined;
      reservationPlanningService
        .planning(this.filter)
        .then((data) => {
          this.displayYear = false;
          this.planning = data;
          this.removeDuplicate();
          this.canHideLoader = true;
          this.datePlanning = moment(this.filter.date);
        })
        .catch((e) => {})
        .finally(() => {
          this.hideCustomLoader();
        });
    },
    getPlanningYearly() {
      this.filter.dateYearly = this.filter.date;

      reservationPlanningService
        .planningYearly(this.filter)
        .then((data) => {
          this.planning = data;
          this.canHideLoader = true;
          this.datePlanning = moment(this.filter.date);
          this.displayYear = true;
        })
        .catch((e) => {})
        .finally(() => {
          this.hideCustomLoader();
        });
    },

    removeDuplicate() {
      this.planning.sort(function compare(a, b) {
        if (a.ownerDisplay.toLowerCase() < b.ownerDisplay.toLowerCase()) return -1;
        if (a.ownerDisplay.toLowerCase() > b.ownerDisplay.toLowerCase()) return 1;
        return 0;
      });
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilterTitle(
        'GaReservationFilterPlanning',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },

    // CREATE RESERVATION

    createReservation() {
      let reservationHote = {
        id: undefined,
        accommodationId: this.filter.accommodationId,
        dateStart: undefined,
        dateEnd: undefined,
        hourCheckin: undefined,
        hourCheckout: undefined,
        comment: undefined,
      };
      let disableResaHoteAccommodation = !!this.filter.accommodationId;

      this.$refs.createSidebar.show(reservationHote, this.accommodations, disableResaHoteAccommodation);
    },

    // EDIT RESERVATION

    editReservation(reservation) {
      let reservationHote = {
        id: reservation.id,
        accommodationId: reservation.accommodationId,
        dateStart: moment(reservation.dateStart).toDate(),
        dateEnd: moment(reservation.dateEnd).toDate(),
        hourCheckin: moment(reservation.dateCheckin).toDate(),
        hourCheckout: moment(reservation.dateCheckout).toDate(),
        comment: reservation.comments,
      };
      let disableResaHoteAccommodation = true;

      this.$refs.createSidebar.show(reservationHote, this.accommodations, disableResaHoteAccommodation);
    },

    // ADD RESERVATION

    addReservation(dateInfo) {
      if (!this.canAddReservation()) return;

      let year = dateInfo[0];
      let month = dateInfo[1];
      let day = dateInfo[2];

      let start = moment().set({year: year, month: month, date: day});
      if (!start.isAfter(moment())) return;

      let reservationHote = {
        id: undefined,
        accommodationId: this.filter.accommodationId,
        dateStart: start.toDate(),
        dateEnd: undefined,
        hourCheckin: undefined,
        hourCheckout: undefined,
        comment: undefined,
      };
      let disableResaHoteAccommodation = !!this.filter.accommodationId;
      this.$refs.createSidebar.show(reservationHote, this.accommodations, disableResaHoteAccommodation);
    },

    // CAN

    canAddReservation() {
      if (this.isPartenaire()) return false;
      if (this.isHousekeeper()) return false;
      return true;
    },
  },
};
</script>

<template>
  <GaView>
    <template v-slot:action>
      <div class="flex gap-2">
        <button v-if="this.isOwner()" class="btn-primary-gradient xs cursor-pointer" @click="createReservation()">
          {{ this.$t('unavailability.add') }}
        </button>
        <span @click="today()" v-if="!this.$isMobile()" class="btn-primary-gradient xs cursor-pointer">
          Aujourd'hui
        </span>
      </div>
    </template>

    <template #content>
      <GaListView>
        <template #tabs>
          <div class="flex items-center md:flex-row flex-row-reverse justify-end md:justify-start">
            <span
              v-for="({label}, index) in this.getDurationsOptions"
              :key="label"
              class="list-table-item"
              :class="durationSelectedId === index && 'active'"
              @click="this.selectDuration(index)"
              >{{ label }}</span
            >
          </div>
        </template>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.search"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-if="!this.isOwner()"
            v-model="this.filter.ownerId"
            @search="this.search"
            label="Hôte"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAutocompleteFilter
            v-model="filter.accommodationId"
            :init-method="autocompleteAccommodationById"
            label="Logement"
            :search-label="autocompleteAccommodationQuery"
            :search-method="autocompleteAccommodation"
            direction="right"
            item-display="display"
            item-value="id"
            @search="this.search" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>

        <template #content>
          <div ref="listView">
            <AkPlanning
              v-if="planning && !displayYear"
              ref="planning"
              v-model="planning"
              :date-ref="datePlanning"
              :nb-days-display="nbDays"
              @next="nextDays"
              @delete-reservation="deleteReservation"
              @edit-reservation="editReservation"
              @add-reservation="addReservation"
              @prev="prevDays" />

            <AkPlanningYear
              v-if="planning && displayYear"
              v-model="planning"
              :date-ref="datePlanning"
              @next="nextYear"
              @prev="prevYear"
              @delete-reservation="deleteReservation"
              @edit-reservation="editReservation"
              @add-reservation="addReservation" />
          </div>
        </template>
      </GaListView>
    </template>
    <!----------------------------------------------------------------------------------------->
    <template #extra>
      <CreateSidebar ref="createSidebar" @done="refresh" />
    </template>
  </GaView>
</template>
