export const MILLISECONDS_PER_YEAR = 31_536_000_000; // 365 * 24 * 3600 * 1000
export const MILLISECONDS_PER_30DAYS = 2_592_000_000; // 30 * 24 * 3600 * 1000
export const MILLISECONDS_PER_WEEK = 604_800_000; // 7 * 24 * 3600 * 1000
export const MILLISECONDS_PER_DAY = 86_400_000;

const firstCharToUpperCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Get Day short string ex: "Lun."
export const getDayShort = (originalDate) => {
  let date = originalDate;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return firstCharToUpperCase(date.toLocaleDateString(undefined, {weekday: 'short'}));
};

// Get Month short string ex: "Jan."
export const getMonthShort = (originalDate) => {
  let date = originalDate;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return firstCharToUpperCase(date.toLocaleDateString(undefined, {month: 'short'}));
};
