import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  litiges(filter) {
    return Api()
      .get('/litiges', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  litige(id) {
    return Api()
      .get('/litige/' + id)
      .then((res) => this.formatRes(res.data));
  },
  update(litige) {
    return Api()
      .put('/litige/' + litige.id, litige)
      .then((res) => this.formatRes(res.data));
  },
  delete(litige) {
    return Api()
      .delete('/litige/' + litige.id)
      .then(() => litige);
  },
  exportList(filter) {
    return Api()
      .get('/litiges/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  stepTrait(litige) {
    return Api()
      .post('/litige/' + litige.id + '/steps/trait', litige)
      .then((res) => this.formatRes(res.data));
  },
  stepValidate(litige) {
    return Api()
      .post('/litige/' + litige.id + '/steps/validate', litige)
      .then((res) => this.formatRes(res.data));
  },
  stepResolve(litige) {
    return Api()
      .post('/litige/' + litige.id + '/steps/resolve', litige)
      .then((res) => this.formatRes(res.data));
  },
  litigeCompleted(id) {
    return Api()
      .get('/litige/completed/' + id)
      .then((res) => this.formatRes(res.data));
  },
  formatRes(e) {
    return e;
  },
};
