import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  stocks(filter) {
    return Api()
      .get('/stocks', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  stock(stockId) {
    return Api()
      .get('/stock/' + stockId)
      .then((res) => this.formatRes(res.data));
  },
  create(stock) {
    return Api()
      .post('/stock', stock)
      .then((res) => this.formatRes(res.data));
  },
  update(stock) {
    return Api()
      .put('/stock/' + stock.id, stock)
      .then((res) => this.formatRes(res.data));
  },
  delete(stock) {
    return Api()
      .delete('/stock/' + stock.id)
      .then(() => stock);
  },
  formatRes(e) {
    return e;
  },
};
