import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  cancelRules(filter) {
    return Api()
      .get('/cancelrules', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  cancelRule(id) {
    return Api()
      .get('/cancelrule/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(cancelRule) {
    return Api()
      .post('/cancelrule', cancelRule)
      .then((res) => this.formatRes(res.data));
  },
  update(cancelRule) {
    return Api()
      .put('/cancelrule/' + cancelRule.id, cancelRule)
      .then((res) => this.formatRes(res.data));
  },
  delete(cancelRule) {
    return Api()
      .delete('/cancelrule/' + cancelRule.id)
      .then(() => cancelRule);
  },
  formatRes(e) {
    e.disabled = !e.enabled;
    return e;
  },
};
