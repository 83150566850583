export default {
  data() {
    return {
      reservationCancelledBefore30: [
        {label: 'Exclure les réservations annulées à plus de 30 jours', value: true},
        {label: 'Inclure les réservations annulées à plus de 30 jours', value: false},
      ],
    };
  },
};
