import {goBack} from '@utils';
import {mapState} from 'pinia';
import {useBack} from '@store/backStore';

export default {
  methods: {
    goBack() {
      goBack(this.$router);
    },
    initGoBack() {
      useBack().setBack(() => this.goBack());
    },
  },
  mounted() {
    useBack().setBack(false);
  },
  computed: {
    ...mapState(useBack, ['hasBack']),
  },
};
