<script>
import GaView from '@components/v2/layout/GaView.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import UtilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import mandatService from '@services/mandatService';
import permissionsMixin from '@mixins/permissionsMixin';
import backMixin from '@mixins/backMixin';
import {useBack} from '../../../store/backStore';

export default {
  name: 'AvenantSign',
  components: {GaView},
  mixins: [randomRef, loaderMixin, backMixin, messageMixin, UtilsMixin, permissionsMixin],
  metaInfo() {
    return {
      title:
        this.avenant && this.parentMandat
          ? this.$t('mandat.avenant_sign_title', {
              dateStart: this.displayDate(this.avenant.dateStart),
              parentMandatNum: this.parentMandat.num,
            })
          : 'Avenant à signer',
    };
  },
  data() {
    return {
      signSuccess: false,
      avenantId: undefined,
      avenant: undefined,
      parentMandat: undefined,
      yousign: undefined,
    };
  },
  mounted() {
    useBack().setBack(() => this.goToHome());
    this.showTotalLoaderGuestadom();
    this.avenantId = this.$route.params.avenantId;
    mandatService.mandat(this.avenantId).then((data) => {
      this.avenant = data;
      mandatService
        .mandat(this.avenant.parentId)
        .then((data) => {
          this.parentMandat = data;
          this.$nextTick(() => {
            this.initV3();
          });
        })
        .finally(() => {
          this.hideLoader();
        });
    });
  },
  methods: {
    initV3() {
      if (this.isV3Yousign) {
        let that = this;
        if (this.yousign) {
          this.yousign.removeMessageListener();
        }
        setTimeout(() => {
          if (!this.signSuccess) {
            this.yousign = new window.Yousign({
              signatureLink: this.nextSignUrl,
              iframeContainerId: 'iframe-container',
              isSandbox: process.env.VUE_APP_YOUSIGN_PLATFORM !== 'prod',
            });
            this.yousign.onSuccess((data) => {
              that.avenantSigned();
            });
          }
        }, 100);
      }
    },
    avenantSigned() {
      this.yousign.removeMessageListener();
      this.success('Signature validée, vous serez rédirigé dans 5 secondes');
      setTimeout(() => {
        mandatService
          .avenantSigned(this.avenant)
          .then((data) => {
            if (data.yousign === null && data.signDate !== null) {
              this.signSuccess = true;
              this.goToDashboard();
            } else if (data.yousign !== this.avenant.yousign) {
              this.avenant = data;
            } else {
              throw '';
            }
          })
          .then(() => {
            if (this.avenant.yousign && !this.signSuccess) this.initV3();
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 503) {
                this.addWarning(this.$t('mandat.error_avenant_not_signed'));
              } else {
                this.addError(this.$t('error.undefined'));
              }
            } else {
              this.addError(this.$t('error.undefined'));
            }
          });
      }, 5000);
    },
    goToHome() {
      this.$router.push({name: 'home'});
    },
    goToDashboard() {
      this.$router.push({name: 'home', hash: '#avenantSigned'});
    },
  },
  computed: {
    isV3Yousign() {
      return this.avenant.yousignVersion === 'v3';
    },
    nextSignUrl() {
      console.log(this.avenant.yousign);
      let production = process.env.VUE_APP_YOUSIGN_PLATFORM !== 'prod' ? '&disable_domain_validation=true' : '';
      return this.avenant.yousign.concat(production);
    },
  },
};
</script>

<template>
  <GaView>
    <template #content>
      <div id="iframe-container" class="iframe-yousign iframe-full flex-1 h-[calc(100vh_-_200px)]"></div>
    </template>
  </GaView>
</template>
