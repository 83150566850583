import {defineStore} from 'pinia';
import Api from '@services/api';
import {removeEmptyParams} from '@utils';

export const usePoleStore = defineStore('pole', {
  state: () => ({poles: undefined}),
  actions: {
    async all() {
      if (this.isFilled()) return this.poles;
      let poles = await Api(true, false)
        .get('/poles', {params: removeEmptyParams({})})
        .then((res) => {
          return res.data;
        });
      this.$patch({
        poles: poles,
      });
      return this.poles;
    },
    clear() {
      this.$patch({
        poles: undefined,
      });
    },
    isFilled() {
      return this.poles !== undefined;
    },
  },
});
