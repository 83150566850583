<script>
/* COMPOSANTS */
import AkLabel from '@components/v2/general/AkLabel';
import AutoComplete from 'primevue/autocomplete';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import {debounce} from '@utils';

/* SERVICES */

export default {
  components: {
    AkLabel,
    AutoComplete,
  },
  inject: {
    pDisabled: {default: null},
    pSubmitted: {default: null},
  },
  mixins: [loaderMixin, utilsMixin],
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    dropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    forceSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    optionLabel: {
      type: [String, Function],
      required: false,
      default: 'label',
    },
    optionValue: {
      type: [String, Function],
      required: false,
      default: 'id',
    },
    displayValue: {
      type: [String, Function],
      required: false,
      default: undefined,
    },
    searchMethod: {
      type: Function,
      required: true,
    },
    initMethod: {
      type: Function,
      required: false,
      default: undefined,
    },
    searchLabel: {
      type: String,
      required: false,
      default: 'query',
    },
    searchObject: {
      type: Object,
      required: false,
      default(rawProps) {
        return {};
      },
    },
  },
  emits: ['update:modelValue', 'search', 'item-select', 'init'],
  data() {
    return {
      item: undefined,
      items: [],
      isInit: false,
    };
  },
  updated() {
    this.mount();
  },

  mounted() {
    if (this.displayValue) {
      this.item = this.displayValue;
    }
    this.isInit = true;
    this.mount();
  },
  methods: {
    mount() {
      this.$nextTick(() => {
        if (this.initMethod && this.item == undefined && this.currentValue != undefined) {
          if (this.$refs.autocomplete) this.$refs.autocomplete.searching = true;
          this.initMethod(this.currentValue)
            .then((data) => {
              this.item = data;
              this.items.push(data);
              this.init(data);
              this.$emit('init', data);
            })
            .finally(() => {
              if (this.$refs.autocomplete) this.$refs.autocomplete.searching = false;
            });
        }
      });
    },
    search(event) {
      if (!event.query) {
        this.reset();
      } else {
        this.filterObject[this.searchLabel] = event.query;
        this.searchMethod(this.filterObject).then((data) => {
          this.items = data;
        });
      }
    },
    itemSelect(event) {
      if (event.value instanceof Object) {
        this.item = event.value;
        this.currentValue = event.value[this.optionValue];
        this.$nextTick(() => {
          this.$emit('item-select', this.item);
        });
      } else if (event.value === '') {
        let firedEvent = false;
        if (this.currentValue != null) {
          firedEvent = true;
        }
        this.currentValue = null;
        if (firedEvent) {
          this.$nextTick(() => {
            this.$emit('item-select', null);
          });
        }
      }
    },
    reset() {
      this.$nextTick(() => {
        this.item = undefined;
        this.currentValue = null;
      });
    },
    init(item) {
      this.item = item;
    },
    onFocus(event) {},
  },
  watch: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    filterObject() {
      return this.searchObject;
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.hasValidator && this.validator.$invalid;
    },
    isRequired() {
      return (
        this.hasValidator &&
        this.validator.required !== undefined &&
        (this.validator.required.$params.type === 'required' ||
          (this.validator.required.$params.type === 'requiredIf' && this.validator.required.$params.prop === true))
      );
    },
    isRequiredFailed() {
      return this.isRequired && this.validator.required.$invalid && this.alreadySubmitted;
    },
  },
};
</script>

<template>
  <div class="w-full input-layout" :class="[className, {'form-group': !isInline, 'form-control': isInline}]">
    <AkLabel v-if="label && !inline && displayLabel" :required="this.isRequired">
      {{ label }}
    </AkLabel>
    <AutoComplete
      ref="autocomplete"
      v-model="item"
      :class="[{'p-invalid': isInvalid && alreadySubmitted}]"
      class="w-full text-dark text-base font-inter"
      inputClass="form-control"
      :inputStyle="{padding: '14px 18px 14px 18px;'}"
      :disabled="isDisabled"
      :optionLabel="optionLabel"
      :placeholder="getPlaceholder"
      :suggestions="items"
      :force-selection="this.forceSelection"
      :dropdown="dropdown"
      @focus="onFocus"
      @change="itemSelect($event)"
      @complete="search($event)">
      <template #item="slotProps">
        <slot :item="slotProps.item" name="item"></slot>
      </template>
    </AutoComplete>

    <div v-if="!isInline" class="d-flex flex-column flex-1">
      <small v-if="isRequiredFailed" class="p-error">
        {{ $t('field_required', [this.label]) }}
      </small>
    </div>
  </div>
</template>
