<script>
import AkLabel from '@components/general/AkLabel';
import Chips from 'primevue/chips';

export default {
  components: {AkLabel, Chips},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    link: {
      type: Object,
      required: false,
      default: undefined,
    },
    displayer: {
      type: Function,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    separator: {
      type: String,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        if (this.displayer) return this.displayer(this.modelValue);
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
    isValidatorMinMaxLength() {
      return (
        this.validator !== undefined &&
        (this.validator.minLength !== undefined || this.validator.maxLength !== undefined) &&
        (this.validator.minLength.$invalid || this.validator.maxLength.$invalid) &&
        this.alreadySubmitted
      );
    },
    isValidatorEmail() {
      return (
        this.validator !== undefined &&
        this.validator.email !== undefined &&
        this.validator.email.$invalid &&
        this.alreadySubmitted
      );
    },
    isValidatorPhone() {
      return (
        this.validator !== undefined &&
        this.validator.phoneValidator !== undefined &&
        this.validator.phoneValidator.$invalid &&
        this.alreadySubmitted
      );
    },
    isValidatorDuplicatedKey() {
      return (
        this.validator !== undefined &&
        this.validator.notUsed !== undefined &&
        this.validator.notUsed.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="className" class="form-group">
    <AkLabel v-if="displayLabel" :required="isRequired">
      {{ label }}
    </AkLabel>
    <Chips
      v-model="currentValue"
      :class="[isInvalid && alreadySubmitted ? 'p-invalid' : '', inputClass]"
      :disabled="isDisabled"
      :placeholder="getPlaceholder"
      :separator="separator"
      class="form-control" />

    <small v-if="isValidatorRequired" class="p-error">{{ $t('field_required', [label]) }} </small>
    <small v-if="isValidatorEmail" class="p-error">{{ $t('bad_format', [label]) }} </small>
    <small v-if="isValidatorMinMaxLength" class="p-error"
      >{{
        $t('min_max_length', [
          $t('company.postalCode'),
          validator.minLength.$params.min,
          validator.maxLength.$params.max,
        ])
      }}
    </small>
    <small v-if="isValidatorPhone" class="p-error">{{ $t('check_phone_number') }} </small>
    <small v-if="isValidatorDuplicatedKey" class="p-error">
      {{ $t('key_already_used') }}
    </small>
  </div>
</template>
