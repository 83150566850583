<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkReservationTooltip from '@components/general/AkReservationTooltip';
import AkDialog from '@components/general/AkDialog';
import AkInputMoney from '@components/input/AkInputMoney';
import AkCalendar from '@components/input/AkCalendar';
import PButton from 'primevue/button';
import GaView from '@components/layout/GaView';

/* MIXINS */
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import messageMixin from '@mixins/messageMixin';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import sidebarMixin from '@mixins/sidebarMixin';
import smilyStatusConst from '@mixins/const/smilyStatusConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import reservationService from '@services/reservationService';
import companyService from '@services/companyService';

export default {
  components: {
    AkDialog,
    AkInputMoney,
    AkCalendar,
    PButton,
    GaView,
  },
  mixins: [
    accommodationTypeConst,
    loaderMixin,
    permissionsMixin,
    randomRef,
    messageMixin,
    reservationTypesConst,
    sidebarMixin,
    utilsMixin,
    smilyStatusConst,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'reservation.list',
    };
  },
  data() {
    return {
      loading: true,
      companies: [],
      filter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      list: [],
      totalItems: 0,
      item: {},
      itemLitige: {},
      itemReclamation: {},
      reservationTooltip: undefined,
    };
  },
  validations() {
    return {
      itemLitige: {
        dateLitige: {required},
        amountLitigeEstimated: {required},
        amountLitigeReported: {required: requiredIf(this.itemLitige.dateStartLitige)},
        amountLitigeOwner: {required: requiredIf(this.itemLitige.dateStartLitige)},
        amountLitigeGav: {required: requiredIf(this.itemLitige.dateStartLitige)},
        amountLitigeGas: {required: requiredIf(this.itemLitige.dateStartLitige)},
      },
      itemReclamation: {
        dateReclamation: {required},
        amountReclamationEstimated: {required},
        amountReclamationReported: {required: requiredIf(this.itemReclamation.dateStartReclamation)},
        amountReclamationOwner: {required: requiredIf(this.itemReclamation.dateStartReclamation)},
        amountReclamationGav: {required: requiredIf(this.itemReclamation.dateStartReclamation)},
        amountReclamationGas: {required: requiredIf(this.itemReclamation.dateStartReclamation)},
      },
    };
  },
  mounted() {
    let p1 = reservationService.reservationsPagination(this.filter);
    p1.then((data) => {
      this.list = data.data;
      this.totalItems = data.totalItems;
    });

    let p2 = companyService.companies();
    p2.then((data) => {
      this.companies = data;
    });

    Promise.all([p1, p2]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    clickSearch() {
      this.filter.page = 1;
      this.search();
    },
    search() {
      this.loading = true;
      reservationService.reservationsPagination(this.filter).then((data) => {
        this.list = data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    displayPeriodForReservation(data) {
      return this.displayDates(data.dateStart, data.dateEnd);
    },
    rowClick(event) {
      let data = event.data;
      this.addReservation(data.id, this.deleteReservationHote);
    },
    deleteReservationHote(success) {
      this.hideSidebar();
      if (success) {
        this.success(this.$t('unavailability.deleted'));
        this.search();
      } else {
        this.addError(this.$t('unavailability.impossible_to_cancel'));
      }
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.search();
    },
    onSort(event) {
      this.filter.sort = [];
      for (let sort of event.multiSortMeta) {
        this.filter.sort.push('' + sort.field + ':' + (sort.order === -1 ? 'DESC' : 'ASC'));
      }
      if (this.filter.sort.length === 0) {
        this.filter.sort.push('id:DESC');
      }
      this.search();
    },

    getLitigeStatusTooltip(data) {
      let status = '[' + this.displayDates(data.dateStartLitige, data.dateEndLitige) + ']';
      if (data.amountLitigeEstimated)
        status += ' ' + this.formatCurrencyEUR(this.formatAmount(data.amountLitigeEstimated));
      return status;
    },
    getReclamationStatusTooltip(data) {
      let status = '[' + this.displayDates(data.dateStartReclamation, data.dateEndReclamation) + ']';
      if (data.amountReclamationEstimated)
        status += ' ' + this.formatCurrencyEUR(this.formatAmount(data.amountReclamationEstimated));
      return status;
    },

    getLitigeDisplay(data) {
      return this.$t(data.dateStartLitige ? 'reservation.endLitige' : 'reservation.startLitige');
    },
    getReclamationDisplay(data) {
      return this.$t(data.dateStartReclamation ? 'reservation.endReclamation' : 'reservation.startReclamation');
    },

    openLitigeDialog(data) {
      if (data.dateEndLitige) return;
      this.itemLitige = {...data};
      this.itemLitige.estimated = !!data.amountLitigeEstimated;

      if (this.itemLitige.dateStartLitige) {
        this.itemLitige.amountLitigeGas = 0;
        this.itemLitige.amountLitigeGav = 0;
        this.itemLitige.amountLitigeOwner = 0;
      }
      this.$refs.dialogLitige.show();
    },

    openReclamationDialog(data) {
      if (data.dateEndReclamation) return;
      this.itemReclamation = {...data};
      this.itemReclamation.estimated = !!data.amountReclamationEstimated;

      if (this.itemReclamation.dateStartReclamation) {
        this.itemReclamation.amountReclamationGas = 0;
        this.itemReclamation.amountReclamationGav = 0;
        this.itemReclamation.amountReclamationOwner = 0;
      }
      this.$refs.dialogReclamation.show();
    },

    handleLitige() {
      this.v$.itemLitige.$touch();
      if (this.v$.itemLitige.$error) return;

      if (this.itemLitige.dateStartLitige) {
        let total = this.itemLitige.amountLitigeReported;
        let totalGas = this.itemLitige.amountLitigeGas;
        let totalGav = this.itemLitige.amountLitigeGav;
        let totalOwner = this.itemLitige.amountLitigeOwner;

        if (total) {
          if (this.addNumbers([totalGas, totalGav, totalOwner]) !== total) {
            this.$refs.dialogLitige.error("Le total des parts n'est pas égal au montant encaissé");
            return;
          }
        }
        this.itemLitige.dateEndLitige = new Date(this.itemLitige.dateLitige);
      } else this.itemLitige.dateStartLitige = new Date(this.itemLitige.dateLitige);
      this.itemLitige.dateLitige = null;

      this.showTotalLoader();
      reservationService
        .updateLitige(this.itemLitige)
        .then((data) => {
          this.success(
            this.$t(this.itemLitige.dateEndLitige ? 'reservation.litigeEnded' : 'reservation.litigeStarted'),
          );
        })
        .finally(() => {
          this.hideLoader();
          this.$refs.dialogLitige.hide();
          this.search();
        });
    },

    handleReclamation() {
      this.v$.itemReclamation.$touch();
      if (this.v$.itemReclamation.$error) return;

      if (this.itemReclamation.dateStartReclamation) {
        let total = this.itemReclamation.amountReclamationReported;
        let totalGas = this.itemReclamation.amountReclamationGas;
        let totalGav = this.itemReclamation.amountReclamationGav;
        let totalOwner = this.itemReclamation.amountReclamationOwner;

        if (total) {
          if (this.addNumbers([totalGas, totalGav, totalOwner]) !== total) {
            this.$refs.dialogReclamation.error("Le total des parts n'est pas égal au montant reversé");
            return;
          }
        }
        this.itemReclamation.dateEndReclamation = new Date(this.itemReclamation.dateReclamation);
      } else this.itemReclamation.dateStartReclamation = new Date(this.itemReclamation.dateReclamation);
      this.itemReclamation.dateReclamation = null;

      this.showTotalLoader();
      reservationService
        .updateReclamation(this.itemReclamation)
        .then((data) => {
          this.success(
            this.$t(
              this.itemReclamation.dateEndReclamation
                ? 'reservation.reclamationEnded'
                : 'reservation.reclamationStarted',
            ),
          );
        })
        .finally(() => {
          this.hideLoader();
          this.$refs.dialogReclamation.hide();
          this.search();
        });
    },
    openFilterPanel() {
      this.toggleReservationFilterList(this.filter, this.clickSearch, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleReservationFilterList(this.filter, this.search, this.resetFilter);
    },
    displaySublineStatus(r) {
      if (r.type === 'OWNER' || r.type === 'OWNER_CANCEL') return 'Hôte';
      if (r.type === 'BLOCKADE' || r.type === 'BLOCKADE_CANCEL') return 'Indisponible';
      return 'Voyageur';
    },
    displayMandatAvenantNum(item) {
      if (item.avenantNum1) return 'Mandat n° ' + item.avenantNum1;
      if (item.avenantNum2) return 'Avenant n° ' + item.avenantNum2;
      return '';
    },
  },
  computed: {},
};
</script>

<template>
  <GaView :ref="ref" :title="$t('reservation.list')">
    <template #action>
      <div class="d-flex justify-content-end">
        <PButton
          class="btn btn-primary"
          @click="openFilterPanel()"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          label="Filtrer">
        </PButton>
      </div>
    </template>

    <template #content>
      <DataTable
        ref="table"
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="50"
        :lazy="true"
        :totalRecords="this.totalItems"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        sort-mode="multiple"
        class="table pointer"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @row-click="rowClick($event)"
        @page="onPage($event)"
        @sort="onSort($event)">
        <template #empty>
          {{ $t('reservation.empty') }}
        </template>

        <Column :header="$t('reservation.localizer')" :sortable="true" sort-field="localizer">
          <template #body="slotProps">
            <span class="pointer">
              <router-link
                :to="{
                  name: 'reservationPage',
                  params: {reservationId: slotProps.data.id},
                }">
                {{
                  slotProps.data.localizer
                    ? slotProps.data.localizer
                    : slotProps.data.bookingCode + '-' + slotProps.data.agentLocalizator
                }}
              </router-link>
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.guest')">
          <template #body="slotProps">
            <span class="pointer">
              {{ slotProps.data.guestDisplay }}
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.avantioType')">
          <template #body="slotProps">
            <span class="pointer">
              {{ displayForSmilyStatusConst(slotProps.data.statusSmily) }}
              <br />
              <span style="font-size: 12px">{{ displaySublineStatus(slotProps.data) }}</span>
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.period')" :sortable="true" sort-field="date_start">
          <template #body="slotProps">
            <span class="pointer">
              {{ displayPeriodForReservation(slotProps.data) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.reservation_site')">
          <template #body="slotProps">
            <span class="pointer">
              {{ slotProps.data.reservationSite }}
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.avenantMandat')">
          <template #body="slotProps">
            <span class="pointer">
              {{ displayMandatAvenantNum(slotProps.data) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.managementStatus')" v-if="!this.isOwner()">
          <template #body="slotProps">
            <div>
              <div v-if="slotProps.data.dateStartLitige" :title="getLitigeStatusTooltip(slotProps.data)">
                <span class="pointer">
                  {{ $t(slotProps.data.dateEndLitige ? 'reservation.litigeEnded' : 'reservation.litigeStarted') }}
                </span>
              </div>
              <div v-if="slotProps.data.dateStartReclamation" :title="getReclamationStatusTooltip(slotProps.data)">
                <span class="pointer">
                  {{
                    $t(
                      slotProps.data.dateEndReclamation
                        ? 'reservation.reclamationEnded'
                        : 'reservation.reclamationStarted',
                    )
                  }}
                </span>
              </div>
            </div>
          </template>
        </Column>

        <Column
          :header="$t('reservation.action')"
          v-if="!this.isOwner() && !this.isPartenaire() && !this.isHousekeeper()">
          <template #body="slotProps">
            <div class="form-inline">
              <button
                class="btn btn-inverse-primary btn-xs"
                style="margin: 5px"
                @click="openLitigeDialog(slotProps.data)"
                v-if="!slotProps.data.dateEndLitige">
                {{ getLitigeDisplay(slotProps.data) }}
              </button>

              <button
                class="btn btn-inverse-primary btn-xs"
                style="margin: 5px"
                @click="openReclamationDialog(slotProps.data)"
                v-if="!slotProps.data.dateEndReclamation">
                {{ getReclamationDisplay(slotProps.data) }}
              </button>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra>
      <AkDialog ref="dialogLitige" :title="getLitigeDisplay(itemLitige)" width="500px" @validate="handleLitige()">
        <div class="form-row" v-if="itemLitige.dateStartLitige">
          <AkCalendar
            v-model="itemLitige.dateStartLitige"
            :label="$t('reservation.dateStartLitige')"
            :disabled="true"
            class-name="col-md-12" />
        </div>
        <div class="form-row">
          <AkCalendar
            v-model="itemLitige.dateLitige"
            :validator="v$.itemLitige.dateLitige"
            :label="$t(itemLitige.dateStartLitige ? 'reservation.dateEndLitige' : 'reservation.dateStartLitige')"
            :min-date="itemLitige.dateStartLitige ? itemLitige.dateStartLitige : null"
            class-name="col-md-12" />
        </div>
        <div class="form-row">
          <AkInputMoney
            v-model="itemLitige.amountLitigeEstimated"
            :label="$t('reservation.amountLitigeEstimated')"
            :disabled="this.itemLitige.estimated"
            :validator="v$.itemLitige.amountLitigeEstimated"
            class-name="col-md-12" />
        </div>
        <div class="form-row" v-if="itemLitige.dateStartLitige">
          <AkInputMoney
            v-model="itemLitige.amountLitigeReported"
            :label="$t('reservation.amountLitigeReported')"
            :validator="v$.itemLitige.amountLitigeReported"
            :required="true"
            class-name="col-md-12" />
        </div>

        <div class="form-row" v-if="itemLitige.dateStartLitige">
          <AkInputMoney
            v-model="itemLitige.amountLitigeGas"
            :label="$t('reservation.amountLitigeGas')"
            :validator="v$.itemLitige.amountLitigeGas"
            class-name="col-md-4" />
          <AkInputMoney
            v-model="itemLitige.amountLitigeGav"
            :label="$t('reservation.amountLitigeGav')"
            :validator="v$.itemLitige.amountLitigeGav"
            class-name="col-md-4" />
          <AkInputMoney
            v-model="itemLitige.amountLitigeOwner"
            :label="$t('reservation.amountLitigeOwner')"
            :validator="v$.itemLitige.amountLitigeOwner"
            class-name="col-md-4" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogReclamation"
        :title="getReclamationDisplay(itemReclamation)"
        width="500px"
        @validate="handleReclamation()">
        <div class="form-row" v-if="itemReclamation.dateStartReclamation">
          <AkCalendar
            v-model="itemReclamation.dateStartReclamation"
            :label="$t('reservation.dateStartReclamation')"
            :disabled="true"
            class-name="col-md-12" />
        </div>
        <div class="form-row">
          <AkCalendar
            v-model="itemReclamation.dateReclamation"
            :validator="v$.itemReclamation.dateReclamation"
            :label="
              $t(
                itemReclamation.dateStartReclamation
                  ? 'reservation.dateEndReclamation'
                  : 'reservation.dateStartReclamation',
              )
            "
            :min-date="itemReclamation.dateStartReclamation ? itemReclamation.dateStartReclamation : null"
            class-name="col-md-12" />
        </div>
        <div class="form-row">
          <AkInputMoney
            v-model="itemReclamation.amountReclamationEstimated"
            :label="$t('reservation.amountReclamationEstimated')"
            :disabled="this.itemReclamation.estimated"
            :validator="v$.itemReclamation.amountReclamationEstimated"
            class-name="col-md-12" />
        </div>
        <div class="form-row" v-if="itemReclamation.dateStartReclamation">
          <AkInputMoney
            v-model="itemReclamation.amountReclamationReported"
            :label="$t('reservation.amountReclamationReported')"
            :validator="v$.itemReclamation.amountReclamationReported"
            :required="true"
            class-name="col-md-12" />
        </div>

        <div class="form-row" v-if="itemReclamation.dateStartReclamation">
          <AkInputMoney
            v-model="itemReclamation.amountReclamationGas"
            :label="$t('reservation.amountReclamationGas')"
            :validator="v$.itemReclamation.amountReclamationGas"
            class-name="col-md-4" />
          <AkInputMoney
            v-model="itemReclamation.amountReclamationGav"
            :label="$t('reservation.amountReclamationGav')"
            :validator="v$.itemReclamation.amountReclamationGav"
            class-name="col-md-4" />
          <AkInputMoney
            v-model="itemReclamation.amountReclamationOwner"
            :label="$t('reservation.amountReclamationOwner')"
            :validator="v$.itemReclamation.amountReclamationOwner"
            class-name="col-md-4" />
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
