import {goBackTo} from '@utils';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [
        {
          name: 'Intervenant',
          id: 'intervenant',
        },
        {
          name: 'Photos',
          id: 'picture',
          hidden: this.hidePictureTab,
        },
        {
          name: 'Cahier des charges',
          id: 'cdc',
        },
      ],
    };
  },
  methods: {},
  computed: {
    hidePictureTab() {
      return this.mission === undefined || this.mission.imagesFicList.length === 0;
    },
  },
};
