export default {
  data() {
    return {
      paymentSourceConst: [
        {label: this.$t('payment.VACATION_CHECK'), value: 'VACATION_CHECK'},
        {label: this.$t('payment.TRANSFERT'), value: 'TRANSFERT'},
        {label: this.$t('payment.CREDIT_CARD'), value: 'CREDIT_CARD'},
        {label: this.$t('payment.VIRTUAL_CARD'), value: 'VIRTUAL_CARD'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForPaymentSourceConst(value) {
      let result = this.paymentSourceConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
