<script>
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import AkCheckbox from '@components/v2/input/AkCheckbox.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

import {email, required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import clientTypeConst from '@mixins/const/clientTypeConst';
import AkToggleButton from '../../components/v2/general/AkToggleButton.vue';
import {Icon} from '@iconify/vue';

export default {
  page: {
    title: 'Sandbox',
    meta: [{name: 'Sandbox', content: 'Sandbox'}],
  },
  setup() {
    return {v$: useVuelidate()};
  },
  mixins: [clientTypeConst],
  components: {Icon, AkToggleButton, AkInputSwitch, AkCheckbox, AkDropdown, AkInputText},
  validations() {
    return {
      validatorTest: {required, email},
      clientType: {required},
    };
  },
  props: {},
  data() {
    return {
      enabled: false,
      validatorTest: undefined,
      clientType: undefined,
    };
  },
};
</script>

<template>
  <div class="flex flex-col">
    <div class="flex gap-3">
      <AkInputText label="Input Text" />
      <AkInputText label="Input Text disabled" :disabled="true" v-model="validatorTest" />
      <AkInputText :display-label="false" label="Input Text sans label" />
      <AkInputText v-model="validatorTest" label="Input Text" :submitted="true" :validator="v$.validatorTest" />
      <AkInputText
        label="Input Text avec lien externe"
        :display-link="true"
        :link-external="{path: 'http://www.google.fr', icon: 'iconamoon:link-external-light'}" />
      <AkInputText
        label="Input Text avec lien interne"
        :display-link="true"
        :link="{route: 'home', icon: 'iconamoon:link-external-light'}" />
    </div>

    <div class="flex gap-3">
      <AkDropdown v-model="clientType" :label="$t('client.type')" :options="clientTypeConst" />
      <AkDropdown
        v-model="clientType"
        :disabled="true"
        :link="{route: 'home', icon: 'iconamoon:link-external-light'}"
        :label="$t('client.type')"
        :options="clientTypeConst" />
      <AkDropdown
        v-model="clientType"
        :submitted="true"
        :validator="v$.clientType"
        :label="$t('client.type')"
        :options="clientTypeConst" />
    </div>
    <div class="flex gap-3">
      <AkCheckbox v-model="enabled" :label="$t('user.enabled')" />
      <AkCheckbox v-model="enabled" :disabled="true" :label="$t('user.enabled')" />
    </div>
    <div class="flex gap-3">
      <AkInputSwitch v-model="enabled" :label="$t('company.zoneUrbaine')" />

      <AkInputSwitch v-model="enabled" :inline="true" :labelBefore="true" :label="$t('company.zoneUrbaine')" />
      <AkInputSwitch v-model="enabled" :inline="true" :labelBefore="false" :label="$t('company.zoneUrbaine')" />
      <AkInputSwitch
        v-model="enabled"
        :inline="true"
        :disabled="true"
        :labelBefore="false"
        :label="$t('company.zoneUrbaine')" />
    </div>
    <div class="flex gap-3 items-start">
      <AkToggleButton label="Test" v-model="enabled"> </AkToggleButton>

      <AkToggleButton label="Disabled" v-model="enabled" :disabled="true"> </AkToggleButton>

      <AkToggleButton v-model="enabled" :large="true">
        <div class="flex flex-col items-center gap-2">
          <Icon icon="mi:user" />
          <span class="font-inter text-gray font-medium text-sm">Utilisateur</span>
        </div>
      </AkToggleButton>
    </div>
  </div>
</template>
