<script>
/* COMPOSANTS */
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';

/* SERVICES */
import companyService from '@services/companyService';

export default {
  components: {
    AkAutocompleteFilter,
    AkListFilter,
  },
  mixins: [accommodationAutocompleteMixin],
  data() {
    return {
      list: [],
      loading: true,
      filter: {},
      companies: [],
    };
  },
  mounted() {
    companyService.companies().then((data) => {
      this.companies = data;
    });
  },
  emits: ['search'],
  methods: {
    search() {
      this.$emit('search', this.filter);
    },
  },
};
</script>

<template>
  <AkAutocompleteFilter
    v-model="filter.accommodationId"
    :init-method="autocompleteAccommodationById"
    :label="$t('partenaire.accommodation')"
    :search-label="autocompleteAccommodationQuery"
    :search-method="autocompleteAccommodation"
    direction="right"
    item-display="display"
    item-value="id"
    @search="search" />
  <AkListFilter
    v-model="filter.userEnabled"
    :items="[
      {name: $t('enabled'), id: true},
      {name: $t('disabled'), id: false},
    ]"
    :label="$t('partenaire.enabledState')"
    direction="right"
    display-filter
    @search="search" />
  <AkListFilter
    v-model="filter.companyId"
    :items="companies"
    :label="$t('employee.company')"
    direction="right"
    display-filter
    @search="search" />
</template>
