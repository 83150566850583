export default {
  methods: {
    getMaxFileUpload() {
      return Variable.MAX_FILE_UPLOAD;
    },
  },
};

export const Variable = Object.freeze({
  MAX_FILE_UPLOAD: 10000000,
});
