<script>
import prettyBytes from 'pretty-bytes';

/* COMPOSANTS */
import FileUpload from 'primevue/fileupload';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';
import envConst from '@mixins/const/envConst';

/* SERVICES */
import ficService from '@services/ficService';

export default {
  components: {FileUpload},
  mixins: [messageMixin, envConst, loaderMixin],
  props: {
    modelValue: {
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
    tableId: {
      type: Number,
      required: true,
    },
    ficType: {
      type: String,
      required: false,
      default: null,
    },
    ficPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    chooseLabel: {
      type: String,
      required: false,
      default: 'Choisir un fichier',
    },
    title: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      required: false,
      default: '',
    },
    customDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondOption: {
      type: Object,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue', 'delete-item'],
  data() {
    return {
      base64: undefined,
    };
  },
  watch: {
    ficPreview: function (newVal, oldVal) {
      if (!this.ficPreview) this.base64 = undefined;
      else this.getPreview();
    },
  },
  mounted() {
    if (this.ficPreview) {
      this.getPreview();
    }
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    hasCurrentValue() {
      return this.currentValue !== undefined && this.currentValue !== null;
    },
    fileSize() {
      if (!this.hasCurrentValue) {
        return '';
      }
      return prettyBytes(this.currentValue.size, {locale: 'en', maximumFractionDigits: 1});
    },
  },
  methods: {
    delete() {
      this.$emit('delete-item');
    },
    getFileIcon() {
      return 'ga-document';
    },
    getFileName() {
      if (!this.hasCurrentValue) return '';
      return this.currentValue.fileName;
    },
    getColorIcon() {
      return 'text-gray';
    },
    downloadFile(isPreview) {
      if (!this.hasCurrentValue) return;
      ficService.download(this.currentValue, isPreview).catch((e) => {
        if (e.response.status === 404) {
          this.addError("Le fichier n'existe pas !");
        }
      });
    },
    uploadFile() {
      this.$refs.uploader.$refs.fileInput.click();
    },
    clearFile() {
      this.$refs.uploader.clear();
      if (this.customDelete) {
        this.$emit('delete-item');
      } else {
        ficService
          .delete(this.currentValue)
          .then(() => {
            this.currentValue = undefined;
            this.success('Le fichier a bien été supprimé');
          })
          .catch((e) => {
            this.addError('Une erreur est survenue');
          });
      }
    },
    selectFiles(event) {
      if (event.files) {
        let fic = {};
        fic.ficType = this.ficType;
        fic.tableName = this.tableName;
        fic.tableId = this.tableId;
        this.showTotalLoaderWithAfter(this.$t('onboarding.document_upload_in_progress'));
        ficService
          .createItem(fic, event.files[0])
          .then((data) => {
            this.currentValue = data;
            this.success('Fichier uploadé');
          })
          .catch((e) => {
            this.addError('Une erreur est survenue');
          })
          .finally(() => {
            this.hideLoader();
          });
      }
    },
    getPreview() {
      ficService
        .preview(this.currentValue)
        .then((data) => {
          this.base64 = data;
        })
        .catch((e) => {
          // Catch l'erreur pour ne pas l'afficher côté IHM
        });
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-2">
    <div
      class="flex gap-2 items-center p-2 rounded-lg bg-detailsBg"
      :class="{'flex-col': ficPreview, 'flex-row': !ficPreview}">
      <template v-if="!ficPreview">
        <div v-if="hasCurrentValue">
          <i :class="[getFileIcon(), getColorIcon()]" class="ga-icon text-3xl" />
        </div>
        <div
          v-if="hasCurrentValue"
          :class="{disabled: disabled}"
          class="truncate flex flex-col flex-1 hover:cursor-pointer gap-1"
          @click="downloadFile(true)">
          <span class="pointer text-primary font-medium truncate text-base">{{ getFileName() }}</span>
          <span class="text-gray font-normal text-sm">{{ fileSize }}</span>
        </div>
        <div v-if="hasCurrentValue" class="flex gap-1">
          <span class="hover:cursor-pointer" @click="downloadFile(false)">
            <i class="ga-icon ga-download text-primary text-2xl" />
          </span>
          <span v-if="!disabled" class="hover:cursor-pointer" @click="clearFile()"
            ><i class="ga-icon ga-trash text-red text-2xl"
          /></span>
        </div>
      </template>
      <template v-else>
        <div
          v-if="hasCurrentValue"
          :class="{disabled: disabled}"
          class="truncate flex flex-col w-full hover:cursor-pointer gap-1">
          <img v-if="ficPreview && base64" :src="base64" class="" @click="downloadFile(true)" />
          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-col gap-1 flex-1">
              <span class="pointer text-primary font-medium text-base break-all whitespace-break-spaces">{{
                getFileName()
              }}</span>
              <span class="text-gray font-normal text-sm">{{ fileSize }}</span>
            </div>
            <div class="flex gap-1">
              <span class="hover:cursor-pointer" @click="downloadFile(false)">
                <i class="ga-icon ga-download text-primary text-2xl" />
              </span>
              <span v-if="!disabled" class="hover:cursor-pointer" @click="clearFile()"
                ><i class="ga-icon ga-trash text-red text-2xl"
              /></span>
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="!hasCurrentValue && !disabled"
        class="flex items-center gap-2 hover:cursor-pointer"
        @click="uploadFile()">
        <i :class="[getColorIcon()]" class="ga-icon ga-plus text-2xl" />
        <div>
          <span class="text-gray">{{ chooseLabel }}</span>
        </div>
      </div>
      <div v-if="!hasCurrentValue && disabled" class="flex gap-2 items-center hover:cursor-pointer disabled">
        <i :class="[getColorIcon()]" class="ga-icon ga-plus text-2xl" />
        <div>
          <span class="text-gray">{{ chooseLabel }}</span>
        </div>
      </div>
    </div>
    <FileUpload
      v-if="!disabled"
      ref="uploader"
      :accept="accept"
      :auto="true"
      :choose-label="chooseLabel"
      :custom-upload="true"
      :invalidFileSizeMessage="$t('file.err_size')"
      :maxFileSize="getMaxFileUpload()"
      :multiple="false"
      :show-upload-button="false"
      class="form-control hidden"
      mode="basic"
      name="uploader"
      @select="(event) => selectFiles(event)" />
  </div>
</template>
