<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';
import moment from 'moment';

/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkLoader from '@components/general/AkLoader.vue';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkCheckbox from '@components/v2/input/AkCheckbox';
import AkButtonValidation from '@components/v2/general/AkButtonValidation';

import PrestationBadge from '@components/general/PrestationBadge.vue';
import AkMissionOrderForm from '@views/missionordercreate/components/AkMissionOrderForm.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import messageMixin from '@mixins/messageMixin';
import backMixin from '@mixins/backMixin';
import utilsMixin from '@mixins/utilsMixin';
import missionMixin from '@mixins/missionMixin';

import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';

/* SERVICES */
import missionOrderCreateService from '@services/missionOrderCreateService';
import documentModelService from '@services/documentModelService';

export default {
  components: {
    GaView,
    AkAutocomplete,
    AkLoader,
    AkPictureAccommodation,
    AkMissionOrderForm,
    AkCheckbox,
    PrestationBadge,
    AkFormSubmitted,
    AkButtonValidation,
  },
  mixins: [
    randomRef,
    permissionsMixin,
    messageMixin,
    backMixin,
    utilsMixin,
    missionMixin,
    accommodationAutocompleteMixin,
    reservationAutocompleteMixin,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      accommodationId: {required},
      reservationId: {required: requiredIf(this.isCheckInOutSelectedWithoutReservation)},
    };
  },
  metaInfo() {
    return {
      title: 'mission_order.title_create',
    };
  },
  data() {
    return {
      accommodationId: null,
      reservationId: null,
      fromWaitingOrdersList: false,
      accommodationDisplay: '',
      reservationDisplay: '',
      documentModelList: [],
      submitted: false,
      missions: [
        {
          type: 'CHECK_IN',
        },
        {
          type: 'CHECK_OUT',
        },
        {
          type: 'CLEANING',
        },
        {
          type: 'CLEANING_AFTER',
        },
        {
          type: 'CLEANING_BEFORE',
        },
        {
          type: 'RUNNING',
        },
        {
          type: 'QUALITY_CONTROL',
        },
        {
          type: 'MAINTENANCE',
        },
      ],
      cdc: [],
      accommodation: undefined,
      allUpsells: [],
    };
  },
  mounted() {
    this.initGoBack();
    this.accommodationId = parseInt(this.$route.params.accommodationId) || null;
    this.reservationId = this.$route.query.reservationId ?? null;
    this.fromWaitingOrdersList = this.accommodationId != null && this.reservationId != null;

    // Si on vient de la liste 'En attente d'ordre' via le bouton 'Créer des missions' de la sidebar
    if (this.fromWaitingOrdersList) {
      this.autocompleteAccommodationById(this.accommodationId).then(
        (data) => (this.accommodationDisplay = data.display),
      );
      this.autocompleteReservationById(this.reservationId).then((data) => (this.reservationDisplay = data.display));
    }

    // Récupérer les modèles de document
    documentModelService
      .list({
        type: 'PRESTATION',
      })
      .then((data) => {
        this.documentModelList = data;
        // Récupérer les informations du logement
        this.getAccommodation();
      })
      .catch((e) => {
        this.addError(e.response.data.error);
      });
  },
  computed: {
    housekeeperPrestationTypes() {
      return this.accommodation?.housekeeperPrestations;
    },
    cdcList() {
      return this.accommodation?.cdcList;
    },
    hasTabSelected() {
      return this.missions.find((mission) => mission.selected);
    },
    isCheckInMissionSelected() {
      let mission = this.missions.find((mission) => mission.type === 'CHECK_IN');
      return mission.selected;
    },
    isCheckOutMissionSelected() {
      let mission = this.missions.find((mission) => mission.type === 'CHECK_OUT');
      return mission.selected;
    },
    isCheckInOutSelectedWithoutReservation() {
      return (this.isCheckInMissionSelected || this.isCheckOutMissionSelected) && !this.reservationId;
    },
  },
  methods: {
    // SERVICE CALLS

    getAccommodation() {
      if (!this.accommodationId) {
        // Pas de logement sélectionné, on ne peut pas faire l'appel
        this.accommodation = undefined;
        this.$refs.reservationAutocomplete.reset();
        return;
      }

      if (this.accommodation && this.accommodationId !== this.accommodation.id) {
        // Un logement différent a été sélectionné, on vide le champ "Réservation"
        this.$refs.reservationAutocomplete.reset();
      }

      this.$refs.accommodationLoader.load();
      this.$refs.missionLoader.load();

      missionOrderCreateService
        .accommodationMissionOrder(this.accommodationId, {reservationId: this.reservationId})
        .then((data) => {
          this.accommodation = data;

          // Copier les extras associés au logement dans le tableau global
          this.allUpsells = this.accommodation.upsells?.slice(0);

          // Mise à jour des missions
          this.updateMissions();
        })
        .finally(() => {
          this.$refs.accommodationLoader.hide();
          this.$refs.missionLoader.hide();
        });
    },

    updateMissions() {
      for (let mission of this.missions) {
        // Init
        mission.selected = false;
        mission.date = '';
        mission.comments = [''];
        mission.numberOfPresta = 1;
        mission.documentModelId = undefined;
        mission.upsells = [];
        mission.assignedStaff = undefined;
        mission.prestationOffers = [{}];

        // Modèles de document (contrat)
        mission.documentModelList = this.documentModelList.filter((doc) => doc.subType === mission.type);

        // Déterminer si la mission est gérée par un concierge
        mission.isAssignedToHousekeeper = this.housekeeperPrestationTypes?.includes(mission.type);

        // Récupérer le prix et la durée des missions depuis le CDC du logement
        let cdc = this.cdcList.find((cdc) => cdc.type === mission.type);

        mission.duration = this.computeDuration(cdc.duration);
        mission.prestationOffers[0].priceHt = cdc.price;

        if (this.isTypeCheckin(mission.type)) {
          mission.receptionCheckinType = this.accommodation.receptionCheckinType;
          mission.startHour = '16:00';
        } else if (this.isTypeCheckout(mission.type)) {
          mission.receptionCheckoutType = this.accommodation.receptionCheckoutType;
          mission.startHour = '10:00';
        }
      }
    },

    createMissionOrders() {
      this.submitted = true;
      this.$refs.btnValid.load();
      this.v$.$touch();

      // Bloquer la création si  :
      // - pas de numéro de réservation renseigné pour des missions Check-in et/ou Check-out
      // - pas de mission sélectionnée
      // - champs obligatoires non saisis

      if (this.v$.$error || !this.hasTabSelected || this.isCheckInOutSelectedWithoutReservation) {
        this.$refs.btnValid.init();
        let msg = this.$t('mission_order.error_missing_fields');
        if (this.accommodationId && !this.hasTabSelected) {
          msg = "Aucune mission n'a été sélectionnée";
          this.submitted = false;
        } else if (this.isCheckInOutSelectedWithoutReservation) msg = this.$t('mission_order.error_no_reservation');
        this.addError(msg);
        return;
      }

      let missionOrders = [];

      this.missions.forEach((mission) => {
        if (mission.selected) {
          let missionOrder = this.createMissionOrder(mission);
          missionOrders.push(missionOrder);
        }
      });

      missionOrderCreateService
        .create(missionOrders)
        .then((data) => {
          this.success(this.$t('mission_order.create_success'));
          this.$router.push({name: 'waiting-orders'});
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.$refs.btnValid.init();
        });
    },

    createMissionOrder(mission) {
      const cdc = this.cdcList.find((cdc) => cdc.type === mission.type);
      const cdcId = cdc ? cdc.id : null;

      const documentModelId = mission.documentModelId;
      mission.prestationOffers.forEach((element) => {
        element.documentModelId = documentModelId;
      });

      // Formatage des dates
      const dateStart = this.compileStartDate(mission.date, mission.startHour);
      const dateEnd = this.compileEndDate(dateStart, mission.duration);
      // Si interne => on ignore la date de début
      const isIntern = this.isAssignedStaffIntern(mission);

      // Extras
      const upsells = this.allUpsells?.filter((u) => u.missionType === mission.type);

      const presta = {
        type: mission.type,
        accommodationId: parseInt(this.accommodationId),
        reservationId: parseInt(this.reservationId),
        cdcId: cdcId,
        assignedStaff: mission.assignedStaff,
        dateStartHourIgnored: isIntern,
        dateStart: dateStart,
        dateEnd: dateEnd,
        prestationOffers: mission.prestationOffers,
        upsells: upsells,
      };

      return presta;
    },

    // COMPILE DATE

    compileStartDate(date, time) {
      let hours;
      let minutes;
      if (typeof time === 'string') {
        hours = time.substring(0, 2);
        minutes = time.substring(3, 5);
      } else {
        hours = time ? time.getHours() : 0;
        minutes = time ? time.getMinutes() : 0;
      }
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
    },
    compileEndDate(startDate, duration) {
      let momentDuration = moment.duration({h: duration.getHours(), m: duration.getMinutes()});
      const endDate = new Date(moment(startDate.getTime()).add(momentDuration));
      return endDate;
    },

    computeDuration(cdcDuration) {
      // Pas de durée définie dans le cahier des charges, mettre par défaut 60 minutes
      if (!cdcDuration || cdcDuration < 0) return new Date(2000, 1, 1, 1, 0);
      let hours = Math.floor(cdcDuration / 60);
      let minutes = cdcDuration % 60;
      return new Date(2000, 1, 1, hours, minutes);
    },

    checkItem(id, missionType) {
      // Recherche l'upsell qui a été coché pour mettre à jour la liste globale
      let idx = this.allUpsells.findIndex((u) => u.id === id);
      this.allUpsells[idx].missionType = missionType;
    },

    showMission(mission) {
      if (this.isTypeCheckin(mission.type)) return mission.receptionCheckinType === 'PHYSICAL';
      if (this.isTypeCheckout(mission.type)) return mission.receptionCheckoutType === 'PHYSICAL';
      return true;
    },
  },
};
</script>

<template>
  <GaView details>
    <!-- CONTENT -->

    <template #content>
      <AkFormSubmitted :submitted="this.submitted" reference="form" @submit="createMissionOrders()">
        <!-- Bloc informations logement + réservation -->
        <div class="flex md:flex-row flex-col mb-4 gap-4 bg-white p-4 rounded-lg">
          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <template v-if="this.fromWaitingOrdersList">
              <!-- Reprise du style d'un champ autocomplete pour garder la mise en forme mais autoriser la sélection du texte -->
              <div class="w-full text-dark text-base font-inter">
                <div
                  class="w-full py-[14px] px-[18px] rounded-lg border-solid border-2 border-[#f1f2f5cc] bg-[#f1f2f5cc] font-bold">
                  {{ this.accommodationDisplay }}
                </div>
              </div>
              <div class="w-full text-dark text-base font-inter">
                <div
                  class="w-full py-[14px] px-[18px] rounded-lg border-solid border-2 border-[#f1f2f5cc] bg-[#f1f2f5cc] font-bold">
                  {{ this.reservationDisplay }}
                </div>
              </div>
            </template>
            <template v-else>
              <AkAutocomplete
                ref="accommodationAutocomplete"
                v-model="accommodationId"
                :placeholder="$t('accommodation_title')"
                option-value="id"
                option-label="display"
                :search-method="this.autocompleteAccommodation"
                :search-label="this.autocompleteAccommodationQuery"
                :init-method="this.autocompleteAccommodationById"
                @item-select="this.getAccommodation()"
                :validator="v$.accommodationId" />
              <AkAutocomplete
                ref="reservationAutocomplete"
                v-model="reservationId"
                :disabled="!this.accommodationId"
                :placeholder="$t('reservation.one')"
                option-value="id"
                option-label="display"
                :init-method="this.autocompleteReservationById"
                :search-method="this.autocompleteReservation"
                :search-object="{accommodationIds: [this.accommodationId]}"
                @item-select="this.getAccommodation()"
                :validator="v$.reservationId" />
            </template>
          </div>

          <!-- Informations du logement -->
          <div class="text-center w-full md:w-1/2">
            <AkLoader ref="accommodationLoader" :scale="2" color="var(--color-primary)" message="Récupération en cours">
              <template #content>
                <AkPictureAccommodation
                  v-if="this.accommodation"
                  disabled-link
                  :id="this.accommodation.id"
                  :md5="this.accommodation.accommodationPicture"
                  height="150"
                  cover
                  :name="this.accommodation.addressDisplay" />
              </template>
            </AkLoader>
          </div>
        </div>

        <!-- Boucler sur les missions pour créer les blocs de chaque type -->
        <AkLoader ref="missionLoader" :scale="2" color="var(--color-primary)" message="Récupération en cours">
          <template #content>
            <div v-if="this.accommodation">
              <template v-for="(mission, idx) in this.missions" :key="mission">
                <div class="flex flex-col gap-4 w-full mb-4 p-6 bg-white rounded-lg" v-if="showMission(mission)">
                  <div class="flex justify-start items-center gap-4">
                    <AkCheckbox v-model="mission.selected" label="" value="" />
                    <PrestationBadge :type="mission.type" />
                  </div>
                  <AkMissionOrderForm
                    v-if="mission.selected"
                    v-model="this.missions[idx]"
                    :upsells="this.allUpsells"
                    @checked-upsell="checkItem" />
                </div>
              </template>
            </div>
          </template>
        </AkLoader>

        <div class="flex md:justify-end justify-center items-center gap-6">
          <AkButtonValidation
            class="btn-primary-gradient"
            @action="this.createMissionOrders"
            :label="$t('mission_order.create')"
            ref="btnValid">
          </AkButtonValidation>
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
