<script>
import {useTabStore} from '@store/tabStore';
import {PermissionMap} from '@mixins/const/permissionConst';

/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import StayRateConsultation from '@views/accounting/visitortax/tabs/StayRateConsultation.vue';
import StayRateTransfert from '@views/accounting/visitortax/tabs/StayRateTransfert.vue';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import stayRatesTabMixin from '@mixins/tab/stayRatesTabMixin';
import AkButtonAction from '../../../components/v2/general/AkButtonAction.vue';

/* SERVICES */

export default {
  components: {
    AkButtonAction,
    GaView,
    StayRateTransfert,
    StayRateConsultation,
    AkButtonValidation,
  },
  mixins: [utilsMixin, permissionsMixin, stayRatesTabMixin],
  metaInfo() {
    return {
      title: 'accounting.visitor_tax',
    };
  },
  data() {
    return {
      tabSelected: undefined,
    };
  },
  mounted() {
    this.tabSelected = useTabStore().getTabActive('StayRatePage', undefined);
  },
  computed: {
    activeTab() {
      if (this.tabSelected) return this.tabSelected;
      return 'stayRateConsultation';
    },

    hasManagePermission() {
      return this.hasPermission(PermissionMap.TDS.MANAGE);
    },
  },
  methods: {
    displayTab(tab) {
      useTabStore().setTabActive('StayRatePage', tab.id);
      this.tabSelected = tab.id;
    },

    // DOWNLOAD

    download() {
      this.$refs.stayRateConsultation.download(this.$refs.btnDownload);
    },

    // FLAG

    flagStayrates() {
      this.$refs.stayRateConsultation.flagStayrates(this.$refs.btnSubmit);
    },
  },
};
</script>
<template>
  <GaView>
    <template #action>
      <div class="flex justify-end gap-4" v-if="isStayRateConsultation">
        <AkButtonAction secondary :label="$t('payment.download')" little @click="download()" />

        <AkButtonValidation
          v-if="isStayRateConsultation && this.hasManagePermission"
          ref="btnSubmit"
          :label="$t('visitor_tax.submit')"
          btn-class="btn-primary-gradient xs"
          @action="flagStayrates" />
      </div>
    </template>
    <template #content>
      <StayRateConsultation
        ref="stayRateConsultation"
        v-if="isStayRateConsultation"
        @tab="this.displayTab"
        :tabs="tabs" />
      <StayRateTransfert v-if="isStayRateTransfert" @tab="this.displayTab" :tabs="tabs" />
    </template>
  </GaView>
</template>
