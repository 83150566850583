export default {
  data() {
    return {
      reservationTypesConst: [
        {label: this.$t('reservation.reservationType_guest'), value: 'GUEST'},
        {label: this.$t('reservation.reservationType_guest_cancel'), value: 'GUEST_CANCEL'},
        {label: this.$t('reservation.reservationType_owner'), value: 'OWNER'},
        {label: this.$t('reservation.reservationType_owner_cancel'), value: 'OWNER_CANCEL'},
        {label: this.$t('reservation.reservationType_blockade'), value: 'BLOCKADE'},
        {label: this.$t('reservation.reservationType_blockade_cancel'), value: 'BLOCKADE_CANCEL'},
        {label: this.$t('undefined'), value: undefined},
      ],
      reservationTypesPlanningConst: [
        {label: this.$t('reservation.reservationType_guest'), value: 'GUEST'},
        {label: this.$t('reservation.reservationType_owner'), value: 'OWNER'},
        {label: this.$t('reservation.reservationType_blockade'), value: 'BLOCKADE'},
        {label: "Demande d'informations", value: 'TENTATIVE'},
      ],
      reservationTypesConstForMissionDashboard: [
        {label: this.$t('reservation.reservationType_guest'), value: 'GUEST'},
        {label: this.$t('reservation.reservationType_owner'), value: 'OWNER'},
      ],
    };
  },
  methods: {
    displayForReservationTypeConst(value) {
      let result = this.reservationTypesConst.find((el) => el.value === value);
      return result ? result.label : '';
    },

    getReservationTypesConst(isOwner) {
      if (isOwner)
        return this.reservationTypesConst.filter(
          (el) => el.value === 'OWNER' || el.value === 'OWNER_CANCEL' || el.value === 'BLOCKADE',
        );
      return this.reservationTypesConst;
    },
    getReservationTypesConstFilter() {
      var tab = this.reservationTypesConst.filter((el) => el.value !== undefined);
      tab.push({label: "Demande d'informations", value: 'TENTATIVE'});
      return tab;
    },
  },
};
