import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  suivi(filter) {
    return Api()
      .get('/missionsuivi', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  formatRes(e) {
    return e;
  },
};
