<script>
/* COMPOSANTS */
import GaTabView from '@components/layout/GaTabView';
import MandatListAdmin from '@views/mandat/tabs/MandatListAdmin';
import AkLoader from '@components/general/AkLoader';
import AvenantList from '@views/mandat/tabs/AvenantList';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import mandatTabMixin from '@mixins/tab/mandatTabMixin';

/* SERVICES */

export default {
  components: {
    MandatListAdmin,
    AvenantList,
    AkLoader,
    GaTabView,
  },
  mixins: [messageMixin, utilsMixin, loaderMixin, permissionsMixin, mandatTabMixin],
  metaInfo() {
    return {
      title: 'settings.mandats',
    };
  },
  data() {
    return {
      activeTab: undefined,
    };
  },
  computed: {},
  mounted() {
    this.$refs.loader.load();
    if (this.$route.hash) {
      this.activeTab = this.$route.hash.slice(1);
    }
    this.$refs.loader.hide();
  },
  methods: {},
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
    <template #content>
      <GaTabView :firstTab="this.activeTab" :title="$t('settings.mandats')" :tabs="tabs">
        <template #mandatList>
          <MandatListAdmin />
        </template>
        <template #avenantList>
          <AvenantList />
        </template>
      </GaTabView>
    </template>
  </AkLoader>
</template>
<style></style>
