<script>
import moment from 'moment/moment';
import {Icon} from '@iconify/vue';

/* MIXINS */
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import AkReservationSlot from '@components/general/AkReservationSlot.vue';

export default {
  components: {AkReservationSlot},
  mixins: [blockadeReasonsConst, reservationTypesConst, permissionsMixin],
  props: {
    slots: {
      type: Array,
      required: true,
    },
    reservations: {
      type: Array,
      required: true,
    },
    accommodation: {
      type: Object,
      required: true,
    },
  },
  emits: ['tooltip', 'edit-reservation', 'delete-reservation'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    typeCss(r) {
      if (r === undefined) return '';
      if (this.isOwner()) {
        if (r.statusSmily === 'Unavailable' && r.type === 'OWNER') return 'owner';
        if (r.statusSmily === 'Unavailable' && r.type === 'BLOCKADE') return 'blockade';
        if (r.statusSmily === 'Booked') return 'occuped';
        return '';
      }
      if (r.statusSmily === 'Unavailable' && r.type === 'OWNER') return 'owner';
      if (r.statusSmily === 'Unavailable' && r.type === 'BLOCKADE') return 'blockade';
      if (r.statusSmily === 'Booked') return 'confirmada';
      if (r.statusSmily === 'Tentative') return 'peticion';
      return '';
    },
    editReservation(data) {
      this.$emit('edit-reservation', data);
    },
    deleteReservation(data) {
      this.$emit('delete-reservation', data);
    },
  },
  computed: {
    displayableReservations() {
      if (this.isGav()) {
        return this.reservations.filter(
          (o) =>
            o.hidden !== true &&
            this.typeCss(o) !== '' &&
            (o.statusSmily === 'Booked' || o.statusSmily === 'Unavailable'),
        );
      }
      return this.reservations.filter((o) => o.hidden !== true && this.typeCss(o) !== '');
    },
    firstDate() {
      return moment(this.slots[0].date);
    },
    lastDate() {
      return moment(this.slots[this.slots.length - 1].date);
    },
  },
};
</script>

<template>
  <AkReservationSlot
    v-for="(r, idx) in this.displayableReservations"
    :key="idx"
    @editReservation="this.editReservation"
    @deleteReservation="this.deleteReservation"
    :first-date="this.firstDate"
    :last-date="this.lastDate"
    :reservation="this.displayableReservations[idx]">
  </AkReservationSlot>
</template>
