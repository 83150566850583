export default {
  data() {
    return {
      accommodationTypeConst: [
        {label: this.$t('accommodation.apartment'), value: 'apartment'},
        {label: this.$t('accommodation.bastide'), value: 'bastide'},
        {label: this.$t('accommodation.boat'), value: 'boat'},
        {label: this.$t('accommodation.bungalow'), value: 'bungalow'},
        {label: this.$t('accommodation.bed_breakfast'), value: 'bed-and-breakfast'},
        {label: this.$t('accommodation.cabin'), value: 'cabin'},
        {label: this.$t('accommodation.castle'), value: 'castle'},
        {label: this.$t('accommodation.cave_house'), value: 'cave'},
        {label: this.$t('accommodation.chalet'), value: 'chalet'},
        {label: this.$t('accommodation.condominium'), value: 'condominium'},
        {label: this.$t('accommodation.cottage'), value: 'cottage'},
        {label: this.$t('accommodation.dormitory'), value: 'dormitory'},
        {label: this.$t('accommodation.earth-house'), value: 'earth-house'},
        {label: this.$t('accommodation.farmhouse'), value: 'farmhouse'},
        {label: this.$t('accommodation.gite'), value: 'gite'},
        {label: this.$t('accommodation.holiday-home'), value: 'holiday-home'},
        {label: this.$t('accommodation.house'), value: 'house'},
        {label: this.$t('accommodation.hut'), value: 'hut'},
        {label: this.$t('accommodation.igloo'), value: 'igloo'},
        {label: this.$t('accommodation.island'), value: 'island'},
        {label: this.$t('accommodation.lighthouse'), value: 'lighthouse'},
        {label: this.$t('accommodation.loft'), value: 'loft'},
        {label: this.$t('accommodation.longere'), value: 'longere'},
        {label: this.$t('accommodation.manor'), value: 'manor'},
        {label: this.$t('accommodation.mas'), value: 'mas'},
        {label: this.$t('accommodation.mill'), value: 'mill'},
        {label: this.$t('accommodation.plane'), value: 'plane'},
        {label: this.$t('accommodation.private-room'), value: 'private-room'},
        {label: this.$t('accommodation.private-room-in-apartment'), value: 'private-room-in-apartment'},
        {label: this.$t('accommodation.private-room-in-house'), value: 'private-room-in-house'},
        {label: this.$t('accommodation.recreational-vehicle'), value: 'recreational-vehicle'},
        {label: this.$t('accommodation.studio'), value: 'studio'},
        {label: this.$t('accommodation.tent'), value: 'tent'},
        {label: this.$t('accommodation.tipi'), value: 'tipi'},
        {label: this.$t('accommodation.train'), value: 'train'},
        {label: this.$t('accommodation.treehouse'), value: 'treehouse'},
        {label: this.$t('accommodation.villa'), value: 'villa'},
        {label: this.$t('accommodation.yurt'), value: 'yurt'},
        {label: this.$t('undefined'), value: undefined},
      ],
      accommodationTypeConstForProspect: [
        {label: this.$t('accommodation.house'), value: 'HOUSE'},
        {label: this.$t('accommodation.apartment'), value: 'APARTMENT'},
        {label: this.$t('accommodation.studio'), value: 'STUDIO'},
        {label: this.$t('accommodation.villa'), value: 'VILLA'},
        {label: this.$t('accommodation.apartHotel'), value: 'APART_HOTEL'},
        {label: this.$t('accommodation.boat'), value: 'BOAT'},
        {label: this.$t('accommodation.bungalow'), value: 'BUNGALOW'},
        {label: this.$t('accommodation.lodge'), value: 'LODGE'},
        {label: this.$t('accommodation.guestroom'), value: 'GUESTROOM'},
        {label: this.$t('accommodation.domain'), value: 'DOMAIN'},
        {label: this.$t('accommodation.farmhouse'), value: 'FARMHOUSE'},
        {label: this.$t('accommodation.park'), value: 'PARK'},
        {label: this.$t('accommodation.cottage'), value: 'COTTAGE'},
        {label: this.$t('accommodation.terracedHouse'), value: 'TERRACED_HOUSE'},
        {label: this.$t('accommodation.mobilHome'), value: 'MOBIL_HOME'},
        {label: this.$t('accommodation.residence'), value: 'RESIDENCE'},
        {label: this.$t('accommodation.trullo'), value: 'TRULLO'},
        {label: this.$t('accommodation.yurt'), value: 'YURT'},
        {label: this.$t('accommodation.treeHouse'), value: 'TREE_HOUSE'},
        {label: this.$t('accommodation.barge'), value: 'BARGE'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForAccommodationTypeConst(value) {
      let result = this.accommodationTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    getAccommodationTypeConstForProspect() {
      return this.accommodationTypeConstForProspect;
    },
    getAccommodationTypeConstOrder() {
      return this.accommodationTypeConst.sort((a, b) => {
        if (a.value === null) return -1;
        if (b.value === null) return 1;
        return a.label.localeCompare(b.label);
      }); // b - a for reverse sort
    },
  },
};
