import countryConst from '@mixins/const/countryConst';

export default {
  mixins: [countryConst],
  methods: {
    formatAddr(item) {
      let tmp = '';
      if (item.address) {
        tmp = item.address;
      }
      tmp += (item.postalCode ? ', ' + item.postalCode : '') + (item.city ? ' ' + item.city : '');
      if (item.country) {
        tmp += ', ' + item.country;
      }
      return tmp;
    },
    formatAddrCountryCode(item) {
      let tmp = '';
      if (item.address) {
        tmp = item.address;
      }
      tmp += (item.postalCode ? ', ' + item.postalCode : '') + (item.city ? ' ' + item.city : '');
      if (item.country) {
        tmp += ', ' + this.displayForCountryConst(item.country);
      }
      return tmp;
    },
    formatAddrWithComplement(item) {
      let tmp = '';
      if (item.address) {
        tmp = item.address;
      }
      if (item.complement) {
        tmp += ', ' + item.complement;
      }
      tmp += (item.postalCode ? ', ' + item.postalCode : '') + (item.city ? ' ' + item.city : '');
      if (item.country) {
        tmp += ', ' + item.country;
      }
      return tmp;
    },
  },
};
