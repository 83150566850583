<script>
import AkLabel from '@components/v2/general/AkLabel';
import Calendar from 'primevue/calendar';

export default {
  components: {AkLabel, Calendar},
  inject: {
    pSubmitted: {default: null},
    pDisabled: {default: null},
    pIgnoredValidator: {default: null},
  },
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    timeOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    showButtonBar: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    customValidator: {
      type: Array,
      required: false,
      default: undefined,
    },
    format: {
      type: String,
      required: false,
      default: 'dd/mm/yy',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    ignoredValidator: {
      type: Array,
      required: false,
      default: undefined,
    },
    maxDate: {
      type: Date,
      required: false,
      default: undefined,
    },
    minDate: {
      type: Date,
      required: false,
      default: undefined,
    },
    selection: {
      type: String,
      required: false,
      default: 'single',
    },
    isForm: {
      type: Boolean,
      required: false,
      default: true,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    view: {
      type: String,
      required: false,
      default: 'date',
    },
  },
  emits: ['update:modelValue', 'value-change'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    getIgnoredValidator() {
      if (this.ignoredValidator != undefined) return this.ignoredValidator;
      if (this.pIgnoredValidator != undefined) return this.pIgnoredValidator;
      return [];
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      if (this.hasValidator && this.getIgnoredValidator.length > 0) {
        for (let error of this.validator.$errors) {
          if (this.getIgnoredValidator.indexOf(error.$validator) === -1) {
            return true;
          }
        }
      } else {
        return this.hasValidator && this.validator.$invalid;
      }
      return false;
    },
    isValidatorMinDate() {
      return (
        this.validator !== undefined &&
        this.validator.minDate !== undefined &&
        this.validator.minDate.$invalid &&
        this.alreadySubmitted
      );
    },
    isValidatorMaxDate() {
      return (
        this.validator !== undefined &&
        this.validator.maxDate !== undefined &&
        this.validator.maxDate.$invalid &&
        this.alreadySubmitted
      );
    },
    isRequired() {
      return (
        this.hasValidator &&
        this.validator.required !== undefined &&
        (this.validator.required.$params.type === 'required' ||
          (this.validator.required.$params.type === 'requiredIf' && this.validator.required.$params.prop === true))
      );
    },
    isRequiredFailed() {
      return (
        this.isRequired &&
        this.getIgnoredValidator.indexOf('required') === -1 &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
    isCustomValidatorFailed() {
      if (this.customValidator)
        for (let v of this.customValidator) {
          console.log('On check le validator : ' + v);
          let error =
            v !== undefined &&
            this.validator !== undefined &&
            this.validator[v] !== undefined &&
            this.validator[v].$invalid &&
            this.alreadySubmitted;
          console.log('Erreur pour : ' + v + ' - ' + error);
          if (error) return true;
        }
      return false;
    },
    getCustomValidatorMessage() {
      if (this.customValidator)
        for (let v of this.customValidator) {
          let error =
            v !== undefined &&
            this.validator !== undefined &&
            this.validator[v] !== undefined &&
            this.validator[v].$invalid &&
            this.alreadySubmitted;
          if (error) return this.validator[v].$message;
        }
      return undefined;
    },
  },
  methods: {
    valueChange(event) {
      this.$nextTick(() => {
        if (
          this.selection === 'single' ||
          (this.selection === 'range' &&
            this.currentValue &&
            this.currentValue.length === 2 &&
            this.currentValue[1] != undefined) ||
          (this.selection === 'range' && !this.currentValue)
        ) {
          this.$emit('value-change', event.value);
        }
      });
    },
  },
};
</script>

<template>
  <div class="w-full input-layout form-control" :class="[className]">
    <AkLabel v-if="label && !inline && displayLabel" :required="isRequired">
      {{ label }}
    </AkLabel>
    <Calendar
      v-model="currentValue"
      class="w-full text-dark text-base font-inter form-control"
      :class="[inputClass, {'p-invalid': isInvalid && alreadySubmitted, disabled: isDisabled}]"
      :dateFormat="format"
      :disabled="isDisabled"
      :max-date="maxDate"
      :min-date="minDate"
      :show-time="showTime"
      :time-only="timeOnly"
      @dateSelect="valueChange"
      @clear-click="valueChange"
      @today-click="valueChange"
      :view="view"
      :show-button-bar="showButtonBar"
      :placeholder="getPlaceholder"
      :selection-mode="selection" />
    <div class="flex flex-col">
      <small v-if="isRequiredFailed && !isInline" class="p-error">{{ $t('field_required', [label]) }}</small>
      <small v-if="isValidatorMinDate && !isInline" class="p-error">{{ $t('error_min_date', [label]) }}</small>
      <small v-if="isValidatorMaxDate && !isInline" class="p-error">{{ $t('error_max_date', [label]) }}</small>
      <small v-if="isCustomValidatorFailed && !isInline" class="p-error">{{ this.getCustomValidatorMessage }}</small>
    </div>
  </div>
</template>
