<script>
import AkLabel from '@components/general/AkLabel';
import Dropdown from 'primevue/dropdown';

export default {
  components: {AkLabel, Dropdown},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    optionLabel: {
      type: [String, Function],
      required: false,
      default: 'label',
    },
    optionValue: {
      type: [String, Function],
      required: false,
      default: 'value',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    ignoredValidator: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionDisabled: {
      type: [Boolean, String],
      required: false,
      default: 'false',
    },
    link: {
      type: Object,
      required: false,
      default: undefined,
    },
    action: {
      type: Object,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultValue: {
      type: [Number, String],
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    if (!this.currentValue && this.defaultValue) {
      this.currentValue = this.defaultValue;
      if (this.defaultValue === '0') this.currentValue = 0;
    }
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      if (this.hasValidator && this.ignoredValidator.length > 0) {
        for (let error of this.validator.$errors) {
          if (this.ignoredValidator.indexOf(error.$validator) === -1) {
            return true;
          }
        }
      } else {
        return this.hasValidator && this.validator.$invalid;
      }
      return false;
    },
    isRequired() {
      return (
        this.hasValidator &&
        this.validator.required !== undefined &&
        (this.validator.required.$params.type === 'required' ||
          (this.validator.required.$params.type === 'requiredIf' && this.validator.required.$params.prop === true))
      );
    },

    isRequiredFailed() {
      return (
        this.isRequired &&
        this.ignoredValidator.indexOf('required') === -1 &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="[className, {'form-group': !isInline, 'form-control': isInline}]">
    <AkLabel v-if="displayLabel && !isInline" :required="this.isRequired || this.required === true">
      {{ label }}
      <template v-if="link !== undefined && isDisabled" #link>
        <router-link :to="{name: link.route, params: link.params}" target="_blank">
          <i :class="link.icon" />
        </router-link>
      </template>
    </AkLabel>

    <Dropdown
      v-model="currentValue"
      :class="[
        inputClass,
        {'p-invalid': isInvalid && alreadySubmitted, 'form-control': !isInline, disabled: isDisabled},
      ]"
      :disabled="isDisabled"
      :editable="editable"
      :loading="loading"
      :option-disabled="optionDisabled"
      :option-label="optionLabel"
      :option-value="optionValue"
      :options="options"
      :placeholder="getPlaceholder" />

    <router-link v-if="isDisabled && !isInline && link !== undefined" :to="{name: link.route, params: link.params}">
      <i :class="link.icon" class="icon-link" />
    </router-link>

    <span v-if="isDisabled && !isInline && action !== undefined" @click="this.action.perform()">
      <i :class="action.icon" class="icon-link" />
    </span>
    <small v-if="isRequiredFailed && !isInline" class="p-error">{{ $t('field_required', [label]) }}</small>
  </div>
</template>
