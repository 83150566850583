<script>
import {helpers, required, requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {useSidebarStore} from '@store/sidebarStore';

const IBAN = require('iban');
const ibanValidator = (value) => !value || IBAN.isValid(value);

const BIC = require('bic');
const bicValidator = (value) => !value || BIC.isValid(value);

/* COMPOSANTS */
import AkLoader from '@components/general/AkLoader.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';
import AkInputText from '@components/v2/input/AkInputText';
import AkDropdown from '@components/v2/input/AkDropdown';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import mandatService from '@services/mandatService';
import itemLabelService from '@services/itemLabelService';
import cancelRuleService from '@services/cancelRuleService';

export default {
  components: {
    AkLoader,
    GaMessages,
    AkFormSubmitted,
    AkButtonFooterSidebar,
    AkInputText,
    AkDropdown,
  },
  mixins: [utilsMixin, messageMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      submitted: false,
      mandat: {},
      mandatClusterRMList: [],
      cancelRuleList: [],
      marcheList: [],
    };
  },
  validations() {
    return {
      mandat: {
        clusterRmId: {required: requiredIf(!this.isAvenant)},
        marcheId: {required: requiredIf(!this.isAvenant)},
        cancelRuleId: {required: requiredIf(!this.isAvenant)},
        iban: {
          required,
          ibanValidator: helpers.withMessage('Vérifier le format du numéro IBAN', ibanValidator),
        },
        bic: {
          required,
          bicValidator: helpers.withMessage('Vérifier le format du numéro BIC', bicValidator),
        },
      },
    };
  },
  mounted() {
    this.$refs.loader.load();

    let p1 = mandatService.mandat(this.currentValue);

    p1.then((data) => {
      this.mandat = data;
    });

    let p2 = itemLabelService.itemLabels({type: 'MANDAT_CLUSTER_RM', enabled: true});
    p2.then((data) => {
      this.mandatClusterRMList = data;
    });

    let p3 = cancelRuleService.cancelRules();
    p3.then((data) => {
      this.cancelRuleList = data;
    });

    let p4 = itemLabelService.itemLabels({type: 'MARCHE', enabled: true});
    p4.then((data) => {
      this.marcheList = data;
    });

    Promise.all([p1, p2, p3, p4]).then(() => {
      this.$refs.loader.hide();
    });
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },

    isAvenant() {
      return !!this.mandat?.parentId;
    },
  },
  methods: {
    close() {
      useSidebarStore().$reset();
    },

    update() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      mandatService
        .update(this.mandat)
        .then((data) => {
          this.callback[0](true);
        })
        .catch((e) => {
          this.callback[0](false);
        })
        .finally(() => {
          this.submitted = false;
          this.close();
        });
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :scale="2" color="var(--color-primary)" message="Récupération en cours">
    <template #content>
      <perfect-scrollbar class="h-full flex flex-col gap-4">
        <div>
          <GaMessages ref="sidebarMessages" />
        </div>
        <AkFormSubmitted :submitted="submitted" class="flex flex-col gap-4" reference="form">
          <AkDropdown
            v-if="!this.isAvenant"
            v-model="mandat.clusterRmId"
            :label="$t('settings.mandat_cluster_rm')"
            :options="mandatClusterRMList"
            option-label="label"
            option-value="id"
            filter
            :validator="v$.mandat.clusterRmId" />

          <AkDropdown
            v-if="!this.isAvenant"
            v-model="mandat.marcheId"
            :label="$t('settings.marche')"
            :options="marcheList"
            option-label="label"
            option-value="id"
            :validator="v$.mandat.marcheId" />

          <AkDropdown
            v-if="!this.isAvenant"
            v-model="mandat.cancelRuleId"
            :label="$t('settings.cancel_rule')"
            :options="cancelRuleList"
            option-label="label"
            option-value="id"
            :validator="v$.mandat.cancelRuleId" />

          <AkInputText v-model="mandat.iban" :label="$t('mandat.iban')" :validator="v$.mandat.iban" />
          <AkInputText v-model="mandat.bic" :label="$t('mandat.bic')" :validator="v$.mandat.bic" />

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancelLabel="$t('cancel')"
            :validateLabel="$t('update')"
            @action="update"
            @cancel="close" />
        </AkFormSubmitted>
      </perfect-scrollbar>
    </template>
  </AkLoader>
</template>
