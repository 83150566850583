<script>
/* COMPOSANTS */
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkDropdown from '@components/v2/input/AkDropdown';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import paymentStatusConst from '@mixins/const/paymentStatusConst';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import reservationCancelledBefore30 from '@mixins/const/reservationCancelledBefore30';
import reservationGuestTypesConst from '@mixins/const/reservationGuestTypesConst';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import sidebarMixin from '@/mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */

export default {
  components: {
    AkAutocomplete,
    AkCalendar,
    AkDropdown,
    MultiSelect,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
    clientAutocompleteMixin,
    reservationAutocompleteMixin,
    reservationCancelledBefore30,
    reservationGuestTypesConst,
    reservationSiteConst,
    paymentStatusConst,
    sidebarMixin,
    utilsMixin,
    companyMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
      this.callback[1]();
      this.itemSelect();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <!--AGENCE-->
    <MultiSelect
      v-model="currentValue.companyIds"
      @change="itemSelect"
      :placeholder="$t('company.one')"
      :options="this.companies"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="name"
      option-value="id" />

    <!--HOTE-->
    <AkAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      :label="$t('owner.ownerDisplay')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <!--VOYAGEUR-->
    <AkAutocomplete
      ref="clientAutocomplete"
      v-model="currentValue.clientId"
      :inline="true"
      :label="$t('client.one')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteClientById"
      :search-label="this.autocompleteClientQuery"
      :search-method="this.autocompleteClient" />

    <!--LOGEMENT-->
    <AkAutocomplete
      @valueChange="itemSelect"
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      :label="$t('accommodation.short')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteAccommodationById"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery" />

    <!--RESERVATIONS-->
    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :label="$t('reservation.number')"
      :search-method="this.autocompleteReservation"
      :init-method="this.autocompleteReservationById" />

    <!--PERIOD CHECK IN-->
    <AkCalendar
      v-model="currentValue.dateCheckinPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCheckin')"
      date-format="dd/mm/yy"
      selection="range" />

    <!--PERIOD CHECK OUT-->
    <AkCalendar
      v-model="currentValue.dateCheckoutPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('reservation.periodCheckout')"
      date-format="dd/mm/yy"
      selection="range" />

    <!--PERIOD DATE DE CREATION-->
    <AkCalendar
      v-model="currentValue.dateCreatedPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('date_created')"
      date-format="dd/mm/yy"
      selection="range" />

    <!--ANNULEES PLUS DE 30J-->
    <AkDropdown
      :inline="true"
      v-model="currentValue.cancelled30Days"
      :label="$t('reservation.cancelledBefore30')"
      :options="this.reservationCancelledBefore30"
      class-name="flex-1 form-control"
      option-label="label"
      option-value="value" />

    <!--PLATEFORME-->
    <MultiSelect
      v-model="currentValue.reservationSiteList"
      :placeholder="$t('reservation.reservation_site')"
      @change="itemSelect"
      :options="reservationSiteConstDefined"
      option-label="label"
      option-value="value"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      :display-label="false" />

    <!--STATUT PAIEMENT-->
    <MultiSelect
      v-model="currentValue.paymentStatusList"
      :placeholder="$t('reservation.status_payment')"
      @change="itemSelect"
      :options="paymentStatusConst"
      option-label="label"
      option-value="value"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      :display-label="false" />

    <!--TYPE DE RESERVATION-->
    <MultiSelect
      v-model="currentValue.typeList"
      :placeholder="$t('reservation.type')"
      @change="itemSelect"
      :options="this.reservationGuestTypesConst"
      option-label="label"
      option-value="value"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      :display-label="false" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
