<script>
/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';
import AkInputText from '@components/v2/input/AkInputText.vue';
import {debounce} from '@utils';
import GaListView from '@components/v2/layout/GaListView.vue';

export default {
  components: {AkInputText},
  mixins: [messageMixin],
  props: {
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: undefined,
    },
    tabs: {
      type: Array,
      required: false,
      default: () => {
        return undefined;
      },
    },
    currentTab: {type: String, default: undefined},
  },
  emits: ['search', 'tab'],
  data() {
    return {
      search: undefined,
      debounceFn: undefined,
      tab: {},
    };
  },
  mounted() {
    this.debounceFn = debounce(() => this.$emit('search', this.search), 500);
  },
  methods: {
    display(tab) {
      this.$emit('tab', tab);
    },
  },
  computed: {
    placeholder() {
      return this.searchPlaceholder ? this.searchPlaceholder : this.$t('search.search');
    },
    visibleTabs() {
      return this.tabs.filter((tab) => !tab.hidden);
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex justify-between items-center flex-col md:flex-row gap-2">
      <perfect-scrollbar class="w-full md:w-auto">
        <div class="flex items-center">
          <span
            v-for="tab of visibleTabs"
            :key="tab"
            :class="{active: this.currentTab === tab.id}"
            class="list-table-item"
            @click="this.display(tab)"
            >{{ tab.name }}</span
          >
        </div>
      </perfect-scrollbar>
      <div class="flex-1"></div>
      <div class="w-full md:w-auto">
        <AkInputText
          v-if="searchable"
          v-model="search"
          :display-label="false"
          icon="ga-icon ga-search"
          :placeholder="placeholder"
          inputClass="xs"
          @keyup="debounceFn"></AkInputText>
      </div>
      <div class="flex gap-2 items-center justify-end w-full md:w-auto flex-wrap">
        <slot name="filter"></slot>
        <div class="flex h-full items-start">
          <slot name="action"></slot>
        </div>
      </div>
    </div>
    <div>
      <slot name="content"></slot>
    </div>
  </div>
  <slot name="extra"></slot>
</template>
