export default {
  data() {
    return {
      maintenanceInterlocutorTypeConst: [
        {label: this.$t('interlocutor.proprietaire'), value: 'PROPRIETAIRE'},
        {label: this.$t('interlocutor.partenaire'), value: 'PARTENAIRE'},
        {label: this.$t('interlocutor.housekeeper'), value: 'CONCIERGE'},
        {label: this.$t('interlocutor.voyageur'), value: 'VOYAGEUR'},
        {label: this.$t('interlocutor.prestataire'), value: 'PRESTATAIRE'},
        {label: this.$t('interlocutor.salarie'), value: 'SALARIE'},
      ],
    };
  },
  methods: {
    displayForMaintenanceInterlocutorTypeConst(value) {
      let result = this.maintenanceInterlocutorTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
