<script>
/* MIXINS */
import prestationTypesConst from '@mixins/const/prestationTypesConst';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      typeDisplayed: '',
    };
  },
  mixins: [prestationTypesConst],
  mounted() {
    this.typeDisplayed = this.type;
  },
  computed: {
    typeClass() {
      if (this.typeDisplayed) {
        return this.typeDisplayed.toLowerCase().replace('_', '-');
      } else {
        return '';
      }
    },
    isTypeCleaning() {
      return (
        this.typeDisplayed === 'CLEANING' ||
        this.typeDisplayed === 'CLEANING_AFTER' ||
        this.typeDisplayed === 'CLEANING_BEFORE'
      );
    },
    isTypeCheckin() {
      return this.typeDisplayed === 'CHECK_IN';
    },
    isTypeCheckout() {
      return this.typeDisplayed === 'CHECK_OUT';
    },
    isTypeMaintenanceOrRunning() {
      return this.typeDisplayed === 'RUNNING' || this.typeDisplayed === 'MAINTENANCE';
    },
    isTypeQualityControl() {
      return this.typeDisplayed === 'QUALITY_CONTROL';
    },
  },
};
</script>

<template>
  <div class="prestation-badge" :class="`--${typeClass}`">
    <div class="badge-icon">
      <i class="ga-icon ga-aspirateur text-2xl text-[#F36D8F]" v-if="this.isTypeCleaning" />
      <i class="ga-icon ga-user_delete text-2xl text-[#898DC5]" v-if="this.isTypeCheckout" />
      <i class="ga-icon ga-user_edit text-2xl text-[#7100EB]" v-if="this.isTypeCheckin" />
      <i class="ga-icon ga-user_out text-2xl text-[#00CBB8]" v-if="this.isTypeMaintenanceOrRunning" />
      <i class="ga-icon ga-user_checked text-2xl text-[#00CBB8]" v-if="this.isTypeQualityControl" />
    </div>
    <span>
      {{ displayForPrestationTypeConst(typeDisplayed) }}
    </span>
  </div>
</template>
