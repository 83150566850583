export default {
  data() {
    return {
      comptaCommissionTypeConst: [
        {label: 'Pourcentage', value: 'PCT'},
        {label: 'Fixe', value: 'FIXE'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
};
