<script>
/* COMPOSANTS */
import RecapitulatifFacturationAllInclusiveList from '@views/settings/ParametrageCommission/ParametrageCommissionAllInclusiveList';
import RecapitulatifFacturationOnlineList from '@views/settings/ParametrageCommission/ParametrageCommissionOnlineList';
import AkLoader from '@components/general/AkLoader.vue';

/* MIXINS */
import recapitulatifFacturationTabMixin from '@mixins/tab/parametrageCommissionTabMixin';

/* SERVICES */

export default {
  components: {
    RecapitulatifFacturationOnlineList,
    RecapitulatifFacturationAllInclusiveList,
    AkLoader,
  },
  mixins: [recapitulatifFacturationTabMixin],
  setup() {},
  metaInfo() {
    return {
      title: 'settings.recapitulatif_facturation',
    };
  },
  data() {
    return {
      activeTab: undefined,
    };
  },
  computed: {},
  mounted() {
    this.$refs.loader.load();
    if (this.$route.hash) {
      this.activeTab = this.$route.hash.slice(1);
    }
    this.$refs.loader.hide();
  },
  methods: {
    getFirstTab() {
      if (this.$route.hash) {
        return this.$route.hash.slice(1);
      }
      return 'offerAllInclusive';
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
    <template #content>
      <GaTabView :firstTab="getFirstTab()" :title="$t('settings.recapitulatif_facturation')" :tabs="tabs">
        <template #offerAllInclusive>
          <RecapitulatifFacturationAllInclusiveList />
        </template>
        <template #offerOnline>
          <RecapitulatifFacturationOnlineList />
        </template>
      </GaTabView>
    </template>
  </AkLoader>
</template>
