<script>
import {useFilterStore} from '@store/filterStore';

/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import GaListView from '@components/v2/layout/GaListView';
import AkListFilter from '@components/v2/filter/AkListFilter';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter';
import AkInputFilter from '@components/v2/filter/AkInputFilter';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination';
import AkAllFilter from '@components/v2/filter/AkAllFilter';

/* MIXINS */
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import messageMixin from '@mixins/messageMixin';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import sidebarMixin from '@mixins/sidebarMixin';
import smilyStatusConst from '@mixins/const/smilyStatusConst';
import utilsMixin from '@mixins/utilsMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';
import filterListMixin from '@mixins/filterListMixin';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import reservationService from '@services/reservationService';

export default {
  components: {
    AkAllFilter,
    AkDatatablePagination,
    AkInputFilter,
    AkAutocompleteFilter,
    AkListFilter,
    GaListView,
    GaView,
  },
  mixins: [
    accommodationTypeConst,
    loaderMixin,
    permissionsMixin,
    randomRef,
    messageMixin,
    reservationTypesConst,
    sidebarMixin,
    utilsMixin,
    smilyStatusConst,
    ownerAutocompleteMixin,
    reservationAutocompleteMixin,
    reservationSiteConst,
    accommodationAutocompleteMixin,
    companyMixin,
    filterListMixin,
  ],
  metaInfo() {
    return {
      title: this.isOwner() ? 'menu.my_reservations' : 'reservation.list_long',
    };
  },
  data() {
    return {
      loading: true,
      accommodations: undefined,
      debounceFn: undefined,
      filter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      list: [],
      totalItems: 0,
      item: {},
      reservationTooltip: undefined,
    };
  },
  mounted() {
    let filter = useFilterStore().getFilter();
    if (filter) {
      this.filter = {...this.filter, ...filter};
    }
    if (this.isOwner()) {
      accommodationService.accommodations().then((data) => {
        this.accommodations = data;
      });
    }
  },
  methods: {
    search() {
      this.loading = true;
      reservationService.reservationsPagination(this.filter).then((data) => {
        this.list = data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    displayPeriodForReservation(data) {
      return this.displayDatesSeparator(data.dateStart, data.dateEnd, false);
    },
    rowClick(event) {
      let data = event.data;
      data.header = data.localizer;
      this.toggleSidebar('GaReservationSidebar', 'Détails de la réservation', data.id, this.deleteReservationHote);
    },
    deleteReservationHote(success) {
      this.hideSidebar();
      if (success) {
        this.success(this.$t('unavailability.deleted'));
        this.search();
      } else {
        this.addError(this.$t('unavailability.impossible_to_cancel'));
      }
    },

    // LITIGE

    getLitigeStatusTooltip(data) {
      let status = '[' + this.displayDates(data.dateStartLitige, data.dateEndLitige) + ']';
      if (data.amountLitigeEstimated)
        status += ' - ' + this.formatCurrencyEUR(this.formatAmount(data.amountLitigeEstimated));
      return status;
    },

    getLitigeDisplay(data) {
      return this.$t(data.dateStartLitige ? 'reservation.endLitige' : 'reservation.startLitige');
    },

    openLitigeSidebar(data) {
      this.toggleSidebar('GaReservationLitigeSidebar', this.getLitigeDisplay(data), data.id, this.createLitige);
    },

    createLitige(success, dateEndLitige) {
      if (success) {
        this.success(this.$t(dateEndLitige ? 'reservation.litigeEnded' : 'reservation.litigeStarted'));
        this.search();
      } else {
        this.addError(this.$t('error.error'));
      }
    },

    // RECLAMATION

    getReclamationStatusTooltip(data) {
      let status = '[' + this.displayDates(data.dateStartReclamation, data.dateEndReclamation) + ']';
      if (data.amountReclamationEstimated)
        status += ' - ' + this.formatCurrencyEUR(this.formatAmount(data.amountReclamationEstimated));
      return status;
    },
    getReclamationDisplay(data) {
      return this.$t(data.dateStartReclamation ? 'reservation.endReclamation' : 'reservation.startReclamation');
    },

    openReclamationSidebar(data) {
      this.toggleSidebar(
        'GaReservationReclamationSidebar',
        this.getReclamationDisplay(data),
        data.id,
        this.createReclamation,
      );
    },

    createReclamation(success, dateEndReclamation) {
      if (success) {
        this.success(this.$t(dateEndReclamation ? 'reservation.reclamationEnded' : 'reservation.reclamationStarted'));
        this.search();
      } else {
        this.addError(this.$t('error.error'));
      }
    },

    handleReclamation() {
      this.v$.itemReclamation.$touch();
      if (this.v$.itemReclamation.$error) {
        this.$refs.dialogReclamation.init();
        return;
      }

      let reclamation = {...this.itemReclamation};

      if (reclamation.dateStartReclamation) {
        let total = reclamation.amountReclamationReported;
        let totalGas = reclamation.amountReclamationGas;
        let totalGav = reclamation.amountReclamationGav;
        let totalOwner = reclamation.amountReclamationOwner;

        if (total) {
          if (this.addNumbers([totalGas, totalGav, totalOwner]) !== total) {
            this.$refs.dialogReclamation.error("Le total des parts n'est pas égal au montant reversé");
            return;
          }
        }
        reclamation.dateEndReclamation = new Date(reclamation.dateReclamation);
      } else reclamation.dateStartReclamation = new Date(reclamation.dateReclamation);
      reclamation.dateReclamation = null;

      reservationService
        .updateReclamation(reclamation)
        .then((data) => {
          this.success(this.$t(reclamation ? 'reservation.reclamationEnded' : 'reservation.reclamationStarted'));
        })
        .finally(() => {
          this.$refs.dialogReclamation.hide();
          this.search();
        });
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaReservationFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.search();
      this.toggleFilterTitle(
        'GaReservationFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    displaySublineStatus(r) {
      if (r.type === 'OWNER' || r.type === 'OWNER_CANCEL') return 'Hôte';
      if (r.type === 'BLOCKADE' || r.type === 'BLOCKADE_CANCEL') return 'Indisponible';
      return 'Voyageur';
    },
  },
  computed: {},
};
</script>

<template>
  <GaView>
    <template #content>
      <GaListView searchable @search="this.searchQuery">
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('employee.company')"
            direction="right" />
          <AkInputFilter
            v-model="this.filter.guestName"
            @search="this.clickSearch"
            label="Voyageur"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.reservationId"
            @search="this.clickSearch"
            label="Réservation"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteReservationById"
            :search-method="this.autocompleteReservation" />

          <AkAllFilter @open="this.openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :loading="loading"
            :value="list"
            :total-records="this.totalItems"
            @row-click="rowClick($event)"
            @search="this.search"
            :filter="this.filter">
            <template #empty>
              {{ $t('reservation.empty') }}
            </template>
            <Column
              :header="$t('reservation.reservation_site')"
              header-class="text-center"
              style="width: 50px; text-align: center">
              <template #body="slotProps">
                <span
                  class="logo-site"
                  :class="iconReservation(slotProps.data.reservationSite)"
                  v-tooltip.bottom="slotProps.data.reservationSite" />
              </template>
            </Column>
            <Column
              :header="$t('reservation.localizer')"
              style="min-width: 200px"
              :sortable="true"
              sort-field="localizer">
              <template #body="slotProps">
                <router-link
                  v-if="this.isGasGav()"
                  :to="{
                    name: 'reservationPage',
                    params: {reservationId: slotProps.data.id},
                  }">
                  {{
                    slotProps.data.localizer
                      ? slotProps.data.localizer
                      : slotProps.data.bookingCode + '-' + slotProps.data.agentLocalizator
                  }}
                </router-link>
                <span v-else class="text-primary font-semibold text-md cursor-pointer">
                  {{
                    slotProps.data.localizer
                      ? slotProps.data.localizer
                      : slotProps.data.bookingCode + '-' + slotProps.data.agentLocalizator
                  }}
                </span>
              </template>
            </Column>

            <Column
              :header="$t('reservation.accommodation')"
              style="min-width: 250px"
              :sortable="true"
              sort-field="j_accommodation_name">
              <template #body="slotProps">
                <span class="pointer">
                  <router-link
                    :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.accommodationId}}">
                    {{ slotProps.data.accommodationName }}
                  </router-link>
                </span>
              </template>
            </Column>

            <Column :header="$t('reservation.guest')" style="width: 250px">
              <template #body="slotProps">
                <router-link
                  v-if="slotProps.data.clientId && this.isGasGav()"
                  :to="{
                    name: 'clientPage',
                    params: {clientId: slotProps.data.clientId},
                  }">
                  <span v-tooltip.bottom="slotProps.data.guestEmail" class="font-semibold">{{
                    slotProps.data.guestDisplay
                  }}</span>
                </router-link>
                <span v-tooltip.bottom="slotProps.data.guestEmail" v-else>
                  {{ slotProps.data.guestDisplay }}
                </span>
              </template>
            </Column>

            <Column :header="$t('reservation.type')" style="width: 150px" sortable sort-field="status_smily">
              <template #body="slotProps">
                <span>
                  {{ displayForSmilyStatusConst(slotProps.data.statusSmily) }}
                  <br />
                  <span class="text-sm">{{ displaySublineStatus(slotProps.data) }}</span>
                </span>
              </template>
            </Column>
            <Column
              :header="$t('reservation.creation_date')"
              style="width: 200px"
              :sortable="true"
              sort-field="date_created">
              <template #body="slotProps">
                <span class="pointer">
                  {{ displayDateTime(slotProps.data.dateCreated) }}
                </span>
              </template>
            </Column>
            <Column :header="$t('reservation.period')" style="width: 100px" :sortable="true" sort-field="date_checkin">
              <template #body="slotProps">
                <span class="pointer">
                  {{ displayPeriodForReservation(slotProps.data) }}
                </span>
              </template>
            </Column>

            <Column :header="$t('reservation.managementStatus')" style="width: 300px" v-if="this.isGasGav()">
              <template #body="slotProps">
                <div>
                  <div v-if="slotProps.data.dateStartLitige" :title="getLitigeStatusTooltip(slotProps.data)">
                    <span>
                      {{ $t(slotProps.data.dateEndLitige ? 'reservation.litigeEnded' : 'reservation.litigeStarted') }}
                    </span>
                  </div>
                  <div v-if="slotProps.data.dateStartReclamation" :title="getReclamationStatusTooltip(slotProps.data)">
                    <span>
                      {{
                        $t(
                          slotProps.data.dateEndReclamation
                            ? 'reservation.reclamationEnded'
                            : 'reservation.reclamationStarted',
                        )
                      }}
                    </span>
                  </div>
                </div>
              </template>
            </Column>

            <Column
              :header="$t('reservation.action')"
              style="width: 200px; min-width: 200px"
              header-class="text-center"
              v-if="this.isGasGav()">
              <template #body="slotProps">
                <div class="flex gap-2 totalItems-center">
                  <button
                    class="btn-table flex totalItems-center justify-center"
                    @click="openLitigeSidebar(slotProps.data)"
                    v-if="!slotProps.data.dateEndLitige">
                    {{ getLitigeDisplay(slotProps.data) }}
                  </button>

                  <button
                    class="btn-table flex totalItems-center justify-center"
                    @click="openReclamationSidebar(slotProps.data)"
                    v-if="!slotProps.data.dateEndReclamation">
                    {{ getReclamationDisplay(slotProps.data) }}
                  </button>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
