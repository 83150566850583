<script>
/* COMPOSANTS */
import GaContentView from '@components/v2/layout/GaContentView.vue';
import AkInputText from '@components/v2/input/AkInputText';
import AkInputTextArea from '@components/v2/input/AkInputTextArea';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkTime from '@components/v2/input/AkTime';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import AkAvatar from '@components/general/AkAvatar.vue';

export default {
  components: {AkAvatar, AkInputText, AkInputTextArea, AkCalendar, GaContentView, AkTime},
  mixins: [utilsMixin],
  props: {
    modelValue: {
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.initDate();
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },

    // GET

    imagesFicList() {
      return this.currentValue?.imagesFicList;
    },
    prestationParticipants() {
      return this.currentValue?.prestationParticipants;
    },

    // IS

    isAssignedStaffPrestataire() {
      return this.currentValue?.assignedStaff === 'PRESTATAIRE';
    },
    isAssignedStaffIntern() {
      return this.currentValue?.assignedStaff === 'INTERN';
    },
    isAssignedStaffHousekeeper() {
      return this.currentValue?.assignedStaff === 'HOUSEKEEPER';
    },
    isAssignedStaffPartner() {
      return this.currentValue?.assignedStaff === 'PARTNER';
    },

    isStatusPrestationCanceled() {
      return this.currentValue?.statusPrestation === 'CANCELED';
    },
  },
  methods: {
    initDate() {
      if (!this.currentValue) return;
      // Formatage des dates pour missions des prestataires / salariés / concierges
      this.prestationParticipants?.forEach((prestationParticipant) => {
        if (prestationParticipant.clockIn) prestationParticipant.clockIn = new Date(prestationParticipant.clockIn);
      });
    },
  },
};
</script>

<template>
  <GaContentView>
    <template #content>
      <!-- Missions prestataires (offres)-->

      <div v-if="isAssignedStaffPrestataire && prestationParticipants">
        <span class="title-border">Informations des prestataires</span>
        <div class="flex flex-col gap-2 mt-4" v-for="(offer, idx) in prestationParticipants" v-bind:key="offer.id">
          <div class="flex md:flex-row flex-col gap-2">
            <div class="flex gap-2 items-center flex-1" v-if="offer.participantId">
              <AkAvatar
                size="medium"
                :user-id="offer.userId"
                :first-name="offer.personFirstName"
                :last-name="offer.personLastName" />
              <router-link
                class="text-primary hover:text-primary text-md font-semibold"
                :to="{name: 'prestatairePage', params: {prestataireId: offer.participantId}}">
                {{ offer.personDisplay }}
              </router-link>
            </div>

            <div v-else class="flex flex-1 flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">Prestataire {{ idx + 1 }}</span>
              <span class="font-inter font-medium text-gray text-md">
                {{ offer.personDisplay }}
              </span>
            </div>
            <AkInputText v-model="offer.priceHt" disabled class-name="flex-1" label="Tarif de la prestation" />
            <AkTime v-model="offer.clockIn" label="Heure de début du prestataire" disabled class-name="flex-1" />
          </div>
          <div class="flex md:flex-row flex-col gap-2">
            <AkInputTextArea
              auto-resize
              :rows="4"
              v-model="offer.commentForPerson"
              placeholder=""
              label="Commentaire pour le prestataire"
              disabled
              class-name="flex-1" />
            <AkInputTextArea
              auto-resize
              :rows="4"
              v-model="offer.commentOnAccommodation"
              placeholder=""
              label="Commentaire du prestataire sur le logement"
              disabled
              class-name="flex-1" />
          </div>
        </div>
      </div>

      <!-- Missions salariés -->

      <div v-if="isAssignedStaffIntern && prestationParticipants">
        <span class="title-border">Informations des salariés</span>
        <div
          class="flex flex-col gap-2 mt-4"
          v-for="(prestationEmployee, idx) in prestationParticipants"
          v-bind:key="prestationEmployee.id">
          <div class="flex md:flex-row flex-col gap-2">
            <div class="flex gap-2 items-center flex-1" v-if="prestationEmployee.participantId">
              <AkAvatar
                size="medium"
                :user-id="prestationEmployee.userId"
                :first-name="prestationEmployee.personFirstName"
                :last-name="prestationEmployee.personLastName" />
              <router-link
                class="text-primary hover:text-primary text-md font-semibold"
                :to="{name: 'employeePage', params: {employeeId: prestationEmployee.participantId}}">
                {{ prestationEmployee.personDisplay }}
              </router-link>
            </div>
            <div v-else class="flex flex-1 flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">Salarié {{ idx + 1 }}</span>
              <span class="font-inter font-medium text-gray text-md">
                {{ prestationEmployee.personDisplay }}
              </span>
            </div>

            <AkTime
              class-name="flex-1"
              v-model="prestationEmployee.clockIn"
              label="Heure de début du salarié"
              disabled />
          </div>
          <div class="flex md:flex-row flex-col gap-2">
            <AkInputTextArea
              auto-resize
              :rows="4"
              class-name="flex-1"
              v-model="prestationEmployee.commentForPerson"
              label="Commentaire pour le salarié"
              disabled />
            <AkInputTextArea
              auto-resize
              :rows="4"
              class-name="flex-1"
              v-model="prestationEmployee.commentOnAccommodation"
              label="Commentaire du salarié sur le logement"
              disabled />
          </div>
        </div>
      </div>

      <!-- Missions concierges -->

      <div v-if="isAssignedStaffHousekeeper && prestationParticipants">
        <span class="title-border">Informations du concierge</span>

        <div
          class="flex flex-col gap-2 mt-4"
          v-for="(prestationHousekeeper, idx) in prestationParticipants"
          v-bind:key="prestationHousekeeper.id">
          <div class="flex md:flex-row flex-col gap-2">
            <div class="flex gap-2 items-center flex-1" v-if="prestationHousekeeper.participantId">
              <AkAvatar
                size="medium"
                :user-id="prestationHousekeeper.userId"
                :first-name="prestationHousekeeper.personFirstName"
                :last-name="prestationHousekeeper.personLastName" />
              <router-link
                class="text-primary hover:text-primary text-md font-semibold"
                :to="{name: 'housekeeperPage', params: {housekeeperId: prestationHousekeeper.participantId}}">
                {{ prestationHousekeeper.personDisplay }}
              </router-link>
            </div>

            <div v-else class="flex flex-1 flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">Concierge {{ idx + 1 }}</span>
              <span class="font-inter font-medium text-gray text-md">
                {{ prestationHousekeeper.personDisplay }}
              </span>
            </div>
            <AkTime
              v-model="prestationHousekeeper.clockIn"
              label="Heure de début du concierge"
              class-name="flex-1"
              disabled />
          </div>
          <div class="flex md:flex-row flex-col gap-2">
            <AkInputTextArea
              auto-resize
              :rows="4"
              v-model="prestationHousekeeper.commentForPerson"
              disabled
              class-name="flex-1"
              label="Commentaire pour le concierge" />
            <AkInputTextArea
              :auto-resize="false"
              :rows="4"
              class-name="flex-1"
              v-model="prestationHousekeeper.commentOnAccommodation"
              label="Commentaire du concierge sur le logement"
              disabled />
          </div>
        </div>
      </div>

      <!-- Missions partenaires (objectif : être générique à tous les profils) -->

      <div v-if="isAssignedStaffPartner && prestationParticipants">
        <span class="title-border">Informations des partenaires</span>
        <div
          class="flex flex-col gap-2 mt-4"
          v-for="(prestationParticipant, idx) in prestationParticipants"
          v-bind:key="prestationParticipant.id">
          <div class="flex md:flex-row flex-col gap-2">
            <div class="flex gap-2 items-center flex-1" v-if="prestationParticipant.participantId">
              <AkAvatar
                size="medium"
                :user-id="prestationParticipant.userId"
                :first-name="prestationParticipant.personFirstName"
                :last-name="prestationParticipant.personLastName" />
              <router-link
                class="text-primary hover:text-primary text-md font-semibold"
                :to="{name: 'partenairePage', params: {partenaireId: prestationParticipant.participantId}}">
                {{ prestationParticipant.personDisplay }}
              </router-link>
            </div>
            <div v-else class="flex flex-1 flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">Partenaire {{ idx + 1 }}</span>
              <span class="font-inter font-medium text-gray text-md">
                {{ prestationParticipant.personDisplay }}
              </span>
            </div>
            <AkCalendar
              v-model="prestationParticipant.clockIn"
              label="Date de validation par le partenaire"
              disabled
              class-name="flex-1" />
          </div>
          <div class="flex md:flex-row flex-col gap-2" v-if="this.isStatusPrestationCanceled">
            <AkCalendar
              v-model="prestationParticipant.dateCancel"
              label="Date d'annulation"
              disabled
              class-name="flex-1" />
          </div>
          <div class="flex md:flex-row flex-col gap-2" v-if="this.isStatusPrestationCanceled">
            <AkInputTextArea
              auto-resize
              :rows="4"
              v-model="prestationParticipant.commentCancel"
              label="Raison de l'annulation de la mission"
              disabled
              class-name="flex-1" />
          </div>
        </div>
      </div>
    </template>
  </GaContentView>
</template>
