<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
// import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import PrestationBadge from '@/components/general/PrestationBadge';
// import AkCalendar from '@components/input/AkCalendar';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import prestationStatusFilterConst from '@mixins/const/prestationStatusConst';
import personnelTypeConst from '@mixins/const/personnelTypeConst';

/* SERVICES */

export default {
  components: {
    // MultiSelect,
    Icon,
    Checkbox,
    PrestationBadge,
    // AkCalendar,
  },
  mixins: [
    utilsMixin,
    sidebarMixin,
    permissionsMixin,
    prestationTypesConst,
    prestationStatusFilterConst,
    personnelTypeConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    prestationTypesList() {
      return this.prestationTypesConst.slice(0, -1);
    },
    prestationStatusList() {
      return this.prestationStatusFilterConst.slice(0, -1);
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <!-- Type de personnel affecté -->
    <!-- <MultiSelect
      v-model="currentValue.assignedStaffs"
      :placeholder="$t('planning_intervenant.assigned_staff')"
      :options="personnelTypeFilterConst"
      option-label="label"
      option-value="value"
      class="flex-1 form-control"
      inline /> -->
    <!-- Statut des missions -->
    <!-- <MultiSelect
      v-model="currentValue.statusList"
      :placeholder="$t('planning_intervenant.status')"
      :options="prestationStatusList"
      option-label="label"
      option-value="value"
      class="flex-1 form-control"
      inline />
    <AkCalendar
      v-model="currentValue.dateCheckin"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('reservation.dateCheckin')"
      date-format="dd/mm/yy"
      selection-mode="single" />
    <AkCalendar
      v-model="currentValue.dateCheckout"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('reservation.dateCheckout')"
      date-format="dd/mm/yy"
      selection-mode="single" /> -->
    <!-- Types de mission : à améliorer -->
    <div>
      <h6>{{ $t('planning_intervenant.mission_type') }}</h6>
      <div>
        <div
          v-for="prestationType in prestationTypesList"
          :key="prestationType.value"
          class="d-flex align-items-center mb-2">
          <Checkbox
            v-model="currentValue.types"
            :inputId="prestationType.value"
            name="prestationType"
            :value="prestationType.value"
            class="col-2" />
          <PrestationBadge :type="prestationType.value" class="col-4" />
        </div>
      </div>
    </div>
    <!-- Recherche -->
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
