/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '../const/permissionConst';

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [
        {
          name: this.$t('mandat.mandat_list'),
          id: 'mandatList',
          icon: 'list',
          active: true,
        },
        {
          name: this.$t('mandat.avenant_list'),
          id: 'avenantList',
          icon: 'list',
        },
      ],
    };
  },
  methods: {},
};
