<script>
export default {
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="{'mb-label': !inline}" class="d-flex align-items-center">
    <label :for="id" class="flex-1">
      <slot />
      {{ required ? ' *' : '' }}</label
    >
    <slot name="link" />
  </div>
</template>
