export default {
  data() {
    return {
      reservationLitigeReclamationConst: [
        {label: 'Litige en cours', value: 'LITIGE_OPEN'},
        {label: 'Litige résolu', value: 'LITIGE_CLOSED'},
        {label: 'Réclamation en cours', value: 'RECLAMATION_OPEN'},
        {label: 'Réclamation résolue', value: 'RECLAMATION_CLOSED'},
      ],
    };
  },
  methods: {},
};
