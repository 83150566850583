<script>
/* COMPOSANTS */
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkCalendar from '@components/v2/input/AkCalendar';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import mandatAutocompleteMixin from '@mixins/autocomplete/mandatAutocompleteMixin';
import ownerChargeStatusConst from '@mixins/const/ownerChargeStatusConst';
import ownerChargeTypeConst from '@mixins/const/ownerChargeTypeConst';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import companyMixin from '@mixins/companyMixin';
import permissionsMixin from '@mixins/permissionsMixin';

export default {
  components: {
    AkAutocomplete,
    AkCalendar,
    MultiSelect,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
    mandatAutocompleteMixin,
    ownerChargeStatusConst,
    ownerChargeTypeConst,
    sidebarMixin,
    utilsMixin,
    companyMixin,
    permissionsMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.mandatAutocomplete) this.$refs.mandatAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
      this.itemSelect();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" style="row-gap: 16px" @submit.prevent="search">
    <!--AGENCE-->
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      @change="itemSelect"
      :placeholder="$t('company.one')"
      :options="this.companies"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="name"
      option-value="id" />

    <!--HOTE-->
    <AkAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      :label="$t('owner.ownerDisplay')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <!--LOGEMENT-->
    <AkAutocomplete
      @valueChange="itemSelect"
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      :label="$t('accommodation.short')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteAccommodationById"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery" />

    <!--MANDAT-->
    <AkAutocomplete
      ref="mandatAutocomplete"
      v-model="currentValue.mandatId"
      :inline="true"
      :label="$t('mandat.details')"
      option-value="id"
      option-label="num"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteMandatById"
      :search-method="this.autocompleteMandat" />

    <!--PERIODE DATE D'OPERATION-->
    <AkCalendar
      v-model="currentValue.dateOperationPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('operation.date_transaction')"
      date-format="dd/mm/yy"
      selection="range" />

    <!--PERIODE DATE DE CREATION-->
    <AkCalendar
      v-model="currentValue.dateCreatedPeriod"
      :inline="true"
      @valueChange="itemSelect"
      :placeholder="$t('date_created')"
      date-format="dd/mm/yy"
      selection="range" />

    <!--TYPE DE FACTURATION-->
    <MultiSelect
      v-model="currentValue.types"
      :inline="true"
      :placeholder="$t('facturation_hote.type')"
      :label="$t('facturation_hote.type')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      @change="itemSelect"
      :options="ownerChargeTypeConstForGAS"
      optionLabel="label"
      optionValue="value" />

    <!--STATUT-->
    <MultiSelect
      v-model="currentValue.status"
      :inline="true"
      :placeholder="$t('status')"
      :label="$t('status')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      @change="itemSelect"
      :options="ownerChargeStatusConstConsultation"
      optionLabel="label"
      optionValue="value" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
