<script>
export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
    filter: {
      type: Object,
      required: false,
      default: () => ({
        page: undefined,
        limit: undefined,
      }),
    },
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: true,
    },
    nbItems: {
      type: Number,
      required: false,
      default: 0,
    },
    rows: {
      type: Number,
      required: false,
      default: 50,
    },
    defaultFilter: {
      type: String,
      required: false,
      default: 'id:DESC',
    },
    expandedRow: {
      type: Array,
      required: false,
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    canChangeOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowGroupMode: {
      type: String,
      required: false,
      default: undefined,
    },
    groupRowsBy: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:filter', 'search', 'row-click', 'row-reorder'],
  mounted() {
    this.$nextTick(() => {
      this.currentFilter.page = 1;
      this.currentFilter.limit = this.rows;
      this.$emit('search');
    });
  },
  methods: {
    onPage(event) {
      if (this.lazy === true) {
        this.currentFilter.page = event.page + 1;
        this.currentFilter.limit = event.rows;
        this.$emit('search');
      }
    },

    onSort(event) {
      if (this.lazy === true) {
        this.currentFilter.sort = [];
        for (let sort of event.multiSortMeta) {
          this.currentFilter.sort.push('' + sort.field + ':' + (sort.order === -1 ? 'DESC' : 'ASC'));
        }
        if (this.currentFilter.sort.length === 0) {
          this.currentFilter.sort.push(this.defaultFilter);
        }
        this.currentFilter.page = 1;
        this.$emit('search');
      }
    },
  },
  computed: {
    currentFilter: {
      get: function () {
        return this.filter;
      },
      set: function (val) {
        this.$emit('update:filter', val);
      },
    },
    expandRows: {
      get: function () {
        return this.expandedRow;
      },
      set: function (val) {
        this.$emit('update:expanded-row', val);
      },
    },
    firstItem() {
      if (this.lazy === false) {
        return 0;
      }
      return this.filter.limit * (this.filter.page - 1);
    },
    nbLinesTotal() {
      if (this.lazy === false) return 0;
      return this.nbItems;
    },
    hasItems() {
      return this.value && this.value.length > 0;
    },
  },
};
</script>

<template>
  <DataTable
    ref="table"
    :class="{'datatable-mobile': this.$isMobile()}"
    :loading="this.loading"
    always-show-paginator
    :paginator="this.hasItems"
    :rows="this.rows"
    :total-records="this.nbLinesTotal"
    :first="this.firstItem"
    :value="value"
    :lazy="lazy"
    :rows-per-page-options="[10, 20, 50, 100]"
    :row-hover="clickable"
    :selection-mode="clickable ? 'single' : undefined"
    :rowGroupMode="rowGroupMode"
    :groupRowsBy="groupRowsBy"
    sort-mode="multiple"
    paginator-template="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
    :current-page-report-template="
      $t('paginator_template_1') + ' {first}-{last} ' + $t('paginator_template_2') + ' {totalRecords}'
    "
    removable-sort
    scrollable
    @page="onPage($event)"
    @sort="onSort($event)"
    @row-click="$emit('row-click', $event)"
    @row-reorder="$emit('row-reorder', $event)">
    <template #empty>
      <slot name="empty" />
    </template>
    <template #groupheader="slotProps">
      <slot name="groupheader" :slot-props="slotProps" />
    </template>
    <template #expansion="slotProps">
      <slot name="expansion" :slot-props="slotProps" />
    </template>
    <Column
      v-if="canChangeOrder && !$isMobile()"
      rowReorder
      style="width: 80px"
      :header="$t('item_label.position')"
      :reorderableColumn="false" />
    <slot />
    <template #footer v-if="this.$slots.footer">
      <slot name="footer" />
    </template>
  </DataTable>
</template>
