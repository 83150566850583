<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import AkCalendar from '@components/input/AkCalendar.vue';
import AkDropdown from '@components/input/AkDropdown.vue';
import AkAutocomplete from '@components/general/AkAutocomplete.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import stayRateService from '@services/stayRateService';

export default {
  components: {
    AkAutocomplete,
    AkDropdown,
    AkCalendar,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      stayrates: [],
      status: [
        {label: this.$t('visitor_tax.state_reversed'), value: true},
        {label: this.$t('visitor_tax.state_not_reversed'), value: false},
        {label: this.$t('visitor_tax.undefined'), value: undefined},
      ],
    };
  },
  mounted() {
    this.searchStayrates();
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accountAutocomplete) this.$refs.accountAutocomplete.reset();
      if (this.$refs.stayrateAutocomplete) this.$refs.stayrateAutocomplete.reset();
      this.callback[1]();
    },
    searchStayrates(event) {
      let search = {};
      if (event === null || event === undefined) {
        if (this.currentValue.tdsGroupId !== null && this.currentValue.tdsGroupId !== undefined) {
          stayRateService.stayrate(this.currentValue.tdsGroupId).then((data) => {
            this.stayrates.push(data);
            this.$refs.stayrateAutocomplete.init(data);
          });
        }
      } else {
        search.query = event.query;
        stayRateService.stayrates(search).then((data) => {
          this.stayrates = data;
        });
      }
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkAutocomplete
      ref="stayrateAutocomplete"
      v-model="currentValue.tdsGroupId"
      :placeholder="$t('visitor_tax.group')"
      :inline="true"
      option-value="id"
      option-label="label"
      :items="stayrates"
      @search="this.searchStayrates"
      class-name="flex-1" />

    <AkCalendar
      v-model="currentValue.from"
      :label="$t('visitor_tax.date_from')"
      date-format="dd/mm/yy"
      selection-mode="single"
      :title="$t('visitor_tax.filter_date')"
      :inline="true"
      :display-label="false"
      class-name="flex-1" />
    <AkCalendar
      v-model="currentValue.to"
      :label="$t('visitor_tax.date_to')"
      date-format="dd/mm/yy"
      selection-mode="single"
      :title="$t('visitor_tax.filter_date')"
      :inline="true"
      :display-label="false"
      class-name="flex-1" />
    <AkDropdown
      v-model="currentValue.stateReversed"
      :label="$t('visitor_tax.status')"
      :display-label="false"
      :options="this.status"
      :required="false"
      :inline="true"
      option-value="value"
      :title="$t('visitor_tax.filter_status')"
      class-name="flex-1" />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
