export default {
  data() {
    return {
      maintenanceStatusConst: [
        {label: this.$t('maintenance.a_resoudre'), value: 'A_RESOUDRE'},
        {label: this.$t('maintenance.a_traiter'), value: 'A_TRAITER'},
        {label: this.$t('maintenance.decision_prise'), value: 'DECISION_PRISE'},
        {label: this.$t('maintenance.en_attente'), value: 'EN_ATTENTE'},
        {label: this.$t('maintenance.accept'), value: 'ACCEPT'},
        {label: this.$t('maintenance.refus'), value: 'REFUS'},
        {label: this.$t('maintenance.en_cours'), value: 'EN_COURS'},
        {label: this.$t('maintenance.resolue'), value: 'RESOLUE'},
      ],
    };
  },
  methods: {
    displayForMaintenanceStatusConst(value) {
      let result = this.maintenanceStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    // liste utilisée pour le filtre de la liste des maintenances
    maintenanceStatusConstForFilter() {
      return this.maintenanceStatusConst.filter((el) => el.value !== 'DECISION_PRISE');
    },
    maintenanceStatusConstForFilterOnlyValues() {
      return this.maintenanceStatusConstForFilter().map((s) => s.value);
    },
    // liste utilisée pour le filtre de la liste des maintenances, sans l'état "Résolue"
    maintenanceStatusConstNotResolueForFilter() {
      return this.maintenanceStatusConstForFilter().filter((el) => el.value !== 'RESOLUE');
    },
    maintenanceStatusConstNotResolueForFilterOnlyValues() {
      return this.maintenanceStatusConstNotResolueForFilter().map((s) => s.value);
    },
    // liste utilisée pour le filtre de la liste des maintenances côté portail Hôte
    maintenanceStatusConstForOwnerFilter() {
      return this.maintenanceStatusConst.slice(3);
    },
  },
};
