<script>
/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@/mixins/sidebarMixin';
import litigeStatusConst from '@mixins/const/litigeStatusConst';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';

/* SERVICES */

export default {
  components: {
    AkAutocomplete,
    MultiSelect,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    companyMixin,
    permissionsMixin,
    utilsMixin,
    sidebarMixin,
    accommodationAutocompleteMixin,
    reservationAutocompleteMixin,
    clientAutocompleteMixin,
    ownerAutocompleteMixin,
    litigeStatusConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :options="this.companies"
      :loading="this.loadingCompanies"
      :placeholder="$t('employee.company')"
      class="flex-1 form-control"
      option-label="name"
      @change="itemSelect"
      option-value="id" />

    <AkAutocomplete
      v-if="!isOwner()"
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :init-method="autocompleteOwnerById"
      :placeholder="$t('owner.ownerDisplay')"
      :search-label="autocompleteOwnerQuery"
      :search-method="autocompleteOwner"
      inline
      option-label="display"
      option-value="id"
      @item-select="itemSelect" />
    <AkAutocomplete
      ref="clientAutocomplete"
      v-model="currentValue.clientId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      placeholder="Voyageur"
      :search-label="this.autocompleteClientQuery"
      :init-method="this.autocompleteClientById"
      :search-method="this.autocompleteClient" />
    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      placeholder="Logement"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery"
      :init-method="this.autocompleteAccommodationById" />

    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      @item-select="itemSelect"
      label="Réservation"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation" />
    <MultiSelect
      v-model="currentValue.statuses"
      :placeholder="$t('maintenance.status')"
      :options="litigeStatusConst"
      option-label="label"
      option-value="value"
      @change="itemSelect"
      class="flex-1 form-control"
      inline />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
