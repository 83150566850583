import {defineStore} from 'pinia';

export const useSidebarStore = defineStore('sidebar', {
  state: () => ({data: {}}),
  actions: {
    hideSidebar() {
      this.data = {};
    },
    addContact(content) {
      let data = {};
      data.content = content;
      data.template = 'GaContactSidebar';
      this.data = data;
    },
    addReservation(content, callback) {
      let data = {};
      data.content = content;
      data.template = 'GaReservationSidebar';
      data.callback = [];
      data.callback[0] = callback;
      this.data = data;
    },
    toggleReservationFilterPlanning(content, search, reset) {
      let data = {};
      data.content = content;
      data.callback = [];
      data.callback[0] = search;
      data.callback[1] = reset;
      data.template = 'GaReservationFilterPlanning';
      this.data = data;
    },
    toggleReservationFilterList(content, search, reset) {
      let data = {};
      data.content = content;
      data.callback = [];
      data.callback[0] = search;
      data.callback[1] = reset;
      data.template = 'GaReservationFilterList';
      this.data = data;
    },
    toggleFilter(name, content, search, reset) {
      let data = {};
      data.content = content;
      data.callback = [];
      data.callback[0] = search;
      data.callback[1] = reset;
      data.template = name;
      this.data = data;
    },
    toggleFilterTitle(name, title, content, search, reset) {
      let data = {};
      data.content = content;
      data.callback = [];
      data.callback[0] = search;
      data.callback[1] = reset;
      data.template = name;
      data.header = title;
      this.data = data;
    },
    toggleSidebar(name, title, content, search, reset) {
      let data = {};
      data.content = content;
      data.callback = [];
      data.callback[0] = search;
      data.callback[1] = reset;
      data.template = name;
      data.header = title;
      this.data = data;
    },

    showComment(content) {
      let data = {};
      data.content = content;
      data.header = 'Commentaire';
      data.template = 'GaCommentSidebar';
      this.data = data;
    },
  },
});
