export default {
  data() {
    return {
      attributionKeysConst: [
        {label: this.$t('attribution.business_developer'), type: 'GAV', value: 'BUSINESS_DEVELOPER'},
        {label: this.$t('attribution.city_manager'), type: 'GAV', value: 'CITY_MANAGER'},
        {label: this.$t('attribution.account_manager'), type: 'GAS', value: 'ACCOUNT_MANAGER'},
        {label: this.$t('attribution.distribution_manager'), type: 'GAS', value: 'DISTRIBUTION_MANAGER'},
      ],
    };
  },
  methods: {
    displayForAttributionKeysConst(value) {
      let result = this.attributionKeysConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
