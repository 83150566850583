<script>
/* COMPOSANTS */
import PButton from 'primevue/button';
import ParametrageCommissionForm from './form/ParametrageCommissionForm';
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import comptaCommissionService from '@services/comptaCommissionService';
import companyService from '@services/companyService';

export default {
  components: {
    ParametrageCommissionForm,
    AkDialog,
    PButton,
  },
  mixins: [loaderMixin, randomRef, permissionsMixin, utilsMixin, sidebarMixin, messageMixin],
  metaInfo() {
    return {
      title: 'settings.recapitulatif_facturation',
    };
  },
  data() {
    return {
      loading: true,
      filter: {},
      list: [],
      companies: [],
      item: {},
    };
  },
  mounted() {
    companyService.companies().then((data) => {
      this.companies = data;
      this.loading = false;
    });
  },
  methods: {
    refresh() {
      if (!this.filter.companyId) {
        this.addError(this.$t('recapitulatif_facturation.error_empty_filter_param'));
        return;
      }

      this.loading = true;
      comptaCommissionService
        .comptaCommissions({offer: 'ONLINE', companyId: this.filter.companyId})
        .then((data) => {
          this.list = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    create() {
      if (!this.$refs.formCreate.validateForm()) return;
      this.showTotalLoader();
      comptaCommissionService
        .create(this.item)
        .then((data) => {
          this.$refs.dialogCreate.hide();
          this.success(this.$t('recapitulatif_facturation.added'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.$refs.dialogCreate.error(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    update() {
      if (!this.$refs.formUpdate.validateForm()) return;
      this.showTotalLoader();
      comptaCommissionService
        .update(this.item)
        .then((data) => {
          this.$refs.dialogUpdate.hide();
          this.success(this.$t('recapitulatif_facturation.updated'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.$refs.dialogUpdate.error(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    delete() {
      this.showTotalLoader();
      comptaCommissionService
        .delete(this.item)
        .then((data) => {
          this.success(this.$t('recapitulatif_facturation.deleted'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    openCreateDialog() {
      this.item = {...this.list[0]};
      this.item.offer = 'ONLINE';
      this.item.companyId = this.filter.companyId;
      this.item.date = new Date();
      this.$refs.dialogCreate.show();
    },
    openDetailsDialog(data) {
      this.item = {...data};
      this.$refs.dialogDetails.show();
    },
    openUpdateDialog(data) {
      this.item = {...data};
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.item = {...data};
      this.$refs.dialogDelete.show();
    },

    openFilterPanel() {
      this.toggleFilter('GaParametrageCommissionFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilter('GaParametrageCommissionFilter', this.filter, this.refresh, this.resetFilter);
    },

    findCompanyDisplay(companyId) {
      let company = this.companies.find((c) => c.id === companyId);
      return company ? company.name : null;
    },
    findDisplay(item) {
      return this.findCompanyDisplay(item.companyId) + ' - ' + this.formatDateIfNeeded(item.date);
    },
    isCurrent(item) {
      return item && item.state == 'CURRENT';
    },
    isFuture(item) {
      return item && item.state == 'FUTURE';
    },
  },
  computed: {
    currentItem() {
      return this.list ? this.list.find(this.isCurrent) : null;
    },
    currentDisplay() {
      return this.currentItem ? this.findDisplay(this.currentItem) : '';
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="this.currentDisplay">
    <template #action>
      <div class="d-flex justify-content-end">
        <PButton
          class="btn btn-inverse-primary btn-xs"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          label="Filtrer"
          @click="openFilterPanel()"
          style="margin-right: 15px">
        </PButton>

        <button v-if="this.filter.companyId" class="btn btn-inverse-primary btn-xs" @click="openCreateDialog()">
          <i class="gst-plus pr-1" />
          {{ $t('recapitulatif_facturation.add') }}
        </button>
      </div>
    </template>

    <template #content>
      <ParametrageCommissionForm v-if="currentItem" v-model="currentItem" :inputDisabled="true" />
      <span v-if="currentItem" class="poppins-bold fs-20 color-secondary mt-2">
        Historique des grilles tarifaires
      </span>
      <DataTable
        v-if="currentItem"
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #empty>
          {{ $t('recapitulatif_facturation.empty') }}
        </template>

        <Column :header="$t('recapitulatif_facturation.date')" field="date" :sortable="true">
          <template #body="slotProps">
            <span>{{ this.formatDateIfNeeded(slotProps.data.date) }}</span>
          </template>
        </Column>

        <Column body-style="text-align: center; overflow: visible" header-style="width: 150px; text-align: center">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <span
                v-if="isFuture(slotProps.data)"
                class="btn btn-inverse-danger btn-xs mr-1"
                @click="openDeleteDialog(slotProps.data)">
                <i class="fe fe-trash" />
              </span>
              <span
                v-if="isFuture(slotProps.data)"
                class="btn btn-inverse-primary btn-xs mr-1"
                @click="openUpdateDialog(slotProps.data)">
                <i class="fe fe-edit" />
              </span>
              <span class="btn btn-inverse-primary btn-xs" @click="openDetailsDialog(slotProps.data)">
                <i class="fe fe-eye" />
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('recapitulatif_facturation.add')"
        :validate-label="$t('add')"
        width="1200px"
        @validate="this.create()">
        <ParametrageCommissionForm ref="formCreate" v-model="item" :inputDisabled="false" :dateVisible="true" />
      </AkDialog>

      <AkDialog
        ref="dialogUpdate"
        :title="$t('recapitulatif_facturation.update')"
        :validate-label="$t('update')"
        width="1200px"
        @validate="this.update()">
        <ParametrageCommissionForm ref="formUpdate" v-model="item" :inputDisabled="false" :dateVisible="false" />
      </AkDialog>

      <AkDialog
        ref="dialogDetails"
        :title="this.findDisplay(this.item)"
        width="1200px"
        :show-validate-button="false"
        :show-cancel-button="false">
        <ParametrageCommissionForm
          ref="formDetails"
          v-if="item"
          v-model="item"
          :inputDisabled="true"
          :dateVisible="true" />
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('recapitulatif_facturation.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaContentView>
</template>
