<script>
import {Vue3Lottie} from 'vue3-lottie';

export default {
  page: {
    title: 'Unauthorized',
    meta: [{name: 'description', content: 'Unauthorized'}],
  },
  components: {Vue3Lottie},
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-4 items-center justify-center md:h-[calc(100vh_-_97px)] h-[calc(100vh_-_163px)]">
    <div class="flex h-[300px] svg-width gap-8">
      <Vue3Lottie :animationData="require('@assets/animations/403.json')" :loop="true" width="100%" />
    </div>
    <h3 class="text-dark font-medium text-2xl text-center">
      {{ $t('403') }}
    </h3>
  </div>
</template>
