<script>
/* COMPOSANTS */

/* MIXINS */
/* SERVICES */
import utilsMixin from '@mixins/utilsMixin';
import companyService from '@services/companyService';
import sidebarMixin from '@mixins/sidebarMixin';
import {Icon} from '@iconify/vue/dist/iconify';
import AkCalendar from '@components/input/AkCalendar';
import AkInputText from '@components/input/AkInputText';

export default {
  components: {
    AkCalendar,
    AkInputText,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkCalendar
      v-model="currentValue.dateStart"
      :label="$t('date_start')"
      class-name="form-control flex-1"
      date-format="dd/mm/yy"
      :inline="true"
      selection-mode="single" />
    <AkCalendar
      v-model="currentValue.dateEnd"
      :label="$t('date_end')"
      class-name="form-control flex-1"
      date-format="dd/mm/yy"
      :inline="true"
      selection-mode="single" />
    <AkInputText
      v-model="currentValue.numVirement"
      :label="this.$t('visitor_tax.transfert_num')"
      :inline="true"
      class-name="form-control flex-1" />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
