<script>
import AkInputText from '@components/v2/input/AkInputText';
import AkAddressGoogle from '@components/input/AkAddressGoogle';
import AkInputSwitch from '@components/v2/input/AkInputSwitch';
import AkDropdown from '@components/v2/input/AkDropdown';

import countryConst from '@mixins/const/countryConst';
import addressFormatter from '@mixins/addressFormatter';
import permissionsMixin from '@mixins/permissionsMixin';
import formatMixin from '@mixins/formatMixin';

const CITIES_TYPE = ['locality', 'administrative_area_level_3'];
const REGIONS_TYPE = [
  'locality',
  'sublocality',
  'postal_code',
  'country',
  'administrative_area_level_1',
  'administrative_area_level_2',
];

export default {
  components: {AkInputSwitch, AkInputText, AkAddressGoogle, AkDropdown},
  mixins: [countryConst, addressFormatter, permissionsMixin, formatMixin],
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}, pIgnoredValidator: {default: null}},
  data() {
    return {
      changeLocation: false,
      unknowAddress: false,
      geolocation: {
        geocoder: null,
        loc: null,
        position: null,
      },
    };
  },
  props: {
    modelValue: {
      required: true,
    },
    fromOnboarding: {
      type: Boolean,
      required: false,
      default: false,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    alwaysDisplayMap: {
      type: Boolean,
      required: false,
      default: true,
    },
    canChangePosition: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    ignoredValidator: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    let c = this.currentValue && this.currentValue.placeId == undefined && this.currentValue.address != undefined;
    if (this.currentValue && this.currentValue.placeId == undefined && this.currentValue.address != undefined) {
      this.unknowAddress = true;
    }
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    getIgnoredValidator() {
      if (this.ignoredValidator != undefined) return this.ignoredValidator;
      if (this.pIgnoredValidator != undefined) return this.pIgnoredValidator;
      return [];
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isValidUnknowAddress() {
      return (
        this.unknowAddress &&
        this.currentValue.address != undefined &&
        this.currentValue.postalCode != undefined &&
        this.currentValue.city != undefined &&
        this.currentValue.country != undefined
      );
    },
    markerPosition() {
      if (this.currentValue.latitude && this.currentValue.longitude)
        return {lat: parseFloat(this.currentValue.latitude), lng: parseFloat(this.currentValue.longitude)};
      return undefined;
    },
    getLabel() {
      if (this.label === undefined) {
        return this.$t('address.address');
      }
      return this.label;
    },
    getValidator() {
      if (this.validator === undefined) {
        return {};
      }
      return this.validator;
    },
    fromOnboardingOwner() {
      if (this.fromOnboarding && this.isOwner()) {
        return true;
      }
      return false;
    },
  },
  watch: {
    isValidUnknowAddress(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.onChangeLocation();
      }
    },
  },
  methods: {
    updateCurrentLocation: function (place) {
      if (place) {
        this.currentValue.latitude = place.geometry.location.lat();
        this.currentValue.longitude = place.geometry.location.lng();
      } else {
        this.changeLocation = false;
      }
    },
    changeUnknowAddress: function () {
      if (this.$refs.googleAddress) this.$refs.googleAddress.clear();
    },
    changeMarkerLocation: function (marker) {
      this.currentValue.latitude = marker.latLng.lat();
      this.currentValue.longitude = marker.latLng.lng();
    },
    onChangeLocation: function () {
      if (!this.currentValue.latitude && !this.currentValue.longitude) {
        if (window.google && window.google.maps) {
          if (!this.geolocation.geocoder) this.geolocation.geocoder = new window.google.maps.Geocoder();
          this.geolocation.geocoder.geocode(
            {address: this.formatAddrCountryCode(this.currentValue)},
            (results, status) => {
              if (status === 'OK') {
                results = this.filterGeocodeResultTypes(results);
                if (results[0]) {
                  this.updateCurrentLocation(results[0], true);
                } else {
                  this.updateCurrentLocation();
                }
              } else {
                this.updateCurrentLocation();
              }
            },
          );
        }
      }
    },
    filterGeocodeResultTypes(results) {
      if (!results || !this.types) return results;
      let output = [];
      let types = [this.types];
      if (types.includes('(cities)')) types = types.concat(CITIES_TYPE);
      if (types.includes('(regions)')) types = types.concat(REGIONS_TYPE);

      for (let r of results) {
        for (let t of r.types) {
          if (types.includes(t)) {
            output.push(r);
            break;
          }
        }
      }
      return output;
    },
  },
};
</script>

<template>
  <div v-if="!isDisabled" class="mb-2">
    <AkInputSwitch v-model="unknowAddress" :inline="true" label="Adresse inconnue" @change="changeUnknowAddress" />
  </div>
  <div class="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 md:gap-4 gap-2">
    <AkAddressGoogle
      v-if="!unknowAddress"
      ref="googleAddress"
      v-model="currentValue"
      :ignored-validator="this.getIgnoredValidator"
      :label="getLabel"
      :required="required"
      className="xl:col-span-2 lg:col-span-4 md:col-span-2"
      :disabled="isDisabled"
      :validator="getValidator.address"
      @clear="updateCurrentLocation"
      @placeChanged="updateCurrentLocation" />
    <AkInputText
      v-if="unknowAddress"
      v-model="currentValue.address"
      :ignored-validator="this.getIgnoredValidator"
      :label="getLabel"
      className="xl:col-span-2 lg:col-span-4 md:col-span-2"
      :required="required"
      :disabled="isDisabled"
      :validator="getValidator.address" />
    <AkInputText
      v-model="currentValue.complement"
      :ignored-validator="this.getIgnoredValidator"
      className="xl:col-span-2 lg:col-span-4 md:col-span-2"
      :label="$t('address.complement')"
      :disabled="isDisabled"
      :validator="getValidator.complement" />
    <AkInputText
      v-model="currentValue.postalCode"
      :label="$t('address.postalCode')"
      :required="required"
      className="xl:col-span-1 lg:col-span-2"
      :disabled="isDisabled"
      :validator="getValidator.postalCode" />
    <AkInputText
      v-model="currentValue.city"
      :ignored-validator="this.getIgnoredValidator"
      :label="$t('address.city')"
      :required="required"
      :formatter="toUppercase"
      className="xl:col-span-1 lg:col-span-2"
      :disabled="isDisabled"
      :validator="getValidator.city" />
    <AkInputText
      v-model="currentValue.state"
      :ignored-validator="this.getIgnoredValidator"
      :label="$t('address.state')"
      :formatter="toUppercase"
      className="xl:col-span-1 lg:col-span-2"
      :required="required"
      :disabled="isDisabled"
      :validator="getValidator.state" />
    <AkDropdown
      v-model="currentValue.country"
      :ignored-validator="this.getIgnoredValidator"
      :label="$t('address.country')"
      :options="countryConst"
      :required="required"
      className="xl:col-span-1 lg:col-span-2"
      :disabled="isDisabled"
      :validator="getValidator.country" />
    <div v-if="canChangePosition && (currentValue.placeId || isValidUnknowAddress) && !isDisabled">
      <AkInputSwitch
        v-model="changeLocation"
        :disabled="(!currentValue.placeId && !isValidUnknowAddress) || isDisabled"
        :inline="true"
        class-name="col-md-6"
        label="Modifier la localisation"
        @change="onChangeLocation" />
    </div>
    <div v-if="markerPosition && (alwaysDisplayMap || changeLocation)" class="md:col-span-2 lg:col-span-4 col-span-1">
      <GMapMap
        :center="markerPosition"
        :options="{gestureHandling: 'cooperative', mapId: 'MAP_ID'}"
        :zoom="15"
        style="width: 100%; height: 300px; border-radius: 8px">
        <GMapMarker
          :clickable="false"
          :draggable="changeLocation"
          :position="markerPosition"
          @dragend="changeMarkerLocation" />
      </GMapMap>
    </div>
  </div>
</template>
