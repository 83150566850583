<script>
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import GaView from '@components/layout/GaView';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputSwitch from '@components/input/AkInputSwitch';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import settingService from '@services/settingService';

export default {
  components: {
    AkInputSwitch,
    AkFormSubmitted,
    GaView,
  },
  mixins: [loaderMixin, messageMixin, randomRef, utilsMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'Envoi des CRGs',
    };
  },
  data() {
    return {
      submitted: false,
      settings: [],
      setting: {},
      val: false,
      id: null,
    };
  },
  validations() {
    return {};
  },
  mounted() {
    this.initSendCRG();
  },
  methods: {
    initSendCRG() {
      let p1 = settingService.setting('SEND_CRG');
      p1.then((data) => {
        //this.settings = data;
        this.setting = data;
        this.val = this.setting.val === 'true';
      });
    },
    update() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;
      this.showTotalLoader();
      this.setting.val = this.val;
      settingService
        .update(this.setting)
        .then((data) => {
          this.setting = data;
          this.success(this.$t('updated'));
        })
        .finally(() => {
          this.hideLoader();
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" title="Envoi des CRG">
    <template #content>
      <AkFormSubmitted :submitted="submitted" reference="form" @submit="update()">
        <div class="row">
          <AkInputSwitch class-name="col-md-6" v-model="val" label="Envoi des CRGs aux hôtes" />
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <button class="btn btn-primary float-right">
              {{ $t('update') }}
            </button>
          </div>
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
