import mandatService from '@services/mandatService';

export default {
  data() {
    return {};
  },
  methods: {
    autocompleteMandat(e) {
      return mandatService.autocomplete(e);
    },
    autocompleteMandatById(e) {
      return mandatService.mandat(e);
    },
  },
};
