<script>
/* COMPOSANTS */
import AkCalendar from '@components/v2/input/AkCalendar';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import companyMixin from '@mixins/companyMixin';
import mandatAutocompleteMixin from '@mixins/autocomplete/mandatAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import crgStatutConst from '@mixins/const/crgStatutConst';

export default {
  components: {
    AkAutocomplete,
    MultiSelect,
    AkCalendar,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    utilsMixin,
    sidebarMixin,
    crgStatutConst,
    companyMixin,
    mandatAutocompleteMixin,
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
    };
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.mandatAutocomplete) this.$refs.mandatAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();

      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent="search">
    <MultiSelect
      v-model="currentValue.companyIds"
      :placeholder="$t('company.one')"
      :options="this.companies"
      :loading="this.loadingCompanies"
      option-label="name"
      option-value="id"
      filter
      filter-placeholder="Filtrer"
      @change="itemSelect"
      class="form-control"
      :display-label="false" />

    <AkAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      label="Hôte"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      placeholder="Logement"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery"
      :init-method="this.autocompleteAccommodationById" />

    <AkAutocomplete
      ref="mandatAutocomplete"
      v-model="currentValue.mandatId"
      :inline="true"
      :label="$t('mandat.details')"
      option-value="id"
      option-label="num"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteMandatById"
      :search-method="this.autocompleteMandat" />

    <AkCalendar
      v-model="currentValue.dateEnd"
      :label="$t('date_end')"
      date-format="dd/mm/yy"
      @valueChange="itemSelect"
      :inline="true"
      selection-mode="single" />

    <MultiSelect
      v-model="currentValue.statusCrg"
      :options="this.crgStatutConst"
      placeholder="Statut"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
