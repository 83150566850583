export default {
  data() {
    return {
      maintenancePriorityConst: [
        {label: this.$t('maintenance.priority_urgent'), value: 'URGENT'},
        {label: this.$t('maintenance.priority_high'), value: 'HIGH'},
        {label: this.$t('maintenance.priority_medium'), value: 'MEDIUM'},
        {label: this.$t('maintenance.priority_low'), value: 'LOW'},
      ],
    };
  },
  methods: {
    displayForMaintenancePriorityConst(value) {
      let result = this.maintenancePriorityConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
