export default {
  data() {
    return {
      litigeStatusConst: [
        {label: this.$t('litige.a_traiter'), value: 'A_TRAITER'},
        {label: this.$t('litige.complete'), value: 'COMPLETE'},
        {label: this.$t('litige.validated'), value: 'VALIDATED'},
        {label: this.$t('litige.refused'), value: 'REFUSED'},
        {label: this.$t('litige.gagne'), value: 'GAGNE'},
        {label: this.$t('litige.perdu'), value: 'PERDU'},
      ],
    };
  },
  methods: {
    displayForLitigeStatusConst(value) {
      let result = this.litigeStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
