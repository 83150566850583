export default {
  data() {
    return {
      facturationVoyageurTypeConst: [
        {label: this.$t('facturation_voyageur.LITIGE'), value: 'LITIGE'},
        {label: this.$t('facturation_voyageur.RECLAMATION'), value: 'RECLAMATION'},
        {label: this.$t('facturation_voyageur.REMBOURSEMENT_VOYAGEUR'), value: 'REMBOURSEMENT_VOYAGEUR'},
      ],
    };
  },
  methods: {
    displayForFacturationVoyageurTypeConst(value) {
      let result = this.facturationVoyageurTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
