<script>
import moment from 'moment/moment';

/* COMPOSANTS */
import permissionsMixin from '@mixins/permissionsMixin';
import personnelTypeConst from '@mixins/const/personnelTypeConst';
import AkPictureAccommodation from '../v2/general/AkPictureAccommodation.vue';
import AkPrestation from './AkPrestation.vue';
import AkUnaivaibility from '@components/general/AkUnaivaibility.vue';
import AkBlockade from './AkBlockade.vue';

export default {
  components: {AkBlockade, AkPrestation, AkPictureAccommodation},
  mixins: [permissionsMixin, personnelTypeConst],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    slots: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue', 'edit-reservation', 'delete-reservation'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    tooltip(event, data) {
      this.$emit('tooltip', event, data);
    },
    hasUnaivibility(slot) {
      if (this.currentValue.unavailabilities) {
        for (let r of this.currentValue.unavailabilities) {
          let start = moment(r.startDate);
          let end = moment(r.endDate);
          if (slot.date.isBetween(start, end, undefined, '[]')) return true;
        }
      }
      return false;
    },
  },
};
</script>

<template>
  <div class="flex border-b last:border-b-0 border-lightGray">
    <div class="w-1/5 border border-lightGray border-y-0 border-l-0 flex">
      <div class="flex gap-4 items-center py-4 px-4">
        <div class="w-[48px]">
          <AkPictureAccommodation cover radius="8" :id="this.currentValue.id" :md5="this.currentValue.picture" />
        </div>

        <div class="overflow-hidden flex-1 text-overflow-ellipsis flex flex-col gap-1">
          <span class="text-primary text-md">
            <router-link
              class="font-medium"
              :to="{name: 'accommodationPage', params: {accommodationId: this.currentValue.id}}">
              {{ this.currentValue.name }}
            </router-link>
          </span>
          <span class="text-gray text-base">
            <router-link
              class="text-base font-normal"
              :to="{name: 'ownerPage', params: {ownerId: this.currentValue.ownerId}}">
              {{ this.currentValue.ownerDisplay }}
            </router-link>
          </span>
        </div>
      </div>
    </div>
    <div class="w-4/5 relative">
      <div class="grid w-full h-full items-center bg-detailsBg prestation-slot absolute z-10 grid-cols-7">
        <div v-for="n in slots" class="text-center h-full flex items-center justify-center" :key="n">
          {{ n.dayNumber }}
        </div>
      </div>
      <div class="grid absolute w-full h-full items-center grid-flow-col z-20 grid-cols-7">
        <AkBlockade
          :key="r.id"
          v-for="r of this.currentValue.reservations"
          :blockade="r"
          :first-date="slots[0].date"
          :end-date="slots[slots.length - 1].date" />
      </div>
      <div class="grid relative py-1.5 w-full h-full gap-1.5 items-center grid-flow-col z-20 grid-cols-7">
        <AkPrestation
          :key="r.id"
          v-for="r of this.currentValue.prestations"
          :prestation="r"
          :first-date="slots[0].date"
          @tooltip="tooltip" />
      </div>
    </div>
  </div>
</template>
