<script>
export default {
  components: {},
  mixins: [],
  emits: ['action'],
  props: {
    label: {
      type: String,
      required: true,
    },
    labelWaiting: {
      type: String,
      required: false,
      default: 'Merci de patienter...',
    },
    little: {
      type: Boolean,
      required: false,
      default: true,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    success: {
      type: Boolean,
      required: false,
      default: false,
    },
    danger: {
      type: Boolean,
      required: false,
      default: false,
    },
    btnClass: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
    buildClass() {
      if (this.btnClass) return this.btnClass;
      return {
        xs: this.little,
        'btn-primary-gradient': !this.secondary && !this.danger && !this.success,
        'btn-light-gray': this.secondary,
        'btn-danger': this.danger,
        'btn-success': this.success,
        disabled: this.disabled,
      };
    },
  },
  methods: {
    processAction() {
      this.loading = true;
      this.$emit('action');
    },
    init() {
      this.loading = false;
    },
    load() {
      this.loading = true;
    },
  },
};
</script>

<template>
  <button :disabled="this.disabled" @click="processAction" type="submit" v-if="!isLoading" :class="buildClass">
    {{ this.label }}
  </button>
  <span v-else class="disabled flex items-center justify-center gap-1" :class="buildClass"
    >{{ this.labelWaiting }}<span class="spinner primary"></span
  ></span>
</template>
