import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  reservationsDefautPaiement(filter) {
    return Api()
      .get('/reservations/defautpaiement', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.items = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },

  formatRes(e) {
    if (e.dateStart) e.dateStart = new Date(e.dateStart);
    if (e.dateEnd) e.dateEnd = new Date(e.dateEnd);
    if (e.dateCheckin) e.dateCheckin = new Date(e.dateCheckin);
    if (e.dateCheckout) e.dateCheckout = new Date(e.dateCheckout);
    if (e.reservationDate) e.reservationDate = new Date(e.reservationDate);

    if (e.type === 'GUEST') {
      e.blockadeReason = null;
      e.comments = null;
      e.occupantInfo = null;
    } else if (e.type === 'OWNER') {
      e.reservationSite = null;
      e.guestFirstName = null;
      e.guestLastName = null;
      e.guestPhone = null;
      e.guestPhone2 = null;
      e.guestMail = null;
      e.nbBabies = null;
      e.cautionDone = null;
      e.paymentDone = null;
      e.blockadeReason = null;
      e.dateCheckin = null;
      e.dateCheckout = null;
    } else if (e.type === 'BLOCKADE') {
      e.reservationSite = null;
      e.guestFirstName = null;
      e.guestLastName = null;
      e.guestPhone = null;
      e.guestPhone2 = null;
      e.guestMail = null;
      e.nbAdults = null;
      e.nbChildren = null;
      e.nbBabies = null;
      e.cautionDone = null;
      e.paymentDone = null;
      e.occupantInfo = null;
    }
    return e;
  },
};
