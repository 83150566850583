import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  getMissions(filter) {
    return Api()
      .get('/prestatairepage/' + filter.prestataireId + '/missions', {params: removeEmptyParams(filter)})
      .then((res) => this.formatRes(res.data));
  },
  getStats(prestataireId, startDate, endDate) {
    return Api()
      .get('/prestatairepage/' + prestataireId + '/stats?dateStart=' + startDate + '&dateEnd=' + endDate)
      .then((res) => this.formatRes(res.data));
  },
  formatRes(e) {
    return e;
  },
};
