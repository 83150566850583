import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  suivi(filter) {
    return Api()
      .get('/missionsuivipartner', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  exportList(filter) {
    return Api()
      .get('/missionsuivipartner/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  complete(updateDTO) {
    return Api()
      .put('/missionsuivipartner/complete', updateDTO)
      .then(() => updateDTO);
  },
  cancel(updateDTO) {
    return Api()
      .put('/missionsuivipartner/cancel', updateDTO)
      .then(() => updateDTO);
  },
  formatRes(e) {
    return e;
  },
};
