<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import filterListMixin from '@mixins/filterListMixin';
import companyMixin from '@mixins/companyMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import mandatAutocompleteMixin from '@mixins/autocomplete/mandatAutocompleteMixin';
import filterMixin from '@mixins/filterMixin';

/* SERVICES */
import comptaAccountService from '@services/comptaAccountService';

export default {
  components: {
    AkButtonAction,
    AkAutocompleteFilter,
    AkListFilter,
    AkAllFilter,
    GaListView,
    AkDatatablePagination,
    GaView,
  },
  mixins: [
    loaderMixin,
    filterListMixin,
    filterMixin,
    companyMixin,
    mandatAutocompleteMixin,
    ownerAutocompleteMixin,
    utilsMixin,
    sidebarMixin,
    permissionsMixin,
  ],
  metaInfo() {
    return {
      title: 'accounting.third_party_account',
    };
  },
  data() {
    return {
      accounts: [],
      loading: true,
      companies: [],
      filter: {},
    };
  },
  mounted() {
    this.filter = this.getFilter('ThirdAccount', this.filter);
    this.search();
  },
  methods: {
    search() {
      this.loading = true;
      this.saveFilter('ThirdAccount', this.filter);
      comptaAccountService.thirdPartyAccount(this.filter).then((data) => {
        this.accounts = data;
        this.trimStartForAccountsKeys();
        this.loading = false;
      });
    },
    trimStartForAccountsKeys() {
      this.$nextTick(() => {
        this.accounts = this.trimStartForArrayKeys(this.accounts, ['accommodationName', 'ownerName']);
      });
    },
    clickSearch() {
      this.search();
    },
    exportThirdPartyDetails() {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      comptaAccountService.exportThirdPartyAccount(this.filter).then(() => {
        this.hideLoader();
      });
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaThirdPartyAccountFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilterTitle(
        'GaThirdPartyAccountFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
  },
  computed: {},
};
</script>
<template>
  <GaView>
    <template #action>
      <AkButtonAction
        v-if="accounts.length > 0"
        secondary
        :label="$t('payment.download')"
        little
        @click="exportThirdPartyDetails()" />
    </template>

    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.clickSearch"
            :label="$t('role.owner')"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />

          <AkAutocompleteFilter
            v-model="this.filter.mandatId"
            itemDisplay="num"
            @search="this.clickSearch"
            :init-method="this.autocompleteMandatById"
            label="Mandat"
            :search-method="this.autocompleteMandat" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :value="accounts"
            :loading="loading"
            :lazy="false"
            :multiSortMeta="[{field: 'mandatNum', order: -1}]">
            <template #empty>
              {{ $t('accounting.empty') }}
            </template>
            <Column
              :header="$t('mandat.details')"
              field="mandatNum"
              style="min-width: 200px; width: 200px"
              :sortable="true">
              <template #body="slotProps">
                <router-link
                  v-if="slotProps.data.accountId !== null"
                  :to="{name: 'accountingThirdPartyDetails', params: {accountId: slotProps.data.accountId}}">
                  {{ slotProps.data.mandatNum }}
                </router-link>
                <span v-else>
                  {{ slotProps.data.mandatNum }}
                </span>
              </template>
            </Column>
            <Column :header="$t('accommodation.short')" sortable field="accommodationName">
              <template #body="slotProps">
                <router-link
                  v-if="slotProps.data.accommodationId !== null"
                  :to="{
                    name: 'accommodationPage',
                    params: {accommodationId: slotProps.data.accommodationId},
                    hash: '#thirdParty',
                  }">
                  {{ slotProps.data.accommodationName }}
                </router-link>
                <span v-else>
                  {{ slotProps.data.accommodationName }}
                </span>
              </template>
            </Column>
            <Column
              :header="$t('owner.ownerDisplay')"
              sortable
              field="ownerName"
              style="min-width: 300px; width: 300px">
              <template #body="slotProps">
                <router-link
                  v-if="slotProps.data.ownerId !== null"
                  :to="{name: 'ownerPage', params: {ownerId: slotProps.data.ownerId}}">
                  {{ slotProps.data.ownerName }}
                </router-link>
                <span v-else>
                  {{ slotProps.data.ownerName }}
                </span>
              </template>
            </Column>
            <Column :header="$t('account.balance')" class="text-right" sortable field="remain">
              <template #body="slotProps">
                {{ formatCurrencyEUR(slotProps.data.remain) }}
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
