import reservationService from '@services/reservationService';

export default {
  data() {
    return {};
  },
  methods: {
    autocompleteReservation(e) {
      return reservationService.autocomplete(e);
    },
    autocompleteReservationById(e) {
      return reservationService.reservationDisplay(e);
    },
  },
};
