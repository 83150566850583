export default {
  methods: {
    isStatusNew(status) {
      return status === 'NEW';
    },
    isStatusInProgress(status) {
      return status === 'IN_PROGRESS';
    },
    isStatusWaitingValidation(status) {
      return status === 'WAITING_VALIDATION';
    },
    isStatusValidated(status) {
      return status === 'VALIDATED';
    },
    isPriorityLow(priority) {
      return priority === 'LOW';
    },
    isPriorityMedium(priority) {
      return priority === 'MEDIUM';
    },
    isPriorityHigh(priority) {
      return priority === 'HIGH';
    },
    isPriorityCritical(priority) {
      return priority === 'CRITICAL';
    },
    classStatus(status) {
      if (this.isStatusNew(status)) return 'ticket-success';
      if (this.isStatusInProgress(status)) return 'ticket-progress';
      if (this.isStatusWaitingValidation(status)) return 'ticket-waitingvalidation';
      if (this.isStatusValidated(status)) return 'ticket-validated';
      return '';
    },
    classPriority(priority) {
      if (this.isPriorityLow(priority)) return 'ticket-low';
      if (this.isPriorityMedium(priority)) return 'ticket-medium';
      if (this.isPriorityHigh(priority)) return 'ticket-high';
      if (this.isPriorityCritical(priority)) return 'ticket-critical';
      return '';
    },
  },
};
