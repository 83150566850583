import Api from '@/services/api';
import {removeEmptyParams} from '@utils';
import {usePoleStore} from '../store/poleStore';

export default {
  poles(filter) {
    return Api()
      .get('/poles', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  poleStorage() {
    let store = usePoleStore();
    return store.all();
  },
  polesByEmployeeId(employeeId) {
    return Api()
      .get('/poles/employee/' + employeeId)
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  pole(id) {
    return Api()
      .get('/pole/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(pole) {
    return Api()
      .post('/pole', pole)
      .then((res) => this.formatRes(res.data));
  },
  update(pole) {
    return Api()
      .put('/pole/' + pole.id, pole)
      .then((res) => this.formatRes(res.data));
  },
  delete(pole) {
    return Api()
      .delete('/pole/' + pole.id)
      .then(() => pole);
  },
  formatRes(e) {
    return e;
  },
};
