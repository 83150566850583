export default {
  data() {
    return {
      avenantStatusFilterConst: [
        {label: this.$t('mandat.enabled_avenant'), value: 'EN_LIGNE'},
        {label: this.$t('mandat.en_cours_de_signature_avenant'), value: 'EN_COURS_DE_SIGNATURE'},
        {label: this.$t('mandat.resiliation_pending_avenant'), value: 'EN_COURS_DE_RESILIATION'},
        {label: this.$t('mandat.disabled_avenant'), value: 'RESILIE'},
      ],
    };
  },
  methods: {
    displayForAvenantStatusFilterConst(value) {
      let result = this.avenantStatusFilterConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
