<script>
/* COMPOSANTS */

import accommodationService from '@services/accommodationService';
import AkAccommodationResume from '@components/v2/general/AkAccommodationResume.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import GaView from '@components/v2/layout/GaView.vue';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import AkLoader from '../../../components/general/AkLoader.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

export default {
  components: {AkButtonAction, AkLoader, AkAccommodationResume, GaListView, GaView},
  mixins: [loaderMixin, messageMixin],
  metaInfo() {
    return {
      title: 'accommodation.my_list',
    };
  },

  props: {},
  data() {
    return {
      accommodations: undefined,
      filter: {enabled: true},
      hasPrepublished: false,
    };
  },

  mounted() {
    this.$refs.loader.load();
    let p2 = accommodationService.resume(this.filter);
    p2.then((data) => {
      this.hasPrepublished = data.some((acc) => acc.status === 'PREPUBLISHED');
      this.accommodations = data;
    }).finally(() => {
      this.$refs.loader.hide();
    });
  },
  methods: {
    exportAccommodations() {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      accommodationService.exportAccommodations(this.filter).then((data) => {
        this.hideLoader();
      });
    },
  },
  computed: {
    canDisplayOnboardingMessageForOwner() {
      return this.isOwner && this.hasPrepublished && this.accommodations.length === 0;
    },
  },
};
</script>

<template>
  <GaView :title="$t('accommodation.my_list')">
    <template #action>
      <AkButtonAction secondary :label="$t('payment.download')" little @click="exportAccommodations()" />
    </template>
    <template #content>
      <GaListView v-if="!canDisplayOnboardingMessageForOwner">
        <template #content>
          <AkLoader message="Chargement en cours" ref="loader">
            <template #content>
              <div class="md:grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 flex flex-col gap-6">
                <AkAccommodationResume v-for="item of this.accommodations" :item="item" :key="item.id" />
              </div>
            </template>
          </AkLoader>
        </template>
      </GaListView>
      <div v-else class="flex items-center justify-center">
        <span class="text-dark font-medium"
          >Les équipes de Guest Adom ont bien reçu votre audit. Vous serez informé par mail dès que l'annonce sera en
          ligne.</span
        >
      </div>
    </template>
  </GaView>
</template>
