import Api from '@/services/api';

export default {
  list() {
    return Api()
      .get('/motifsrefus')
      .then((res) => {
        return res.data;
      });
  },
};
