import Api from '@/services/api';
import {createFormDataFiles, removeEmptyParams} from '@utils';

export default {
  tickets(filter) {
    return Api()
      .get('/tickets', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  ticketsValidated(filter) {
    return Api()
      .get('/tickets/validated', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  ticketsAccommodation(accommodationId) {
    return Api()
      .get('/tickets/accommodation/' + accommodationId)
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  count(filter) {
    return Api(true, false)
      .get('/ticket/count', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  ticket(id) {
    return Api()
      .get('/ticket/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(ticket, files) {
    let data = createFormDataFiles(ticket, files);
    return Api(true, false)
      .post('/ticket', data, {
        transformRequest: (data) => {
          return data; // thats enough
        },
      })
      .then((res) => this.formatRes(res.data));
  },
  requestUnavailability(request) {
    return Api()
      .post('/ticket/unavailability', request)
      .then((res) => this.formatRes(res.data));
  },
  validate(ticket) {
    return Api()
      .put('/ticket/validate/' + ticket.id, ticket)
      .then((res) => this.formatRes(res.data));
  },
  refuse(ticket) {
    return Api()
      .put('/ticket/refuse/' + ticket.id, ticket)
      .then((res) => this.formatRes(res.data));
  },
  update(ticket, files) {
    let data = createFormDataFiles(ticket, files);
    return Api()
      .put('/ticket/' + ticket.id, data)
      .then((res) => this.formatRes(res.data));
  },
  delete(ticket) {
    return Api()
      .delete('/ticket/' + ticket.id)
      .then(() => ticket);
  },
  formatRes(e) {
    if (e.dueDate) e.dueDate = new Date(e.dueDate);
    if (e.startDate) e.startDate = new Date(e.startDate);
    if (e.closeDate) e.closeDate = new Date(e.closeDate);
    return e;
  },
};
