export default {
  data() {
    return {
      ownerChargeStatusConst: [
        {label: this.$t('facturation_hote.IN_PROGRESS'), value: 'IN_PROGRESS'},
        {label: this.$t('facturation_hote.VALIDATED'), value: 'VALIDATED'},
        {label: this.$t('facturation_hote.DELETED'), value: 'DELETED'},
      ],
      ownerChargeStatusConstConsultation: [
        {label: this.$t('facturation_hote.VALIDATED'), value: 'VALIDATED'},
        {label: this.$t('facturation_hote.DELETED'), value: 'DELETED'},
      ],
    };
  },
  methods: {
    displayForOwnerChargeStatusConst(value) {
      let result = this.ownerChargeStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
