import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  comptaCommissions(filter) {
    return Api()
      .get('/commissions', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  comptaCommission(id) {
    return Api()
      .get('/commissions/' + id)
      .then((res) => this.formatRes(res.data));
  },
  comptaCommissionForDate(filter) {
    return Api()
      .get('/commissions/date', {params: removeEmptyParams(filter)})
      .then((res) => this.formatRes(res.data));
  },
  create(comptaCommission) {
    return Api()
      .post('/commission', comptaCommission)
      .then((res) => this.formatRes(res.data));
  },
  update(comptaCommission) {
    return Api()
      .put('/commission', comptaCommission)
      .then((res) => this.formatRes(res.data));
  },
  delete(comptaCommission) {
    return Api()
      .post('/commission/delete', comptaCommission)
      .then(() => comptaCommission);
  },
  formatRes(e) {
    return e;
  },
};
