<script>
import {useVuelidate} from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';

const IBAN = require('iban');
const ibanValidator = (value) => !value || IBAN.isValid(value);

const BIC = require('bic');
const bicValidator = (value) => !value || BIC.isValid(value);

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import AkChips from '@components/v2/input/AkChips.vue';
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import AkCheckboxButton from '@components/v2/input/AkCheckboxButton.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import companyMixin from '@mixins/companyMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import transfertFrequencyConst from '@mixins/const/transfertFrequencyConst';

/* SERVICES */
import stayRateService from '@services/stayRateService';
import formatMixin from '@mixins/formatMixin';

export default {
  components: {
    AkButtonFooterSidebar,
    AkChips,
    AkCheckboxButton,
    AkSidebar,
    GaMessages,
    AkDropdown,
    AkInputText,
  },
  mixins: [messageMixin, civilitiesConst, companyMixin, permissionsMixin, formatMixin, transfertFrequencyConst],
  props: {
    stayRateId: {
      required: false,
      default: -1,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      updateSidebarVisible: false,
      stayRate: {},
      currentAccount: '',
      submitted: false,
      errors: [],
      regionalConst: [
        {label: this.$t('stayrate.city'), value: false},
        {label: this.$t('stayrate.regional'), value: true},
      ],
      list: [],
      loading: true,
    };
  },
  validations() {
    return {
      stayRate: {
        label: {required},
        codes: {required},
        regional: {required},
        account: {required},
        avantioLabel: {required},
        iban: {
          ibanValidator: helpers.withMessage('Vérifier le format du numéro IBAN', ibanValidator),
        },
        bic: {
          bicValidator: helpers.withMessage('Vérifier le format du numéro BIC', bicValidator),
        },
      },
    };
  },
  emits: ['update', 'hide'],
  methods: {
    show() {
      this.loading = true;
      this.updateSidebarVisible = true;
      stayRateService.stayrates().then((data) => {
        this.list = data;
        this.loading = false;
      });
    },
    hide() {
      this.updateSidebarVisible = false;
      this.stayRate = {};
      this.$emit('hide');
    },
    update() {
      if (this.stayRateId === -1) return;

      this.submitted = true;
      this.v$.stayRate.$touch();
      if (this.v$.stayRate.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      this.errors = [];
      if (this.currentAccount !== this.stayRate.account) {
        for (let stayRate of this.list) {
          if (stayRate.account === this.stayRate.account) {
            this.errors.push(this.$t('account.already_exist'));
            this.$refs.footerSidebar.init();
            return;
          }
        }
      }

      this.stayRate.codes = this.stayRate.codes.toString();

      stayRateService
        .update(this.stayRate)
        .then(() => {
          this.success(this.$t('stayrate.updated'));
          this.stayRate = {};
          this.$emit('update');
          this.hide();
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.$refs.sidebarMessages.error(this.$t('account.already_exist'));
            } else {
              this.$refs.sidebarMessages.error(this.$t('error.' + e.response.data.error));
            }
          }
          this.$refs.btnUpdate.init();
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
  watch: {
    stayRateId: {
      immediate: true,
      handler(e) {
        if (e && e !== -1) {
          stayRateService.stayrate(e).then((stayRate) => {
            this.stayRate = stayRate;
            this.currentAccount = JSON.parse(JSON.stringify(this.stayRate.account));
            if (this.stayRate.codes) this.stayRate.codes = this.stayRate.codes.split(',');
            this.updateSidebarVisible = true;
          });
        }
      },
    },
  },
};
</script>

<template>
  <AkSidebar
    v-model:visible="updateSidebarVisible"
    :baseZIndex="9998"
    :dismissable="true"
    @hide="hide"
    block-scroll
    position="right">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('stayrate.update') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar v-if="!loading" class="h-full flex flex-col gap-4">
          <div class="grid grid-cols-1 gap-4 md:gap-2">
            <AkInputText
              v-model="stayRate.label"
              :label="$t('stayrate.label')"
              :submitted="submitted"
              :validator="v$.stayRate.label" />
            <AkChips
              v-model="stayRate.codes"
              :label="$t('stayrate.codesChip')"
              :placeholder="$t('stayrate.codes')"
              :validator="v$.stayRate.codes"
              :submitted="submitted"
              separator="," />
            <AkCheckboxButton
              v-model="stayRate.regional"
              :label="$t('stayrate.type')"
              :multiple="false"
              :options="regionalConst"
              :submitted="submitted"
              :validator="v$.stayRate.regional" />
            <p v-if="errors.length">
              <b v-for="(error, index) in errors" :key="index" style="color: red">
                {{ error }}
              </b>
            </p>
            <AkInputText
              v-model="stayRate.account"
              :label="$t('stayrate.account')"
              :submitted="submitted"
              :validator="v$.stayRate.account" />
            <AkInputText
              v-model="stayRate.avantioLabel"
              :label="$t('stayrate.avantio_label')"
              :submitted="submitted"
              :validator="v$.stayRate.avantioLabel" />
            <AkDropdown
              v-model="stayRate.transfertFrequency"
              :label="$t('stayrate.frequency')"
              :options="transfertFrequencyConst"
              :required="false"
              :submitted="submitted"
              option-value="value" />
            <AkInputText
              v-model="stayRate.iban"
              :label="$t('stayrate.iban')"
              :submitted="submitted"
              :validator="v$.stayRate.iban" />
            <AkInputText
              v-model="stayRate.bic"
              :label="$t('stayrate.bic')"
              :submitted="submitted"
              :validator="v$.stayRate.bic" />
          </div>

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancel-label="$t('cancel')"
            :validate-label="$t('update')"
            @action="update()"
            @cancel="hide()" />
        </perfect-scrollbar>
        <div v-else class="flex flex-col items-center gap-2 pt-4">
          <span class="spinner primary xl" />
          <span class="text-gray font-normal font-inter">Récuperation en cours...</span>
        </div>
      </div>
    </template>
  </AkSidebar>
</template>
