export default {
  data() {
    return {
      ticketStatusConst: [
        {label: this.$t('ticket.status_new'), value: 'NEW'},
        {label: this.$t('ticket.status_in_progress'), value: 'IN_PROGRESS'},
        {label: this.$t('ticket.status_waiting_validation'), value: 'WAITING_VALIDATION'},
        {label: this.$t('ticket.status_validated'), value: 'VALIDATED'},
      ],
    };
  },
  methods: {
    displayForTicketStatusConst(value) {
      let result = this.ticketStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    displayForTicketStatusConstWithoutUndefined() {
      return this.ticketStatusConst.filter((el) => el.value !== undefined);
    },
  },
};
