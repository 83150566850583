<script>
/* COMPOSANTS */
import AkLabel from '@components/general/AkLabel';
import AutoComplete from 'primevue/autocomplete';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */

export default {
  components: {
    AkLabel,
    AutoComplete,
  },
  inject: {
    pDisabled: {default: null},
    pSubmitted: {default: null},
  },
  mixins: [loaderMixin, utilsMixin],
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    items: {
      type: Array,
      required: true,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    dropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    optionLabel: {
      type: [String, Function],
      required: false,
      default: 'label',
    },
    optionValue: {
      type: [String, Function],
      required: false,
      default: 'id',
    },
    displayValue: {
      type: [String, Function],
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue', 'search', 'item-select'],
  data() {
    return {
      item: undefined,
      isInit: false,
    };
  },
  mounted() {
    if (this.displayValue) {
      this.item = this.displayValue;
    }
    this.isInit = true;
  },
  methods: {
    search(event) {
      this.$emit('search', event);
    },
    itemSelect(event) {
      this.$emit('item-select', event);
    },
    reset() {
      this.item = null;
    },
    init(item) {
      this.item = item;
    },
  },
  watch: {
    item(newVal, oldVal) {
      if (newVal && newVal[this.optionValue]) {
        this.currentValue = newVal[this.optionValue];
      } else if (this.displayValue && this.isDisabled) {
        this.item = newVal;
      } else {
        this.currentValue = null;
      }
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.hasValidator && this.validator.$invalid;
    },

    isRequired() {
      return this.hasValidator && this.validator.required !== undefined;
    },
    isRequiredFailed() {
      return this.isRequired && this.validator.required.$invalid && this.alreadySubmitted;
    },
  },
};
</script>

<template>
  <div :class="[className, {'form-group': !isInline, 'form-control': isInline, disabled: isDisabled}]">
    <AkLabel v-if="label && !inline && displayLabel" :required="this.isRequired">
      {{ label }}
    </AkLabel>
    <AutoComplete
      v-model="item"
      :class="[{'p-invalid': isInvalid && alreadySubmitted, 'form-control': !isInline}]"
      :disabled="isDisabled"
      :optionLabel="optionLabel"
      :placeholder="getPlaceholder"
      :suggestions="items"
      :dropdown="dropdown"
      forceSelection
      @itemSelect="itemSelect($event)"
      @complete="search($event)">
      <template #item="slotProps">
        <slot :item="slotProps.item" name="item"></slot>
      </template>
    </AutoComplete>

    <div v-if="!isInline" class="d-flex flex-column flex-1">
      <small v-if="isRequiredFailed" class="p-error">
        {{ $t('field_required', [this.label]) }}
      </small>
    </div>
  </div>
</template>
