<script>
export default {
  components: {},
  mixins: [],
  inject: {pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isActive() {
      return this.currentValue;
    },
    hasLabel() {
      return this.label;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
  },
  methods: {
    toggle() {
      if (!this.isDisabled) this.currentValue = !this.currentValue;
    },
  },
};
</script>

<template>
  <div
    class="toggle-button py-3"
    :class="[large ? 'px-12' : 'px-5', {active: isActive, disabled: isDisabled}]"
    @click="toggle">
    <span v-if="hasLabel" class="font-inter text-dark text-sm font-medium">{{ label }}</span>
    <slot v-else></slot>
  </div>
</template>
