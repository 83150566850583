<script>
import useVuelidate from '@vuelidate/core';
import {maxLength, required, requiredIf} from '@vuelidate/validators';
import moment from 'moment';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import GaMessages from '@components/ga/layout/GaMessages';
import AkButtonValidation from '@components/v2/general/AkButtonValidation';
import AkSidebar from '@components/v2/general/AkSidebar';
import AkMissionAssignment from './AkMissionAssignment';
import PrestationBadge from '@components/general/PrestationBadge';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import ticketPriorityConst from '@mixins/const/ticketPriorityConst';
import ticketStatusConst from '@mixins/const/ticketStatusConst';
import utilsMixin from '@mixins/utilsMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';
import ticketInterlocutorTypeConst from '@mixins/const/ticketInterlocutorTypeConst';
import companyMixin from '@mixins/companyMixin';
import poleMixin from '@mixins/poleMixin';
import missionMixin from '@mixins/missionMixin';

/* SERVICES */
import missionPlanningParticipantService from '@services/missionPlanningParticipantService';
import accommodationService from '@services/accommodationService';
import ticketCategoryService from '@services/ticketCategoryService';
import ticketService from '@services/ticketService';

export default {
  components: {
    AkPictureAccommodation,
    AkButtonValidation,
    AkSidebar,
    GaMessages,
    AkFormSubmitted,
    PrestationBadge,
    AkMissionAssignment,
  },
  mixins: [messageMixin, permissionsMixin, utilsMixin, missionMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  emits: ['mission-assigned'],
  data() {
    return {
      createSidebarVisible: false,
      missionAssignment: undefined,
      submitted: false,
    };
  },
  validations() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {
    show(mission) {
      this.createSidebarVisible = true;
      this.initMission(mission);
    },
    initMission(mission) {
      if (!mission) return;
      this.missionAssignment = {...mission};
    },
    hide() {
      this.submitted = false;
      this.missionAssignment = undefined;
      this.createSidebarVisible = false;
    },
    assignMission() {
      this.submitted = true;
      this.$refs.btnValid.load();
      this.v$.$touch();

      if (this.v$.$error) {
        this.$refs.btnValid.init();
        return;
      }

      let mission = {
        prestationId: this.missionAssignment.id,
        assignedStaff: this.missionAssignment.assignedStaff,
        prestationEmployees: this.missionAssignment.prestationEmployees,
        prestationHousekeepers: this.missionAssignment.prestationHousekeepers,
      };

      // Appel WS
      missionPlanningParticipantService
        .assign(mission)
        .then((data) => {
          this.addSuccess(this.$t('planning_intervenant.success'));
          this.$emit('mission-assigned');
          this.hide();
        })
        .catch((e) => {
          let error = e.response ? e.response.data?.error : e;
          this.addError(error);
          this.$refs.btnValid.init();
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
  computed: {},
};
</script>

<template>
  <AkSidebar
    :baseZIndex="9998"
    v-model:visible="createSidebarVisible"
    block-scroll
    position="right"
    :dismissable="true">
    <template #container>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('planning_intervenant.schedule_mission') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4">
          <AkFormSubmitted
            :submitted="this.submitted"
            class="flex flex-col gap-4"
            reference="form"
            @submit="assignMission()">
            <div class="pending-mission w-full flex flex-col bg-lightGray rounded-xl">
              <AkPictureAccommodation
                disabled-link
                :id="this.missionAssignment.id"
                :md5="this.missionAssignment.accommodationPicture"
                height="150"
                cover
                :name="
                  this.missionAssignment.accommodationName +
                  ' - ' +
                  this.formatDateIfNeeded(this.missionAssignment.dateStart) +
                  ' - ' +
                  this.missionAssignment.durationDisplay
                "></AkPictureAccommodation>
              <div class="p-2 flex flex-col gap-1">
                <span class="text-dark text-ellipsis block overflow-hidden font-semibold whitespace-nowrap">{{
                  this.missionAssignment.reservationNum
                }}</span>
                <span
                  class="text-dark text-ellipsis overflow-hidden items-center font-normal whitespace-nowrap text-sm flex gap-1">
                  <PrestationBadge :type="this.missionAssignment.type" />
                  <span>-</span>
                  <span> {{ statusDisplay(this.missionAssignment.assignedStaff?.replace(' ', '')) }}</span>
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div v-for="(item, idx) in this.missionAssignment.prestationEmployees" :key="item.id">
                <AkMissionAssignment
                  v-model="this.missionAssignment.prestationEmployees[idx]"
                  :index="idx"
                  :dateStart="this.missionAssignment.dateStart"
                  :duration="this.missionAssignment.duration"
                  :companyId="this.missionAssignment.companyId"
                  :assignedStaff="this.missionAssignment.assignedStaff" />
              </div>
              <div v-for="(item, idx) in this.missionAssignment.prestationHousekeepers" :key="item.id">
                <AkMissionAssignment
                  v-model="this.missionAssignment.prestationHousekeepers[idx]"
                  :index="idx"
                  :dateStart="this.missionAssignment.dateStart"
                  :duration="this.missionAssignment.duration"
                  :companyId="this.missionAssignment.companyId"
                  :assignedStaff="this.missionAssignment.assignedStaff" />
              </div>
            </div>
            <div class="flex justify-end items-center gap-6">
              <AkButtonValidation
                class="btn-primary-gradient"
                @action="this.assignMission"
                label="Planifier"
                ref="btnValid"></AkButtonValidation>
            </div>
          </AkFormSubmitted>
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
