import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  cdcs(filter) {
    return Api()
      .get('/cdcs', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  cdcsByAccommodation(accommodationId) {
    return Api()
      .get('/cdcs/accommodation/' + accommodationId)
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  cdc(cdcId) {
    return Api()
      .get('/cdc/' + cdcId)
      .then((res) => this.formatRes(res.data));
  },
  create(cdc) {
    return Api()
      .post('/cdc', cdc)
      .then((res) => this.formatRes(res.data));
  },
  update(cdc) {
    return Api()
      .put('/cdc/' + cdc.id, cdc)
      .then((res) => this.formatRes(res.data));
  },
  delete(cdc) {
    return Api()
      .delete('/cdc/' + cdc.id)
      .then(() => cdc);
  },
  addItem(addItemDto) {
    return Api()
      .post('/cdc/additem', addItemDto)
      .then((res) => res.data);
  },
  formatRes(e) {
    return e;
  },
};
