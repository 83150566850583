<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';
import {useSidebarStore} from '@store/sidebarStore';

/* COMPOSANTS */
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputMoney from '@components/v2/input/AkInputMoney';
import AkLoader from '@components/general/AkLoader';
import GaMessages from '@components/ga/layout/GaMessages';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import reservationService from '@services/reservationService';

export default {
  components: {AkLoader, GaMessages, AkFormSubmitted, AkInputMoney, AkCalendar, AkButtonFooterSidebar},
  mixins: [sidebarMixin, utilsMixin, messageMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      reservation: undefined,
      itemReclamation: {},
      submitted: false,
    };
  },
  validations() {
    return {
      itemReclamation: {
        dateReclamation: {required},
        amountReclamationEstimated: {required},
        amountReclamationReported: {required: requiredIf(this.itemReclamation.dateStartReclamation)},
        amountReclamationOwner: {required: requiredIf(this.itemReclamation.dateStartReclamation)},
        amountReclamationGav: {required: requiredIf(this.itemReclamation.dateStartReclamation)},
        amountReclamationGas: {required: requiredIf(this.itemReclamation.dateStartReclamation)},
      },
    };
  },
  mounted() {
    this.$refs.loader.load();

    reservationService
      .reservationReclamation(this.currentValue)
      .then((data) => {
        this.reservation = data;

        this.itemReclamation = {...this.reservation};
        this.itemReclamation.estimated = !!this.itemReclamation.amountReclamationEstimated;

        if (this.itemReclamation.dateStartReclamation) {
          this.itemReclamation.amountReclamationGas = 0;
          this.itemReclamation.amountReclamationGav = 0;
          this.itemReclamation.amountReclamationOwner = 0;
        }
      })
      .finally(() => this.$refs.loader.hide());
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    dateCreated() {
      if (!this.reservation) return '';
      let date = this.reservation.dateCreated;
      return date ? this.displayDate(date) : '-';
    },
  },
  methods: {
    close() {
      useSidebarStore().$reset();
    },

    create() {
      this.submitted = true;
      this.v$.itemReclamation.$touch();

      if (this.v$.itemReclamation.$error) {
        this.$refs.submit.init();
        return;
      }

      if (this.itemReclamation.dateStartReclamation) {
        let total = this.itemReclamation.amountReclamationReported;
        let totalGas = this.itemReclamation.amountReclamationGas;
        let totalGav = this.itemReclamation.amountReclamationGav;
        let totalOwner = this.itemReclamation.amountReclamationOwner;

        if (total) {
          if (this.addNumbers([totalGas, totalGav, totalOwner]) !== total) {
            this.$refs.sidebarMessages.error("Le total des parts n'est pas égal au montant reversé");
            this.$refs.submit.init();
            return;
          }
        }
        this.itemReclamation.dateEndReclamation = new Date(this.itemReclamation.dateReclamation);
      } else this.itemReclamation.dateStartReclamation = new Date(this.itemReclamation.dateReclamation);

      this.itemReclamation.dateReclamation = null;
      this.$refs.loader.load();

      reservationService
        .updateReclamation(this.itemReclamation)
        .then((data) => {
          this.callback[0](true, this.itemReclamation.dateEndReclamation);
        })
        .catch(() => {
          this.callback[0](false, this.itemReclamation.dateEndReclamation);
        })
        .finally(() => {
          this.submitted = false;
          this.close();
        });
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :scale="2" color="var(--color-primary)" message="Récupération en cours">
    <template #content>
      <perfect-scrollbar class="h-full flex flex-col gap-4" :options="{suppressScrollX: true}">
        <div>
          <GaMessages ref="sidebarMessages" />
        </div>
        <div class="flex flex-col gap-4 mb-4">
          <div class="flex justify-between">
            <div>
              <div class="font-inter text-dark font-medium text-md">
                Réservation
                <router-link
                  @click="close"
                  class="font-inter text-dark font-medium text-md"
                  :to="{
                    name: 'reservationPage',
                    params: {reservationId: reservation.id},
                  }"
                  >{{ reservation.localizer }}
                </router-link>
              </div>
              <div class="font-inter text-gray text-sm font-medium">le {{ this.dateCreated }}</div>
            </div>
          </div>
        </div>
        <AkFormSubmitted :submitted="submitted" class="flex flex-col md:gap-4 gap-2" reference="form">
          <div class="flex flex-col gap-4">
            <AkCalendar
              v-if="itemReclamation.dateStartReclamation"
              v-model="itemReclamation.dateStartReclamation"
              :maxDate="new Date()"
              :label="$t('reservation.dateStartReclamation')"
              :disabled="true" />

            <AkCalendar
              v-model="itemReclamation.dateReclamation"
              :maxDate="new Date()"
              :validator="v$.itemReclamation.dateReclamation"
              :label="
                $t(
                  itemReclamation.dateStartReclamation
                    ? 'reservation.dateEndReclamation'
                    : 'reservation.dateStartReclamation',
                )
              "
              :min-date="itemReclamation.dateStartReclamation ? itemReclamation.dateStartReclamation : null" />

            <AkInputMoney
              v-model="itemReclamation.amountReclamationEstimated"
              :label="$t('reservation.amountReclamationEstimated')"
              :disabled="this.itemReclamation.estimated"
              :validator="v$.itemReclamation.amountReclamationEstimated" />

            <AkInputMoney
              v-if="itemReclamation.dateStartReclamation"
              v-model="itemReclamation.amountReclamationReported"
              :label="$t('reservation.amountReclamationReported')"
              :validator="v$.itemReclamation.amountReclamationReported"
              :required="true" />
            <div class="grid grid-cols-2 md:grid-cols-3 gap-4" v-if="itemReclamation.dateStartReclamation">
              <AkInputMoney
                v-model="itemReclamation.amountReclamationGas"
                :label="$t('reservation.amountReclamationGas')"
                :validator="v$.itemReclamation.amountReclamationGas" />
              <AkInputMoney
                v-model="itemReclamation.amountReclamationGav"
                :label="$t('reservation.amountReclamationGav')"
                :validator="v$.itemReclamation.amountReclamationGav" />
              <AkInputMoney
                v-model="itemReclamation.amountReclamationOwner"
                :label="$t('reservation.amountReclamationOwner')"
                :validator="v$.itemReclamation.amountReclamationOwner" />
            </div>
          </div>
          <AkButtonFooterSidebar
            ref="submit"
            :cancelLabel="$t('cancel')"
            :validateLabel="$t('create')"
            @action="create"
            @cancel="close" />
        </AkFormSubmitted>
      </perfect-scrollbar>
    </template>
  </AkLoader>
</template>
