<script>
import AkAvatar from '@components/general/AkAvatar';

export default {
  components: {AkAvatar},
  props: {
    limit: {
      type: Number,
      required: false,
      default: 5,
    },
    size: {
      type: String,
      required: true,
      default: 'small',
    },
    avatars: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div class="avatar-stack">
    <slot>
      <AkAvatar
        v-for="(avatar, index) in avatars.slice(0, this.limit)"
        :key="index"
        :size="this.size"
        :first-name="avatar.firstName"
        :last-name="avatar.lastName"
        :user-id="avatar.userId" />
      <div v-if="avatars.length > this.limit" class="v-avatar">
        <div :class="[size && 'is-' + this.size]">
          <span class="avatar is-more">
            <span class="inner p-avatar">
              <span>+{{ avatars.length - this.limit }}</span>
            </span>
          </span>
        </div>
      </div>
    </slot>
  </div>
</template>
