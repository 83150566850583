import ownerService from '@services/ownerService';

export default {
  data() {
    return {
      autocompleteOwnerQuery: 'ownerName',
    };
  },
  methods: {
    autocompleteOwner(e) {
      return ownerService.ownersAutocomplete(e);
    },
    autocompleteOwnerById(e) {
      return ownerService.owner(e);
    },
    autocompleteOwnerCustomer() {
      return {ownerStatus: 'CUSTOMER'};
    },
  },
};
