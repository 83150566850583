<script>
/* COMPOSANTS */
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/v2/layout/GaView';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import messageMixin from '@mixins/messageMixin';
import cdcmodelTypesConst from '@mixins/const/cdcmodelTypesConst';

/* SERVICES */
import cdcmodelService from '@services/cdcmodelService';
import loaderMixin from '@mixins/loaderMixin';

export default {
  components: {GaView, GaListView, AkDatatablePagination, AkButtonAction, AkDialog},
  mixins: [cdcmodelTypesConst, loaderMixin, messageMixin, randomRef],
  metaInfo() {
    return {
      title: 'settings.cdcmodels',
    };
  },
  data() {
    return {
      models: [],
      loading: true,
    };
  },
  computed: {},
  mounted() {
    cdcmodelService.cdcmodelsDefault().then((data) => {
      this.models = data;
      this.loading = false;
    });
  },
  methods: {
    resetCdc() {
      this.loading = true;
      cdcmodelService
        .resetCdc()
        .then(() => {
          this.success(this.$t('cdcmodel.cdc_reset_done'));
        })
        .catch((e) => {
          if (e.response && e.response.status === 412) {
            this.addError(this.$t('cdcmodel.error_reset_cdc_prestation'));
          } else {
            this.addError(this.$t('error.' + e.response.data.error));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialogConfirmReset(item) {
      this.item = item;
      this.$refs.dialogConfirmReset.show();
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.cdcmodels')">
    <template #action>
      <div class="flex justify-end">
        <AkButtonAction :label="$t('cdcmodel.reset')" little @click="openDialogConfirmReset()" />
      </div>
    </template>

    <template #content>
      <GaListView>
        <template #content>
          <AkDatatablePagination :lazy="false" :value="models" :loading="loading">
            <template #empty>
              {{ $t('zone.empty') }}
            </template>
            <Column :header="$t('cdcmodel.type')">
              <template #body="slotProps">
                <router-link :to="{name: 'settingsCdcDetails', params: {cdcModelId: slotProps.data.id}}">
                  {{ displayForCdcmodelTypeConst(slotProps.data.type) }}
                </router-link>
              </template>
            </Column>
            <Column :header="$t('cdcmodel.item_count')">
              <template #body="slotProps">
                {{ slotProps.data.itemCount }}
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogConfirmReset"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('confirm_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.resetCdc()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('cdcmodel.confirm_reset') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
