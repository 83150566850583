<script>
/* COMPOSANTS */
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import AkInputText from '@components/v2/input/AkInputText.vue';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import sidebarMixin from '@/mixins/sidebarMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';

/* SERVICES */
import companyService from '@services/companyService';

export default {
  components: {
    AkButtonFooterSidebarFilter,
    AkInputText,
    AkDropdown,
    MultiSelect,
  },
  mixins: [sidebarMixin, permissionsMixin, accommodationAutocompleteMixin, companyMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: [],
    };
  },
  mounted() {
    this.initCompanies();
  },
  methods: {
    itemSelect() {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) {
        this.$refs.accommodationAutocomplete.reset();
      }
      this.callback[1]();
    },
    initCompanies() {
      companyService.companies().then((data) => {
        this.companies = data;
      });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :loading="this.loadingCompanies"
      :options="this.companies"
      :placeholder="$t('employee.company')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="name"
      option-value="id"
      @change="itemSelect" />
    <AkInputText v-model="currentValue.contactName" :placeholder="$t('prestataire.prestataire')" @change="itemSelect" />
    <AkInputText
      v-model="currentValue.contactEmail"
      :placeholder="$t('client.email')"
      type="email"
      @valueChange="itemSelect" />
    <AkInputText
      v-model="currentValue.contactPhone"
      :placeholder="$t('contact.phone')"
      type="tel"
      @valueChange="itemSelect" />
    <AkDropdown
      v-model="currentValue.userEnabled"
      :label="$t('status')"
      :options="[
        {label: $t('prestataire.all'), value: null},
        {label: $t('actives'), value: true},
        {label: $t('inactives'), value: false},
      ]"
      inline
      option-label="label"
      option-value="value"
      @value-change="itemSelect" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search()" @reset="reset()" />
  </form>
</template>
