export default {
  data() {
    return {
      maintenanceTagConst: [
        {label: this.$t('maintenance.usure'), value: 'USURE'},
        {label: this.$t('maintenance.litige'), value: 'LITIGE'},
      ],
    };
  },
  methods: {
    displayForMaintenanceTagConst(value) {
      let result = this.maintenanceTagConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
