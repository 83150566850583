<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkInputFilter from '@components/v2/filter/AkInputFilter.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import PrestataireCreateSidebar from '@views/prestataires/PrestataireCreateSidebar.vue';
import PrestataireUpdateSidebar from '@views/prestataires/PrestataireUpdateSidebar.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

/* MIXINS */
import prestataireStatusConst from '@mixins/const/prestataireStatusConst';
import permissionsMixin from '@mixins/permissionsMixin';
import filterListMixin from '@mixins/filterListMixin';
import companyMixin from '@mixins/companyMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import prestataireService from '@services/prestataireService';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

export default {
  components: {
    AkInputSwitch,
    AkButtonAction,
    PrestataireCreateSidebar,
    PrestataireUpdateSidebar,
    AkAllFilter,
    AkInputFilter,
    AkListFilter,
    GaView,
    GaListView,
    AkDatatablePagination,
  },
  mixins: [permissionsMixin, prestataireStatusConst, filterListMixin, companyMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'prestataire.title',
    };
  },
  data() {
    return {
      list: [],
      totalItems: 0,
      loading: true,
      query: '',
      filter: {
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {},
  methods: {
    search() {
      this.loading = true;
      prestataireService.prestatairePagination(this.filter).then((data) => {
        this.list = data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    toggleCreateSidebar() {
      this.search();
    },
    toggleUpdateSidebar() {
      this.search();
    },
    getStatusStyle(status) {
      switch (status) {
        case 'AVAILABLE':
          return 'color: rgb(34 197 94);';
        case 'UNAVAILABLE':
          return 'color: rgb(239 68 68);';
        case 'IN_MISSION':
          return 'color: rgb(234 179 8);';
      }
    },
    openCreateSidebar() {
      if (this.$refs.prestataireCreateSidebar) this.$refs.prestataireCreateSidebar.show();
    },
    openUpdateSidebar(data) {
      if (this.$refs.prestataireUpdateSidebar) this.$refs.prestataireUpdateSidebar.show(data.id);
    },
    changeEnabled(data) {
      if (data.enabled) this.enableUser(data.id);
      else this.disableUser(data.id);
    },
    disableUser(id) {
      prestataireService.disable(id).then(() => {
        this.search();
      });
    },
    enableUser(id) {
      prestataireService.enable(id).then(() => {
        this.search();
      });
    },
    resetFilter() {
      this.filter = {
        sort: ['id:DESC'],
        page: 1,
        limit: 50,
      };
      this.toggleFilterTitle(
        'GaPrestataireFilterList',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaPrestataireFilterList',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
  },
};
</script>

<template>
  <GaView>
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <AkButtonAction :label="$t('add')" little @click="openCreateSidebar()" />
      </div>
    </template>
    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="isGas()"
            v-model="filter.companyIds"
            :display-filter="true"
            :items="companies"
            :label="$t('employee.company')"
            :multi-selection="true"
            direction="right"
            @search="search" />
          <AkInputFilter v-model="filter.contactName" :label="$t('prestataire.prestataire')" @search="search" />
          <AkInputFilter v-model="filter.contactEmail" :label="$t('client.email')" @search="search" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :filter="filter"
            :loading="loading"
            :nb-items="totalItems"
            :value="list"
            @search="search">
            <template #empty>
              {{ $t('prestataire.empty') }}
            </template>
            <Column
              :header="$t('interlocutor.prestataire')"
              header-style="width: 200px;"
              sort-field="j_last_name"
              sortable>
              <template #body="slotProps">
                <router-link :to="{name: 'prestatairePage', params: {prestataireId: slotProps.data.id}}">
                  {{ slotProps.data.contactLastName }}&nbsp;{{ slotProps.data.contactFirstName }}
                </router-link>
              </template>
            </Column>
            <Column v-if="this.isGas()" :header="$t('prestataire.company')" sort-field="j_company_name" sortable>
              <template #body="slotProps">
                {{ slotProps.data.companyName }}
              </template>
            </Column>
            <Column :header="$t('prestataire.email')" sort-field="j_email" sortable>
              <template #body="slotProps">
                {{ slotProps.data.contactEmail }}
              </template>
            </Column>
            <Column :header="$t('contact.phone')" sort-field="j_phone" sortable>
              <template #body="slotProps">
                {{ slotProps.data.contactPhone }}
              </template>
            </Column>
            <Column :header="$t('prestataire.availability')" sort-field="status" sortable>
              <template #body="slotProps">
                <span :style="getStatusStyle(slotProps.data.status)">{{
                  displayForPrestataireStatusConst(slotProps.data.status)
                }}</span>
              </template>
            </Column>
            <Column :header="$t('prestataire.enabled')" sort-field="j_enabled" sortable>
              <template #body="slotProps">
                <AkInputSwitch
                  validationPopup
                  :nameOnPopup="slotProps.data.contactLastName + ' ' + slotProps.data.contactFirstName"
                  v-model="slotProps.data.enabled"
                  @change="changeEnabled(slotProps.data)" />
              </template>
            </Column>
            <Column header-style=" width: 25px;">
              <template #body="slotProps">
                <div class="flex items-center gap-2">
                  <router-link :to="{name: 'prestatairePage', params: {prestataireId: slotProps.data.id}}">
                    <i class="ga-icon ga-external text-2xl text-primary" />
                  </router-link>
                  <div class="cursor-pointer" @click="openUpdateSidebar(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </div>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <PrestataireCreateSidebar ref="prestataireCreateSidebar" @creation="toggleCreateSidebar" />
      <PrestataireUpdateSidebar ref="prestataireUpdateSidebar" @update="toggleUpdateSidebar" />
    </template>
  </GaView>
</template>
