<script>
import AkLabel from '@components/general/AkLabel';
import Checkbox from 'primevue/checkbox';

export default {
  components: {Checkbox},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: null,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid && this.alreadySubmitted;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  mounted() {
    this.id = 'id_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  },
  methods: {},
};
</script>

<template>
  <div :class="className" class="form-group">
    <div class="form-check">
      <Checkbox
        :id="id"
        v-model="currentValue"
        :binary="true"
        :disabled="disabled"
        class="form-check-input"
        value="{{this.label}}" />
      <div class="d-flex align-items-center">
        <label class="flex-1" :for="id">
          {{ this.label }}
          {{ isRequired ? ' *' : '' }}</label
        >
      </div>

      <div>
        <small v-if="isValidatorRequired" class="p-error">{{ $t('field_required', [label]) }}</small>
      </div>
    </div>
  </div>
</template>
