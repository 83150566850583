import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  taskgroups(filter) {
    return Api()
      .get('/taskgroups', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  taskgroup(taskgroupId) {
    return Api()
      .get('/taskgroup/' + taskgroupId)
      .then((res) => this.formatRes(res.data));
  },
  create(taskgroup) {
    return Api()
      .post('/taskgroup', taskgroup)
      .then((res) => this.formatRes(res.data));
  },
  update(taskgroup) {
    return Api()
      .put('/taskgroup/' + taskgroup.id, taskgroup)
      .then((res) => this.formatRes(res.data));
  },
  delete(taskgroup) {
    return Api()
      .delete('/taskgroup/' + taskgroup.id)
      .then(() => taskgroup);
  },
  formatRes(e) {
    return e;
  },
};
