<script>
/* COMPOSANTS */
import {Icon} from '@iconify/vue';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';
/* MIXINS */
/* SERVICES */

export default {
  components: {AkButtonValidation, Icon},
  props: {
    title: {type: String, required: true},
    width: {type: String, default: '600px'},
    message: {type: String, required: true},
    loadable: {type: Boolean, required: false, default: false},
  },
  emits: ['show', 'hide', 'yes', 'no'],
  data() {
    return {
      display: false,
    };
  },
  mounted() {},
  methods: {
    performYes(event) {
      if (event === undefined) return;
      if (this.loadable) this.$refs.btnYes.load();
      this.$emit('yes');
    },
    performNo(event) {
      if (event === undefined) return;
      this.hide();
      this.$emit('no');
    },
    show() {
      this.setDisplay(true);
      this.$emit('show');
    },
    hide() {
      this.setDisplay(false);
      this.$emit('hide');
    },
    init() {
      if (this.$refs.btnYes) this.$refs.btnYes.init();
    },
    setDisplay(value) {
      this.display = value;
    },
  },
};
</script>

<template>
  <PDialog v-model:visible="display" :header="title" :modal="true" :style="{width: width}" @hide="hide()">
    <div class="flex items-center gap-2">
      <Icon icon="fluent:warning-20-regular" class="text-red" width="26" />
      <span>{{ message }}</span>
    </div>

    <template #footer>
      <div class="flex justify-center gap-2 w-full">
        <span class="btn-light-gray xs" @click="performNo">
          {{ $t('no') }}
        </span>
        <AkButtonValidation ref="btnYes" class="btn-primary-gradient xs" @click="performYes" :label="$t('yes')" />
      </div>
    </template>
  </PDialog>
</template>
