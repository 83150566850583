<script>
import moment from 'moment/moment';
import {Icon} from '@iconify/vue';

/* MIXINS */
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import prestationTypesConst from '../../mixins/const/prestationTypesConst';
import missionMixin from '../../mixins/missionMixin';

export default {
  components: {},
  mixins: [permissionsMixin, sidebarMixin, prestationTypesConst, missionMixin],
  props: {
    prestation: {
      type: Object,
      required: true,
    },
    firstDate: {
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    typeCss() {
      if (
        this.prestation.status === 'TODO' ||
        this.prestation.status === 'DOING' ||
        this.prestation.status === 'ACCEPTED'
      )
        return 'bg-[#75BB99]';
      if (this.prestation.status === 'COMPLETED') return 'bg-[#27B363]';
      if (this.prestation.status === 'CANCELED') return 'bg-[#E3324B]';
      if (this.prestation.status === 'PROPOSED') return 'bg-[#F9855E]';
      if (this.prestation.status === 'TO_PLAN') return 'bg-[#F27438]';

      return '';
    },
    textCss() {
      if (
        this.prestation.status === 'TODO' ||
        this.prestation.status === 'DOING' ||
        this.prestation.status === 'ACCEPTED'
      )
        return 'text-white';
      if (this.prestation.status === 'COMPLETED') return 'text-white';
      if (
        this.prestation.status === 'CANCELED' ||
        this.prestation.status === 'PROPOSED' ||
        this.prestation.status === 'TO_PLAN'
      )
        return 'text-white';
      return '';
    },
    title() {
      return this.displayForPrestationTypesConst(this.prestation.type);
    },
    subTitle() {
      if (this.prestation.accommodationName) return this.prestation.accommodationName;
      return this.displayForPersonnelTypeConst(this.prestation.assignedStaff);
    },
    styleReservation() {
      let colStart = moment(this.prestation.dateStart).diff(moment(this.firstDate), 'days');
      return 'grid-column : ' + (colStart + 1) + ' / ' + (colStart + 2);
    },
    hourDisplay() {
      let start = moment(this.prestation.dateStart);
      let end = moment(this.prestation.dateEnd);
      return start.format('HH:mm') + ' - ' + end.format('HH:mm');
    },

    isTypeMaintenanceOrRunning() {
      return this.prestation.type === 'RUNNING' || this.prestation.type === 'MAINTENANCE';
    },
  },
};
</script>

<template>
  <router-link
    :to="{name: 'mission-order-detail', params: {missionOrderId: this.prestation.id}}"
    class="flex flex-col items-center p-2 gap-1 overflow-hidden cursor-pointer rounded-full mx-2"
    :class="[this.typeCss]"
    v-tooltip.bottom="this.prestation.addressDisplay"
    :style="this.styleReservation">
    <span
      class="font-medium flex items-center gap-1 flex-1 text-md w-11/12 justify-center overflow-hidden whitespace-pre-wrap break-words text-ellipsis text-center"
      :class="this.textCss">
      <i class="ga-icon ga-aspirateur text-2xl" v-if="this.isTypeCleaning(this.prestation.type)" />
      <i class="ga-icon ga-user_delete text-2xl" v-if="this.isTypeCheckout(this.prestation.type)" />
      <i class="ga-icon ga-user_edit text-2xl" v-if="this.isTypeCheckin(this.prestation.type)" />
      <i class="ga-icon ga-user_out text-2xl" v-if="this.isTypeMaintenanceOrRunning" />
      <i class="ga-icon ga-user_checked text-2xl" v-if="this.isTypeQualityControl(this.prestation.type)" />

      {{ this.title }}</span
    >
    <span
      class="font-normal flex-1 text-sm overflow-hidden w-11/12 whitespace-nowrap text-ellipsis text-center"
      :class="this.textCss"
      >{{ this.subTitle }}</span
    >
    <span
      class="font-normal flex-1 text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center"
      :class="this.textCss"
      >{{ this.hourDisplay }}</span
    >
  </router-link>
</template>
