<script>
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import GaView from '@components/v2/layout/GaView';
import AkInputText from '@components/v2/input/AkInputText';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkButtonValidation from '@components/v2/general/AkButtonValidation';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import societeService from '@services/societeService';

export default {
  components: {AkButtonValidation, AkInputText, AkCalendar, GaView, AkFormSubmitted},
  mixins: [randomRef, utilsMixin, messageMixin, loaderMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.societe_guestadom',
    };
  },
  data() {
    return {
      loading: true,
      submitted: false,
      societe: {},
    };
  },
  validations() {
    return {};
  },
  mounted() {
    this.showTotalLoader();
    societeService
      .societeGuestadom()
      .then((data) => {
        this.societe = data;
        this.loading = false;
      })
      .finally(() => {
        this.hideLoader();
      });
  },
  methods: {
    update() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;

      societeService
        .update(this.societe)
        .then((data) => {
          this.societe = data;
          this.addSuccess(this.$t('updated'));
        })
        .finally(() => {
          this.$refs.btnValid.init();
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" details :title="$t('settings.societe_guestadom')">
    <template #content>
      <AkFormSubmitted :submitted="submitted" reference="form" @submit="update()" class="flex flex-col md:gap-4 gap-2">
        <div class="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-2">
          <AkInputText v-model="societe.formeJuridiqueDeLaSociete" :label="$t('societe.formeJuridique')" />
          <AkInputText v-model="societe.raisonSocialeDeLaSociete" :label="$t('societe.raisonSociale')" />
          <AkInputText v-model="societe.capital" :label="$t('societe.capital')" />
        </div>
        <div class="grid md:grid-cols-6 grid-cols-1 md:gap-4 gap-2">
          <AkInputText
            class="col-span-2"
            v-model="societe.adresseDuSiegeSocial"
            :label="$t('societe.adresseDuSiegeSocial')" />
          <AkInputText
            class="col-span-2"
            v-model="societe.complementDAdresseSiegeSocial"
            :label="$t('societe.complementDAdresseSiegeSocial')" />
          <AkInputText
            class="md:col-span-1 col-span-2"
            v-model="societe.cpDuSiegeSocial"
            :label="$t('societe.cpDuSiegeSocial')" />
          <AkInputText
            class="md:col-span-1 col-span-2"
            v-model="societe.villeDuSiegeSocial"
            :label="$t('societe.villeDuSiegeSocial')" />
        </div>
        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkInputText v-model="societe.lieuDuRcs" :label="$t('societe.lieuDuRcs')" />
          <AkInputText v-model="societe.numeroDuRcs" :label="$t('societe.numeroDuRcs')" />
        </div>
        <div class="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-2">
          <AkInputText
            v-model="societe.fonctionDuRepresentantDeLaSociete"
            :label="$t('societe.fonctionDuRepresentant')" />
          <AkInputText v-model="societe.nomDuGerant" :label="$t('societe.nomDuGerant')" />
          <AkInputText v-model="societe.prenomDuGerant" :label="$t('societe.prenomDuGerant')" />
        </div>
        <div class="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-2">
          <AkInputText
            v-model="societe.numeroTvaIntracommunautaire"
            :label="$t('societe.numeroTvaIntracommunautaire')" />
          <AkInputText v-model="societe.siret" :label="$t('societe.siret')" />
          <AkInputText v-model="societe.siren" :label="$t('societe.siren')" />
        </div>
        <div class="grid md:grid-cols-4 grid-cols-1 md:gap-4 gap-2">
          <AkInputText
            v-model="societe.numeroCarteProfessionnelImmobiliere"
            :label="$t('societe.numeroCarteProfessionnelImmobiliere')" />
          <AkCalendar
            v-model="societe.dateDeDelivranceCarteProfessionnelImmobiliere"
            :label="$t('societe.dateDeDelivranceCarteProfessionnelImmobiliere')" />
          <AkCalendar
            v-model="societe.dateDeValiditeCarteProfessionnelImmobiliere"
            :label="$t('societe.dateDeValiditeCarteProfessionnelImmobiliere')" />
          <AkInputText
            v-model="societe.organismeDelivranceCarteProfessionnelImmobiliere"
            :label="$t('societe.organismeDelivranceCarteProfessionnelImmobiliere')" />
        </div>
        <div class="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-2">
          <AkInputText v-model="societe.nomCaisseDeGarantie" :label="$t('societe.nomCaisseDeGarantie')" />
          <AkInputText v-model="societe.montantCaisseDeGarantie" :label="$t('societe.montantCaisseDeGarantie')" />
          <AkInputText
            v-model="societe.zoneGeographiqueCouvertureCaisseDeGarantie"
            :label="$t('societe.zoneGeographiqueCouvertureCaisseDeGarantie')" />
        </div>
        <div class="grid md:grid-cols-6 grid-cols-1 md:gap-4 gap-2">
          <AkInputText
            class="col-span-2"
            v-model="societe.adresseCaisseDeGarantie"
            :label="$t('societe.adresseCaisseDeGarantie')" />
          <AkInputText
            class="col-span-2"
            v-model="societe.complementDAdresseCaisseDeGarantie"
            :label="$t('societe.complementDAdresseCaisseDeGarantie')" />
          <AkInputText
            class="md:col-span-1 col-span-2"
            v-model="societe.cpCaisseDeGarantie"
            :label="$t('societe.cpCaisseDeGarantie')" />
          <AkInputText
            class="md:col-span-1 col-span-2"
            v-model="societe.villeCaisseDeGarantie"
            :label="$t('societe.villeCaisseDeGarantie')" />
        </div>
        <div class="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-2">
          <AkInputText v-model="societe.nomAssuranceRc" :label="$t('societe.nomAssuranceRc')" />
          <AkInputText v-model="societe.numeroDePoliceAssuranceRc" :label="$t('societe.numeroDePoliceAssuranceRc')" />
          <AkInputText
            v-model="societe.zoneDeGeographieCouvertureAssuranceRc"
            :label="$t('societe.zoneDeGeographieCouvertureAssuranceRc')" />
        </div>
        <div class="grid md:grid-cols-6 grid-cols-1 md:gap-4 gap-2">
          <AkInputText
            class="col-span-2"
            v-model="societe.adresseAssuranceRc"
            :label="$t('societe.adresseAssuranceRc')" />
          <AkInputText
            class="col-span-2"
            v-model="societe.complementDAdresseAssuranceRc"
            :label="$t('societe.complementDAdresseAssuranceRc')" />
          <AkInputText
            class="md:col-span-1 col-span-2"
            v-model="societe.cpAssuranceRc"
            :label="$t('societe.cpAssuranceRc')" />
          <AkInputText
            class="md:col-span-1 col-span-2"
            v-model="societe.villeAssuranceRc"
            :label="$t('societe.villeAssuranceRc')" />
        </div>
        <div class="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-2">
          <AkInputText v-model="societe.civiliteResponsableRgpd" :label="$t('societe.civiliteResponsableRgpd')" />
          <AkInputText v-model="societe.prenomResponsableRgpd" :label="$t('societe.prenomResponsableRgpd')" />
          <AkInputText v-model="societe.nomResponsableRgpd" :label="$t('societe.nomResponsableRgpd')" />
        </div>
        <div class="grid md:grid-cols-4 grid-cols-1 md:gap-4 gap-2">
          <AkInputText v-model="societe.ape" :label="$t('societe.ape')" />
          <AkInputText v-model="societe.numeroGarantie" :label="$t('societe.numero_garantie')" />
          <AkInputText v-model="societe.numeroCompteSequestre" :label="$t('societe.numero_compte_sequestre')" />
          <AkInputText v-model="societe.nomBanqueCompteSequestre" :label="$t('societe.nom_banque_compte_sequestre')" />
        </div>
        <div class="flex md:justify-end justify-center md:mt-4 mt-2">
          <AkButtonValidation ref="btnValid" :label="$t('update')" @action="this.update"></AkButtonValidation>
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
