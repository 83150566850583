import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  ticketSubCategorys(filter) {
    return Api()
      .get('/ticketsubcategorys', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  ticketSubCategory(id) {
    return Api()
      .get('/ticketsubcategory/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(ticketSubCategory) {
    return Api()
      .post('/ticketsubcategory', ticketSubCategory)
      .then((res) => this.formatRes(res.data));
  },
  update(ticketSubCategory) {
    return Api()
      .put('/ticketsubcategory/' + ticketSubCategory.id, ticketSubCategory)
      .then((res) => this.formatRes(res.data));
  },
  delete(ticketSubCategory) {
    return Api()
      .delete('/ticketsubcategory/' + ticketSubCategory.id)
      .then(() => ticketSubCategory);
  },
  formatRes(e) {
    return e;
  },
};
