<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import AkInputFilter from '@components/v2/filter/AkInputFilter.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import ownerTypesConst from '@mixins/const/ownerTypesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import sidebarMixin from '@mixins/sidebarMixin';
import prospectOfferConst from '@mixins/const/prospectOfferConst';
import companyMixin from '@mixins/companyMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import filterListMixin from '@mixins/filterListMixin';

/* SERVICES */
import ownerService from '@services/ownerService';
import {useFilterStore} from '../../store/filterStore';

export default {
  components: {
    AkInputSwitch,
    AkInputFilter,
    AkAutocompleteFilter,
    AkAllFilter,
    GaListView,
    AkListFilter,
    AkDatatablePagination,
    GaView,
  },
  mixins: [
    ownerTypesConst,
    filterListMixin,
    accommodationAutocompleteMixin,
    prospectOfferConst,
    companyMixin,
    randomRef,
    utilsMixin,
    permissionsMixin,
    sidebarMixin,
  ],
  metaInfo() {
    return {
      title: 'owner.list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      totalItems: 0,
      item: {},
      filter: {
        ownerStatus: 'CUSTOMER',
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    let filter = useFilterStore().getFilter();
    if (filter) {
      this.filter = {...this.filter, ...filter};
    }
    this.search();
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.OWNER.MANAGE);
    },
  },
  methods: {
    search() {
      this.loading = true;
      ownerService.owners(this.filter).then((response) => {
        this.list = response;
        this.loadFieldsForSort();
        this.loading = false;
      });
    },
    loadFieldsForSort() {
      for (let owner of this.list) {
        if (owner.contactList.length > 0) {
          let contact = owner.contactList[0];
          owner.contactPhone = contact.phone;
          owner.contactEmail = contact.email;
          owner.contactDisplay = contact.display;
        }
      }
    },
    classForAccess(data) {
      return data.userId ? 'ga-icon ga-check_circle text-primary' : 'ga-icon ga-close_circle text-gray';
    },
    changeUserEnabled(data) {
      if (!this.hasManagePermission) return;
      if (data.userEnabled) this.enableUser(data);
      else this.disableUser(data);
    },
    disableUser(data) {
      ownerService.disable(data).then((data) => {
        //this.search();
      });
    },
    enableUser(data) {
      ownerService.enable(data).then((data) => {
        //this.search();
      });
    },
    resetFilter() {
      this.filter = {
        ownerStatus: 'CUSTOMER',
        sort: ['id:DESC'],
      };

      this.toggleFilterTitle(
        'GaOwnerListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaOwnerListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    sortOwnerTypeField({ownerType}) {
      return this.ownerTypesConstAlphabetical.findIndex(({value}) => value === ownerType);
    },
  },
};
</script>

<template>
  <GaView>
    <template #content>
      <GaListView searchable @search="this.searchQuery">
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.search"
            :label="$t('employee.company')"
            direction="right" />
          <AkInputFilter @search="search" v-model="filter.ownerName" :label="$t('role.owner')" />
          <AkAutocompleteFilter
            v-model="filter.accommodationId"
            :init-method="autocompleteAccommodationById"
            :label="$t('accommodation_title')"
            :search-label="autocompleteAccommodationQuery"
            :search-method="autocompleteAccommodation"
            direction="right"
            item-display="display"
            item-value="id"
            @search="search" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination :loading="loading" :value="list" :lazy="false">
            <template #empty>
              {{ $t('owner.empty') }}
            </template>
            <Column
              :header="$t('prospect.ownerType')"
              :sortable="true"
              :sort-field="sortOwnerTypeField"
              field="ownerType"
              style="width: 200px">
              <template #body="slotProps">
                {{ displayForOwnerTypeConst(slotProps.data.ownerType) }}
              </template>
            </Column>
            <Column
              :header="$t('prospect.company')"
              style="width: 300px; min-width: 300px"
              :sortable="true"
              field="companyName">
              <template #body="slotProps">
                {{ slotProps.data.companyName }}
              </template>
            </Column>
            <Column :header="$t('prospect.offer')" style="width: 150px" :sortable="true" field="offer">
              <template #body="slotProps">
                {{ displayForProspectOfferConst(slotProps.data.offer) }}
              </template>
            </Column>
            <Column
              :header="$t('interlocutor.proprietaire')"
              style="min-width: 300px"
              :sortable="true"
              field="contactDisplay">
              <template #body="slotProps">
                <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.id}}">
                  {{ slotProps.data.contactDisplay }}
                </router-link>
              </template>
            </Column>
            <Column :header="$t('contact.email')" style="width: 400px" :sortable="true" field="contactEmail">
              <template #body="slotProps">
                {{ slotProps.data.contactEmail }}
              </template>
            </Column>
            <Column :header="$t('contact.phone')" style="width: 200px" :sortable="true" field="contactPhone">
              <template #body="slotProps">
                {{ slotProps.data.contactPhone }}
              </template>
            </Column>
            <Column
              v-if="this.isGasGav()"
              :header="$t('user.access')"
              body-style="text-align: center; overflow: visible"
              header-style=" width: 150px; text-align: center">
              <template #body="slotProps">
                <i class="text-2xl" :class="classForAccess(slotProps.data)" />
              </template>
            </Column>
            <Column
              v-if="this.isGasGav()"
              :header="$t('user.enabled')"
              :sortable="true"
              body-style="text-align: center; overflow: visible"
              field="userEnabled"
              header-style=" width: 100px; text-align: center">
              <template #body="slotProps">
                <AkInputSwitch
                  validationPopup
                  :nameOnPopup="slotProps.data.contactDisplay"
                  v-model="slotProps.data.userEnabled"
                  @change="changeUserEnabled(slotProps.data)" />
              </template>
            </Column>

            <Column header-style=" width: 100px;" header=" ">
              <template #body="slotProps">
                <div class="flex items-center gap-2">
                  <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.id}}">
                    <i class="ga-icon ga-external text-2xl text-primary" />
                  </router-link>
                  <router-link
                    v-if="hasManagePermission"
                    :to="{name: 'ownerUpdate', params: {ownerId: slotProps.data.id}}">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </router-link>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
