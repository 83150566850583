<script>
import {useVuelidate} from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';

const IBAN = require('iban');
const ibanValidator = (value) => !value || IBAN.isValid(value);

const BIC = require('bic');
const bicValidator = (value) => !value || BIC.isValid(value);

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import AkChips from '@components/v2/input/AkChips.vue';
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import AkCheckboxButton from '@components/v2/input/AkCheckboxButton.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import companyMixin from '@mixins/companyMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import transfertFrequencyConst from '@mixins/const/transfertFrequencyConst';

/* SERVICES */
import stayRateService from '@services/stayRateService';
import formatMixin from '@mixins/formatMixin';

export default {
  components: {
    AkButtonFooterSidebar,
    AkChips,
    AkCheckboxButton,
    AkSidebar,
    GaMessages,
    AkDropdown,
    AkInputText,
  },
  mixins: [messageMixin, civilitiesConst, companyMixin, permissionsMixin, formatMixin, transfertFrequencyConst],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      createSidebarVisible: false,
      stayRate: {},
      submitted: false,
      errors: [],
      regionalConst: [
        {label: this.$t('stayrate.city'), value: false},
        {label: this.$t('stayrate.regional'), value: true},
      ],
      list: [],
    };
  },
  validations() {
    return {
      stayRate: {
        label: {required},
        codes: {required},
        regional: {required},
        account: {required},
        avantioLabel: {required},
        iban: {
          ibanValidator: helpers.withMessage('Vérifier le format du numéro IBAN', ibanValidator),
        },
        bic: {
          bicValidator: helpers.withMessage('Vérifier le format du numéro BIC', bicValidator),
        },
      },
    };
  },
  emits: ['creation'],
  methods: {
    show() {
      this.createSidebarVisible = true;
      this.stayRate.regional = false;
      stayRateService.stayrates().then((data) => {
        this.list = data;
      });
    },
    hide() {
      this.createSidebarVisible = false;
      this.stayRate = {};
    },
    create() {
      this.submitted = true;
      this.v$.stayRate.$touch();
      if (this.v$.stayRate.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      this.errors = [];
      for (let stayRate of this.list) {
        if (stayRate.account === this.stayRate.account) {
          this.errors.push(this.$t('account.already_exist'));
          this.$refs.footerSidebar.init();
          return;
        }
      }

      this.stayRate.codes = this.stayRate.codes.toString();

      stayRateService
        .create(this.stayRate)
        .then(() => {
          this.success(this.$t('stayrate.added'));
          this.stayRate = {};
          this.$emit('creation');
          this.hide();
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.$refs.sidebarMessages.error(this.$t('account.already_exist'));
            } else {
              this.$refs.sidebarMessages.error(this.$t('error.' + e.response.data.error));
            }
          }
          this.$refs.btnCreate.init();
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <AkSidebar
    v-model:visible="createSidebarVisible"
    :baseZIndex="9998"
    :dismissable="true"
    @hide="hide"
    block-scroll
    position="right">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('stayrate.add') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4">
          <div class="grid grid-cols-1 gap-4 md:gap-2">
            <AkInputText
              v-model="stayRate.label"
              :label="$t('stayrate.label')"
              :submitted="submitted"
              :validator="v$.stayRate.label" />
            <AkChips
              v-model="stayRate.codes"
              :label="$t('stayrate.codesChip')"
              :placeholder="$t('stayrate.codes')"
              :validator="v$.stayRate.codes"
              :submitted="submitted"
              separator="," />
            <AkCheckboxButton
              v-model="stayRate.regional"
              :label="$t('stayrate.type')"
              :multiple="false"
              :options="regionalConst"
              :submitted="submitted"
              :validator="v$.stayRate.regional" />
            <p v-if="errors.length">
              <b v-for="(error, index) in errors" :key="index" style="color: red">
                {{ error }}
              </b>
            </p>
            <AkInputText
              v-model="stayRate.account"
              :label="$t('stayrate.account')"
              :submitted="submitted"
              :validator="v$.stayRate.account" />
            <AkInputText
              v-model="stayRate.avantioLabel"
              :label="$t('stayrate.avantio_label')"
              :submitted="submitted"
              :validator="v$.stayRate.avantioLabel" />
            <AkDropdown
              v-model="stayRate.transfertFrequency"
              :label="$t('stayrate.frequency')"
              :options="transfertFrequencyConst"
              :required="false"
              :submitted="submitted"
              option-value="value" />
            <AkInputText
              v-model="stayRate.iban"
              :label="$t('stayrate.iban')"
              :submitted="submitted"
              :validator="v$.stayRate.iban" />
            <AkInputText
              v-model="stayRate.bic"
              :label="$t('stayrate.bic')"
              :submitted="submitted"
              :validator="v$.stayRate.bic" />
          </div>

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancel-label="$t('cancel')"
            :validate-label="$t('add')"
            @action="create()"
            @cancel="hide()" />
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
