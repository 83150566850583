<script>
/* COMPOSANTS */
import GaListTabView from '@components/v2/layout/GaListTabView';
import AkInputFilter from '@components/v2/filter/AkInputFilter';
import AkCalendarPeriodFilter from '@components/v2/filter/AkCalendarPeriodFilter.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import filterListMixin from '@mixins/filterListMixin';

/* SERVICES */
import comptaReconciliationService from '@services/comptaReconciliationService';

export default {
  components: {
    GaListTabView,
    AkInputFilter,
    AkCalendarPeriodFilter,
    AkDatatablePagination,
  },
  mixins: [utilsMixin, sidebarMixin, filterListMixin],
  props: {
    tabs: {
      type: Array,
      required: false,
      default: () => {
        return undefined;
      },
    },
  },
  emits: ['tab'],
  data() {
    return {
      filter: {
        page: 1,
        limit: 50,
        sort: ['date:DESC'],
      },
      list: [],
      nbItems: 0,
      reconciliations: [],
      stayratesGroup: [],
      loading: false,

      operations: [],
      reconciliationIds: [],
      groupeTDS: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    // SEARCH

    search() {
      this.loading = true;
      this.list = [];

      comptaReconciliationService
        .comptaReconciliations(this.filter)
        .then((data) => {
          this.nbItems = data.items;
          this.list = data.data;
          for (let reconciliation of this.reconciliations) {
            let transfert = reconciliation.reconciliation;
            transfert.tdsGroupLabel = reconciliation.label;
            this.list.push(transfert);
          }
        })
        .finally(() => (this.loading = false));
    },

    // SIDEBAR

    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'accountingStayRateDetails',
        params: {reconciliationId: data.id, groupLabel: data.tdsGroupLabel},
      });
      // this.toggleSidebar('GaStayRateSidebar', this.$t('review.details'), data.id);
    },
  },
};
</script>
<template>
  <GaListTabView :tabs="tabs" currentTab="stayRateTransfert" @tab="this.$emit('tab', $event)">
    <template #filter>
      <AkInputFilter
        v-model="this.filter.numVirement"
        @search="this.clickSearch"
        :label="$t('visitor_tax.transfert_num')"
        direction="right" />
      <AkCalendarPeriodFilter v-model="this.filter.period" direction="right" @search="this.search" label="Période" />
    </template>

    <template #content>
      <AkDatatablePagination
        :loading="this.loading"
        :value="this.list"
        lazy
        :nb-items="this.nbItems"
        :filter="this.filter"
        @search="this.search"
        @row-click="rowClick($event)">
        <template #empty>
          {{ $t('operation.empty') }}
        </template>

        <Column :header="$t('accounting.operations')" sort-field="date" sortable style="max-width: 125px; width: 125px">
          <template #body="slotProps">
            {{ this.formatDateIfNeeded(slotProps.data.date) }}
          </template>
        </Column>

        <Column
          :header="$t('visitor_tax.transfert_num')"
          sort-field="num_virement"
          sortable
          style="min-width: 200px; width: 300px">
          <template #body="slotProps">
            {{ slotProps.data.numVirement }}
          </template>
        </Column>

        <Column :header="$t('visitor_tax.group')">
          <template #body="slotProps">
            {{ slotProps.data.tdsGroupLabel }}
          </template>
        </Column>

        <Column
          :header="$t('visitor_tax.period')"
          sort-field="date_start"
          sortable
          style="max-width: 150px; width: 150px">
          <template #body="slotProps">
            {{ this.displayDatesSeparator(slotProps.data.dateStart, slotProps.data.dateEnd, false) }}
          </template>
        </Column>

        <Column
          :header="$t('accounting.amount')"
          sort-field="balance"
          sortable
          style="max-width: 100px; width: 100px"
          class="text-right">
          <template #body="slotProps">
            {{ this.formatCurrencyEUR(slotProps.data.balance) }}
          </template>
        </Column>
      </AkDatatablePagination>
    </template>
  </GaListTabView>
</template>
