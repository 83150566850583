<script>
import AkLabel from '@components/general/AkLabel';
import InputText from 'primevue/inputtext';

export default {
  components: {AkLabel, InputText},
  inject: {
    pSubmitted: {default: null},
    pDisabled: {default: null},
  },
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    ignoredValidator: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    displayLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    link: {
      type: Object,
      required: false,
      default: undefined,
    },
    linkExternal: {
      type: Object,
      required: false,
      default: undefined,
    },
    displayer: {
      type: Function,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        if (this.displayer) return this.displayer(this.modelValue);
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      if (this.hasValidator && this.ignoredValidator.length > 0) {
        for (let error of this.validator.$errors) {
          if (this.ignoredValidator.indexOf(error.$validator) === -1) {
            return true;
          }
        }
      } else {
        return this.hasValidator && this.validator.$invalid;
      }
      return false;
    },
    isRequired() {
      return (
        this.hasValidator &&
        this.validator.required !== undefined &&
        (this.validator.required.$params.type === 'required' ||
          (this.validator.required.$params.type === 'requiredIf' && this.validator.required.$params.prop === true))
      );
    },
    isRequiredFailed() {
      return (
        this.isRequired &&
        this.ignoredValidator.indexOf('required') === -1 &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },

    isMinLength() {
      return this.hasValidator && this.validator.minLengthValue !== undefined;
    },
    isMinLengthFailed() {
      return this.isMinLength && this.validator.minLengthValue.$invalid && this.alreadySubmitted;
    },

    isMaxLength() {
      return this.hasValidator && this.validator.maxLengthValue !== undefined;
    },
    isMaxLengthFailed() {
      return this.isMaxLength && this.validator.maxLengthValue.$invalid && this.alreadySubmitted;
    },

    isMinMaxLength() {
      return this.isMinLength && this.isMaxLength;
    },
    isMinMaxLengthFailed() {
      return this.isMinMaxLength && (this.isMinLengthFailed || this.isMaxLengthFailed);
    },

    isEmail() {
      return this.hasValidator && this.validator.email !== undefined;
    },
    isEmailFailed() {
      return this.isEmail && this.validator.email.$invalid && this.alreadySubmitted;
    },

    isPhone() {
      return this.hasValidator && this.validator.phoneValidator !== undefined;
    },
    isPhoneFailed() {
      return this.isPhone && this.validator.phoneValidator.$invalid && this.alreadySubmitted;
    },

    isIban() {
      return this.hasValidator && this.validator.ibanValidator !== undefined;
    },
    isIbanFailed() {
      return this.isIban && this.validator.ibanValidator.$invalid && this.alreadySubmitted;
    },

    isBic() {
      return this.hasValidator && this.validator.bicValidator !== undefined;
    },
    isBicFailed() {
      return this.isBic && this.validator.bicValidator.$invalid && this.alreadySubmitted;
    },

    isNotUsed() {
      return this.hasValidator && this.validator.notUsed !== undefined;
    },
    isNotUsedFailed() {
      return this.isNotUsed && this.validator.notUsed.$invalid && this.alreadySubmitted;
    },
  },
  methods: {
    openExternalLink(url) {
      window.open(url);
    },
  },
};
</script>

<template>
  <div :class="[className, {'form-group': !isInline, 'form-control': isInline}]">
    <AkLabel v-if="label && !inline && displayLabel" :required="this.isRequired || this.required === true">
      {{ label }}
      <template v-if="linkExternal !== undefined || link !== undefined" #link>
        <a v-if="linkExternal && linkExternal.path" :href="linkExternal.path" target="_blank">
          <i :class="linkExternal.icon" class="color-secondary" />
        </a>
        <router-link
          v-if="displayLink && link !== undefined"
          :to="{name: link.route, params: link.params}"
          target="_blank">
          <i :class="link.icon" class="color-secondary" />
        </router-link>
      </template>
    </AkLabel>
    <InputText
      v-model="currentValue"
      :class="[inputClass, {'p-invalid': isInvalid && alreadySubmitted, 'form-control': !isInline}]"
      :disabled="isDisabled"
      :placeholder="getPlaceholder"
      :type="inputType" />

    <div v-if="!isInline" class="d-flex flex-column flex-1">
      <small v-if="isRequiredFailed" class="p-error">
        {{ $t('field_required', [this.label]) }}
      </small>
      <small v-if="isEmailFailed" class="p-error">
        {{ $t('bad_format', [this.label]) }}
      </small>
      <small v-if="isPhoneFailed" class="p-error">
        {{ $t('check_phone_number') }}
      </small>
      <small v-if="isIbanFailed" class="p-error">
        {{ $t('check_iban_number') }}
      </small>
      <small v-if="isBicFailed" class="p-error">
        {{ $t('check_bic_number') }}
      </small>
      <small v-if="isNotUsedFailed" class="p-error">
        {{ $t('key_already_used') }}
      </small>
      <small v-if="isMinMaxLengthFailed" class="p-error">
        {{
          $t('min_max_length', [
            this.label,
            this.validator.minLengthValue.$params.min,
            this.validator.maxLengthValue.$params.max,
          ])
        }}
      </small>
      <small v-else-if="isMinLengthFailed" class="p-error">
        {{ $t('min_length', [this.label, this.validator.minLengthValue.$params.min]) }}
      </small>
      <small v-else-if="isMaxLengthFailed" class="p-error">
        {{ $t('max_length', [this.label, this.validator.maxLengthValue.$params.max]) }}
      </small>
    </div>
  </div>
</template>
