<script>
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import LitigeItemList from '@views/litige/components/LitigeItemList.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import litigeService from '@services/litigeService';

export default {
  components: {
    AkButtonFooterSidebar,
    AkSidebar,
    GaMessages,
    LitigeItemList,
  },
  mixins: [utilsMixin, messageMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      sidebarVisible: false,
      litigeUpdate: {},
      submitted: false,
    };
  },
  validations() {
    return {
      litigeUpdate: {
        amountEstimatedTtc: {required},
        litigeItemList: {
          $each: helpers.forEach({
            amountEstimatedTtc: {required},
          }),
        },
      },
    };
  },
  emits: ['litige-updated'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    totalAmountEstimatedTtc() {
      if (!this.litigeUpdate) return null;
      let total = 0;
      for (let item of this.litigeUpdate.litigeItemList) {
        total = this.addNumbers([total, item.amountEstimatedTtc]);
      }
      return total;
    },
  },
  methods: {
    // SIDEBAR

    show(litige) {
      this.sidebarVisible = true;
      this.initLitige(litige);
    },
    initLitige(litige) {
      if (!litige) return;
      this.litigeUpdate = {...litige};
    },
    hide() {
      this.submitted = false;
      this.litigeUpdate = undefined;
      this.sidebarVisible = false;
    },

    // PROCESS

    process() {
      this.submitted = true;
      this.v$.$touch();

      this.litigeUpdate.amountEstimatedTtc = this.totalAmountEstimatedTtc;

      if (this.v$.litigeUpdate.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      litigeService
        .stepTrait(this.litigeUpdate)
        .then((data) => {
          this.addSuccess(this.$t('litige.processed'));
          this.$emit('litige-updated');
          this.hide();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.$refs.footerSidebar.init();
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    // CALLBACKS

    displayRemovedMessage() {
      this.$refs.sidebarMessages.success(this.$t('file.deleted'));
    },
  },
};
</script>

<template>
  <AkSidebar v-model:visible="sidebarVisible" :baseZIndex="9998" dismissable @hide="hide" block-scroll position="right">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('litige.process_litige') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4" :options="{suppressScrollX: true}">
          <div class="w-full">
            <LitigeItemList
              ref="equipments"
              v-model="litigeUpdate.litigeItemList"
              :submitted="this.submitted"
              displayForSidebar
              @removed="displayRemovedMessage" />
          </div>
          <div class="flex flex-row gap-4">
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{
                $t('litige.total_amount_estimated_ttc')
              }}</span>
              <span class="font-inter font-medium text-gray text-md">{{
                this.formatCurrencyEUR(this.totalAmountEstimatedTtc)
              }}</span>
            </div>
          </div>

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancelLabel="$t('cancel')"
            :validateLabel="$t('litige.process')"
            @action="process"
            @cancel="hide()" />
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
