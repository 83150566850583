<script>
import {useVuelidate} from '@vuelidate/core';
import {email, required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkInputFilter from '@components/v2/filter/AkInputFilter.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import EmployeeCreateSidebar from '@views/employee/EmployeeCreateSidebar.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

/* MIXINS */
import civilitiesConst from '@mixins/const/civilitiesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import randomRef from '@mixins/randomRef';
import roleKeysConst from '@mixins/const/roleKeysConst';
import roleTypesConst from '@mixins/const/roleTypesConst';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import companyMixin from '@mixins/companyMixin';
import filterListMixin from '@mixins/filterListMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import employeeService from '@services/employeeService';
import poleService from '@services/poleService';
import roleService from '@services/roleService';

export default {
  components: {
    EmployeeCreateSidebar,
    AkAllFilter,
    AkInputFilter,
    AkListFilter,
    GaListView,
    AkButtonAction,
    AkDatatablePagination,
    GaView,
    AkInputSwitch,
  },
  mixins: [
    civilitiesConst,
    permissionsMixin,
    randomRef,
    roleKeysConst,
    roleTypesConst,
    utilsMixin,
    sidebarMixin,
    companyMixin,
    filterListMixin,
    messageMixin,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'employee.list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      totalItems: 0,
      item: {},
      employee: {},
      poles: [],
      roles: [],
      filter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
    };
  },
  validations() {
    return {
      employee: {
        civility: {required},
        firstName: {required},
        lastName: {required},
        email: {required, email},
        roleKey: {required},
        roleType: {required},
        companyId: {required: requiredIf(this.requireCompany)},
      },
    };
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.RH.MANAGE);
    },
    requireCompany() {
      return this.isAdmin() && this.employee.roleType === 'GAV';
    },
  },
  mounted() {
    this.initEmployee();
    this.loading = true;

    let p1 = poleService.poles().then((data) => {
      this.poles = data;
    });

    let p2 = roleService.roles().then((data) => {
      this.roles = data
        .filter((role) => {
          if (this.isGas()) return role.type === 'GAS';
          if (this.isGav()) return role.type === 'GAV';
          return false;
        })
        .map((role) => {
          return {value: role.id, label: this.roleKeysConst.find((r) => r.value === role.technicalName).label};
        });
    });

    Promise.all([p1, p2]).then(() => (this.loading = false));
  },
  methods: {
    initEmployee() {
      this.employee = {};
      if (this.isGav()) this.employee.roleType = 'GAV';
      else if (this.isGas()) this.employee.roleType = 'GAS';
    },
    search() {
      this.loading = true;
      employeeService.employeesPagination(this.filter).then((data) => {
        this.list = data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    toggleCreateSidebar() {
      this.$nextTick(() => {
        this.$refs.employeeCreateSidebar.hide();
        this.search();
      });
    },
    openCreateSidebar() {
      if (this.$refs.employeeCreateSidebar) this.$refs.employeeCreateSidebar.show();
    },
    changeUserEnabled(data) {
      if (data.userEnabled) this.enableUser(data);
      else this.disableUser(data);
    },
    disableUser(data) {
      employeeService.disable(data).then();
    },
    enableUser(data) {
      employeeService.enable(data).then();
    },
    canDisable(data) {
      if (this.isMe(data.userId)) return false;
      return this.hasManagePermission;
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaEmployeeListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaEmployeeListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
  },
};
</script>
<template>
  <GaView>
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <AkButtonAction v-if="hasManagePermission" :label="$t('add')" little @click="openCreateSidebar()" />
      </div>
    </template>
    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="isGas()"
            v-model="filter.poleIds"
            :display-filter="true"
            :items="poles"
            :label="$t('team')"
            :multi-selection="true"
            direction="right"
            @search="search" />
          <AkInputFilter v-model="filter.name" :label="$t('interlocutor.salarie')" @search="search" />
          <AkListFilter
            v-model="filter.roleIds"
            :display-filter="true"
            :items="roles"
            :label="$t('employee.job')"
            :multi-selection="true"
            direction="right"
            item-display="label"
            item-value="value"
            @search="search" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :filter="filter"
            :loading="loading"
            :nb-items="totalItems"
            :value="list"
            @search="search">
            <template #empty>
              {{ $t('employee.empty') }}
            </template>
            <Column :header="$t('interlocutor.salarie')" field="last_name" header-style="width: 300px" sortable>
              <template #body="slotProps">
                <router-link
                  v-if="hasPermission('RH_VIEW')"
                  :to="{name: 'employeePage', params: {employeeId: slotProps.data.id}}">
                  {{ slotProps.data.lastName }}&nbsp;{{ slotProps.data.firstName }}
                </router-link>
                <template v-else>{{ slotProps.data.lastName }}&nbsp;{{ slotProps.data.firstName }}</template>
              </template>
            </Column>
            <Column :header="$t('employee.email')" field="email" header-style="width: 400px" sortable>
              <template #body="slotProps">
                {{ slotProps.data.email }}
              </template>
            </Column>
            <Column :header="$t('employee.roleKey')">
              <template #body="slotProps">
                {{ displayForRoleKeysConst(slotProps.data.roleKey) }}
              </template>
            </Column>
            <Column
              :header="$t('user.enabled')"
              body-style="text-align: center; overflow: visible"
              header-style=" width: 100px; text-align: center"
              sort-field="j_user_enabled"
              sortable>
              <template #body="slotProps">
                <AkInputSwitch
                  validationPopup
                  :nameOnPopup="slotProps.data.lastName + ' ' + slotProps.data.firstName"
                  widthPopup="500px"
                  v-model="slotProps.data.userEnabled"
                  :disabled="!canDisable(slotProps.data)"
                  @change="changeUserEnabled(slotProps.data)" />
              </template>
            </Column>
            <Column header-style=" width: 100px;">
              <template #body="slotProps">
                <div class="flex items-center gap-2">
                  <router-link
                    v-if="hasPermission('RH_VIEW')"
                    :to="{name: 'employeePage', params: {employeeId: slotProps.data.id}}">
                    <i class="ga-icon ga-external text-2xl text-primary" />
                  </router-link>
                  <router-link
                    v-if="hasPermission('RH_MANAGE')"
                    :to="{name: 'employeeUpdate', params: {employeeId: slotProps.data.id}}">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </router-link>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <EmployeeCreateSidebar ref="employeeCreateSidebar" @creation="toggleCreateSidebar" />
    </template>
  </GaView>
</template>
