import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  settings(filter) {
    return Api()
      .get('/settings', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  setting(id) {
    return Api()
      .get('/setting/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(setting) {
    return Api()
      .post('/setting', setting)
      .then((res) => this.formatRes(res.data));
  },
  update(setting) {
    return Api()
      .put('/setting/' + setting.id, setting)
      .then((res) => this.formatRes(res.data));
  },
  delete(setting) {
    return Api()
      .delete('/setting/' + setting.id)
      .then(() => setting);
  },
  formatRes(e) {
    return e;
  },
};
