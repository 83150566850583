<script>
/* MIXINS */
import missionMixin from '@mixins/missionMixin';

export default {
  mixins: [missionMixin],
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusClass() {
      if (this.status) {
        return this.status.toLowerCase().replace('_', '-');
      } else {
        return 'anomaly';
      }
    },
  },
  methods: {},
};
</script>

<template>
  <div class="prestation-status-badge" :class="`--${statusClass}`">
    {{ statusDisplay(status?.replace(' ', '')) }}
  </div>
</template>

<style lang="scss" scoped>
.m-auto .prestation-status-badge {
  margin: auto;
}

.prestation-status-badge {
  @apply rounded-[32px] text-white flex items-center px-4 py-[5px] w-fit gap-2;

  &.--proposed,
  &.--partially-accepted {
    background: #fe895e;
  }

  &.--to-plan {
    background: #f27438;
  }

  &.--accepted,
  &.--todo,
  &.--to-do,
  &.--doing {
    background: #75bb99;
  }

  &.--canceled {
    background: #e3324b;
  }

  &.--completed,
  &.--finish {
    background: #27b363;
  }

  &.--litigation,
  &.--anomaly {
    background: #e3324b;
  }
}
</style>
