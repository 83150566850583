export default {
  methods: {
    searchQuery(query) {
      this.filter.page = 1;
      this.filter.query = query;
      this.search();
    },
    clickSearch() {
      this.filter.page = 1;
      this.search();
    },
  },
};
