<script>
/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import companyMixin from '@mixins/companyMixin';
import roleKeysConst from '@mixins/const/roleKeysConst';

/* SERVICES */
import roleService from '@services/roleService';
import poleService from '@services/poleService';

export default {
  components: {
    AkButtonFooterSidebarFilter,
    AkDropdown,
    AkInputText,
    MultiSelect,
  },
  mixins: [utilsMixin, sidebarMixin, permissionsMixin, companyMixin, roleKeysConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      enabledStateConst: [
        {label: this.$t('employee.all'), value: null},
        {label: this.$t('actives'), value: true},
        {label: this.$t('inactives'), value: false},
      ],
      roles: [],
      poles: [],
    };
  },
  mounted() {
    roleService.roles().then((data) => {
      this.roles = data
        .filter((role) => {
          if (this.isGas()) return role.type === 'GAS';
          if (this.isGav()) return role.type === 'GAV';
          return false;
        })
        .map((role) => {
          return {value: role.id, label: this.roleKeysConst.find((r) => r.value === role.technicalName).label};
        });
    });

    if (this.isGas())
      poleService.poles().then((data) => {
        this.poles = data;
      });
  },
  methods: {
    itemSelect() {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :loading="this.loadingCompanies"
      :options="this.companies"
      :placeholder="$t('employee.company')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="name"
      option-value="id"
      @change="itemSelect" />
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.poleIds"
      :options="poles"
      :placeholder="$t('team')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      optionLabel="display"
      optionValue="id"
      @change="itemSelect" />
    <AkInputText v-model="currentValue.name" :placeholder="$t('interlocutor.salarie')" @valueChange="itemSelect" />
    <AkInputText
      v-model="currentValue.email"
      :placeholder="$t('client.email')"
      type="email"
      @valueChange="itemSelect" />
    <AkInputText v-model="currentValue.phone" :placeholder="$t('contact.phone')" type="tel" @valueChange="itemSelect" />
    <MultiSelect
      v-model="currentValue.roleIds"
      :options="roles"
      :placeholder="$t('employee.job')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="label"
      option-value="value"
      @change="itemSelect" />
    <AkDropdown
      v-model="currentValue.userEnabled"
      :label="$t('status')"
      :options="enabledStateConst"
      inline
      option-label="label"
      option-value="value"
      @valueChange="itemSelect" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search()" @reset="reset()" />
  </form>
</template>
