<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      required: false,
      default: 'color-black',
    },
    bgColor: {
      type: String,
      required: false,
      default: '',
    },
    border: {
      type: String,
      required: false,
      default: 'border-round',
    },
    size: {
      type: String,
      required: false,
      default: 'big',
      //big, small, tiny
    },
  },
  methods: {},
};
</script>

<template>
  <div class="ga-icon" :class="[border, bgColor, size]">
    <i :class="[icon, iconColor]" />
  </div>
</template>
