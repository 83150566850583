import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  partenairePrestations(filter) {
    return Api()
      .get('/partenaireprestations', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  partenairePrestation(id) {
    return Api()
      .get('/partenaireprestations/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(partenairePrestation) {
    return Api()
      .post('/partenaireprestation', partenairePrestation, {
        transformRequest: (data) => {
          return data;
        },
      })
      .then((res) => this.formatRes(res.data));
  },
  update(partenairePrestation) {
    return Api()
      .put('/partenaireprestation/' + partenairePrestation.id, partenairePrestation)
      .then((res) => this.formatRes(res.data));
  },
  delete(partenairePrestation) {
    return Api()
      .delete('/partenaireprestation/' + partenairePrestation.id)
      .then(() => partenairePrestation);
  },
  formatRes(e) {
    return e;
  },
};
