<script>
import AkLabel from '@components/v2/general/AkLabel';
import Dropdown from 'primevue/dropdown';

export default {
  components: {AkLabel, Dropdown},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    optionLabel: {
      type: [String, Function],
      required: false,
      default: 'label',
    },
    optionValue: {
      type: [String, Function],
      required: false,
      default: 'value',
    },
    optionGroupLabel: {
      type: [String, Function],
      required: false,
      default: 'label',
    },
    optionGroupChildren: {
      type: [String, Function],
      required: false,
      default: 'items',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionDisabled: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    link: {
      type: Object,
      required: false,
      default: undefined,
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:modelValue', 'value-change'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid;
    },
    isInline() {
      return this.inline;
    },
  },
  methods: {
    valueChange(event) {
      this.$emit('value-change', event.value);
    },
  },
};
</script>

<template>
  <div class="w-full input-layout" :class="[className, {'form-group': !isInline, 'form-control': isInline}]">
    <AkLabel :required="isRequired">
      {{ label }}
    </AkLabel>

    <Dropdown
      v-model="currentValue"
      class="w-full text-dark font-inter form-control"
      :class="[inputClass, {'p-invalid': isInvalid && alreadySubmitted, disabled: isDisabled}]"
      :disabled="isDisabled"
      :editable="editable"
      :loading="loading"
      :option-disabled="optionDisabled"
      :option-group-children="optionGroupChildren"
      :option-group-label="optionGroupLabel"
      :option-label="optionLabel"
      :option-value="optionValue"
      @change="valueChange"
      :options="options"
      :placeholder="getPlaceholder" />

    <router-link v-if="isDisabled && link !== undefined" :to="{name: link.route, params: link.params}">
      <i :class="link.icon" class="icon-link" />
    </router-link>

    <small v-if="isValidatorRequired && alreadySubmitted" class="p-error">{{ $t('field_required', [label]) }}</small>
  </div>
</template>
