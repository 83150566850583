import {defineStore} from 'pinia';

export const useAxios = defineStore('axios', {
  state: () => ({data: {}, page: {}}),
  getters: {
    requests: (state) => state.data,
    pages: (state) => state.page,
  },
  actions: {
    all() {
      return this.data;
    },
    get(url) {
      return this.data[url];
    },
    getPage(pageName) {
      return this.page[pageName];
    },
    delete(url) {
      delete this.data[url];
    },
    deletePage(page) {
      delete this.page[page];
    },
    add(url, signal) {
      this.data[url] = signal;
    },
    addPage(pageName, signal) {
      let controller = this.page[pageName];
      if (controller) controller.push(signal);
      else controller = [signal];
      this.page[pageName] = controller;
    },
  },
});
