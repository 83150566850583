import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  housekeeperPrestations(filter) {
    return Api()
      .get('/housekeeperprestations', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  housekeeperPrestation(id) {
    return Api()
      .get('/housekeeperprestation/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(housekeeperPrestation) {
    return Api()
      .post('/housekeeperprestation', housekeeperPrestation)
      .then((res) => this.formatRes(res.data));
  },
  update(housekeeperPrestation) {
    return Api()
      .put('/housekeeperprestation/' + housekeeperPrestation.id, housekeeperPrestation)
      .then((res) => this.formatRes(res.data));
  },
  delete(housekeeperPrestation) {
    return Api()
      .delete('/housekeeperprestation/' + housekeeperPrestation.id)
      .then(() => housekeeperPrestation);
  },
  formatRes(e) {
    return e;
  },
};
