import poleService from '@services/poleService';

export default {
  data() {
    return {
      poles: [],
    };
  },
  mounted() {
    poleService.poleStorage().then((data) => (this.poles = data));
  },
  computed: {
    loadingPoles() {
      return this.poles === undefined || this.poles.length === 0;
    },
  },
  methods: {
    displayForPole(id, returnLabel = '') {
      let result = this.poles.find((pole) => pole.id === id);
      return result ? result.name : returnLabel;
    },
  },
};
