<script>
/* COMPOSANTS */
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkCalendar from '@components/v2/input/AkCalendar';
import MultiSelect from 'primevue/multiselect';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import ownerChargeStatusConst from '@mixins/const/ownerChargeStatusConst';
import ownerChargeTypeConst from '@mixins/const/ownerChargeTypeConst';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import companyMixin from '@mixins/companyMixin';

export default {
  components: {
    AkAutocomplete,
    AkCalendar,
    MultiSelect,
  },
  mixins: [
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
    ownerChargeStatusConst,
    ownerChargeTypeConst,
    sidebarMixin,
    utilsMixin,
    companyMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
      this.itemSelect();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" style="row-gap: 16px" @submit.prevent="search">
    <!--AGENCE-->
    <MultiSelect
      v-model="currentValue.companyIds"
      @change="itemSelect"
      :placeholder="$t('employee.company')"
      :options="this.companies"
      class="form-control"
      option-label="name"
      option-value="id" />

    <!--HOTE-->
    <AkAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      label="Hôte"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <!--LOGEMENT-->
    <AkAutocomplete
      @valueChange="itemSelect"
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      label="Logement"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteAccommodationById"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery" />

    <!--COMPTE-->
    <!--????-->

    <!--PERIODE DATE D'OPERATION-->
    <AkCalendar
      v-model="currentValue.dateOperationPeriod"
      :inline="true"
      @valueChange="itemSelect"
      placeholder="Période date d'opération"
      date-format="dd/mm/yy"
      selection="range" />

    <!--TYPE DE FACTURATION-->
    <MultiSelect
      v-model="currentValue.type"
      :inline="true"
      :placeholder="$t('facturation_hote.type')"
      :label="$t('facturation_hote.type')"
      class="flex-1 form-control"
      @change="itemSelect"
      :options="ownerChargeTypeConst"
      optionLabel="label"
      optionValue="value" />

    <!--STATUT-->
    <MultiSelect
      v-model="currentValue.status"
      :inline="true"
      :placeholder="$t('facturation_hote.status')"
      :label="$t('facturation_hote.status')"
      class="flex-1 form-control"
      @change="itemSelect"
      :options="ownerChargeStatusConst"
      optionLabel="label"
      optionValue="value" />

    <div class="flex justify-end items-center gap-6">
      <i
        v-on:click="this.reset()"
        class="ga-icon ga-refresh text-3xl text-gray hover:text-primary hover:cursor-pointer" />
      <button class="btn-primary-gradient" type="submit">{{ this.$t('search.search') }}</button>
    </div>
  </form>
</template>
