<script>
/* COMPOSANTS */
import AkLoader from '@components/general/AkLoader';
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import comptaAccountService from '@services/comptaAccountService';
import {Icon} from '@iconify/vue';

export default {
  components: {
    Icon,
    PButton,
    AkLoader,
  },
  mixins: [randomRef, messageMixin, loaderMixin, utilsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'account.consultation',
    };
  },
  data() {
    return {
      loading: false,
      displayedNum: '',
      operations: [],
      accounts: [],
      displayAccountNum: false,
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    this.$refs.loader.hide();
  },
  computed: {
    totalDebit() {
      if (!this.operations) return 0;
      let total = 0;
      for (let operation of this.operations) {
        if (operation.amount < 0) total += operation.amount;
      }
      return total;
    },
    totalCredit() {
      if (!this.operations) return 0;
      let total = 0;
      for (let operation of this.operations) {
        if (operation.amount >= 0) total += operation.amount;
      }
      return total;
    },
    totalBalance() {
      return this.totalCredit + this.totalDebit;
    },

    totalDebitDisplay() {
      return this.formatCurrencyEUR(-1 * this.totalDebit);
    },
    totalCreditDisplay() {
      return this.formatCurrencyEUR(this.totalCredit);
    },
    totalBalanceDisplay() {
      return this.formatCurrencyEUR(this.totalBalance);
    },
    canDisplayAccountNum() {
      return this.displayAccountNum;
    },
  },
  methods: {
    clickSearch() {
      this.filter.page = 1;
      this.search();
    },
    search() {
      if (
        (this.filter.num === null || this.filter.num === undefined || this.filter.num === '') &&
        (this.filter.accommodationId === null ||
          this.filter.accommodationId === undefined ||
          this.filter.accommodationId === '')
      ) {
        this.addError(this.$t('account.no_account_number_or_accommodation'), true, true);
        this.operations = [];
        return;
      }

      this.$refs.loader.load();
      if (
        this.filter.num !== null &&
        this.filter.num !== undefined &&
        this.filter.accommodationId !== null &&
        this.filter.accommodationId !== undefined
      ) {
        this.filter.num = null;
      }
      let p1 = comptaAccountService.byAccountNum(this.filter);
      p1.then(this.loadOperations).catch((e) => {
        if (e.response) {
          if (e.response.status === 412) {
            this.$refs.loader.hide();
            this.addError(this.$t('account.no_exist'), true, true);
          } else {
            this.addError(e.response, true, true);
          }
        }
      });
      Promise.all([p1]).then(() => {
        this.$refs.loader.hide();
      });
    },
    loadOperations(data) {
      this.operations = data;

      if (this.filter.num !== '' && this.filter.num !== null && this.filter.num !== undefined) {
        this.displayedNum = this.$t('account.number') + ' : ' + this.filter.num;
        this.displayAccountNum = true;
      }

      if (
        this.filter.accommodationId !== '' &&
        this.filter.accommodationId !== null &&
        this.filter.accommodationId !== undefined
      ) {
        this.displayedNum = '';
        this.displayAccountNum = false;
      }
    },
    formatCredit(amount) {
      if (amount < 0) return '';
      return this.formatCurrencyEUR(amount);
    },
    formatDebit(amount) {
      if (amount >= 0) return '';
      return this.formatCurrencyEUR(-amount);
    },
    displayComment(slotProp, event) {
      this.showComment(slotProp);
      event.stopPropagation();
    },
    exportAccount() {
      this.showTotalLoaderWithAfter(this.$t('account.account_creation_in_progress'));
      comptaAccountService.exportAccount(this.filter).then((data) => {
        this.hideLoader();
      });
    },
    openFilterPanel() {
      this.toggleFilter('GaAccountFilterList', this.filter, this.clickSearch, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilter('GaAccountFilterList', this.filter, this.search, this.resetFilter);
    },
  },
};
</script>
<template>
  <GaView ref="ref" :title="$t('accounting.consultation')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button
          v-if="operations.length > 0"
          class="btn btn-primary"
          @click="exportAccount()"
          style="margin-right: 15px">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>

    <template #content>
      <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
        <template #content>
          <DataTable
            :always-show-paginator="false"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :rows-per-page-options="[10, 20, 50]"
            :value="operations"
            class="table"
            current-page-report-template="{first}-{last}/{totalRecords}"
            removable-sort
            sort-field="position"
            responsive-layout="scroll"
            striped-rows>
            <template #header>
              <div class="flex align-items-center justify-content-start">
                <span v-if="this.canDisplayAccountNum">
                  {{ this.displayedNum }}
                </span>
              </div>
            </template>
            <template #empty>
              {{ $t('operation.empty') }}
            </template>
            <Column :header="this.$t('operation.date')" field="date" :sortable="true" style="width: 30%">
              <template #body="slotProps">
                {{ displayDate(slotProps.data.date) }}
              </template>
            </Column>

            <Column :header="this.$t('operation.label')" :sortable="true" field="label" style="width: 30%">
              <template #body="slotProps">
                <div class="flex items-center justify-between">
                  <span class="flex-1">{{ slotProps.data.label }}</span>
                  <Icon
                    icon="ph:info"
                    width="18px"
                    v-if="slotProps.data.comment"
                    class="cursor-pointer hover:text-primary"
                    @click="displayComment(slotProps.data, $event)" />
                </div>
              </template>
            </Column>

            <Column
              :header="this.$t('accounting.debit')"
              class="text-right"
              field="amount"
              :sortable="true"
              style="width: 10%">
              <template #body="slotProps">
                {{ this.formatDebit(slotProps.data.amount) }}
              </template>
            </Column>

            <Column
              :header="this.$t('accounting.credit')"
              class="text-right"
              field="amount"
              :sortable="true"
              style="width: 10%">
              <template #body="slotProps">
                {{ this.formatCredit(slotProps.data.amount) }}
              </template>
            </Column>
            <template #footer>
              <div class="d-flex align-items-center justify-content-end" style="column-gap: 40px">
                <span
                  ><span class="poppins-medium">{{ $t('accounting.debit') }} :</span> {{ this.totalDebitDisplay }}</span
                >
                <span
                  ><span class="poppins-medium">{{ $t('accounting.credit') }} :</span>
                  {{ this.totalCreditDisplay }}</span
                >
                <span
                  ><span class="poppins-medium">{{ $t('accounting.balance') }} :</span>
                  {{ this.totalBalanceDisplay }}</span
                >
              </div>
            </template>
          </DataTable>
        </template>
      </AkLoader>
    </template>
  </GaView>
</template>
