<script>
/* COMPOSANTS */

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import upsellService from '@services/upsellService';
import upsellStatusConst from '@mixins/const/upsellStatusConst';
import GaView from '../../components/v2/layout/GaView.vue';
import GaListView from '../../components/v2/layout/GaListView.vue';
import AkDatatablePagination from '../../components/v2/general/AkDatatablePagination.vue';
import AkAllFilter from '../../components/v2/filter/AkAllFilter.vue';
import filterListMixin from '../../mixins/filterListMixin';
import ownerAutocompleteMixin from '../../mixins/autocomplete/ownerAutocompleteMixin';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import companyMixin from '../../mixins/companyMixin';
import clientAutocompleteMixin from '../../mixins/autocomplete/clientAutocompleteMixin';
import AkButtonAction from '../../components/v2/general/AkButtonAction.vue';

export default {
  components: {
    AkButtonAction,
    AkAutocompleteFilter,
    AkListFilter,
    AkAllFilter,
    AkDatatablePagination,
    GaListView,
    GaView,
  },
  mixins: [
    randomRef,
    utilsMixin,
    ownerAutocompleteMixin,
    clientAutocompleteMixin,
    companyMixin,
    messageMixin,
    permissionsMixin,
    upsellStatusConst,
    sidebarMixin,
    loaderMixin,
    filterListMixin,
  ],
  metaInfo() {
    return {
      title: 'upsell.list',
    };
  },
  data() {
    return {
      loading: false,
      filter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      totalItems: 0,
      upsells: [],
      companies: [],
    };
  },
  watch: {},
  mounted() {},
  computed: {
    isEmptyUpsells() {
      return this.upsells.length === 0;
    },
  },
  methods: {
    search() {
      this.loading = true;
      upsellService
        .upsellsPagination(this.filter)
        .then((data) => {
          this.upsells = data.data;
          this.totalItems = data.totalItems;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // FILTER
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaUpsellListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaUpsellListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    exportUpsells() {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      upsellService.exportUpsells(this.filter).then((data) => {
        this.hideLoader();
      });
    },
    rowClick(event) {},
    displayPeriodForReservation(dateStart, dateEnd) {
      return this.displayDates(dateStart, dateEnd);
    },
  },
};
</script>

<template>
  <GaView ::title="$t('upsell.list')">
    <template #action>
      <div class="flex">
        <AkButtonAction
          secondary
          :label="$t('payment.download')"
          :disabled="this.isEmptyUpsells"
          little
          @click="exportUpsells()" />
      </div>
    </template>
    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.clickSearch"
            :label="$t('role.owner')"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAutocompleteFilter
            v-model="this.filter.clientId"
            @search="this.clickSearch"
            label="Voyageur"
            item-value="id"
            item-display="display"
            direction="right"
            :search-label="this.autocompleteClientQuery"
            :init-method="this.autocompleteClientById"
            :search-method="this.autocompleteClient" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :loading="loading"
            :value="upsells"
            :filter="this.filter"
            :nb-items="totalItems"
            lazy
            @search="search"
            @row-click="rowClick($event)">
            <template #empty>
              {{ $t('upsell.empty') }}
            </template>
            <Column
              :header="$t('upsell.companyName')"
              :sortable="true"
              sort-field="j_company_name"
              style="min-width: 200px; width: 200px">
              <template #body="slotProps">
                {{ slotProps.data.companyName }}
              </template>
            </Column>
            <Column
              :header="$t('upsell.accommodationName')"
              :sortable="true"
              sort-field="j_accommodation_name"
              style="min-width: 200px; width: 350px">
              <template #body="slotProps">
                <router-link
                  :to="{
                    name: 'accommodationPage',
                    params: {
                      accommodationId: slotProps.data.accommodationId,
                    },
                  }">
                  {{ slotProps.data.accommodationName }}
                </router-link>
              </template>
            </Column>
            <Column :header="$t('upsell.ownerName')" style="min-width: 200px; width: 200px">
              <template #body="slotProps">
                <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.ownerId}}">
                  {{ slotProps.data.ownerName }}
                </router-link>
              </template>
            </Column>
            <Column
              :header="$t('upsell.reservationLocalizer')"
              :sortable="true"
              style="min-width: 200px; width: 300px"
              sort-field="j_localizer">
              <template #body="slotProps">
                <router-link
                  :to="{
                    name: 'reservationPage',
                    params: {
                      reservationId: slotProps.data.reservationId,
                    },
                  }">
                  {{ slotProps.data.reservationLocalizer }}
                </router-link>
              </template>
            </Column>

            <Column header="Séjour" :sortable="true" sort-field="j_date_checkin" style="min-width: 150px; width: 150px">
              <template #body="slotProps">
                {{ this.displayPeriodForReservation(slotProps.data.dateCheckin, slotProps.data.dateCheckout) }}
              </template>
            </Column>
            <Column header="Extra" :sortable="true" sort-field="name" style="min-width: 150px">
              <template #body="slotProps">
                {{ slotProps.data.name }}
              </template>
            </Column>
            <Column :header="$t('upsell.amount')" class="text-right" style="min-width: 100px; width: 100px">
              <template #body="slotProps">
                {{ this.formatCurrencyEUR(slotProps.data.amount) }}
              </template>
            </Column>
            <Column
              :header="$t('upsell.buyDate')"
              :sortable="true"
              sort-field="date_buy"
              style="min-width: 150px; width: 150px">
              <template #body="slotProps">
                {{ this.formatDateIfNeeded(slotProps.data.dateBuy) }}
              </template>
            </Column>
            <Column
              :header="$t('upsell.approbationDate')"
              :sortable="true"
              sort-field="date_approbation"
              style="min-width: 150px; width: 150px">
              <template #body="slotProps">
                {{ this.formatDateIfNeeded(slotProps.data.dateApprobation) }}
              </template>
            </Column>
            <Column
              :header="$t('upsell.status')"
              :sortable="true"
              sort-field="status"
              style="min-width: 100px; width: 100px">
              <template #body="slotProps">
                {{ this.displayForUpsellStatusConst(slotProps.data.status) }}
                <br />
                <span class="text-sm">
                  {{ this.displayForUpsellStatusEnsoConst(slotProps.data.statusEnso) }}
                </span>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra></template>
  </GaView>
</template>
