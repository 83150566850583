<script>
import {getDayShort, getMonthShort} from '@utils/dateTime';
import $ from 'jquery';

/* COMPOSANTS */
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import missionMixin from '@mixins/missionMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import personnelTypeConst from '@mixins/const/personnelTypeConst';
import moment from 'moment';
import AkPrestationLine from '../../../../components/general/AkPrestationLine.vue';
import prestationStatusConst from '../../../../mixins/const/prestationStatusConst';
import Integer from 'vuelidate/lib/validators/integer';

/* SERVICES */

export default {
  components: {AkPrestationLine},
  mixins: [utilsMixin, missionMixin, prestationTypesConst, prestationStatusConst, personnelTypeConst],
  props: {
    modelValue: {
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
    hasPending: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      height: '',
    };
  },
  setup() {
    return {};
  },
  watch: {
    hasPending(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.height = this.styleHeight();
      }
    },
  },
  mounted() {
    this.height = this.styleHeight();
  },
  updated() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    slots() {
      let list = [];
      if (this.dates)
        for (let d of this.dates) {
          let date = moment(d).startOf('date');
          let slot = {};
          slot.date = date;
          slot.firstDayOfMonth = date.date() === 1;
          slot.firstDay = d === this.dates[0];
          slot.dayNumber = date.format('DD');
          slot.dayNumberTitle = date.format('DD/MM');
          slot.dayTitle = date.format('ddd');
          slot.wk = date.isoWeekday() === 7 || date.isoWeekday() === 6;
          slot.today = date.isSame(moment().startOf('date'));
          list.push(slot);
        }
      return list;
    },
  },
  methods: {
    getDayShort,
    getMonthShort,
    displayTooltip() {},
    styleHeight() {
      let pending = $('.pending-missions');
      let offset = 0;
      if (pending && pending.length > 0 && this.hasPending) {
        offset = pending[0].clientHeight;
        offset += 17;
      }
      let p = undefined;
      if (this.$isMobile()) {
        p = 420 + parseInt(offset);
      } else {
        p = 375 + parseInt(offset);
      }
      return 'max-height: calc(100vh - ' + p + 'px)';
    },
  },
};
</script>

<template>
  <perfect-scrollbar class="ak-planning">
    <div class="flex border-b border-dashed border-lightGray min-w-[1800px] lg:min-w-[auto]">
      <div class="w-1/5 border-0 border-lightGray border-y-0">
        <div
          class="flex gap-4 items-center py-2 px-3.5 h-full text-sm font-semibold opacity-80 border-0 border-r border-lightGray uppercase text-gray">
          <div class="flex gap-4 items-center py-2 font-semibold uppercase text-gray">Intervenant</div>
        </div>
      </div>
      <div class="w-4/5 relative">
        <div class="grid w-full h-full items-center z-10 grid-cols-7">
          <div
            v-for="n in slots"
            class="text-center text-gray opacity-80 h-full flex flex-col items-center justify-center text-sm font-semibold gap-2 px-2 py-4"
            :key="n">
            <span class="uppercase font-semibold">{{ n.dayTitle }}</span>
            <span class="font-semibold">{{ n.dayNumberTitle }}</span>
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar
      class="min-w-[1800px] lg:min-w-[auto] ps_rail_index border-l border-r border-lightGray"
      :options="{suppressScrollX: true}">
      <div v-if="currentValue && currentValue.length > 0" class="body" :style="this.height">
        <AkPrestationLine
          v-for="(p, idx) in currentValue"
          :key="p"
          v-model="currentValue[idx]"
          :slots="slots"
          @tooltip="displayTooltip" />
      </div>
      <div v-else class="flex flex-col gap-2 mt-8 mb-8 items-center justify-center">
        <div>
          <span class="ga-icon ga-calendar text-4xl" />
        </div>
        <div>Aucun intervenant</div>
      </div>
    </perfect-scrollbar>
    <div
      class="flex justify-between items-center gap-4 border px-2 py-2 border-lightGray min-w-[1800px] lg:min-w-[auto]">
      <div class="flex gap-4">
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#75BB99]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('TODO') }}</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#27B363]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('COMPLETED') }}</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#E3324B]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('CANCELED') }}</span>
        </div>
      </div>
    </div>
  </perfect-scrollbar>

  <!-- <div class="participants-planning">
    <DataTable
      :value="currentValue"
      :paginator="true"
      :always-show-paginator="false"
      :rows="10"
      :rows-per-page-options="[10, 20, 50]"
      :loading="this.loading"
      class="table pointer gd-datatable"
      current-page-report-template="{first}-{last}/{totalRecords}"
      removable-sort
      responsive-layout="scroll"
      striped-rows>
      <Column field="name" :header="this.$t('planning_intervenant.participant')">
        <template #body="slotProps">
          <span>{{ slotProps.data.name }} ({{ this.displayForPersonnelTypeConst(slotProps.data.assignedStaff) }})</span>
        </template>
      </Column>

      <Column v-for="date in dates" :key="date.toString()" :field="date.toString()">
        <template #header>
          <div class="d-flex justify-content-around align-items-center w-100 px-1">
            <span v-text="getDayShort(date)"></span>
            <span class="date-badge bg-primary" v-text="new Date(date).getDate()"></span>
            <span v-text="getMonthShort(date)"></span>
          </div>
        </template>
        <template #body="slotProps">
          <div :class="getDayBackground(slotProps.data.dateMap[dateToIndex(date)])" class="day">
            <template v-for="mission in slotProps.data.dateMap[dateToIndex(date)]" :key="mission.id">
              <a
                v-if="mission.id !== null"
                class="task"
                :class="getTaskBackground(mission)"
                :href="`/mission-order/${mission.id}`"
                v-tooltip.bottom="mission.addressDisplay">
                <span v-text="mission.accommodationName"></span>
                <span v-text="displayForPrestationTypeConst(mission.type)" class="mt-1"></span>
                <span v-text="displayPrestationTime(mission)"></span>
              </a>
            </template>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>-->
</template>

<style lang="scss" scoped>
.participants-planning {
  th {
    padding: 13px 0;

    &:first-child {
      width: 133px !important;
      padding: 13px 24px;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 4px;
    font: 600 14px/20px 'Nunito Sans';
    color: #475467;

    &:first-child {
      text-align: center;
      padding: 8px;
    }

    &:not(:first-child) {
      vertical-align: top;
    }
  }

  .date-badge {
    border-radius: 32px;
    padding: 5px 8px;
    font: 600 12px/18px 'Nunito Sans';
    color: white;
  }

  td {
    .day {
      display: flex;
      flex-direction: column;
      margin: 8px;

      &.unavailability {
        border-radius: 4px;
        padding: 5%;
        background-image: linear-gradient(
          45deg,
          #cccccc 25%,
          #ffffff 25%,
          #ffffff 50%,
          #cccccc 50%,
          #cccccc 75%,
          #ffffff 75%,
          #ffffff 100%
        );
        background-size: 40px 40px;
        width: 100%;
        height: 100%;
        min-height: 100px;
      }

      > .task {
        padding: 8px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        > span {
          font: 400 14px/20px 'Nunito Sans';
          color: white;
        }
      }
    }
  }
}
</style>
