<script>
import moment from 'moment/moment';
import {Icon} from '@iconify/vue';

/* MIXINS */
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import prestationTypesConst from '../../mixins/const/prestationTypesConst';

export default {
  components: {},
  mixins: [permissionsMixin, sidebarMixin, prestationTypesConst],
  props: {
    unaivibility: {
      type: Object,
      required: true,
    },
    firstDate: {
      required: true,
    },
    endDate: {
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    styleReservation() {
      let colStart = 0;
      if (moment(this.unaivibility.startDate).isSameOrBefore(moment(this.firstDate))) {
        colStart = 1;
      } else {
        colStart = moment(this.unaivibility.startDate).diff(moment(this.firstDate), 'days') + 1;
      }

      let colEnd = 0;
      if (moment(this.unaivibility.endDate).isSameOrAfter(moment(this.endDate))) {
        colEnd = -1;
      } else {
        colEnd = moment(this.unaivibility.endDate).diff(moment(this.firstDate), 'days') + 2;
      }
      console.log('grid-column : ' + colStart + ' / ' + colEnd);
      return 'grid-column : ' + colStart + ' / ' + colEnd;
    },
  },
};
</script>

<template>
  <div class="overflow-hidden prestation-slot unavailable h-full" :style="this.styleReservation"></div>
</template>
