import prestataireService from '@services/prestataireService';

export default {
  methods: {
    autocompletePrestataire(e) {
      return prestataireService.autocomplete(e);
    },
    autocompletePrestataireById(e) {
      return prestataireService.prestataireDisplay(e);
    },
  },
};
