import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  accommodationRoles(filter) {
    return Api()
      .get('/accommodationroles', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  mesContacts(filter) {
    return Api()
      .get('/accommodationroles/mescontacts', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  createForCurrent(accommodation) {
    return Api().post('/accommodationrole/current', accommodation);
  },
  createForEmployee(accommodation) {
    return Api().post('/accommodationrole/employee', accommodation);
  },
  delete(accommodationRoleId) {
    return Api()
      .delete('/accommodationrole/' + accommodationRoleId)
      .then(() => accommodationRoleId);
  },
};
