import moment from 'moment/moment';
import bigDecimal from 'js-big-decimal';
import {swap} from '@utils';
import $ from 'jquery';

export default {
  methods: {
    toggleElement(refs, ref, event) {
      event.stopPropagation();
      $(refs[ref]).toggle();
      $(event.target).toggleClass('-rotate-180');
    },
    toggleElementRotate(refs, ref, elem) {
      event.stopPropagation();
      $(refs[ref]).toggle();
      $(refs[elem]).toggleClass('-rotate-180');
    },

    toggleElement90(refs, ref, event) {
      event.stopPropagation();
      $(refs[ref]).toggle();
      $(event.target).toggleClass('-rotate-90');
    },

    // OBJECTS

    isEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    // LISTS

    findIndexForId(list, id) {
      for (let i = 0; i < list.length; i++) if (list[i].id == id) return i;
      return -1;
    },
    replaceInListById(list, data) {
      let index = this.findIndexForId(list, data.id);
      list[index] = data;
    },
    removeInListById(list, id) {
      let index = this.findIndexForId(list, id);
      list.splice(index, 1);
    },
    increaseInListById(list, id) {
      let index = this.findIndexForId(list, id);
      if (index < list.length - 1) swap(list, index, index + 1);
    },
    decreaseInListById(list, id) {
      let index = this.findIndexForId(list, id);
      if (index > 0) swap(list, index, index - 1);
    },
    reorderInListByIdAndDelta(list, id, delta) {
      let index = this.findIndexForId(list, id);
      if (index >= 0) {
        if (delta > 0) {
          list.splice(index + delta + 1, 0, list[index]);
          list.splice(index, 1);
        }
        if (delta < 0) {
          list.splice(index + delta, 0, list[index]);
          list.splice(index + 1, 1);
        }
      }
    },
    setListSize(list, size) {
      while (list?.length < size) {
        list.push({});
      }
      while (list?.length > size) {
        list.pop();
      }
    },
    setListSizeFull(list, size, accommodationId, type) {
      while (list?.length < size) {
        list.push({
          accommodationId: accommodationId,
          type: type,
          tmpId: new Date().getTime(),
        });
      }
      while (list?.length > size) {
        list.pop();
      }
    },
    insertFirst(list, item) {
      list.splice(0, 0, item);
      return list;
    },
    appendLists(list1, list2) {
      let list = [];
      for (let i = 0; i < list1.length; i++) list.push(list1[i]);
      for (let i = 0; i < list2.length; i++) list.push(list2[i]);
      return list;
    },
    cloneElements(list0) {
      let list1 = [];
      list0.forEach((element) => {
        list1.push({...element});
      });
      return list1;
    },

    // DATES

    formatDateIfNeeded(date) {
      if (!date) return '';
      if (typeof date === 'string') return date;
      return this.displayDate(date);
    },
    displayDateTime(date, defaultValue) {
      if (!date) return defaultValue ? defaultValue : '';
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    displayTime(date, defaultValue) {
      if (!date) return defaultValue ? defaultValue : '';
      return moment(date).format('HH:mm');
    },
    displayDate(date, defaultValue) {
      if (!date) return defaultValue ? defaultValue : '';
      return moment(date).format('DD/MM/YYYY');
    },
    displayDates(date1, date2) {
      if (date1 && date2) return this.displayDate(date1) + ' - ' + this.displayDate(date2);
      if (date1) return this.displayDate(date1);
      if (date2) return this.displayDate(date2);
      return '';
    },
    displayDatesSeparator(date1, date2, withSeparator) {
      if (date1 && date2) return this.displayDate(date1) + (withSeparator ? ' - ' : ' ') + this.displayDate(date2);
      if (date1) return this.displayDate(date1);
      if (date2) return this.displayDate(date2);
      return '';
    },
    displayDateYearWithTwoNumbers(date) {
      if (!date) return '';
      return moment(date).format('DD/MM/YY');
    },
    displayDateWith(date, format) {
      if (!date) return '';
      return moment(date).format(format);
    },
    displayDateWithPrefix(date, format, prefix) {
      if (!date) return '';
      return this.$t(prefix) + ' ' + moment(date).format(format);
    },
    displayUTCDate(date) {
      if (!date) return '';
      return moment(date).utcOffset(0, true).toDate();
    },
    displayUTCTime(date) {
      if (!date) return '';
      const datetime = moment(date).utcOffset(0, true).toDate();
      return moment(datetime).format('HH:mm');
    },

    durationFromNow(date) {
      return moment(date).fromNow(false);
    },
    durationFromNowPrefix(date, prefix) {
      return this.$t(prefix) + ' ' + moment(date).fromNow(true);
    },
    createDateAsUTC(date) {
      return new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
        ),
      );
    },
    convertDateToUTC(date) {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      );
    },
    dateToUtc(date) {
      if (!date) return null;
      return moment(date).utc(true).toDate();
    },
    dateToUtcMidnight(date) {
      return moment(date).startOf('day').utc(true).toDate();
    },
    dateToUtcEndOfDay(date) {
      return moment(date).endOf('day').utc(true).toDate();
    },
    createDateFromTime(time) {
      if (!time) return null;
      if (time.toString().length === 5) {
        const hours = +time.toString().split(':')[0];
        const minutes = +time.toString().split(':')[1];
        return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), hours, minutes);
      } else {
        return new Date(time);
      }
    },

    currentTimeStamp() {
      let today = new Date();
      return today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    },
    fromNow(date) {
      return moment(date).fromNow(true);
    },

    addYears(date, nb) {
      const dateCopy = new Date(date);
      dateCopy.setFullYear(dateCopy.getFullYear() + nb);
      return dateCopy;
    },

    addMonths(date, nb) {
      const dateCopy = new Date(date);
      dateCopy.setMonth(dateCopy.getMonth() + nb);
      return dateCopy;
    },

    addDays(date, nb) {
      const dateCopy = new Date(date);
      dateCopy.setDate(dateCopy.getDate() + nb);
      return dateCopy;
    },

    /**
     * Calcule la différence en minutes entre 2 dates
     *
     * Paramètre d'entrées : objets Date
     *
     * Retour : entier positif
     **/
    diffMins(date1, date2) {
      const diffMs = date2.getTime() - date1.getTime();
      const diffSec = diffMs / 1000;
      const diffMins = diffSec / 60;
      // Retourner la valeur absolue de l'arrondi
      return Math.abs(Math.round(diffMins));
    },

    // STRING

    contains(s1, s2) {
      return s1.includes(s2);
    },
    containsI(s1, s2) {
      return s1.toLowerCase().includes(s2.toLowerCase());
    },
    trimStartForArrayKeys(list, keys) {
      list.map((el) => {
        keys.forEach((key) => {
          if (!Object.prototype.hasOwnProperty.call(el, key)) return;
          let value = el[key];
          if (!(typeof value === 'string')) return;
          value = value.trimStart();
          el[key] = value;
        });
        return el;
      });
      return list;
    },
    convertStringToNumberIfNeeded(value) {
      if (typeof value === 'string') {
        return Number(value);
      }
      return value;
    },

    // AMOUNTS

    formatAmount(num) {
      let display = Number.parseFloat(num).toFixed(2);
      if (display === '-0.00') return '0.00';
      return display;
    },
    formatCredit(amount) {
      if (amount < 0) return '';
      return this.formatAmount(amount);
    },
    formatDebit(amount) {
      if (amount >= 0) return '';
      return this.formatAmount(-amount);
    },
    formatCreditWithEuro(amount) {
      let r = this.formatCredit(amount);
      return r !== '' ? this.formatCurrencyEUR(r) : '';
    },
    formatDebitWithEuro(amount) {
      let r = this.formatDebit(amount);
      return r !== '' ? this.formatCurrencyEUR(r) : '';
    },

    formatCurrency(num, currency) {
      return new Intl.NumberFormat(this.$i18n.locale, {style: 'currency', currency: currency}).format(num);
    },
    formatCurrencyEUR(num) {
      return this.formatCurrency(num, 'EUR');
    },
    formatNumber(num) {
      return new Intl.NumberFormat(this.$i18n.locale).format(num);
    },

    formatPercentage(num) {
      let numPercent = num / 100.0;
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numPercent);
    },

    // NUMBERS

    addNumbers(numbers) {
      let sum = new bigDecimal(0);
      for (const number of numbers) {
        sum = sum.add(new bigDecimal(number));
      }
      return parseFloat(sum.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue());
    },

    addNumbersBy(list, applier) {
      let sum = new bigDecimal(0);
      for (const elem of list) {
        let value = this.apply(elem, applier);
        sum = sum.add(new bigDecimal(value));
      }
      return parseFloat(sum.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue());
    },

    multiplyNumbers(numbers) {
      let product = new bigDecimal(1);
      for (const number of numbers) {
        product = product.multiply(new bigDecimal(number));
      }
      return parseFloat(product.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue());
    },

    divideNumbers(numbers) {
      if (!numbers) return;
      let firstNumber = numbers.shift();
      let division = new bigDecimal(firstNumber);
      for (const number of numbers) {
        division = division.divide(new bigDecimal(number));
      }
      return parseFloat(division.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue());
    },

    convertNumberToStringIfNeeded(value) {
      if (typeof value === 'number') {
        return value.toString();
      }
      return value;
    },

    // CHECK TYPES

    isFunction(obj) {
      return typeof obj === 'function' || obj instanceof Function;
    },
    isString(obj) {
      return typeof obj === 'string' || obj instanceof String;
    },

    // FUNCTION

    apply(obj, applier) {
      if (this.isFunction(applier)) return applier(obj);
      if (this.isString(applier)) return obj[applier];
      return null;
    },

    toFunction(applier) {
      if (this.isFunction(applier)) return applier;
      if (this.isString(applier)) return (obj) => obj[applier];
      return null;
    },

    // DEBUG

    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },
    computeExecutionTime(callback) {
      const startTime = performance.now();
      callback();
      const endTime = performance.now();
      const executionTime = endTime - startTime;
      return executionTime;
    },

    //FILTER

    countFilter(filter) {
      let nb = 0;
      for (const key of Object.keys(filter)) {
        if (!this.empty(key, filter[key])) {
          nb++;
        }
      }
      return nb.toString();
    },
    empty(k, v) {
      let type = typeof v;
      if (k === 'limit' || k === 'sort' || k === 'page') return true;
      if (type === 'undefined') return true;
      if (type === 'boolean') return !v;
      if (v === null) return true;
      if (v === undefined) return true;
      if (v instanceof Array) {
        if (v.length < 1) return true;
      } else if (type === 'string') {
        if (v.length < 1) return true;
        if (v === '0') return true;
      } else if (type === 'number') {
        if (v === 0) return true;
      }
      return false;
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
    },

    //ERROR

    msgForErr(tr, e) {
      if (!e.response) return tr('error.undefined');
      if (!e.response.data) return tr('error.undefined');
      if (!e.response.data.error) return tr('error.undefined');

      let error = e.response.data.error;
      let lingKey = 'error.' + error;
      let lingValue = tr(lingKey);
      return lingKey === lingValue ? error : lingValue;
    },

    //RESERVATION SITE

    reservationSiteDisplay(site) {
      switch (site) {
        case 'Booking.com':
          return 'B';
        case 'Airbnb.com':
          return 'Abnb';
        case 'Guest-adom.com':
          return 'VGA';
        case 'guest-adom.happystay.com':
          return 'Happy';
        default:
          return site;
      }
    },
  },
};
