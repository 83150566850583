<script>
import $ from 'jquery';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import GaMessages from '@components/ga/layout/GaMessages';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';

/* MIXINS */
/* SERVICES */

export default {
  components: {AkButtonValidation, AkFormSubmitted, GaMessages},
  props: {
    title: {type: String, required: true},
    width: {type: String, default: '600px'},
    cancelLabel: {type: String, default: undefined},
    validateLabel: {type: String, default: undefined},
    autoHideOnValidate: {type: Boolean, default: false},
    autoHideOnCancel: {type: Boolean, default: true},
    showValidateButton: {type: Boolean, default: true},
    showCancelButton: {type: Boolean, default: true},
  },
  emits: ['show', 'hide', 'cancel', 'validate'],
  data() {
    return {
      display: false,
      submitted: false,
    };
  },
  computed: {
    buildCancelLabel() {
      return this.cancelLabel ? this.cancelLabel : this.$t('cancel');
    },
    buildValidateLabel() {
      return this.validateLabel ? this.validateLabel : this.$t('validate');
    },
  },
  mounted() {},
  methods: {
    init() {
      this.$refs.btnValid.init();
    },
    show() {
      this.submitted = false;
      this.setDisplay(true);
      this.$emit('show');
    },
    hide() {
      this.setDisplay(false);
      this.$emit('hide');
    },
    cancel() {
      if (this.autoHideOnCancel) this.setDisplay(false);
      this.$emit('cancel');
    },
    validate() {
      this.submitted = true;
      if (this.autoHideOnValidate) this.setDisplay(false);
      this.$emit('validate');
    },
    setDisplay(value) {
      this.display = value;
    },
    success(msg) {
      this.$refs.messages.success(msg);
      this.init();
      this.scrollTop();
    },
    error(msg) {
      this.$refs.messages.error(msg);
      this.init();
      this.scrollTop();
    },
    resetMessages() {
      this.$refs.messages.resetMessages();
    },
    scrollTop() {
      $('.p-dialog-content').scrollTop(0);
    },
  },
};
</script>

<template>
  <PDialog
    v-model:visible="display"
    :header="title"
    :modal="true"
    :style="{width: width}"
    @hide="cancel"
    :class="{'no-footer': !this.showCancelButton && !this.showValidateButton}">
    <GaMessages ref="messages" />
    <AkFormSubmitted :submitted="submitted" reference="form">
      <slot />
    </AkFormSubmitted>

    <template #footer v-if="this.showCancelButton || this.showValidateButton">
      <div class="flex justify-center gap-2 w-full">
        <span v-if="this.showCancelButton" class="btn-light-gray xs" @click="cancel">
          {{ buildCancelLabel }}
        </span>
        <AkButtonValidation
          ref="btnValid"
          btn-class="btn-primary-gradient xs"
          :label="buildValidateLabel"
          v-if="this.showValidateButton"
          @action="validate">
        </AkButtonValidation>
      </div>
    </template>
  </PDialog>
</template>
