import clientService from '@services/clientService';

export default {
  data() {
    return {
      autocompleteClientQuery: 'name',
    };
  },
  methods: {
    autocompleteClient(e) {
      return clientService.autocomplete(e);
    },
    autocompleteClientById(e) {
      return clientService.clientDisplay(e);
    },
  },
};
