import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  cdcmodels(filter) {
    return Api()
      .get('/cdcmodels', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  cdcmodelsDefault() {
    return Api()
      .get('/cdcmodels/default')
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  resetCdc() {
    return Api()
      .post('/cdcmodels/reset')
      .then((res) => {
        return res.data;
      });
  },
  cdcmodel(cdcmodelId) {
    return Api()
      .get('/cdcmodel/' + cdcmodelId)
      .then((res) => this.formatRes(res.data));
  },
  create(cdcmodel) {
    return Api()
      .post('/cdcmodel', cdcmodel)
      .then((res) => this.formatRes(res.data));
  },
  update(cdcmodel) {
    return Api()
      .put('/cdcmodel/' + cdcmodel.id, cdcmodel)
      .then((res) => this.formatRes(res.data));
  },
  delete(cdcmodel) {
    return Api()
      .delete('/cdcmodel/' + cdcmodel.id)
      .then(() => cdcmodel);
  },
  formatRes(e) {
    return e;
  },
};
