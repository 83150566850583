<script>
/* COMPOSANTS */
import GaListTabView from '@components/v2/layout/GaListTabView';
import AkListFilter from '@components/v2/filter/AkListFilter';
import AkCalendarPeriodFilter from '@components/v2/filter/AkCalendarPeriodFilter.vue';
import AkDatatable from '@components/v2/general/AkDatatable';
import AkCheckbox from '@components/v2/input/AkCheckbox';

/* MIXINS */
import stayrateMixin from '@mixins/stayrateMixin';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import loaderMixin from '@mixins/loaderMixin';
import filterListMixin from '@mixins/filterListMixin';
import visitorTaxStatusConst from '@mixins/const/visitorTaxStatusConst';
import stayrateTypeConst from '@mixins/const/stayrateTypeConst';

/* SERVICES */
import comptaOperationService from '@services/comptaOperationService';

export default {
  components: {
    GaListTabView,
    AkListFilter,
    AkCalendarPeriodFilter,
    AkDatatable,
    AkCheckbox,
  },
  mixins: [
    stayrateMixin,
    utilsMixin,
    messageMixin,
    permissionsMixin,
    loaderMixin,
    filterListMixin,
    visitorTaxStatusConst,
    stayrateTypeConst,
  ],
  props: {
    tabs: {
      type: Array,
      required: false,
      default: () => {
        return undefined;
      },
    },
  },
  emits: ['tab'],
  data() {
    return {
      filter: {
        sort: ['date:DESC'],
      },
      list: [],
      nbItems: 0,
      loading: false,
      selectAll: false,
    };
  },
  mounted() {},
  computed: {
    visitorTaxStatusList() {
      return this.visitorTaxStatusConst.slice(0, -1);
    },

    // TOTAL

    total() {
      return this.list.reduce((total, stayrate) => (total += stayrate.amount), 0);
    },
    totalSelected() {
      return this.selectedStayrates.reduce((total, stayrate) => (total += stayrate.amount), 0);
    },
    totalReversed() {
      return this.listReversed.reduce((total, stayrate) => (total += stayrate.amount), 0);
    },
    totalNotReversed() {
      return this.listNotReversed.reduce((total, stayrate) => (total += stayrate.amount), 0);
    },
    totalDisplay() {
      return this.formatCurrencyEUR(this.formatAmount(this.total));
    },
    totalSelectedDisplay() {
      return this.formatCurrencyEUR(this.formatAmount(this.totalSelected));
    },
    totalReversedDisplay() {
      return this.formatCurrencyEUR(this.formatAmount(this.totalReversed));
    },
    totalNotReversedDisplay() {
      return this.formatCurrencyEUR(this.formatAmount(this.totalNotReversed));
    },

    // LIST

    listReversed() {
      return this.list.filter((stayrate) => stayrate.stateReversed);
    },
    listNotReversed() {
      return this.list.filter((stayrate) => !stayrate.stateReversed);
    },

    // FLAG

    hasManagePermission() {
      return this.hasPermission(PermissionMap.TDS.MANAGE);
    },

    selectedStayrates() {
      return this.listNotReversed.filter((stayrate) => stayrate.selected);
    },
  },
  methods: {
    // SEARCH

    search() {
      this.loading = true;

      comptaOperationService
        .staytaxes(this.filter)
        .then((data) => {
          this.list = data.data;
          this.nbItems = data.items;
        })
        .finally(() => (this.loading = false));
    },

    // DOWNLOAD

    // $event = btnDownload du composant parent (StayRatePage)
    download($event) {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      comptaOperationService
        .exportStayrate(this.filter)
        .then((data) => {})
        .catch((error) => {
          let message = this.msgForErr(this.$t, error);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    // FLAG

    selectAllStayrates() {
      for (let stayrate of this.listNotReversed) {
        stayrate.selected = this.selectAll;
      }
    },

    checkIfAllSelected() {
      this.selectAll = this.selectedStayrates.length === this.listNotReversed.length;
    },

    // $event = btnSubmit du composant parent (StayRatePage)
    flagStayrates($event) {
      $event.load();

      // Aucune TDS sélectionnée
      if (this.selectedStayrates.length === 0) {
        this.addError(this.$t('visitor_tax.error_empty_selection'));
        $event.init();
        return;
      }

      // TDS de différents lots sélectionnées
      const tdsGroupId = this.selectedStayrates[0].tdsGroupId;
      let hasSameTdsGroup = this.selectedStayrates.every((s) => s.tdsGroupId === tdsGroupId);

      if (!hasSameTdsGroup) {
        this.addError(this.$t('visitor_tax.error_not_same_group'));
        $event.init();
        return;
      }

      const operationsIds = this.selectedStayrates.map((s) => s.id);
      this.loading = true;

      comptaOperationService
        .createTDSGroup({operationIds: operationsIds})
        .then(() => {
          this.success(this.$t('visitor_tax.stayrate_group_added'));
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('visitor_tax.error_stayrate_account'), true, true);
            } else {
              this.addError(this.$t('error.' + e.response.data.error));
            }
          }
        })
        .finally(() => {
          $event.init();
          this.loading = false;
          this.search();
        });
    },
  },
};
</script>

<template>
  <GaListTabView :tabs="tabs" currentTab="stayRateConsultation" @tab="this.$emit('tab', $event)">
    <template #filter>
      <AkListFilter
        v-model="this.filter.tdsGroupIds"
        :items="this.stayrates"
        item-display="label"
        multi-selection
        display-filter
        @search="this.search"
        :label="$t('visitor_tax.group')"
        direction="right" />
      <AkCalendarPeriodFilter v-model="this.filter.period" direction="right" @search="this.search" label="Période" />
      <AkListFilter
        v-model="this.filter.stateReversed"
        :items="this.visitorTaxStatusList"
        item-value="value"
        item-display="label"
        @search="this.search"
        :label="$t('visitor_tax.status')"
        direction="right" />
    </template>

    <template #content>
      <div
        v-if="!this.loading"
        class="md:flex grid grid-cols-2 items-center justify-end md:gap-10 gap-2 mb-4 text-dark text-sm">
        <div class="flex flex-col">
          <span class="font-inter font-semibold text-dark text-base">{{ $t('visitor_tax.total_not_reversed') }}</span
          ><span class="font-inter font-medium text-gray text-md">{{ this.totalNotReversedDisplay }}</span>
        </div>

        <div class="flex flex-col">
          <span class="font-inter font-semibold text-dark text-base">{{ $t('visitor_tax.total_reversed') }}</span
          ><span class="font-inter font-medium text-gray text-md">{{ this.totalReversedDisplay }}</span>
        </div>

        <div class="flex flex-col">
          <span class="font-inter font-semibold text-dark text-base">{{ $t('visitor_tax.total_not_selected') }}</span
          ><span class="font-inter font-medium text-gray text-md">{{ this.totalDisplay }}</span>
        </div>

        <div class="flex flex-col">
          <span class="font-inter font-semibold text-dark text-base">{{ $t('visitor_tax.total_selected') }}</span
          ><span class="font-inter font-medium text-gray text-md">{{ this.totalSelectedDisplay }}</span>
        </div>
      </div>

      <AkDatatable :loading="this.loading" :value="this.list" :filter="this.filter" paginator @search="this.search">
        <template #empty>
          {{ $t('operation.empty') }}
        </template>

        <Column v-if="this.hasManagePermission" class="w-[40px]">
          <template #header>
            <AkCheckbox v-model="selectAll" label="" value="" binary @change="selectAllStayrates" />
          </template>
          <template #body="slotProps">
            <AkCheckbox
              v-if="!slotProps.data.stateReversed"
              v-model="slotProps.data.selected"
              label=""
              :value="slotProps.data"
              @change="checkIfAllSelected" />
          </template>
        </Column>

        <Column :header="$t('accounting.operations')" sort-field="date" sortable style="max-width: 125px; width: 125px">
          <template #body="slotProps">
            {{ displayDate(slotProps.data.date) }}
          </template>
        </Column>

        <Column
          :header="$t('accommodation.ownerName')"
          sort-field="ownerDisplay"
          sortable
          style="min-width: 200px; width: 300px">
          <template #body="slotProps">
            <router-link
              v-if="slotProps.data.ownerId"
              class="whitespace-pre-wrap"
              :to="{name: 'ownerPage', params: {ownerId: slotProps.data.ownerId}}">
              {{ slotProps.data.ownerDisplay }}
            </router-link>
          </template>
        </Column>

        <Column :header="$t('reservation.one')" sort-field="localizer" sortable style="width: 200px; min-width: 200px">
          <template #body="slotProps">
            <router-link
              v-if="slotProps.data.reservationId"
              :to="{
                name: 'reservationPage',
                params: {
                  reservationId: slotProps.data.reservationId,
                },
              }">
              {{ slotProps.data.localizer }}
            </router-link>
          </template>
        </Column>

        <Column
          :header="$t('visitor_tax.period')"
          sort-field="dateStart"
          sortable
          style="max-width: 100px; width: 100px">
          <template #body="slotProps">
            {{ this.displayDatesSeparator(slotProps.data.dateStart, slotProps.data.dateEnd, false) }}
          </template>
        </Column>

        <Column
          :header="$t('visitor_tax.group')"
          sort-field="tdsGroupLabel"
          sortable
          style="min-width: 200px; width: 300px">
          <template #body="slotProps">
            {{ slotProps.data.tdsGroupLabel }}
          </template>
        </Column>

        <Column
          :header="$t('accounting.amount')"
          sort-field="amount"
          sortable
          class="text-right"
          style="width: 100px; min-width: 100px">
          <template #body="slotProps">
            {{ this.formatCurrencyEUR(this.formatAmount(slotProps.data.amount)) }}
          </template>
        </Column>

        <Column :header="$t('type')" sort-field="typeTDS" sortable style="width: 100px; min-width: 100px">
          <template #body="slotProps">
            {{ this.displayForStayrateTypeConst(slotProps.data.typeTDS) }}
          </template>
        </Column>

        <Column
          :header="$t('visitor_tax.status')"
          sort-field="stateReversed"
          sortable
          style="width: 100px; min-width: 100px">
          <template #body="slotProps">
            {{ this.displayForVisitorTaxStatusConst(slotProps.data.stateReversed) }}
          </template>
        </Column>
      </AkDatatable>
    </template>
  </GaListTabView>
</template>
