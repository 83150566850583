import utilsMixin from '../utilsMixin';

export default {
  mixins: [utilsMixin],
  data() {
    return {
      smilyStatusConst: [
        {label: 'Réservée', value: 'Booked'},
        {label: 'Tentative', value: 'Tentative'},
        {label: 'Annulée', value: 'Canceled'},
        {label: 'Indisponible', value: 'Unavailable'},
      ],
      smilyStatusConstForPlanning: [
        {label: 'Réservée', class: 'confirmada', displayGav: true},
        {label: 'Hôte', class: 'propietario', displayGav: true},
        {label: 'Indisponible', class: 'unavailable', displayGav: true},
        {label: "Demande d'informations", class: 'peticion_informacion', displayGav: false},
        //{label: this.$t('undefined'), value: null},
      ],
      smilyStatusConstForPlanningOwner: [
        {label: 'Occupé par un voyageur', class: 'occuped', value: 'GUEST'},
        {label: "Occupé par l'hôte", class: 'propietario', value: 'OWNER'},
        {label: 'Indisponible', class: 'unavailable', value: 'BLOCKADE'},
        //{label: this.$t('undefined'), value: null},
      ],
    };
  },
  methods: {
    displayForSmilyStatusConst(value) {
      let result = this.smilyStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    getPlanningSmily(isOwner, isGav, forList = false) {
      if (isOwner) return this.smilyStatusConstForPlanningOwner;
      if (isGav) return this.smilyStatusConstForPlanning.filter((el) => el.displayGav || forList);
      return this.smilyStatusConstForPlanning;
    },
  },
};
