<script>
export default {
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="{'mb-2.5': !inline}" class="flex items-center justify-between">
    <label :for="id" class="flex-1 font-inter font-semibold text-base text-dark">
      <slot />
      {{ required ? ' *' : '' }}</label
    >
    <slot name="link" />
  </div>
</template>
