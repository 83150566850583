<script>
/* COMPOSANTS */
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import housekeeperService from '@services/housekeeperService';
import permissionsMixin from '@mixins/permissionsMixin';

export default {
  components: {
    AkInputSwitch,
    AkDatatablePagination,
  },
  mixins: [randomRef, loaderMixin, messageMixin, utilsMixin, permissionsMixin],
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      loading: true,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.list = [];
      this.loading = true;
      housekeeperService.housekeepers(this.filter).then((data) => {
        this.list = data;
        this.hydrateContactFields();
        this.loading = false;
      });
    },
    hydrateContactFields() {
      for (let item of this.list) {
        item.contactCompanyName = item.contact?.companyName;
        item.contactCivility = item.contact?.civility;
        item.contactLastName = item.contact?.lastName;
        item.contactFirstName = item.contact?.firstName;
        item.contactEmail = item.contact?.email;
        item.contactPhone = item.contact?.phone;
        item.accommodationNumber = item.accommodationIds?.length;
      }
    },
    changeEnabled(data) {
      if (data.userId === undefined) {
        this.addError(this.$t('partenaire.errorNoId'));
        data.userEnabled = false;
        return;
      }
      if (data.userEnabled) this.enable(data);
      else this.disable(data);
    },
    enable(data) {
      housekeeperService.enable(data).then(() => {
        this.refresh();
      });
    },
    disable(data) {
      housekeeperService.disable(data).then(() => {
        this.refresh();
      });
    },
  },
  watch: {
    filter: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<template>
  <AkDatatablePagination :lazy="false" :loading="loading" :value="list">
    <template #empty>
      {{ $t('partenaire.empty') }}
    </template>
    <Column :header="$t('partenaire.company')" sortable field="companyName" sort-field="companyName" />
    <Column :header="$t('contact.companyName')" sortable field="contactCompanyName" sort-field="contactCompanyName" />
    <Column :header="$t('interlocutor.housekeeper')" sortable field="contactLastName" sort-field="contactLastName">
      <template #body="slotProps">
        {{ slotProps.data.contactLastName }}&nbsp;{{ slotProps.data.contactFirstName }}
      </template>
    </Column>
    <Column :header="$t('contact.email')" sortable field="contactEmail" sort-field="contactEmail" />
    <Column :header="$t('contact.phone')" sortable field="contactPhone" sort-field="contactPhone" />
    <Column :header="$t('partenaire.accommodation_number')" sortable field="accommodationNumber">
      <template #body="slotProps">
        {{ slotProps.data.accommodationIds?.length }}
      </template>
    </Column>
    <Column :header="$t('user.enabled')" sortable class="text-center" style="width: 100px" sort-field="userEnabled">
      <template #body="slotProps">
        <AkInputSwitch
          validationPopup
          :nameOnPopup="slotProps.data.contactLastName + ' ' + slotProps.data.contactFirstName"
          v-model="slotProps.data.userEnabled"
          @change="changeEnabled(slotProps.data)" />
      </template>
    </Column>
  </AkDatatablePagination>
</template>
