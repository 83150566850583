<script>
export default {
  props: {
    buttonVisible: {type: Boolean, default: true},
    className: {type: String, default: ''},
    little: {type: Boolean, default: false},
    title: {type: String, required: false},
  },
  methods: {},
};
</script>

<template>
  <div :class="className" class="flex-column d-flex align-items-center">
    <span v-if="title" class="poppins-medium fs-16 color-secondary bloc-title-space">{{ title }}</span>
    <div class="d-flex flex-column align-items-center full-width">
      <div :class="{little: little}" class="d-flex flex-column bloc-content bloc-content-btn">
        <slot name="content"></slot>
      </div>
      <div class="button-zone d-flex" v-if="buttonVisible">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>
