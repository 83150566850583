import moment from 'moment/moment';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import prestationStatusConst from '@mixins/const/prestationStatusConst';
import personnelTypeConst from '@mixins/const/personnelTypeConst';

export default {
  mixins: [utilsMixin, prestationStatusConst, personnelTypeConst],
  methods: {
    // IS MISSION TYPE

    isTypeCheckin(type) {
      return type === 'CHECK_IN';
    },
    isTypeCheckout(type) {
      return type === 'CHECK_OUT';
    },
    isTypeQualityControl(type) {
      return type === 'QUALITY_CONTROL';
    },
    isTypeCleaning(type) {
      return type === 'CLEANING' || type === 'CLEANING_AFTER' || type === 'CLEANING_BEFORE';
    },
    isTypeCleaningBefore(type) {
      return type === 'CLEANING_BEFORE';
    },
    isTypeCleaningAfter(type) {
      return type === 'CLEANING_AFTER';
    },
    isTypeMaintenance(type) {
      return type === 'MAINTENANCE';
    },
    isTypeRunning(type) {
      return type === 'RUNNING';
    },

    // IS ASSIGNED STAFF

    isAssignedStaffIntern(mission) {
      return mission.assignedStaff === 'INTERN';
    },

    isAssignedStaffPrestataire(mission) {
      return mission.assignedStaff === 'PRESTATAIRE';
    },

    isAssignedStaffHousekeeper(mission) {
      return mission.assignedStaff === 'HOUSEKEEPER';
    },

    isAssignedStaffPartner(mission) {
      return mission.assignedStaff === 'PARTNER';
    },

    // GET DATES STR OF THE WEEK

    getDatesStrOfTheWeek(firstDayOfTheWeek) {
      const dates = [moment(firstDayOfTheWeek).format('YYYY-MM-DD')];
      dates[0] = this.addDays(moment(dates[0]), 0);
      for (let i = 0; i < 6; i++) {
        const date = this.addDays(moment(dates[i]), 1);
        dates.push(this.dateToUtc(date));
      }
      return dates;
    },

    /*
     * Méthodes pour les cartes de missions utilisées dans :
     * - Planning logement (AccommodationPlanning)
     * - Planning intervenants (sous-composants -> ParticipantPlanningList / ParticipantPlanningDetails)
     */

    // DISPLAY PRESTATION TIME

    displayPrestationTime(mission) {
      if (
        (this.isAssignedStaffIntern(mission) || this.isAssignedStaffHousekeeper(mission)) &&
        mission.status === 'TO_PLAN'
      )
        return '';
      return this.displayTime(mission.dateStart) + ' - ' + this.displayTime(mission.dateEnd);
    },

    // DATE TO INDEX

    dateToIndex(date) {
      return moment(date, 'MM/DD/Y').format('YYYY-MM-DD');
    },

    // DAY BACKGROUND
    getDayBackground(missions) {
      if (!missions) return '';
      if (missions.find((m) => m?.type === 'UNAVAILABILITY')) return 'unavailability';
    },

    // TASK BACKGROUND

    getTaskBackground(mission) {
      switch (mission.status) {
        case 'CANCELED':
        case 'LITIGATION':
          return 'bg-danger';
        case 'PROPOSED':
        case 'PARTIALLY_ACCEPTED':
        case 'TO_PLAN':
          return 'bg-warning';
        case 'ACCEPTED':
        case 'TODO':
        case 'TO_DO':
        case 'DOING':
        case 'COMPLETED':
        case 'FINISH':
          return 'bg-success';
      }
    },

    // STATUS DISPLAY

    statusDisplay(status) {
      if (status === 'INTERN' || status === 'PRESTATAIRE' || status === 'HOUSEKEEPER' || status === 'PARTNER') {
        return this.displayForPersonnelTypeConst(status);
      }
      const display = this.displayForPrestationStatusConst(status);
      return display !== '' ? display : this.$t('anomaly');
    },
  },
};
