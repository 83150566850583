<script>
import $ from 'jquery';
import AkInputNumber from '@components/v2/input/AkInputNumber.vue';
import DropdownMenuAk from '@components/v2/general/DropdownMenuAk.vue';

export default {
  components: {AkInputNumber, DropdownMenuAk},
  inject: {
    pSubmitted: {default: null},
    pDisabled: {default: null},
  },
  props: {
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    direction: {
      type: String,
      required: false,
      default: 'center',
      validator(value) {
        return ['center', 'left', 'right'].includes(value);
      },
    },
    mode: {
      type: String,
      default: undefined,
    },
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      oldValue: undefined,
    };
  },
  emits: ['update:modelValue', 'search'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        if (val === '') val = undefined;
        this.$emit('update:modelValue', val);
      },
    },
    labelDisplay() {
      if (this.currentValue && (!this.$refs.dropdown || !this.$refs.dropdown.menu.isOpen)) {
        return this.currentValue + (this.mode === 'currency' ? ' €' : '');
      } else if (this.oldValue && this.$refs.dropdown && this.$refs.dropdown.menu.isOpen) {
        return this.oldValue + (this.mode === 'currency' ? ' €' : '');
      }
      return this.label;
    },
    changed() {
      return this.currentValue !== this.oldValue;
    },
    hasValue() {
      return (
        (this.currentValue !== undefined && (!this.$refs.dropdown || !this.$refs.dropdown.menu.isOpen)) ||
        (this.oldValue !== undefined && this.$refs.dropdown && this.$refs.dropdown.menu.isOpen)
      );
    },
  },
  methods: {
    launchSearch() {
      this.$refs.dropdown.hide();
      this.$nextTick(() => {
        if (this.changed) this.$emit('search');
      });
    },
    reset() {
      this.currentValue = undefined;
      this.launchSearch();
    },
    scrollToTop() {
      this.oldValue = this.currentValue;
      this.$nextTick(() => {
        if (this.$refs.scroll) this.$refs.scroll.$el.scrollTop = 0;
        if (!this.$isMobile()) $(this.$refs.input.$refs.input.$el).focus();
      });
    },
  },
};
</script>

<template>
  <dropdown-menu-ak
    @opened="scrollToTop"
    @closed="launchSearch"
    ref="dropdown"
    overlay
    class="dropdown-filter dropdown-xl"
    :closeOnClickOutside="true"
    :direction="direction"
    mode="click">
    <template #trigger>
      <div
        class="flex items-center gap-1 py-2.5 px-1.5 hover:cursor-pointer hover:text-primary"
        :class="[hasValue ? 'text-primary' : 'text-gray']">
        <span class="flex-1 font-inter font-medium text-sm">{{ labelDisplay }}</span>
        <i class="ga-icon ga-chevron_down icon-rotate" />
      </div>
    </template>
    <template #body>
      <form @submit.prevent="launchSearch">
        <AkInputNumber ref="input" v-model="currentValue" :inline="false" :mode="mode" :label="label" class="pb-2" />
      </form>
      <hr />
      <div
        class="flex gap-2 items-center text-gray justify-center pt-4 hover:text-primary hover:cursor-pointer"
        @click="reset">
        <i class="ga-icon ga-refresh text-3xl" />
        <span class="font-medium text-base">Réinitialiser</span>
      </div>
    </template>
  </dropdown-menu-ak>
</template>
