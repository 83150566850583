<script>
/* COMPOSANTS */
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import prestataireStatusConst from '@mixins/const/prestataireStatusConst';
import civilitiesConst from '@mixins/const/civilitiesConst';

/* SERVICES */
import prestataireService from '@services/prestataireService';

export default {
  components: {
    AkInputSwitch,
    AkDatatablePagination,
  },
  mixins: [randomRef, loaderMixin, messageMixin, utilsMixin, prestataireStatusConst, civilitiesConst],
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      loading: true,
      totalItems: 0,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.list = [];
      this.loading = true;
      prestataireService.prestatairePagination(this.filter).then((data) => {
        this.list = data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    getStatusStyle(status) {
      switch (status) {
        case 'AVAILABLE':
          return 'color: rgb(34 197 94);';
        case 'UNAVAILABLE':
          return 'color: rgb(239 68 68);';
        case 'IN_MISSION':
          return 'color: rgb(234 179 8);';
      }
    },
    changeEnabled(data) {
      if (data.enabled) this.enable(data.id);
      else this.disable(data.id);
    },
    enable(id) {
      prestataireService.enable(id).then(() => {
        this.refresh();
      });
    },
    disable(id) {
      prestataireService.disable(id).then(() => {
        this.refresh();
      });
    },
  },
  watch: {
    filter: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<template>
  <AkDatatablePagination :lazy="false" :loading="loading" :nb-items="totalItems" :filter="filter" :value="list">
    <template #empty>
      {{ $t('employee.empty') }}
    </template>
    <Column :header="$t('prestataire.company')" sortable field="companyName" sort-field="companyName" />
    <Column :header="$t('interlocutor.prestataire')" sortable field="contactLastName" sort-field="contactLastName">
      <template #body="slotProps">
        {{ slotProps.data.contactLastName }}&nbsp;{{ slotProps.data.contactFirstName }}
      </template>
    </Column>
    <Column :header="$t('prestataire.email')" sortable field="contactEmail" sort-field="contactEmail" />
    <Column :header="$t('contact.phone')" field="contactPhone" sortable sort-field="contactPhone" />
    <Column :header="$t('prestataire.availability')" sortable sort-field="status">
      <template #body="slotProps">
        <span :style="getStatusStyle(slotProps.data.status)">{{
          displayForPrestataireStatusConst(slotProps.data.status)
        }}</span>
      </template>
    </Column>
    <Column :header="$t('prestataire.enabled')" class="text-center" style="width: 80px" sortable sort-field="enabled">
      <template #body="slotProps">
        <AkInputSwitch
          validationPopup
          :nameOnPopup="slotProps.data.contactLastName + ' ' + slotProps.data.contactFirstName"
          v-model="slotProps.data.enabled"
          @change="changeEnabled(slotProps.data)" />
      </template>
    </Column>
  </AkDatatablePagination>
</template>
