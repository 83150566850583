<script>
import AkLabel from '@components/general/AkLabel';
import Checkbox from 'primevue/checkbox';

export default {
  components: {Checkbox},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    binary: {
      type: Boolean,
      required: false,
      default: true,
    },
    labelClass: {
      type: String,
      required: false,
      default: 'font-semibold text-dark text-base',
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      id: null,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
  },
  mounted() {
    this.id = 'id_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  },
  methods: {
    change(event) {
      this.$nextTick(() => {
        this.$emit('change');
      });
    },
  },
};
</script>

<template>
  <div :class="className" class="form-group">
    <div class="flex items-center gap-2">
      <Checkbox
        :inputId="id"
        v-model="currentValue"
        :disabled="disabled"
        :binary="binary"
        class="form-check-input"
        :value="value"
        @change="change($event)" />
      <label class="flex-1 font-inter" :class="[this.labelClass, {'cursor-pointer': !disabled}]" :for="id">
        {{ this.label }}</label
      >
    </div>
  </div>
</template>
