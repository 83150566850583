<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import AkInputFilter from '@components/v2/filter/AkInputFilter.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import companyMixin from '@mixins/companyMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';

/* SERVICES */
import partenaireService from '@services/partenaireService';

export default {
  components: {
    AkInputSwitch,
    AkAllFilter,
    GaListView,
    AkButtonAction,
    AkInputFilter,
    AkAutocompleteFilter,
    AkListFilter,
    AkDatatablePagination,
    GaView,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin, loaderMixin, companyMixin, accommodationAutocompleteMixin],
  metaInfo() {
    return {
      title: 'partenaire.list',
    };
  },
  data() {
    return {
      list: [],
      loading: true,
      query: '',
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      this.loading = true;
      partenaireService.partenaires(this.filter).then((data) => {
        this.list = data;
        this.hydrateContactFields();
        this.loading = false;
      });
    },
    hydrateContactFields() {
      for (let partenaire of this.list) {
        partenaire.contactCompanyName = partenaire.contact.companyName;
        partenaire.contactLastName = partenaire.contact.lastName;
        partenaire.contactFirstName = partenaire.contact.firstName;
        partenaire.contactEmail = partenaire.contact.email;
        partenaire.contactPhone = partenaire.contact.phone;
        partenaire.accommodationNumber = partenaire.accommodationIds.length;
      }
    },
    changeEnabled(data) {
      if (data.userId === undefined) {
        this.addError(this.$t('partenaire.errorNoId'));
        data.userEnabled = false;
        return;
      }
      if (data.userEnabled) this.enable(data);
      else this.disable(data);
    },
    enable(data) {
      partenaireService.enable(data).then((data) => {
        this.search();
      });
    },
    disable(data) {
      partenaireService.disable(data).then((data) => {
        this.search();
      });
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaPartenaireFilterList',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaPartenaireFilterList',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
    exportList() {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      partenaireService
        .exportList(this.filter)
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>

<template>
  <GaView>
    <template #action>
      <AkButtonAction secondary :label="$t('download')" little @click="exportList" />
      <router-link :to="{name: 'partenaireCreate'}" class="xs btn-primary-gradient">
        {{ $t('add') }}
      </router-link>
    </template>
    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="isGas()"
            v-model="filter.companyIds"
            :display-filter="true"
            :items="companies"
            :label="$t('employee.company')"
            :multi-selection="true"
            direction="right"
            @search="search" />
          <AkInputFilter v-model="filter.contactCompanyName" :label="$t('contact.companyName')" @search="search" />
          <AkAutocompleteFilter
            v-model="filter.accommodationId"
            :init-method="autocompleteAccommodationById"
            :label="$t('partenaire.accommodation')"
            :search-label="autocompleteAccommodationQuery"
            :search-method="autocompleteAccommodation"
            direction="right"
            item-display="display"
            item-value="id"
            @search="search" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination :lazy="false" :loading="loading" :value="list">
            <template #empty>
              {{ $t('partenaire.empty') }}
            </template>
            <Column
              :header="$t('interlocutor.partenaire')"
              :sortable="true"
              header-style="width: 200px;"
              sort-field="contactLastName">
              <template #body="slotProps">
                <router-link :to="{name: 'partenairePage', params: {partenaireId: slotProps.data.id}}">
                  {{ slotProps.data.contact?.lastName }}&nbsp;{{ slotProps.data.contact?.firstName }}
                </router-link>
              </template>
            </Column>
            <Column
              :header="$t('contact.companyName')"
              :sortable="true"
              field="contactCompanyName"
              header-style="width: 200px;"
              sort-field="contactCompanyName" />
            <Column
              :header="$t('partenaire.company')"
              :sortable="true"
              field="companyName"
              header-style="width: 250px;"
              sort-field="companyName" />
            <Column
              :header="$t('contact.email')"
              :sortable="true"
              field="contactEmail"
              header-style="width: 200px;"
              sort-field="contactEmail" />
            <Column
              :header="$t('contact.phone')"
              :sortable="true"
              field="contactPhone"
              header-style="width: 150px;"
              sort-field="contactPhone" />
            <Column :header="$t('partenaire.accommodation_number')" :sortable="true" field="accommodationNumber">
              <template #body="slotProps">
                {{ slotProps.data.accommodationIds?.length }}
              </template>
            </Column>
            <Column
              :header="$t('user.enabled')"
              :sortable="true"
              body-style="text-align:center"
              header-style="width: 120px;"
              sort-field="userEnabled">
              <template #body="slotProps">
                <AkInputSwitch
                  validationPopup
                  :nameOnPopup="slotProps.data.contact?.lastName + ' ' + slotProps.data.contact?.firstName"
                  v-model="slotProps.data.userEnabled"
                  :disabled="slotProps.data.userId === undefined"
                  @change="changeEnabled(slotProps.data)" />
              </template>
            </Column>
            <Column header-style="width: 50px;">
              <template #body="slotProps">
                <div class="flex items-center gap-2">
                  <router-link :to="{name: 'partenairePage', params: {partenaireId: slotProps.data.id}}">
                    <i class="ga-icon ga-external text-2xl text-primary" />
                  </router-link>
                  <router-link :to="{name: 'partenaireUpdate', params: {partenaireId: slotProps.data.id}}">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </router-link>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
