<script>
/* COMPOSANTS */
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import roleKeysConst from '@mixins/const/roleKeysConst';
import roleTypesConst from '@mixins/const/roleTypesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import employeeService from '@services/employeeService';

export default {
  components: {
    AkInputSwitch,
    AkDatatablePagination,
  },
  mixins: [randomRef, loaderMixin, messageMixin, utilsMixin, roleKeysConst, roleTypesConst, permissionsMixin],
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      item: {},
      loading: true,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.list = [];
      this.loading = true;
      employeeService.employees(this.filter).then((data) => {
        this.list = data;
        this.hydrateContactFields();
        this.loading = false;
      });
    },
    hydrateContactFields() {
      for (let item of this.list) {
        item.contactCompanyName = item.contact?.companyName;
        item.contactCivility = item.contact?.civility;
        item.contactLastName = item.contact?.lastName;
        item.contactFirstName = item.contact?.firstName;
        item.contactEmail = item.contact?.email;
        item.contactPhone = item.contact?.phone;
        item.accommodationNumber = item.accommodationIds?.length;
      }
    },
    changeUserEnabled(data) {
      if (data.userEnabled) this.enableUser(data);
      else this.disableUser(data);
    },
    disableUser(data) {
      employeeService.disable(data);
    },
    enableUser(data) {
      employeeService.enable(data);
    },
    classForEnabled(data) {
      return data.userEnabled ? 'ti ti-check text-primary' : 'ti ti-close';
    },
  },
  watch: {
    filter: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<template>
  <AkDatatablePagination :lazy="false" :loading="loading" :value="list">
    <template #empty>
      {{ $t('employee.empty') }}
    </template>
    <Column :header="$t('interlocutor.salarie')" sortable field="lastName" sort-field="lastName">
      <template #body="slotProps"> {{ slotProps.data.lastName }}&nbsp;{{ slotProps.data.firstName }} </template>
    </Column>
    <Column :header="$t('employee.email')" :sortable="true" field="email" sort-field="email" />
    <Column :header="$t('employee.roleKey')" field="roleKey">
      <template #body="slotProps">
        {{ displayForRoleKeysConst(slotProps.data.roleKey) }}
      </template>
    </Column>
    <Column :header="$t('user.enabled')" :sortable="true" field="userEnabled" class="text-center">
      <template #body="slotProps">
        <AkInputSwitch
          validationPopup
          :nameOnPopup="slotProps.data.lastName + ' ' + slotProps.data.firstName"
          v-model="slotProps.data.userEnabled"
          @change="changeUserEnabled(slotProps.data)" />
      </template>
    </Column>
    <Column style="width: 100px">
      <template #body="slotProps">
        <div class="flex justify-end gap-2">
          <router-link
            v-if="this.hasPermission('RH_VIEW')"
            :to="{name: 'employeePage', params: {employeeId: slotProps.data.id}}">
            <i class="ga-icon ga-external text-2xl text-primary" />
          </router-link>
          <router-link
            v-if="this.hasPermission('RH_MANAGE') && !this.isAdmin"
            :to="{name: 'employeeUpdate', params: {employeeId: slotProps.data.id}}">
            <i class="ga-icon ga-edit text-2xl text-primary" />
          </router-link>
        </div>
      </template>
    </Column>
  </AkDatatablePagination>
</template>
