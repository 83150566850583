export default {
  data() {
    return {
      countryConst: [
        {label: this.$t('countries.AF'), value: 'AF'},
        {label: this.$t('countries.ZA'), value: 'ZA'},
        {label: this.$t('countries.AL'), value: 'AL'},
        {label: this.$t('countries.DZ'), value: 'DZ'},
        {label: this.$t('countries.DE'), value: 'DE'},
        {label: this.$t('countries.AD'), value: 'AD'},
        {label: this.$t('countries.AO'), value: 'AO'},
        {label: this.$t('countries.AI'), value: 'AI'},
        {label: this.$t('countries.AQ'), value: 'AQ'},
        {label: this.$t('countries.AG'), value: 'AG'},
        {label: this.$t('countries.SA'), value: 'SA'},
        {label: this.$t('countries.AR'), value: 'AR'},
        {label: this.$t('countries.AM'), value: 'AM'},
        {label: this.$t('countries.AW'), value: 'AW'},
        {label: this.$t('countries.AU'), value: 'AU'},
        {label: this.$t('countries.AT'), value: 'AT'},
        {label: this.$t('countries.AZ'), value: 'AZ'},
        {label: this.$t('countries.BS'), value: 'BS'},
        {label: this.$t('countries.BH'), value: 'BH'},
        {label: this.$t('countries.BD'), value: 'BD'},
        {label: this.$t('countries.BB'), value: 'BB'},
        {label: this.$t('countries.BE'), value: 'BE'},
        {label: this.$t('countries.BZ'), value: 'BZ'},
        {label: this.$t('countries.BM'), value: 'BM'},
        {label: this.$t('countries.BT'), value: 'BT'},
        {label: this.$t('countries.BY'), value: 'BY'},
        {label: this.$t('countries.BO'), value: 'BO'},
        {label: this.$t('countries.BA'), value: 'BA'},
        {label: this.$t('countries.BW'), value: 'BW'},
        {label: this.$t('countries.BN'), value: 'BN'},
        {label: this.$t('countries.BR'), value: 'BR'},
        {label: this.$t('countries.BG'), value: 'BG'},
        {label: this.$t('countries.BF'), value: 'BF'},
        {label: this.$t('countries.BI'), value: 'BI'},
        {label: this.$t('countries.BJ'), value: 'BJ'},
        {label: this.$t('countries.KH'), value: 'KH'},
        {label: this.$t('countries.CM'), value: 'CM'},
        {label: this.$t('countries.CA'), value: 'CA'},
        {label: this.$t('countries.CV'), value: 'CV'},
        {label: this.$t('countries.CL'), value: 'CL'},
        {label: this.$t('countries.CN'), value: 'CN'},
        {label: this.$t('countries.CY'), value: 'CY'},
        {label: this.$t('countries.CO'), value: 'CO'},
        {label: this.$t('countries.KM'), value: 'KM'},
        {label: this.$t('countries.CG'), value: 'CG'},
        {label: this.$t('countries.CD'), value: 'CD'},
        {label: this.$t('countries.KP'), value: 'KP'},
        {label: this.$t('countries.KR'), value: 'KR'},
        {label: this.$t('countries.CR'), value: 'CR'},
        {label: this.$t('countries.HR'), value: 'HR'},
        {label: this.$t('countries.CU'), value: 'CU'},
        {label: this.$t('countries.CW'), value: 'CW'},
        {label: this.$t('countries.CI'), value: 'CI'},
        {label: this.$t('countries.DK'), value: 'DK'},
        {label: this.$t('countries.DJ'), value: 'DJ'},
        {label: this.$t('countries.DM'), value: 'DM'},
        {label: this.$t('countries.ES'), value: 'ES'},
        {label: this.$t('countries.EE'), value: 'EE'},
        {label: this.$t('countries.SZ'), value: 'SZ'},
        {label: this.$t('countries.FJ'), value: 'FJ'},
        {label: this.$t('countries.FI'), value: 'FI'},
        {label: this.$t('countries.FR'), value: 'FR'},
        {label: this.$t('countries.GA'), value: 'GA'},
        {label: this.$t('countries.GM'), value: 'GM'},
        {label: this.$t('countries.GH'), value: 'GH'},
        {label: this.$t('countries.GI'), value: 'GI'},
        {label: this.$t('countries.GD'), value: 'GD'},
        {label: this.$t('countries.GL'), value: 'GL'},
        {label: this.$t('countries.GR'), value: 'GR'},
        {label: this.$t('countries.GP'), value: 'GP'},
        {label: this.$t('countries.GU'), value: 'GU'},
        {label: this.$t('countries.GT'), value: 'GT'},
        {label: this.$t('countries.GG'), value: 'GG'},
        {label: this.$t('countries.GN'), value: 'GN'},
        {label: this.$t('countries.GQ'), value: 'GQ'},
        {label: this.$t('countries.GW'), value: 'GW'},
        {label: this.$t('countries.GY'), value: 'GY'},
        {label: this.$t('countries.GF'), value: 'GF'},
        {label: this.$t('countries.GE'), value: 'GE'},
        {label: this.$t('countries.GS'), value: 'GS'},
        {label: this.$t('countries.HT'), value: 'HT'},
        {label: this.$t('countries.HN'), value: 'HN'},
        {label: this.$t('countries.HU'), value: 'HU'},
        {label: this.$t('countries.IN'), value: 'IN'},
        {label: this.$t('countries.ID'), value: 'ID'},
        {label: this.$t('countries.IQ'), value: 'IQ'},
        {label: this.$t('countries.IR'), value: 'IR'},
        {label: this.$t('countries.IE'), value: 'IE'},
        {label: this.$t('countries.IS'), value: 'IS'},
        {label: this.$t('countries.IL'), value: 'IL'},
        {label: this.$t('countries.IT'), value: 'IT'},
        {label: this.$t('countries.JM'), value: 'JM'},
        {label: this.$t('countries.JP'), value: 'JP'},
        {label: this.$t('countries.JE'), value: 'JE'},
        {label: this.$t('countries.JO'), value: 'JO'},
        {label: this.$t('countries.KZ'), value: 'KZ'},
        {label: this.$t('countries.KE'), value: 'KE'},
        {label: this.$t('countries.KG'), value: 'KG'},
        {label: this.$t('countries.KI'), value: 'KI'},
        {label: this.$t('countries.KW'), value: 'KW'},
        {label: this.$t('countries.RE'), value: 'RE'},
        {label: this.$t('countries.LA'), value: 'LA'},
        {label: this.$t('countries.LS'), value: 'LS'},
        {label: this.$t('countries.LV'), value: 'LV'},
        {label: this.$t('countries.LB'), value: 'LB'},
        {label: this.$t('countries.LY'), value: 'LY'},
        {label: this.$t('countries.LR'), value: 'LR'},
        {label: this.$t('countries.LI'), value: 'LI'},
        {label: this.$t('countries.LT'), value: 'LT'},
        {label: this.$t('countries.LU'), value: 'LU'},
        {label: this.$t('countries.MK'), value: 'MK'},
        {label: this.$t('countries.MG'), value: 'MG'},
        {label: this.$t('countries.MY'), value: 'MY'},
        {label: this.$t('countries.MW'), value: 'MW'},
        {label: this.$t('countries.MV'), value: 'MV'},
        {label: this.$t('countries.ML'), value: 'ML'},
        {label: this.$t('countries.MT'), value: 'MT'},
        {label: this.$t('countries.MA'), value: 'MA'},
        {label: this.$t('countries.MQ'), value: 'MQ'},
        {label: this.$t('countries.MU'), value: 'MU'},
        {label: this.$t('countries.MR'), value: 'MR'},
        {label: this.$t('countries.YT'), value: 'YT'},
        {label: this.$t('countries.MX'), value: 'MX'},
        {label: this.$t('countries.MD'), value: 'MD'},
        {label: this.$t('countries.MC'), value: 'MC'},
        {label: this.$t('countries.MN'), value: 'MN'},
        {label: this.$t('countries.MS'), value: 'MS'},
        {label: this.$t('countries.ME'), value: 'ME'},
        {label: this.$t('countries.MZ'), value: 'MZ'},
        {label: this.$t('countries.MM'), value: 'MM'},
        {label: this.$t('countries.NA'), value: 'NA'},
        {label: this.$t('countries.NR'), value: 'NR'},
        {label: this.$t('countries.NI'), value: 'NI'},
        {label: this.$t('countries.NE'), value: 'NE'},
        {label: this.$t('countries.NG'), value: 'NG'},
        {label: this.$t('countries.NU'), value: 'NU'},
        {label: this.$t('countries.NO'), value: 'NO'},
        {label: this.$t('countries.NC'), value: 'NC'},
        {label: this.$t('countries.NZ'), value: 'NZ'},
        {label: this.$t('countries.NP'), value: 'NP'},
        {label: this.$t('countries.OM'), value: 'OM'},
        {label: this.$t('countries.UG'), value: 'UG'},
        {label: this.$t('countries.UZ'), value: 'UZ'},
        {label: this.$t('countries.PK'), value: 'PK'},
        {label: this.$t('countries.PW'), value: 'PW'},
        {label: this.$t('countries.PA'), value: 'PA'},
        {label: this.$t('countries.PG'), value: 'PG'},
        {label: this.$t('countries.PY'), value: 'PY'},
        {label: this.$t('countries.NL'), value: 'NL'},
        {label: this.$t('countries.BQ'), value: 'BQ'},
        {label: this.$t('countries.PH'), value: 'PH'},
        {label: this.$t('countries.PL'), value: 'PL'},
        {label: this.$t('countries.PF'), value: 'PF'},
        {label: this.$t('countries.PR'), value: 'PR'},
        {label: this.$t('countries.PT'), value: 'PT'},
        {label: this.$t('countries.PE'), value: 'PE'},
        {label: this.$t('countries.QA'), value: 'QA'},
        {label: this.$t('countries.HK'), value: 'HK'},
        {label: this.$t('countries.MO'), value: 'MO'},
        {label: this.$t('countries.RO'), value: 'RO'},
        {label: this.$t('countries.GB'), value: 'GB'},
        {label: this.$t('countries.RU'), value: 'RU'},
        {label: this.$t('countries.RW'), value: 'RW'},
        {label: this.$t('countries.CF'), value: 'CF'},
        {label: this.$t('countries.DO'), value: 'DO'},
        {label: this.$t('countries.EH'), value: 'EH'},
        {label: this.$t('countries.BL'), value: 'BL'},
        {label: this.$t('countries.KN'), value: 'KN'},
        {label: this.$t('countries.SM'), value: 'SM'},
        {label: this.$t('countries.MF'), value: 'MF'},
        {label: this.$t('countries.SX'), value: 'SX'},
        {label: this.$t('countries.PM'), value: 'PM'},
        {label: this.$t('countries.VC'), value: 'VC'},
        {label: this.$t('countries.SH'), value: 'SH'},
        {label: this.$t('countries.LC'), value: 'LC'},
        {label: this.$t('countries.SV'), value: 'SV'},
        {label: this.$t('countries.WS'), value: 'WS'},
        {label: this.$t('countries.AS'), value: 'AS'},
        {label: this.$t('countries.ST'), value: 'ST'},
        {label: this.$t('countries.RS'), value: 'RS'},
        {label: this.$t('countries.SC'), value: 'SC'},
        {label: this.$t('countries.SL'), value: 'SL'},
        {label: this.$t('countries.SG'), value: 'SG'},
        {label: this.$t('countries.SK'), value: 'SK'},
        {label: this.$t('countries.SI'), value: 'SI'},
        {label: this.$t('countries.SO'), value: 'SO'},
        {label: this.$t('countries.SD'), value: 'SD'},
        {label: this.$t('countries.SS'), value: 'SS'},
        {label: this.$t('countries.LK'), value: 'LK'},
        {label: this.$t('countries.CH'), value: 'CH'},
        {label: this.$t('countries.SR'), value: 'SR'},
        {label: this.$t('countries.SE'), value: 'SE'},
        {label: this.$t('countries.SJ'), value: 'SJ'},
        {label: this.$t('countries.SY'), value: 'SY'},
        {label: this.$t('countries.SN'), value: 'SN'},
        {label: this.$t('countries.TJ'), value: 'TJ'},
        {label: this.$t('countries.TZ'), value: 'TZ'},
        {label: this.$t('countries.TW'), value: 'TW'},
        {label: this.$t('countries.TD'), value: 'TD'},
        {label: this.$t('countries.CZ'), value: 'CZ'},
        {label: this.$t('countries.TF'), value: 'TF'},
        {label: this.$t('countries.IO'), value: 'IO'},
        {label: this.$t('countries.PS'), value: 'PS'},
        {label: this.$t('countries.TH'), value: 'TH'},
        {label: this.$t('countries.TL'), value: 'TL'},
        {label: this.$t('countries.TG'), value: 'TG'},
        {label: this.$t('countries.TK'), value: 'TK'},
        {label: this.$t('countries.TO'), value: 'TO'},
        {label: this.$t('countries.TT'), value: 'TT'},
        {label: this.$t('countries.TN'), value: 'TN'},
        {label: this.$t('countries.TM'), value: 'TM'},
        {label: this.$t('countries.TR'), value: 'TR'},
        {label: this.$t('countries.TV'), value: 'TV'},
        {label: this.$t('countries.UA'), value: 'UA'},
        {label: this.$t('countries.UY'), value: 'UY'},
        {label: this.$t('countries.VU'), value: 'VU'},
        {label: this.$t('countries.VE'), value: 'VE'},
        {label: this.$t('countries.VN'), value: 'VN'},
        {label: this.$t('countries.WF'), value: 'WF'},
        {label: this.$t('countries.YE'), value: 'YE'},
        {label: this.$t('countries.ZM'), value: 'ZM'},
        {label: this.$t('countries.ZW'), value: 'ZW'},
        {label: this.$t('countries.EG'), value: 'EG'},
        {label: this.$t('countries.AE'), value: 'AE'},
        {label: this.$t('countries.EC'), value: 'EC'},
        {label: this.$t('countries.ER'), value: 'ER'},
        {label: this.$t('countries.VA'), value: 'VA'},
        {label: this.$t('countries.FM'), value: 'FM'},
        {label: this.$t('countries.US'), value: 'US'},
        {label: this.$t('countries.ET'), value: 'ET'},
        {label: this.$t('countries.BV'), value: 'BV'},
        {label: this.$t('countries.CX'), value: 'CX'},
        {label: this.$t('countries.NF'), value: 'NF'},
        {label: this.$t('countries.IM'), value: 'IM'},
        {label: this.$t('countries.KY'), value: 'KY'},
        {label: this.$t('countries.CC'), value: 'CC'},
        {label: this.$t('countries.CK'), value: 'CK'},
        {label: this.$t('countries.FO'), value: 'FO'},
        {label: this.$t('countries.HM'), value: 'HM'},
        {label: this.$t('countries.FK'), value: 'FK'},
        {label: this.$t('countries.MP'), value: 'MP'},
        {label: this.$t('countries.MH'), value: 'MH'},
        {label: this.$t('countries.PN'), value: 'PN'},
        {label: this.$t('countries.SB'), value: 'SB'},
        {label: this.$t('countries.TC'), value: 'TC'},
        {label: this.$t('countries.VG'), value: 'VG'},
        {label: this.$t('countries.VI'), value: 'VI'},
        {label: this.$t('countries.UM'), value: 'UM'},
        {label: this.$t('countries.AX'), value: 'AX'},
      ],
    };
  },
  methods: {
    displayForCountryConst(value, returnLabel = value) {
      let result = this.countryConst.find((el) => el.value === value);
      return result ? result.label : returnLabel;
    },
  },
};
