export default {
  data() {
    return {
      crgStatutConst: [
        {label: 'Soldes négatifs', value: 'SOLDE_NEGATIF'},
        {label: 'Soldes positifs', value: 'SOLDE_POSITIF'},
        {label: 'Soldes à zéro', value: 'SOLDE_ZERO'},
        {label: 'Mandats résiliés', value: 'MANDAT_RESILIATED'},
      ],
    };
  },
  methods: {},
};
