import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';
import moment from 'moment/moment';

export default {
  comptaAccount(id) {
    return Api()
      .get('/comptaaccount/' + id)
      .then((res) => this.formatRes(res.data));
  },
  comptaAccounts(filter) {
    return Api()
      .get('/comptaaccounts', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  comptaAccountsWithOwnersName(filter) {
    return Api()
      .get('/comptaaccounts/autocomplete', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  byAccountNum(filter) {
    return Api()
      .get('/comptaaccounts/byaccountnum', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  balance(filter) {
    return Api()
      .get('/comptaaccounts/balance', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.accounts.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  thirdPartyAccount(filter) {
    return Api()
      .get('/comptaaccounts/thirdparty', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  exportBalance(filter) {
    return Api()
      .get('/comptaaccounts/balance/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  exportPdfBalance(filter) {
    return Api()
      .get('/comptaaccounts/balance/export/pdf', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  exportThirdPartyAccount(filter) {
    return Api()
      .get('/comptaaccounts/thirdparty/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  exportAccount(filter) {
    return Api()
      .get('/comptaaccounts/byaccountnum/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  create(comptaAccount) {
    return Api()
      .post('/comptaaccount', comptaAccount)
      .then((res) => this.formatRes(res.data));
  },
  update(comptaAccount) {
    return Api()
      .put('/comptaaccount/' + comptaAccount.id, comptaAccount)
      .then((res) => this.formatRes(res.data));
  },
  delete(comptaAccount) {
    return Api()
      .delete('/comptaaccount/' + comptaAccount.id)
      .then(() => comptaAccount);
  },
  formatRes(e) {
    if (e.date) {
      e.date = moment(e.date).utc(true).toDate();
    }
    return e;
  },
};
