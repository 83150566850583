<script>
import {debounce} from '@utils';

/* COMPOSANTS */
import AkAutocomplete from '@components/general/AkAutocomplete';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import housekeeperService from '@services/housekeeperService';

export default {
  components: {
    AkAutocomplete,
  },
  mixins: [loaderMixin, utilsMixin],
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: Number,
      required: false,
    },
    companyId: {
      type: Number,
      required: false,
      default: undefined,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      items: [],
      debounceFn: undefined,
    };
  },
  mounted() {
    if (this.currentValue !== undefined && this.currentValue !== null) {
      housekeeperService.housekeeper(this.currentValue).then((d) => {
        this.items.push(d);
        this.$refs.autocomplete.init(d);
      });
    }
    this.debounceFn = debounce((e) => this.search(e));
  },
  methods: {
    search(event) {
      let search = {query: event.query};
      if (this.companyId) {
        search.companyId = this.companyId;
      }
      housekeeperService.housekeepersForAutocomplete(search).then((data) => {
        this.items = data;
      });
    },
    reset() {
      this.$refs.autocomplete.reset();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <AkAutocomplete
    ref="autocomplete"
    v-model="currentValue"
    :inline="inline"
    :validator="validator"
    :submitted="submitted"
    :disabled="disabled"
    :label="label"
    :placeholder="placeholder"
    :className="className"
    option-label="display"
    option-value="id"
    :items="items"
    @search="debounceFn" />
</template>
