<script>
/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import upsellStatusConst from '@mixins/const/upsellStatusConst';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';

/* SERVICES */

export default {
  components: {
    AkAutocomplete,
    AkInputText,
    AkCalendar,
    MultiSelect,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    utilsMixin,
    sidebarMixin,
    permissionsMixin,
    upsellStatusConst,
    reservationAutocompleteMixin,
    clientAutocompleteMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      statuses: [],
    };
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      inline
      @item-select="itemSelect"
      label="Réservation"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation"
      :search-object="{accommodationId: this.currentValue.accommodationId}" />

    <AkInputText
      v-model="currentValue.name"
      inline
      @value-change="itemSelect"
      :display-label="false"
      :placeholder="$t('upsell.name')" />

    <AkCalendar
      v-model="currentValue.periodDateCheckin"
      inline
      @value-change="itemSelect"
      :placeholder="$t('reservation.periodCheckin')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.periodDateCheckout"
      inline
      @value-change="itemSelect"
      :placeholder="$t('reservation.periodCheckout')"
      date-format="dd/mm/yy"
      selection="range" />

    <AkCalendar
      v-model="currentValue.periodDateBuy"
      inline
      @value-change="itemSelect"
      :placeholder="$t('upsell.buyDate')"
      date-format="dd/mm/yy"
      selection="range" />

    <MultiSelect
      v-model="currentValue.statuses"
      :placeholder="$t('upsell.status')"
      :options="this.upsellStatusConstForFilter()"
      option-label="label"
      @change="itemSelect"
      option-value="value"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      inline />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
