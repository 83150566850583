export default {
  data() {
    return {
      prestationStatusConst: [
        {label: this.$t('prestation.status_todo'), value: 'ACCEPTED'},
        {label: this.$t('prestation.status_proposed'), value: 'PROPOSED'},
        {label: this.$t('prestation.status_partially_accepted'), value: 'PARTIALLY_ACCEPTED'},
        {label: this.$t('prestation.status_partially_canceled'), value: 'PARTIALLY_CANCELED'},
        {label: this.$t('prestation.status_to_plan'), value: 'TO_PLAN'},
        {label: this.$t('prestation.status_todo'), value: 'TODO'},
        {label: this.$t('prestation.status_todo'), value: 'TO_DO'},
        {label: this.$t('prestation.status_doing'), value: 'DOING'},
        {label: this.$t('prestation.status_completed'), value: 'COMPLETED'},
        {label: this.$t('prestation.status_completed'), value: 'FINISH'},
        {label: this.$t('prestation.status_canceled'), value: 'CANCELED'},
        {label: this.$t('prestation.status_litigation'), value: 'LITIGATION'},
        {label: this.$t('undefined'), value: undefined},
      ],
      prestationStatusFilterConst: [
        {label: this.$t('prestation.status_todo'), value: 'ACCEPTED'},
        {label: this.$t('prestation.status_proposed'), value: 'PROPOSED'},
        {label: this.$t('prestation.status_partially_accepted'), value: 'PARTIALLY_ACCEPTED'},
        {label: this.$t('prestation.status_partially_canceled'), value: 'PARTIALLY_CANCELED'},
        {label: this.$t('prestation.status_completed'), value: 'COMPLETED'},
        {label: this.$t('prestation.status_canceled'), value: 'CANCELED'},
      ],
      prestationStatusForPlanningIntervenant: [
        {label: this.$t('prestation.status_todo'), value: 'ACCEPTED'},
        {label: this.$t('prestation.status_completed'), value: 'COMPLETED'},
        {label: this.$t('prestation.status_canceled'), value: 'CANCELED'},
      ],
    };
  },
  methods: {
    displayForPrestationStatusConst(value) {
      let result = this.prestationStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    displayForPrestationStatusFilterConst(value) {
      let result = this.prestationStatusFilterConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
