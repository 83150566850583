export default {
  data() {
    return {
      reservationSpecifitiesConst: [
        {label: 'Arrivée physique', value: 'PHYSICAL'},
        {label: 'Linge inclus', value: 'LAUNDRY'},
      ],
    };
  },
  methods: {},
};
