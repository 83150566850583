<script>
import {useVuelidate} from '@vuelidate/core';
import {email, required} from '@vuelidate/validators';
import {isValidPhoneNumber} from 'libphonenumber-js';
import $ from 'jquery';

const phoneValidator = (value) => !value || isValidPhoneNumber(value, 'FR');

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkFilesUpload from '@components/v2/input/AkFilesUpload.vue';
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import companyMixin from '@mixins/companyMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import {useCompanyStore} from '@store/companyStore';

/* SERVICES */
import prestataireService from '@services/prestataireService';
import formatMixin from '@mixins/formatMixin';

export default {
  components: {
    AkButtonFooterSidebar,
    AkSidebar,
    AkFilesUpload,
    AkCalendar,
    GaMessages,
    AkDropdown,
    AkInputText,
  },
  mixins: [messageMixin, civilitiesConst, companyMixin, permissionsMixin, formatMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      poles: [],
      files: [],
      createSidebarVisible: false,
      prestataire: {},
      submitted: false,
    };
  },
  validations() {
    return {
      prestataire: {
        civility: {required},
        firstName: {required},
        lastName: {required},
        email: {required, email},
        phone: {required, phoneValidator: phoneValidator},
        homePhone: {phoneValidator: phoneValidator},
        companyId: {required},
      },
    };
  },
  emits: ['creation'],
  mounted() {
    if (this.isGav()) this.prestataire.companyId = this.myCompanyId();
  },
  methods: {
    show() {
      this.createSidebarVisible = true;
      this.$nextTick(() => {
        $(this.$refs.content).focus();
      });
    },
    hide() {
      this.createSidebarVisible = false;
      this.prestataire = {};
    },
    create() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      const prestataireDTO = {
        companyId: this.prestataire.companyId,
        contact: this.prestataire,
      };

      prestataireService
        .create(prestataireDTO, this.files)
        .then(() => {
          this.success(this.$t('prestataire.added'));
          this.prestataire = {};
          this.$emit('creation');
          this.hide();
        })
        .catch((e) => {
          this.$refs.sidebarMessages.error(this.$t('error.' + e.response.data.error));
          this.$refs.footerSidebar.init();
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <AkSidebar
    v-model:visible="createSidebarVisible"
    :baseZIndex="9998"
    :dismissable="true"
    @hide="hide"
    block-scroll
    position="right">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('prestataire.create') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div ref="content" class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4">
          <span class="title-border text-dark text-xl font-bold mb-3">Informations</span>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2">
            <AkDropdown
              v-model="prestataire.civility"
              :label="$t('prestataire.civility')"
              :options="civilitiesConst"
              :submitted="submitted"
              :validator="v$.prestataire.civility" />
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2">
            <AkInputText
              v-model="prestataire.lastName"
              :label="$t('prestataire.lastName')"
              :submitted="submitted"
              :formatter="toUppercase"
              :validator="v$.prestataire.lastName" />
            <AkInputText
              v-model="prestataire.firstName"
              :label="$t('prestataire.firstName')"
              :submitted="submitted"
              :formatter="toUppercase"
              :validator="v$.prestataire.firstName" />
          </div>
          <div class="grid grid-cols-1 gap-4 md:gap-2">
            <AkCalendar
              v-model="prestataire.birthDate"
              :label="$t('prestataire.birthDate')"
              :submitted="submitted"
              :validator="v$.prestataire.birthDate"
              :date-format="'dd/mm/yy'"
              :maxDate="new Date()" />
          </div>
          <span class="title-border text-dark text-xl font-bold mb-3">Coordonnées</span>
          <div class="grid grid-cols-1 gap-4 md:gap-2">
            <AkInputText
              v-model="prestataire.email"
              :label="$t('prestataire.email')"
              :submitted="submitted"
              :validator="v$.prestataire.email"
              type="email"
              icon="ga-icon ga-email" />
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2">
            <AkInputText
              v-model="prestataire.phone"
              :label="$t('prestataire.phone')"
              :submitted="submitted"
              :validator="v$.prestataire.phone"
              type="tel"
              icon="ga-icon ga-call" />
            <AkInputText
              v-model="prestataire.homePhone"
              :label="$t('prestataire.homePhone')"
              :submitted="submitted"
              :validator="v$.prestataire.homePhone"
              type="tel"
              icon="ga-icon ga-call" />
          </div>
          <template v-if="isGas()">
            <span class="title-border text-dark text-xl font-bold mb-3">Informations complémentaires</span>
            <AkDropdown
              v-model="prestataire.companyId"
              :label="$t('prestataire.company')"
              :options="companies"
              :submitted="submitted"
              :validator="v$.prestataire.companyId"
              option-label="name"
              option-value="id" />
          </template>
          <span class="title-border text-dark text-xl font-bold mb-3">{{ $t('files') }}</span>
          <AkFilesUpload v-model="files" multiple />

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancel-label="$t('cancel')"
            :validate-label="$t('add')"
            @action="create()"
            @cancel="hide()" />
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
