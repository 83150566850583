<script>
import {Icon} from '@iconify/vue';
import ficService from '@services/ficService';
import FileUpload from 'primevue/fileupload';
import Avatar from 'primevue/avatar';
import EnvConst from '@mixins/const/envConst';

export default {
  components: {
    Avatar,
    FileUpload,
  },
  mixins: [EnvConst],
  emits: ['picture-update'],
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    canUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'large',
    },
    className: {
      type: String,
      required: false,
      default: 'profile',
    },
  },
  data() {
    return {
      base64data: undefined,
      uploading: false,
    };
  },
  computed: {
    getInitiale() {
      return `${this.firstName ? this.firstName.charAt(0).toUpperCase() : ''}${
        this.lastName ? this.lastName.charAt(0).toUpperCase() : ''
      }`;
    },
  },
  mounted() {
    this.loadPicture();
  },
  methods: {
    loadPicture() {
      ficService
        .displayUser(this.userId)
        .then((data) => {
          this.base64data = data;
        })
        .catch(() => {});
    },
    uploadPicture() {
      if (this.canUpload) this.$refs.uploader.$refs.fileInput.click();
    },
    selectFiles(event) {
      if (event.files) {
        this.uploading = true;
        ficService.uploadUserPicture(event.files[0]).finally(() => {
          this.loadPicture();
          this.uploading = false;
          this.$refs.uploader.clear();
          this.$emit('picture-update');
        });
      }
    },
  },
};
</script>

<template>
  <div class="flex justify-center">
    <div :class="[className, size && 'is-' + this.size]" class="group relative">
      <div
        class="group-[.is-medium]:w-38 group-[.is-medium]:h-38 group-[.is-large]:w-40 group-[.is-large]:h-40 rounded-full">
        <Avatar
          v-if="!base64data"
          :label="getInitiale"
          :size="this.size"
          shape="circle"
          class="w-full h-full"
          @click="uploadPicture" />
        <img v-if="base64data" :src="base64data" class="w-full h-full object-center object-cover rounded-full" />
      </div>

      <div
        v-if="canUpload"
        class="absolute cursor-pointer group-[.is-large]:bottom-[-21px] group-[.is-large]:left-[calc(50%-21px)] bg-white rounded-full group-[.is-large]:w-[42px] group-[.is-large]:h-[42px] flex items-center justify-center"
        @click="uploadPicture">
        <i class="ga-icon ga-camera text-[28px]" v-if="!this.uploading" />
        <vue-loaders
          color="var(--color-dark)"
          :scale="0.75"
          class="flex items-center justify-center"
          name="ball-clip-rotate"
          v-else />
      </div>
    </div>
    <div class="clearboth" />
    <FileUpload
      v-if="canUpload"
      ref="uploader"
      :auto="true"
      :cancel-label="$t('cancel_file')"
      :choose-label="$t('add_file')"
      accept="image/*"
      :custom-upload="true"
      :invalidFileSizeMessage="$t('file.err_size')"
      :maxFileSize="getMaxFileUpload()"
      :multiple="false"
      :show-upload-button="false"
      class="hidden"
      mode="basic"
      name="uploader"
      @select="selectFiles" />
  </div>
</template>
