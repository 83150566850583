<script>
/* COMPOSANTS */
import PrestationBadge from '@components/general/PrestationBadge.vue';
import PrestationStatusBadge from '@components/general/PrestationStatusBadge.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import missionOrderService from '@services/missionOrderService';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import GaView from '@components/v2/layout/GaView.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import filterListMixin from '@mixins/filterListMixin';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import companyMixin from '@mixins/companyMixin';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import missionMixin from '@mixins/missionMixin';
import {useTabStore} from '../../../store/tabStore';

export default {
  components: {
    AkAutocompleteFilter,
    AkAllFilter,
    AkListFilter,
    GaListView,
    AkDatatablePagination,
    GaView,
    PrestationBadge,
    PrestationStatusBadge,
  },
  mixins: [
    permissionsMixin,
    missionMixin,
    utilsMixin,
    sidebarMixin,
    filterListMixin,
    companyMixin,
    ownerAutocompleteMixin,
    reservationAutocompleteMixin,
  ],
  metaInfo() {
    return {
      title: 'mission_order.title',
    };
  },
  data() {
    return {
      list: [],
      loading: false,
      textSearch: '',
      filter: {
        page: 1,
        limit: 50,
        sort: ['date_start:DESC'],
      },
      totalItems: 0,
      activeTab: 1,
    };
  },
  watch: {
    activeTab: function () {
      this.filter.page = 1;
      this.search();
    },
  },
  mounted() {
    this.activeTab = useTabStore().getTabActive('MissionOrderList', 1);
  },
  computed: {
    getStatusFromTab() {
      if (this.activeTab === 1) {
        return 'PROPOSED';
      }
      if (this.activeTab === 2) {
        return 'ACCEPTED';
      }
      if (this.activeTab === 3) {
        return 'COMPLETED';
      }
      if (this.activeTab === 4) {
        return 'LITIGATION';
      }
      if (this.activeTab === 5) {
        return 'CANCELED';
      }
      return '';
    },
    isActiveTabCanceled() {
      return this.activeTab === 5;
    },
    first() {
      return (this.filter.page - 1) * this.filter.limit;
    },
  },
  methods: {
    rowClick(event) {
      this.$router.push({
        name: 'mission-order-detail',
        params: {missionOrderId: event.data.id},
      });
    },
    search() {
      this.loading = true;

      this.filter.status = this.getStatusFromTab;

      missionOrderService
        .pagination(this.filter)
        .then((data) => {
          this.totalItems = data.totalItems;
          this.list = data.data;
        })
        .finally(() => (this.loading = false));
    },
    // FILTER
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaMissionOrderListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['date_start:DESC'],
      };
      this.toggleFilterTitle(
        'GaMissionOrderListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    displayPeriodForReservation(data) {
      if (data) return this.displayDatesSeparator(data.dateCheckin, data.dateCheckout, false);
      return '-';
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
      useTabStore().setTabActive('MissionOrderList', tab);
    },
  },
};
</script>

<template>
  <GaView>
    <template #content>
      <GaListView searchable @search="searchQuery">
        <template #tabs>
          <div class="flex items-center">
            <span class="list-table-item" @click="this.changeActiveTab(1)" :class="[{active: this.activeTab === 1}]">
              {{ this.$t('mission_order.tabs.proposed') }}
            </span>
            <span class="list-table-item" @click="this.changeActiveTab(2)" :class="[{active: this.activeTab === 2}]">
              {{ this.$t('mission_order.tabs.accepted') }}
            </span>
            <span class="list-table-item" @click="this.changeActiveTab(3)" :class="[{active: this.activeTab === 3}]">
              {{ this.$t('mission_order.tabs.completed') }}
            </span>
            <span class="list-table-item" @click="this.changeActiveTab(5)" :class="[{active: this.activeTab === 5}]">
              {{ this.$t('mission_order.tabs.canceled') }}
            </span>
            <span class="list-table-item" @click="activeTab = 0" :class="[{active: this.activeTab === 0}]">
              {{ this.$t('mission_order.tabs.all') }}
            </span>
          </div>
        </template>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.clickSearch"
            :label="$t('role.owner')"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAutocompleteFilter
            v-model="this.filter.reservationId"
            @search="this.clickSearch"
            label="Réservation"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteReservationById"
            :search-method="this.autocompleteReservation" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :value="list"
            lazy
            :filter="this.filter"
            @search="search"
            :loading="loading"
            class="cursor-pointer"
            :totalRecords="totalItems"
            @row-click="rowClick($event)">
            <template #empty> {{ this.$t('mission_order.empty') }}</template>

            <Column
              :header="$t('mission_order.reservation')"
              style="width: 200px; min-width: 200px"
              sortable
              sort-field="j_reservation_num">
              <template #body="slotProps">
                <router-link
                  v-if="slotProps.data.reservation"
                  :to="{
                    name: 'reservationPage',
                    params: {
                      reservationId: slotProps.data.reservation.id,
                    },
                  }">
                  {{ slotProps.data.reservation?.reservationNum ?? '-' }}
                </router-link>
                <span v-else>-</span>
              </template>
            </Column>

            <Column
              :header="$t('mission_order.mission_status')"
              style="width: 150px; min-width: 150px"
              class="text-center"
              body-class="m-auto">
              <template #body="slotProps">
                <PrestationStatusBadge :status="slotProps.data.statusParticipants" />
              </template>
            </Column>

            <Column
              :header="$t('mission_order.assigned_staff')"
              style="width: 200px; min-width: 200px"
              sortable
              sort-field="assigned_staff">
              <template #body="slotProps">
                <span class="border border-gray rounded-full p-2 flex gap-2 items-center justify-between">
                  {{ this.displayForPersonnelTypeConst(slotProps.data.assignedStaff) }}
                  <span class="bg-primary text-white rounded-full flex items-center justify-center w-6 h-6">
                    {{ slotProps.data.participantNumber }}</span
                  >
                </span>
              </template>
            </Column>
            <Column
              :header="$t('mission_order.date_created')"
              style="width: 150px; min-width: 150px"
              sortable
              sort-field="date_created">
              <template #body="slotProps">
                {{ new Date(slotProps.data.dateCreated).toLocaleDateString() }}
              </template>
            </Column>

            <Column
              :header="$t('company.one')"
              v-if="this.isGas()"
              style="width: 200px; min-width: 200px"
              sort-field="j_accommodation_company_id">
              <template #body="slotProps">
                {{ slotProps.data.companyName }}
              </template>
            </Column>

            <Column
              :header="$t('mission_order.accommodation')"
              style="min-width: 200px"
              sortable
              sort-field="j_accommodation_name">
              <template #body="slotProps">
                <router-link
                  :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.accommodation.id}}">
                  {{ slotProps.data.accommodation.name }}
                </router-link>
                <div>
                  {{ slotProps.data.accommodation.address }}
                </div>
                <div>
                  {{ slotProps.data.accommodation.postalCode + ' ' + slotProps.data.accommodation.city }}
                </div>
              </template>
            </Column>

            <Column
              :header="$t('mission_order.date_start')"
              style="width: 150px; min-width: 150px"
              sortable
              sort-field="date_start">
              <template #body="slotProps">
                {{ slotProps.data.dateStart ? new Date(slotProps.data.dateStart).toLocaleDateString() : '-' }}
              </template>
            </Column>

            <Column
              :header="$t('reservation.period')"
              style="width: 150px; min-width: 150px"
              sortable
              sort-field="j_reservation_date_checkin">
              <template #body="slotProps">
                {{ this.displayPeriodForReservation(slotProps.data.reservation) }}
              </template>
            </Column>

            <Column
              :header="$t('mission_order.date_cancel')"
              sortable
              sort-field="cancellation_date"
              v-if="this.isActiveTabCanceled"
              style="width: 200px; min-width: 200px">
              <template #body="slotProps">
                {{ this.displayDateTime(slotProps.data.cancellationDate) }}
              </template>
            </Column>

            <Column
              :header="$t('mission_order.canceller')"
              v-if="this.isActiveTabCanceled"
              style="width: 200px; min-width: 200px">
              <template #body="slotProps">
                {{ slotProps.data.cancellationUsername }}
              </template>
            </Column>

            <Column
              :header="$t('mission_order.type')"
              style="min-width: 200px; width: 200px"
              sortable
              sort-field="type">
              <template #body="slotProps">
                <PrestationBadge :type="slotProps.data.type" :key="slotProps.data.type" />
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
